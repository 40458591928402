import { ReactNode, useCallback, useEffect, useState } from "react";
import { useTheme } from "@mui/material";

export default function SalesforceWrapper({ children, embeddedHeight }: {
    embeddedHeight?: number,
    children: ReactNode
}) {
    const [client, setClient] = useState<object>();
    const [height, setHeight] = useState(embeddedHeight);
    const theme = useTheme();

    const loadJavascript = (src, onLoadSuccessCallback, onLoadErrorCallback) => {
        const js = document.createElement("script");
        js.src = src;
        js.setAttribute("async", "");

        // Setup success callback
        if (onLoadSuccessCallback) {
            js.onload = onLoadSuccessCallback;
        }

        // Setup error callback
        if (onLoadErrorCallback) {
            js.onerror = onLoadErrorCallback;
        }

        // Add the script tag to <head>
        document.head.appendChild(js);
    };

    // const getContext = useCallback(() => {
    //     const callback = (msg) => {
    //         console.log('context callback', msg)
    //         if (msg.status !== 200) {
    //             alert("Error: " + msg.status);
    //             return;
    //         }
    //         alert("Payload: ", msg.payload);
    //     }

    //     console.log('get context', client);
    //     if (client != null) {
    //         window.Sfdc.canvas.client.ctx(callback, client);
    //     }

    //     setTimeout(getContext, 1000);
    // }, [client]);

    // useEffect(() => {
    //     if (!client) {
    //         console.log('no client');
    //         return;
    //     }

    //     getContext();
    // }, [client]);

    useEffect(() => {
        console.log("updateCanvasHeight", client, height);
        if (!client) return;

        const cli = window["Sfdc"]["canvas"]["client"];
        if (!cli) {
            console.error("cli not found");
            return;
        }

        const newSize = { height: `${height}px` };
        const result = cli.resize(client, newSize);
        console.log("resized canvas", newSize, result);

        if (!embeddedHeight) {
            localStorage.setItem("salesforce.canvas.height", `${height}`);
        }

    }, [client, height]);

    const initSalesforceCanvas = () => {
        console.log("Load canvas script");

        const secondaryAuth = sessionStorage.getItem("secondaryAuth");
        if (!secondaryAuth) {
            console.error("missing secondaryAuth");
            return;
        }

        const clientJson = JSON.parse(atob(secondaryAuth));
        if (!clientJson.oauthToken) {
            console.error("invalid auth", client);
            return;
        }

        loadJavascript(
            "canvas-all.js",
            () => {
                const cli = window["Sfdc"]["canvas"]["client"];
                if (!cli) {
                    console.error("cli not found");
                    return;
                }
                console.log("canvas client", clientJson);
                setClient(clientJson);

                // cli.autogrow(clientJson, true, 100);
                // window.Sfdc.canvas.client.resize(client, { height: "400px", width: "400px" });

                // window.Sfdc.canvas.client.subscribe(client,
                //     {
                //         name: 'canvas.scroll', onData: function (event) {
                //             console.log("Parent's contentHeight; " + event.heights.contentHeight);
                //             console.log("Parent's pageHeight; " + event.heights.pageHeight);
                //             console.log("Parent's scrollTop; " + event.heights.scrollTop);
                //             console.log("Parent's contentWidth; " + event.widths.contentWidth);
                //             console.log("Parent's pageWidth; " + event.widths.pageWidth);
                //             console.log("Parent's scrollLeft; " + event.widths.scrollLeft);
                //         }
                //     }
                // );

                // window.Sfdc.canvas.client.subscribe(client,
                //     {
                //         name: 'canvas.orientation',
                //         onData: function (event) {
                //             console.log("Parent's orientation: " + event.orientation +
                //                 "Canvas app height: " + event.clientHeight +
                //                 "Canvas app width: " + event.clientWidth);
                //         }
                //     });

                console.log("canvas size", cli.size());

                if (!embeddedHeight) {
                    const hStr = localStorage.getItem("salesforce.canvas.height");
                    try {
                        console.log("load height");
                        const h = hStr ? Number.parseInt(hStr) : 500;
                        console.log("height", hStr, h);
                        setHeight(h);
                    } catch (e) {
                        console.error(e);
                    }
                }

            },
            () => {
                console.error("error loading script");
            }
        );
    };

    const onChangeHeight = useCallback(() => {
        if (!client || !height) return;
        const h = height < 1200 ? height + 100 : 500;
        setHeight(h);
    }, [height, client]);

    useEffect(() => {
        initSalesforceCanvas();
    }, []);

    const color = theme.palette.primary.main;

    return (
        <>
            {children}
            {!embeddedHeight && (
                <div onClick={onChangeHeight} style={{
                    position: "fixed",
                    bottom: 0,
                    right: 0,
                    zIndex: 9999,
                    borderRight: `8px solid ${color}`,
                    borderBottom: `8px solid ${color}`,
                    width: 32,
                    height: 64
                }}>&nbsp;</div>
            )}
        </>
    );
}