import LaunchIcon from '@mui/icons-material/Launch';
import LinkIcon from '@mui/icons-material/Link';
import PreviewIcon from '@mui/icons-material/Preview';
import { URI } from 'src/pi/api/URI';


export function LinkUrlIcon({ url }: { url?: string; }) {
    if (!url) return null;

    try {
        const linkUrl = new URI(url);
        switch (linkUrl.scheme) {
            case "openphone:": // return <img src="icons/openphone48.png" width="24"/>
            case "waze:": // ...
            case "mailto:":
            case "tel:":
            case "sms:":
            case "callto:":
            case "http:":
            case "https:":
                return <LaunchIcon />;
            case "page:":
                return <LinkIcon />;
            case "dataform:": // will load on top of waiting page
                return <PreviewIcon />;
            case "dataview:": // may work
                break;
        }
    } catch (e) {
        console.error('failed to parse url', e, url);
    }

    return <LinkIcon />;
}
