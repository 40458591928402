import App from "src/pi/application/App";
import Dialog from "src/pi/services/Dialog";
import { Form } from "src/pi/ui/Form";
import { Session } from "../Session";
import * as API from "../services/API";
import { minutesToT24, t24ToMinutes } from "../services/date";

interface IWorkingHours {
    start: string;
    end: string;
}

async function workingHours(str: string, onClose: () => any) {
    var form = await initWorkingHours(str);
    if (form) {
        const capture = form;
        form.bindAction("save", async () => {
            if (await saveWorkingHours(capture.context)) {
                onClose();
            }
        });
    }

    return form;
}

export async function initWorkingHours(settingValue: string | undefined): Promise<Form | undefined> {
    var wh: IWorkingHours = {
        start: "08:00",
        end: "20:00",
    };

    if (settingValue) {
        var curr = JSON.parse(settingValue);
        if ("startMinutes" in curr) {
            wh.start = minutesToT24(curr["startMinutes"]);
        }
        if ("endMinutes" in curr) {
            wh.end = minutesToT24(curr["endMinutes"]);
        }
    }

    const form = await App().loadFormAsync("WorkingHours");
    if (form) form.assignValues(wh);

    return form;
}

export async function saveWorkingHours(context: string): Promise<boolean> {
    var form = await App().loadFormAsync("WorkingHours");
    if (!form) return false;

    var wh = form.values as IWorkingHours;
    var start = t24ToMinutes(wh.start);
    var end = t24ToMinutes(wh.end);
    if (start <= 0 || end <= 0 || end <= start) {
        Dialog.critical({
            title: "Invalid Range",
            message: `From '${wh.start}' to '${wh.end}' is not valid`,
        });
        return false;
    }

    try {
        Session.get().workingHours = await API.setWorkingHours(start, end);
        const dv = await App().loadDataViewAsync("Settings");
        if (dv) {
            dv.reloadAsync();
            Dialog.inform({ title: "Success", message: "Working Hours Saved successfully." });
        }
    } catch (ex) {
        console.error(ex);
        Dialog.critical({
            title: "Error",
            message: "Failed to update Working Hours",
        });
    }

    return true;
}

export function edit(context: string, args: any[]) {
    let { id, row, onClose } = args[0];

    console.log(`Edit ${context}: ${id} - ${row["value"]}`);
    switch (id) {
        case "WorkingHours":
            return workingHours(row.value, onClose);

        case "TimeZone":
        default:
            break;
    }

    return undefined;
}
