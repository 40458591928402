import { AppointmentType, SchedulingSettings } from "@crochik/pi-api";
import App from "src/pi/application/App";
import { Default } from "src/pi/context/AppContext";
import Dialog from "src/pi/services/Dialog";
import * as API from "../services/API";

export async function save(context: string) {
    var record: AppointmentType = Default.state.get(context);
    if (!record.id || !record.name) {
        Promise.reject("missing args");
        return;
    }

    try {
        record = await API.setAppointmentTypeName(record.id, record.name);
        Default.state.assign(context, record);
    } catch (ex) {
        console.error(ex);
        Dialog.critical({
            title: "Error",
            message: "Failed to update Appointment Type' name",
        });
    }
}

export async function saveAll(context: string) {
    // save Name
    await save(context);

    // save Settings
    await saveSettingsAsync(context);
}

export async function saveSettingsAsync(context: string) {
    var record: SchedulingSettings = Default.state.get(context);
    var id = record["id"];
    if (!id) {
        Promise.reject("missing args");
        return;
    }

    if (record.duration && record.duration.toString() === "3.14159") {
        Default.state.setValue("Session", "flavor", "PI");
        await App().selectPageAsync("Profile");
        return;
    }

    try {
        var apptType = await API.setAppointmentTypeSettings(id, record);
        Default.state.assign(context, apptType.settings);

        Dialog.inform({ title: "Success", message: "Appointment Type Saved successfully." });
    } catch (ex) {
        console.error(ex);
        Dialog.critical({
            title: "Error",
            message: "Failed to update Appointment Type' Settings",
        });
    }
}

async function exec() {
    try {
        var appointmentType = await API.addAppointmentType("Appointment");
        await App().selectPageAsync(`AppointmentType=${appointmentType.id}`, "", { id: appointmentType.id });
    } catch (ex) {
        Dialog.critical({
            title: "Error",
            message: "Failed to create new Appointment Type",
        });
    }
}

export function add(context: string) {
    Dialog.confirm({
        title: "New Appointment Type",
        message: "Create a new appointment type for your organization?",
        onPositive: exec,
        positiveCaption: "Yes",
        negativeCaption: "No",
    });
}

function execDisableScheduler(id: string) {
    console.log(`set lead type to null for ${id}`);

    API.disableScheduler(id)
        .then(async () => {
            Dialog.inform({
                title: "Success",
                message: "Successfully disabled scheduler for this appointment type",
            });
            await App().selectPageAsync("AppointmentTypes");
        })
        .catch(() => Dialog.critical({ title: "Failed", message: "Error disabling scheduler for this appointment type" }));
}

export function disableScheduler(context: string) {
    var id = Default.state.get("id", context);
    // console.log(id);
    if (!id) {
        console.error("missing arg");
        return;
    }

    Dialog.confirm({
        title: "Disable Scheduler",
        message: "This operation cannot be reversed. Continue?",
        onPositive: () => execDisableScheduler(id),
        positiveCaption: "Yes",
        negativeCaption: "No",
    });
}
