import { MaskTextField } from "./MaskTextField";
import { postalCode } from "./TextMask";

export class PostalCodeField extends MaskTextField {
    getInputProps() {
        var props = {
            ...super.getInputProps(),
            type: "text",
            inputComponent: postalCode,
        };

        return props;
    }
}
