import Typography from "@mui/material/Typography";
import { observer } from "mobx-react";
import * as React from "react";

import app, { App } from "../../../application/App";
import ActionMenuBar from "../ActionMenuBar";
import { NotificationMenu } from "../NotificationMenu";

interface Props {
    embedded?: boolean;
}

@observer
export default class TitleBarComponent extends React.Component<Props> {
    app: App;

    constructor(props: Props) {
        super(props);
        this.app = app();
    }

    renderTitle() {
        return (
            <Typography variant="h6" noWrap={true} color="inherit">
                {this.app.title}
            </Typography>
        );
    }

    render() {
        const { embedded } = this.props;

        if (this.app.page) {
            return (
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                    {this.renderTitle()}
                    {this.app.pageMenu &&
                        <ActionMenuBar context={`page.${this.app.page.name}`} menu={this.app.pageMenu} />
                    }
                    {!embedded &&
                        <NotificationMenu />
                    }
                </div>
            );
        }

        return this.renderTitle();
    }
}
