import { Alert } from "@mui/material";
import React, { Suspense, useEffect, useLayoutEffect, useRef, useState } from "react";
import { UnlayerOptions } from "react-email-editor";
import * as api from "@crochik/pi-api";
import * as sendgridApi from "src/apis/sendgrid";
import App from "src/pi/application/App";
import { useApplicationContext } from "src/pi/application/ApplicationContext";
import { Action } from "src/pi/context/IForm";
import DataService, { IDataFormActionRequest } from "src/pi/services/DataService";
import { Form } from "src/pi/ui/Form";
import { FormDialog } from "../../FormDialog";
import { Loading } from "../../Loading";
import { UnlayerTemplate } from "./UnlayerTemplate";
import { Client, parseResponseError } from "../../../../api/Client";
import { URI } from "../../../../api/URI";

const Editor = React.lazy(() => import("react-email-editor"));

export interface IProps {
    id?: string;
    showSave: boolean;
    onDone: (id: string | undefined) => void;
    onStopSaving: () => void;
    initValues?: object;
}

export function UnlayerTemplateEditor({ showSave, onDone, onStopSaving: onDiscard, id, initValues }: IProps) {
    const emailEditorRef = useRef<any>(null);
    const parentRef = useRef<any>(null);
    const [height, setHeight] = useState(1000);
    const [session, setSession] = useState<sendgridApi.UnlayerSession>();
    const [unlayerOptions, setUnlayerOptions] = useState<UnlayerOptions>();
    const [saveForm, setSaveForm] = useState<Form>();
    const [template, setTemplate] = useState<UnlayerTemplate>();
    const [isLoading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>();

    const appContext = useApplicationContext();

    useEffect(() => {
        if (!appContext.clientConfiguration){
            return;
        }

        new sendgridApi.UnlayerApi(appContext.clientConfiguration)
            .get(initValues?.["TemplateType"], initValues?.["TemplatedObjectId"])
            .then((result) => {
                setSession(result);

                const options = {
                    user: {
                        id: result?.userId,
                        signature: result?.signature,
                    },
                    mergeTags: result?.mergeTags,
                    features: {
                        ai: {
                            smartButtons: true,
                            smartHeadings: true,
                            magicImage: false,
                            smartText: true,
                        },
                        stockImages: true,
                        // sendTestEmail: true,
                    },
                    tools: {
                        heading: {
                            enabled: false,
                        },
                    },
                };

                setUnlayerOptions(options as any);
            })
            .catch((reason) => {
                console.error(reason);
                parseResponseError(reason).then(error => setError(error));
            });
    }, [appContext?.clientConfiguration, initValues]);

    useEffect(() => {
        if (!id) {
            setLoading(false);
            return;
        }

        if (!appContext?.apiConfig) {
            setError("Failed to get app context");
            return;
        }

        new api.CustomObjectApi(appContext.apiConfig)
            .customObjectGetObject("UnlayerTemplate", id)
            .then((obj) => {
                setTemplate(obj as any as UnlayerTemplate);
                setLoading(false);
            })
            .catch((reason) => {
                setError("Error loding template");
            });
    }, [id, appContext?.apiConfig]);

    useLayoutEffect(() => {
        if (!parentRef?.current) return;

        const resizeObserver = new ResizeObserver((event) => {
            const h = event[0].contentBoxSize[0].blockSize;
            setHeight(h);
        });

        resizeObserver.observe(parentRef.current);
    }, []);

    const onLoad = () => {
        emailEditorRef.current.editor.registerCallback("image", function (file, done) {
            if (!appContext?.clientConfiguration) {
                setError("can't upload");
                return;
            }

            const client = new Client(appContext.clientConfiguration.basePath, appContext.clientConfiguration.accessToken);

            var formData = new FormData();
            formData.append("file", file.attachments[0]);
            const url = `/sendgrid/v1/Unlayer/Upload`;
            client
                .postForm<sendgridApi.UnlayerImageUploadResponse>(url, formData)
                .then((response) => {
                    console.log("upload successfull", response);
                    done({ progress: 100, url: response.url });
                })
                .catch((reason) => {
                    console.error("failed to upload image");
                });
        });

        if (template?.Design) {
            console.log("load design", template?._id);
            const templateJson = JSON.parse(template.Design);
            emailEditorRef.current.editor.loadDesign(templateJson);

            delete template?.Design;
        }
    };

    const onReady = () => {
        // editor is ready
        // ...
    };

    useEffect(() => {
        if (!showSave) {
            setSaveForm(undefined);
            return;
        }

        if (!emailEditorRef.current) return;

        const url = new URI(`dataform:/api/v1/CustomObject/UnlayerTemplate/Add`);
        if (initValues) {
            Object.keys(initValues).forEach(name => {
                url.searchParams.set(name, initValues[name].toString());
            });
        }
        const urlPath = url.getPathAndQuery();
        const formPromise = App().loadFormAsync(urlPath);

        emailEditorRef.current.editor.exportHtml(async (data) => {
            const { design, html } = data;
            const form = await formPromise;
            if (!form) return;

            const formObj = Form.create(form, urlPath);
            const values = {
                ...template,
                Design: JSON.stringify(design, null, 2),
                Html: html,
                PreviousVersionId: template?._id,
            };
            delete values._id;

            formObj.assignValues(values);

            console.log("save", template, values);

            setSaveForm(formObj);
        });
    }, [showSave, template, initValues]);

    const onCloseSaveFormAsync = () => {
        setSaveForm(undefined);
        onDiscard();
        return Promise.resolve();
    };

    const onSaveAsync = async (action: Action) => {
        if (!saveForm || !saveForm.url) {
            console.error("missing form");
            return;
        }

        const request: IDataFormActionRequest = {
            // action: action.action,
            action: "Add",
            parameters: saveForm.getValues(),
            selectedIds: [],
        };

        const result = await DataService().dataFormActionAsync(saveForm.url, request);
        if (!result.success) {
            alert(result.message);
            return;
        }

        if (result.ids?.length !== 1) {
            console.error("didn't get id?");
            return;
        }

        await onCloseSaveFormAsync();

        onDone(result.ids[0]);
    };

    return (
        <>
            <div ref={parentRef} style={{ position: "relative", width: "100%", height: "100%", overflow: "hidden" }}>
                {error && <Alert severity="error">{error}</Alert>}
                {isLoading && !error && <Loading />}
                {session && unlayerOptions && (
                    <Suspense fallback={<Loading />}>
                        <Editor
                            style={{ minHeight: height }}
                            ref={emailEditorRef}
                            onLoad={onLoad}
                            onReady={onReady}
                            projectId={session.projectId}
                            options={unlayerOptions}
                        />
                    </Suspense>
                )}
            </div>
            {saveForm && <FormDialog form={saveForm} onCloseAsync={onCloseSaveFormAsync} onActionAsync={onSaveAsync} />}
        </>
    );
}
