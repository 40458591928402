import { observable, action } from "mobx";

import { Default } from "../context/AppContext";
import { Service } from "../context/Services";

export interface LastError {
    visible: boolean;
    title?: string;
    message?: string;
    critical?: boolean;
    onClose?: () => any;
}

export class ErrorService extends Service {
    lastError: LastError;

    constructor() {
        super();

        this.lastError = observable({
            title: undefined,
            message: undefined,
            cirtical: undefined,
            onClose: undefined,
            visible: false,
        });
    }

    @action
    message(message: any, critical: boolean, title?: string, onClose?: () => any) {
        this.lastError.message = message;
        this.lastError.title = title;
        this.lastError.onClose = onClose;
        this.lastError.critical = critical;
        this.lastError.visible = true;
    }

    critical(message: any, title?: string, onClose?: () => any) {
        this.message(message, true, title, onClose);
    }

    error(message: any, title?: string, onClose?: () => any) {
        this.message(message, false, title, onClose);
    }
}

export function register() {
    Default.services.register(ErrorService, new ErrorService());
}

export default function get(): ErrorService {
    return Default.services.getService(ErrorService);
}
