import { FormControl } from "@mui/material";
import MuiTextField from "@mui/material/TextField";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from "moment";
import { InputTextField } from "./InputTextField";

export class DateField extends InputTextField {
    protected onChange = (value: moment.Moment | null, keyboardInputValue?: string) => {

        if (this.props.onChange) {
            this.props.onChange(this.props.field, value?.format("YYYY-MM-DDTHH:mm") ?? null);
        }
    };

    render() {
        let { value } = this.props;
        if (value) {
            value = moment(value).format("YYYY-MM-DDTHH:mm");
        }

        const props = {
            ...this.calcProps(),
            fullWidth: true,
            type: "date",
            value,
        };

        if (!props.value && props.disabled) {
            // prevent rendering place holder
            props.type = "text";

            return <MuiTextField {...props} variant="filled" />;
        }

        return (
            <div
                style={{
                    display: "flex",
                    paddingTop: 16,
                    paddingBottom: 8,
                    // ...style,
                }}
                key={this.props.field.name}
            >
                <FormControl style={{ width: "100%" }} >
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            value={value}
                            onChange={this.onChange}
                            disabled={props.disabled}
                            renderInput={(p) => <MuiTextField {...p} label={props.label} variant="filled" required={props.required ?? false} />}
                        />
                    </LocalizationProvider>
                </FormControl>
            </div>
        );
    }
}
