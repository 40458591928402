import { IBootstrap, ISlimUser, Session } from "../Session";
import { Default } from "../../pi/context/AppContext";
import { observable } from "mobx";
import { ILoginService } from "./LoginService";
import { User } from "oidc-client-ts";

export class ImpersonateLoginService implements ILoginService {
    token: string;
    sessionManager: Session;

    constructor(bootstrap: IBootstrap, sessionManager: Session, token: string) {
        this.token = token.startsWith("#") ? token.substring(1) : token;
        this.sessionManager = sessionManager;
    }

    canRefresh(): Promise<boolean> {
        return Promise.resolve(false);
    }
    refreshToken(): Promise<User | null> {
        return Promise.resolve(null);
    }

    async init(): Promise<ISlimUser | undefined> {
        const user = {
            access_token: this.token
        };

        await this.sessionManager.updateStatus(user, false);

        Default.state.set(
            "login",
            observable({
                user
            })
        );

        return user;
    }

    signIn(provider?: string, tenant?: string, userId?: string): Promise<any> {
        // do nothing
        return Promise.resolve(undefined);
    }

    signOut(): Promise<any> {
        try {
            window.close();
        } catch {
            document.location.href = "logout.html";
        }

        return Promise.resolve(undefined);
    }
}