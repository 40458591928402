import * as api from "@crochik/pi-api";
import Dialog from "src/pi/services/Dialog";
import { DataView } from "src/pi/ui/DataView";
import * as API from "../services/API";

export async function save(context: string, args: any[]) {
    const entityId = args[0] as string;
    const added = args[1] as api.EntityMetadata[];
    const callback = args[2] as () => any;

    API.metaData
        .addToOrg(entityId, added)
        .then((ok) => {
            Dialog.inform({ title: "Success", message: "Saved" });
            callback();
        })
        .catch((ex) => {
            Dialog.critical({ title: "Organization Metadata", message: "Error trying to save metadata." });
            callback();
        });
}

export async function remove(context: string, args: any[]) {
    const entityId = args[0] as string;
    const view = args[1] as DataView;
    const callback = args[2] as () => any;

    var list = view.records.map((x) => view.get(x) as api.EntityMetadata).filter((x) => view.isSelected(x));

    API.metaData
        .removeFromOrg(entityId, list)
        .then((ok) => {
            Dialog.inform({ title: "Success", message: "Removed" });
            callback();
        })
        .catch((ex) => {
            Dialog.critical({ title: "Organization Metadata", message: "Error trying to remove." });
            callback();
        });
}
