import { Button, ButtonGroup, Chip, Grid, Paper, Theme, Typography, useTheme } from "@mui/material";
import { StyleSheet, css } from "aphrodite";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { TimeSlot } from "@crochik/pi-api";
import { IDayPickerConfig, TimePickerWindow, formatDate, formatTime, getDayPickerConfig, getIsSameDay } from "./dateTimeUtils";
import "./day-picker.css";

interface IProps {
    onSelect(slot: TimeSlot): void;
    selectedSlot?: TimeSlot;
    slots: TimeSlot[];
    timeZone: string;
}

export function TimeslotPicker(props: IProps) {
    const { onSelect, selectedSlot, slots, timeZone } = props;

    const theme = useTheme();
    const styles = createStyleSheet(theme);

    const [selectedDate, setSelectedDate] = useState<Date>();
    const [selectedMonth, setSelectedMonth] = useState<Date>();
    const [dayPickerConfig, setDayPickerConfig] = useState<IDayPickerConfig>();

    useEffect(() => {
        const config = getDayPickerConfig(slots, TimePickerWindow.All);
        setDayPickerConfig(config);
        setSelectedDate(config.selectedDate);
        setSelectedMonth(config.selectedDate);
    }, [slots]);

    const times = useMemo(() => {
        return dayPickerConfig?.slots?.filter((timeSlot) => {
            const isSameDay = getIsSameDay(timeSlot.start, selectedDate);
            return isSameDay;
        });
    }, [selectedDate, dayPickerConfig]);

    const onSetSelectedWindow = (value: TimePickerWindow) => () => {
        const config = getDayPickerConfig(slots, value, selectedDate);
        setDayPickerConfig(config);
        setSelectedDate(config.selectedDate);
        setSelectedMonth(config.selectedDate);
    };

    const onSetSelectedTimeSlot = useCallback((value: TimeSlot) => () => onSelect(value), [onSelect]);

    const onSelectDate = (value: Date | undefined) => {
        if (!!value) {
            setSelectedDate(value);
        }
    };

    const onMonthChange = (value: Date) => setSelectedMonth(value);

    const renderGrid = useCallback(() => {
        if (!times?.length) {
            return <div className={css(styles.noTimeslotsText)}>{"No timeslots available"}</div>;
        }
        return times?.map((time, i) => {
            return (
                <Grid item key={`slot:${i}`}>
                    <Chip
                        sx={{ color: time.start === selectedSlot?.start ? "#fff" : theme.palette.primary.main }}
                        label={formatTime(time?.start, timeZone)}
                        onClick={onSetSelectedTimeSlot(time)}
                        variant={time.start === selectedSlot?.start ? "filled" : "outlined"}
                        color="primary"
                    />
                </Grid>
            );
        });
    }, [styles, theme, onSetSelectedTimeSlot, times, selectedSlot, timeZone]);

    return (
        <Paper className={css(styles.container)} elevation={3}>
            <ButtonGroup>
                <Button
                    sx={{ color: dayPickerConfig?.window === TimePickerWindow.All ? "#fff" : theme.palette.primary.main, fontSize: 12 }}
                    variant={dayPickerConfig?.window === TimePickerWindow.All ? "contained" : "outlined"}
                    onClick={onSetSelectedWindow(TimePickerWindow.All)}
                    disableElevation
                    size="small"
                >
                    {TimePickerWindow.All}
                </Button>
                <Button
                    sx={{ color: dayPickerConfig?.window === TimePickerWindow.Morning ? "#fff" : theme.palette.primary.main, fontSize: 12 }}
                    variant={dayPickerConfig?.window === TimePickerWindow.Morning ? "contained" : "outlined"}
                    onClick={onSetSelectedWindow(TimePickerWindow.Morning)}
                    disableElevation
                >
                    {TimePickerWindow.Morning}
                </Button>
                <Button
                    sx={{ color: dayPickerConfig?.window === TimePickerWindow.Afternoon ? "#fff" : theme.palette.primary.main, fontSize: 12 }}
                    variant={dayPickerConfig?.window === TimePickerWindow.Afternoon ? "contained" : "outlined"}
                    onClick={onSetSelectedWindow(TimePickerWindow.Afternoon)}
                    disableElevation
                >
                    {TimePickerWindow.Afternoon}
                </Button>
                <Button
                    sx={{ color: dayPickerConfig?.window === TimePickerWindow.Evening ? "#fff" : theme.palette.primary.main, fontSize: 12 }}
                    variant={dayPickerConfig?.window === TimePickerWindow.Evening ? "contained" : "outlined"}
                    onClick={onSetSelectedWindow(TimePickerWindow.Evening)}
                    disableElevation
                >
                    {TimePickerWindow.Evening}
                </Button>
            </ButtonGroup>
            <DayPicker
                mode="single"
                selected={selectedDate}
                onSelect={onSelectDate}
                fromDate={dayPickerConfig?.fromDate}
                toDate={dayPickerConfig?.toDate}
                disabled={dayPickerConfig?.disabledDays}
                month={selectedMonth}
                onMonthChange={onMonthChange}
                className={css(styles.dayPicker)}
                modifiersClassNames={{
                    selected: css(styles.dayPickerSelected),
                    today: css(styles.today)
                }}
            />
            <Typography fontSize={18} gutterBottom>
                {formatDate(selectedDate, true)}
            </Typography>
            <Grid container direction="row" justifyContent="center" spacing={2}>
                {renderGrid()}
            </Grid>
        </Paper>
    );
}

const createStyleSheet = (theme: Theme) => {
    return StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: "#fff",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxWidth: 450,
            width: "100%",
            padding: 16,
            // paddingTop: 16,
            // paddingBottom: 16,
            minHeight: 600,
            // borderRadius: 8,
            // boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        },
        dayPicker: {
            fontFamily: theme.typography.fontFamily,
            marginLeft: 0,
            marginRight: 0,
        },
        dayPickerSelected: {
            color: "#fff",
            backgroundColor: theme.palette.primary.main,
        },
        noTimeslotsText: {
            marginTop: 24,
            fontWeight: "bold",
        },
        today: {
            fontWeight: 'bold',
            textDecoration: 'underline'
        }
    });
};
