import { observer } from "mobx-react";
import * as React from "react";
import app, { App } from "../../../application/App";
import { CustomPage2, ExternalPage, FormPage, GridPage, LayoutPage } from "../../../application/Page";

import { Alert, LinearProgress } from "@mui/material";
import { DataViewComponent } from "../DataGridComponent";
import { DataViewBarChartComponent } from "../DataViewComponent/DataViewBarChart";
import { VerticalForm } from "../VerticalForm";
import { ExternalPageComponent } from "./ExternalPageComponent";

interface IState {
    error?: string;
    errorInfo?: any;
}

interface IProps {
    embedded?: boolean;
}
@observer
export default class PageComponent extends React.Component<IProps, IState> {
    app: App;

    constructor(props: any) {
        super(props);
        this.app = app();
        this.state = {};
    }

    renderForm(page: FormPage, args: any[]) {
        const { embedded } = this.props;

        return <VerticalForm key={page.name} form={page.form} style={!!embedded ? undefined : { padding: 24 }} />;
    }

    renderGrid(page: GridPage, args: any[]) {
        if (!page.data.grid) {
            return <LinearProgress style={{ width: "100%" }} />;
        }

        const { embedded } = this.props;

        // TODO: use new URL(...)
        var url = page.data.grid;
        const index = url.indexOf("#");
        const barChart = url.indexOf("barChart", index) > 0;
        if (index > 0) {
            // const hints = url.substr(index + 1);
            // console.log(hints);
            url = url.substr(0, index);
        }

        const style = !!embedded ? undefined :
            app().innerWidth < 600
                ? {
                    padding: 6,
                }
                : {
                    padding: 24,
                };

        if (barChart) {
            return <DataViewBarChartComponent view={url} updateAppTitle={true} style={style} />;
        }

        return <DataViewComponent key={page.name} view={url} args={this.app.args} updateAppTitle={true} style={style} />;
    }

    renderExternal(page: ExternalPage, args: any[]) {
        return <ExternalPageComponent page={page} />;
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo,
        });
    }

    render() {
        const { error } = this.state;
        if (error) {
            return <Alert severity="error">{error.toString()}</Alert>;
        }

        const { embedded } = this.props;

        var page = this.app.page;
        var args = this.app.args;

        if (page instanceof FormPage) return this.renderForm(page, args);
        if (page instanceof GridPage) return this.renderGrid(page, args);
        // if (page instanceof CustomPage) return page.render(args);
        if (page instanceof CustomPage2) return page.render(args, embedded);
        if (page instanceof LayoutPage) return page.render(args, embedded);
        if (page instanceof ExternalPage) return this.renderExternal(page, args);

        return <div>{page ? page.name : ""}</div>;
    }
}
