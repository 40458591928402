import AddIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/HighlightOff";
import { Autocomplete, AutocompleteRenderInputParams, IconButton, TextField, Typography } from "@mui/material";
import { StyleSheet, css } from "aphrodite";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormField } from "@crochik/pi-api";
import { DataView } from "../../DataView";
import { FieldComponent } from "../FormBody/FormCell/FieldComponent";
interface Props {
    view: DataView;
}

export function DataViewFilterFormAutocomplete(props: Props) {
    const { view } = props;
    const [selectedOption, setSelectedOption] = useState<FormField | null>(null);
    const [adding, setAdding] = useState<boolean>();

    useEffect(() => {
        setSelectedOption(null);
    }, [view.filterForm?.fields]);

    const options = useMemo(() => {
        if (!view.filterForm?.fields) return [];

        const fields = !view.fixedFields ?
            view.filterForm.fields :
            view.filterForm.fields.filter(x=> !(x.name! in view.fixedFields!))

        return fields.filter((x) => !x.isVisible) ?? [];
    }, [view.filterForm]);

    useEffect(() => {
        setAdding(false);
    }, [selectedOption]);

    const onChange = useCallback((_: React.SyntheticEvent<Element, Event>, value: FormField | null) => setSelectedOption(value), [setSelectedOption]);
    const onClear = () => setSelectedOption(null);
    const onAdd = () => {
        setAdding(true);
    };
    const onClose = () => setAdding(false);

    const renderInput = useCallback((params: AutocompleteRenderInputParams) => {
        return <TextField {...params} label="Filter by..." autoFocus={true} />;
    }, []);

    if (!options.length) {
        return null;
    }

    return (
        <div className={css(styles.container)}>
            {!!selectedOption && !!view?.filterForm ? (
                <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
                    <FieldComponent
                        key="filterOfFilters"
                        form={view.filterForm}
                        field={selectedOption}
                        value={undefined}
                        style={{ flexGrow: 1 }}
                        extraProps={{ autoOpen: true }}
                    />
                    <IconButton onClick={onClear}>
                        <CloseIcon />
                    </IconButton>
                </div>
            ) : adding ? (
                <Autocomplete
                    disablePortal
                    onChange={onChange}
                    options={options}
                    renderInput={renderInput}
                    sx={{ width: "100%", backgroundColor: "#fff" }}
                    getOptionLabel={(x) => x.label ?? x.name ?? ""}
                    openOnFocus={true}
                    onClose={onClose}
                />
            ) : (
                <IconButton onClick={onAdd} size="large">
                    <AddIcon />
                    &nbsp;<Typography fontSize="12pt">Filter</Typography>
                </IconButton>
            )}
        </div>
    );
}

const styles = StyleSheet.create({
    container: {
        marginTop: 8,
    },
});
