import MuiTextField from "@mui/material/TextField";
import moment from "moment";
import { InputTextField } from "./InputTextField";

// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import { DateTimePicker, DateTimePickerProps } from '@mui/lab';

export class DateTimeField extends InputTextField {
    // dateTimeProps(): DateTimePickerProps {
    //     const field = this.props.field;
    //     var props: DateTimePickerProps = {
    //         label: field.label || field.name,
    //         disabled: this.props.disabled,
    //         value: this.props.value || '',
    //         onChange: this.onChange,
    //         autoFocus: this.props.autoFocus,
    //         renderInput: (props) => <MuiTextField {...props} />
    //     };
    //     return props;
    // }
    // render() {
    //     var props = {
    //         ...this.dateTimeProps(),
    //         fullWidth: true,
    //         type: 'date'
    //     };
    //     return (
    //         <LocalizationProvider dateAdapter={AdapterDateFns}>
    //             <DateTimePicker  {...props} />
    //         </LocalizationProvider>
    //     );
    // }
    render() {
        let { value } = this.props;
        if (value) {
            value = moment(value).format("YYYY-MM-DDTHH:mm");
        }

        const props = {
            ...this.calcProps(),
            fullWidth: true,
            type: "datetime-local",
            value,
        };

        if (!props.value && props.disabled) {
            // prevent rendering place holder
            props.type = "text";
        }

        return <MuiTextField {...props} variant="filled" />;
    }
}
