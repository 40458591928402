/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Singer
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
// @ts-nocheck

import * as url from "url";
import * as portableFetch from "portable-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "https://localhost".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = portableFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError";
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 *
 * @export
 * @interface Actor
 */
export interface Actor {}

/**
 *
 * @export
 * @interface Condition
 */
export interface Condition {
    /**
     *
     * @type {string}
     * @memberof Condition
     */
    fieldName?: string;
    /**
     *
     * @type {Operator}
     * @memberof Condition
     */
    operator?: Operator;
    /**
     *
     * @type {any}
     * @memberof Condition
     */
    value?: any;
}

/**
 *
 * @export
 * @interface DataView
 */
export interface DataView {
    /**
     *
     * @type {string}
     * @memberof DataView
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof DataView
     */
    title?: string;
    /**
     *
     * @type {Array<FormField>}
     * @memberof DataView
     */
    fields?: Array<FormField>;
    /**
     *
     * @type {string}
     * @memberof DataView
     */
    defaultSort?: string;
    /**
     *
     * @type {string}
     * @memberof DataView
     */
    keyField?: string;
    /**
     *
     * @type {boolean}
     * @memberof DataView
     */
    isSelectable?: boolean;
    /**
     *
     * @type {Array<FormAction>}
     * @memberof DataView
     */
    actions?: Array<FormAction>;
    /**
     *
     * @type {DataViewDetail}
     * @memberof DataView
     */
    detail?: DataViewDetail;
    /**
     *
     * @type {Array<string>}
     * @memberof DataView
     */
    filter?: Array<string>;
    /**
     *
     * @type {boolean}
     * @memberof DataView
     */
    searchable?: boolean;
    /**
     *
     * @type {number}
     * @memberof DataView
     */
    pageSize?: number;
}

/**
 *
 * @export
 * @interface DataViewDetail
 */
export interface DataViewDetail {
    /**
     *
     * @type {string}
     * @memberof DataViewDetail
     */
    page?: string;
}

/**
 *
 * @export
 * @interface DataViewRequest
 */
export interface DataViewRequest {
    /**
     *
     * @type {Array<Condition>}
     * @memberof DataViewRequest
     */
    criteria?: Array<Condition>;
    /**
     *
     * @type {number}
     * @memberof DataViewRequest
     */
    top?: number;
    /**
     *
     * @type {string}
     * @memberof DataViewRequest
     */
    orderBy?: string;
    /**
     *
     * @type {number}
     * @memberof DataViewRequest
     */
    skip?: number;
}

/**
 *
 * @export
 * @enum {string}
 */
export enum FIELDTYPE {
    Undefined = <any>"undefined",
    Address = <any>"address",
    Boolean = <any>"boolean",
    Date = <any>"date",
    Datetime = <any>"datetime",
    Dictionary = <any>"dictionary",
    Email = <any>"email",
    Hidden = <any>"hidden",
    Label = <any>"label",
    Lookup = <any>"lookup",
    Number = <any>"number",
    Password = <any>"password",
    Phone = <any>"phone",
    Postalcode = <any>"postalcode",
    Select = <any>"select",
    Text = <any>"text",
    Time = <any>"time",
    Url = <any>"url",
}

/**
 *
 * @export
 * @interface FieldOptions
 */
export interface FieldOptions {
    /**
     *
     * @type {string}
     * @memberof FieldOptions
     */
    t?: string;
}

/**
 *
 * @export
 * @interface FormAction
 */
export interface FormAction {
    /**
     *
     * @type {string}
     * @memberof FormAction
     */
    action?: string;
    /**
     *
     * @type {string}
     * @memberof FormAction
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof FormAction
     */
    label?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof FormAction
     */
    enable?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof FormAction
     */
    visible?: Array<string>;
}

/**
 *
 * @export
 * @interface FormField
 */
export interface FormField {
    /**
     *
     * @type {FIELDTYPE}
     * @memberof FormField
     */
    type?: FIELDTYPE;
    /**
     *
     * @type {any}
     * @memberof FormField
     */
    defaultValue?: any;
    /**
     *
     * @type {boolean}
     * @memberof FormField
     */
    isRequired?: boolean;
    /**
     *
     * @type {any}
     * @memberof FormField
     */
    style?: any;
    /**
     *
     * @type {FieldOptions}
     * @memberof FormField
     */
    options?: FieldOptions;
    /**
     *
     * @type {string}
     * @memberof FormField
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof FormField
     */
    label?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof FormField
     */
    enable?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof FormField
     */
    visible?: Array<string>;
}

/**
 *
 * @export
 * @interface Invoice
 */
export interface Invoice {
    /**
     *
     * @type {Array<InvoiceItem>}
     * @memberof Invoice
     */
    items?: Array<InvoiceItem>;
    /**
     *
     * @type {string}
     * @memberof Invoice
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof Invoice
     */
    accountId?: string;
    /**
     *
     * @type {string}
     * @memberof Invoice
     */
    organizationId?: string;
    /**
     *
     * @type {string}
     * @memberof Invoice
     */
    entityId?: string;
    /**
     *
     * @type {string}
     * @memberof Invoice
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof Invoice
     */
    description?: string;
    /**
     *
     * @type {number}
     * @memberof Invoice
     */
    total?: number;
    /**
     *
     * @type {Date}
     * @memberof Invoice
     */
    createdOn?: Date;
    /**
     *
     * @type {Date}
     * @memberof Invoice
     */
    referenceDate?: Date;
    /**
     *
     * @type {Actor}
     * @memberof Invoice
     */
    lastActor?: Actor;
    /**
     *
     * @type {number}
     * @memberof Invoice
     */
    balance?: number;
}

/**
 *
 * @export
 * @interface InvoiceItem
 */
export interface InvoiceItem {
    /**
     *
     * @type {string}
     * @memberof InvoiceItem
     */
    itemId?: string;
    /**
     *
     * @type {string}
     * @memberof InvoiceItem
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof InvoiceItem
     */
    description?: string;
    /**
     *
     * @type {number}
     * @memberof InvoiceItem
     */
    unitPrice?: number;
}

/**
 *
 * @export
 * @interface JobsDataViewResponse
 */
export interface JobsDataViewResponse {
    /**
     *
     * @type {DataViewRequest}
     * @memberof JobsDataViewResponse
     */
    request?: DataViewRequest;
    /**
     *
     * @type {Array<SingerJob>}
     * @memberof JobsDataViewResponse
     */
    result?: Array<SingerJob>;
    /**
     *
     * @type {DataView}
     * @memberof JobsDataViewResponse
     */
    view?: DataView;
}

/**
 *
 * @export
 * @enum {string}
 */
export enum Operator {
    Eq = <any>"Eq",
    Ne = <any>"Ne",
    Gt = <any>"Gt",
    Gte = <any>"Gte",
    Lt = <any>"Lt",
    Lte = <any>"Lte",
}

/**
 *
 * @export
 * @interface SingerImportConfig
 */
export interface SingerImportConfig {
    /**
     *
     * @type {string}
     * @memberof SingerImportConfig
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof SingerImportConfig
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof SingerImportConfig
     */
    accountId?: string;
    /**
     *
     * @type {string}
     * @memberof SingerImportConfig
     */
    entityId?: string;
    /**
     *
     * @type {{ [key: string]: SingerStreamConfig; }}
     * @memberof SingerImportConfig
     */
    streams?: { [key: string]: SingerStreamConfig };
    /**
     *
     * @type {SingerState}
     * @memberof SingerImportConfig
     */
    state?: SingerState;
    /**
     *
     * @type {TapConfig}
     * @memberof SingerImportConfig
     */
    tapConfig?: TapConfig;
    /**
     *
     * @type {string}
     * @memberof SingerImportConfig
     */
    currentTag?: string;
    /**
     *
     * @type {Date}
     * @memberof SingerImportConfig
     */
    extractStartedOn?: Date;
    /**
     *
     * @type {Date}
     * @memberof SingerImportConfig
     */
    loadEndedOn?: Date;
}

/**
 *
 * @export
 * @interface SingerJob
 */
export interface SingerJob {
    /**
     *
     * @type {string}
     * @memberof SingerJob
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof SingerJob
     */
    accountId?: string;
    /**
     *
     * @type {string}
     * @memberof SingerJob
     */
    configId?: string;
    /**
     *
     * @type {string}
     * @memberof SingerJob
     */
    tag?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof SingerJob
     */
    extractLog?: Array<string>;
    /**
     *
     * @type {Array<SingerMetricMessage>}
     * @memberof SingerJob
     */
    extractMetrics?: Array<SingerMetricMessage>;
    /**
     *
     * @type {Date}
     * @memberof SingerJob
     */
    startedOn?: Date;
    /**
     *
     * @type {Date}
     * @memberof SingerJob
     */
    extractEndedOn?: Date;
    /**
     *
     * @type {Date}
     * @memberof SingerJob
     */
    loadStartedOn?: Date;
    /**
     *
     * @type {Date}
     * @memberof SingerJob
     */
    loadEndedOn?: Date;
    /**
     *
     * @type {SingerState}
     * @memberof SingerJob
     */
    initialState?: SingerState;
    /**
     *
     * @type {SingerState}
     * @memberof SingerJob
     */
    state?: SingerState;
}

/**
 *
 * @export
 * @interface SingerJobSummary
 */
export interface SingerJobSummary {
    /**
     *
     * @type {string}
     * @memberof SingerJobSummary
     */
    stream?: string;
    /**
     *
     * @type {number}
     * @memberof SingerJobSummary
     */
    added?: number;
    /**
     *
     * @type {number}
     * @memberof SingerJobSummary
     */
    exception?: number;
    /**
     *
     * @type {number}
     * @memberof SingerJobSummary
     */
    failed?: number;
    /**
     *
     * @type {number}
     * @memberof SingerJobSummary
     */
    merged?: number;
    /**
     *
     * @type {number}
     * @memberof SingerJobSummary
     */
    skip?: number;
    /**
     *
     * @type {number}
     * @memberof SingerJobSummary
     */
    unknown?: number;
    /**
     *
     * @type {number}
     * @memberof SingerJobSummary
     */
    updated?: number;
}

/**
 *
 * @export
 * @enum {string}
 */
export enum SingerMetric {
    HttpRequestDuration = <any>"http_request_duration",
    RecordCount = <any>"record_count",
    JobDuration = <any>"job_duration",
}

/**
 *
 * @export
 * @interface SingerMetricMessage
 */
export interface SingerMetricMessage {
    /**
     *
     * @type {SingerMetricType}
     * @memberof SingerMetricMessage
     */
    type?: SingerMetricType;
    /**
     *
     * @type {SingerMetric}
     * @memberof SingerMetricMessage
     */
    metric?: SingerMetric;
    /**
     *
     * @type {number}
     * @memberof SingerMetricMessage
     */
    value?: number;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof SingerMetricMessage
     */
    tags?: { [key: string]: string };
}

/**
 *
 * @export
 * @enum {string}
 */
export enum SingerMetricType {
    Timer = <any>"Timer",
    Counter = <any>"Counter",
}

/**
 *
 * @export
 * @interface SingerState
 */
export interface SingerState {
    /**
     *
     * @type {string}
     * @memberof SingerState
     */
    currentStream?: string;
    /**
     *
     * @type {{ [key: string]: { [key: string]: any; }; }}
     * @memberof SingerState
     */
    bookmarks?: { [key: string]: { [key: string]: any } };
}

/**
 *
 * @export
 * @interface SingerStreamConfig
 */
export interface SingerStreamConfig {
    /**
     *
     * @type {string}
     * @memberof SingerStreamConfig
     */
    name?: string;
    /**
     *
     * @type {Array<Condition>}
     * @memberof SingerStreamConfig
     */
    inactiveConditions?: Array<Condition>;
}

/**
 *
 * @export
 * @interface TapConfig
 */
export interface TapConfig {}

/**
 * ConfigApi - fetch parameter creator
 * @export
 */
export const ConfigApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(options: any = {}): FetchArgs {
            const localVarPath = `/singer/v1/Config`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === "function" ? configuration.accessToken("oauth2", []) : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling getById.");
            }
            const localVarPath = `/singer/v1/Config/({id})`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === "function" ? configuration.accessToken("oauth2", []) : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * ConfigApi - functional programming interface
 * @export
 */
export const ConfigApiFp = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SingerImportConfig> {
            const localVarFetchArgs = ConfigApiFetchParamCreator(configuration).get(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SingerImportConfig> {
            const localVarFetchArgs = ConfigApiFetchParamCreator(configuration).getById(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    };
};

/**
 * ConfigApi - factory interface
 * @export
 */
export const ConfigApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(options?: any) {
            return ConfigApiFp(configuration).get(options)(fetch, basePath);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById(id: string, options?: any) {
            return ConfigApiFp(configuration).getById(id, options)(fetch, basePath);
        },
    };
};

/**
 * ConfigApi - interface
 * @export
 * @interface ConfigApi
 */
export interface ConfigApiInterface {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApiInterface
     */
    get(options?: any): Promise<SingerImportConfig>;

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApiInterface
     */
    getById(id: string, options?: any): Promise<SingerImportConfig>;
}

/**
 * ConfigApi - object-oriented interface
 * @export
 * @class ConfigApi
 * @extends {BaseAPI}
 */
export class ConfigApi extends BaseAPI implements ConfigApiInterface {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public get(options?: any) {
        return ConfigApiFp(this.configuration).get(options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public getById(id: string, options?: any) {
        return ConfigApiFp(this.configuration).getById(id, options)(this.fetch, this.basePath);
    }
}

/**
 * JobApi - fetch parameter creator
 * @export
 */
export const JobApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        afterJob(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling afterJob.");
            }
            const localVarPath = `/singer/v1/Job/({id})/Transform`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "POST" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === "function" ? configuration.accessToken("oauth2", []) : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        extract(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling extract.");
            }
            const localVarPath = `/singer/v1/Config({id})/Extract`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "POST" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === "function" ? configuration.accessToken("oauth2", []) : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling getById.");
            }
            const localVarPath = `/singer/v1/Job/({id})`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === "function" ? configuration.accessToken("oauth2", []) : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {number} [top]
         * @param {string} [orderBy]
         * @param {number} [skip]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobsById(id: string, top?: number, orderBy?: string, skip?: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling getJobsById.");
            }
            const localVarPath = `/singer/v1/Config({id})/Job`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === "function" ? configuration.accessToken("oauth2", []) : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (top !== undefined) {
                localVarQueryParameter["Top"] = top;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter["OrderBy"] = orderBy;
            }

            if (skip !== undefined) {
                localVarQueryParameter["Skip"] = skip;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {DataViewRequest} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobsDataView(id: string, body?: DataViewRequest, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling getJobsDataView.");
            }
            const localVarPath = `/singer/v1/Config({id})/Job/DataView`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "POST" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === "function" ? configuration.accessToken("oauth2", []) : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter["Content-Type"] = "application/json-patch+json";

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = <any>"DataViewRequest" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || "";

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSummaryById(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling getSummaryById.");
            }
            const localVarPath = `/singer/v1/Job/({id})/Summary`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === "function" ? configuration.accessToken("oauth2", []) : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactions(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling getTransactions.");
            }
            const localVarPath = `/singer/v1/Job/({id})/Transaction`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === "function" ? configuration.accessToken("oauth2", []) : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replay(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling replay.");
            }
            const localVarPath = `/singer/v1/Job/({id})/Load`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "POST" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === "function" ? configuration.accessToken("oauth2", []) : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        run(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling run.");
            }
            const localVarPath = `/singer/v1/Config({id})/Job`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "POST" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === "function" ? configuration.accessToken("oauth2", []) : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {string} [filename]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        simulateLoading(id: string, filename?: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling simulateLoading.");
            }
            const localVarPath = `/singer/v1/Job/({id})/Simulate`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "POST" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === "function" ? configuration.accessToken("oauth2", []) : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (filename !== undefined) {
                localVarQueryParameter["filename"] = filename;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * JobApi - functional programming interface
 * @export
 */
export const JobApiFp = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        afterJob(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = JobApiFetchParamCreator(configuration).afterJob(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        extract(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = JobApiFetchParamCreator(configuration).extract(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SingerJob> {
            const localVarFetchArgs = JobApiFetchParamCreator(configuration).getById(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} id
         * @param {number} [top]
         * @param {string} [orderBy]
         * @param {number} [skip]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobsById(
            id: string,
            top?: number,
            orderBy?: string,
            skip?: number,
            options?: any
        ): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SingerJob>> {
            const localVarFetchArgs = JobApiFetchParamCreator(configuration).getJobsById(id, top, orderBy, skip, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} id
         * @param {DataViewRequest} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobsDataView(id: string, body?: DataViewRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JobsDataViewResponse> {
            const localVarFetchArgs = JobApiFetchParamCreator(configuration).getJobsDataView(id, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSummaryById(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SingerJobSummary>> {
            const localVarFetchArgs = JobApiFetchParamCreator(configuration).getSummaryById(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactions(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Invoice>> {
            const localVarFetchArgs = JobApiFetchParamCreator(configuration).getTransactions(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replay(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = JobApiFetchParamCreator(configuration).replay(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        run(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = JobApiFetchParamCreator(configuration).run(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} id
         * @param {string} [filename]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        simulateLoading(id: string, filename?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = JobApiFetchParamCreator(configuration).simulateLoading(id, filename, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    };
};

/**
 * JobApi - factory interface
 * @export
 */
export const JobApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        afterJob(id: string, options?: any) {
            return JobApiFp(configuration).afterJob(id, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        extract(id: string, options?: any) {
            return JobApiFp(configuration).extract(id, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById(id: string, options?: any) {
            return JobApiFp(configuration).getById(id, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} id
         * @param {number} [top]
         * @param {string} [orderBy]
         * @param {number} [skip]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobsById(id: string, top?: number, orderBy?: string, skip?: number, options?: any) {
            return JobApiFp(configuration).getJobsById(id, top, orderBy, skip, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} id
         * @param {DataViewRequest} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobsDataView(id: string, body?: DataViewRequest, options?: any) {
            return JobApiFp(configuration).getJobsDataView(id, body, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSummaryById(id: string, options?: any) {
            return JobApiFp(configuration).getSummaryById(id, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactions(id: string, options?: any) {
            return JobApiFp(configuration).getTransactions(id, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replay(id: string, options?: any) {
            return JobApiFp(configuration).replay(id, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        run(id: string, options?: any) {
            return JobApiFp(configuration).run(id, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} id
         * @param {string} [filename]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        simulateLoading(id: string, filename?: string, options?: any) {
            return JobApiFp(configuration).simulateLoading(id, filename, options)(fetch, basePath);
        },
    };
};

/**
 * JobApi - interface
 * @export
 * @interface JobApi
 */
export interface JobApiInterface {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApiInterface
     */
    afterJob(id: string, options?: any): Promise<{}>;

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApiInterface
     */
    extract(id: string, options?: any): Promise<{}>;

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApiInterface
     */
    getById(id: string, options?: any): Promise<SingerJob>;

    /**
     *
     * @param {string} id
     * @param {number} [top]
     * @param {string} [orderBy]
     * @param {number} [skip]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApiInterface
     */
    getJobsById(id: string, top?: number, orderBy?: string, skip?: number, options?: any): Promise<Array<SingerJob>>;

    /**
     *
     * @param {string} id
     * @param {DataViewRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApiInterface
     */
    getJobsDataView(id: string, body?: DataViewRequest, options?: any): Promise<JobsDataViewResponse>;

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApiInterface
     */
    getSummaryById(id: string, options?: any): Promise<Array<SingerJobSummary>>;

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApiInterface
     */
    getTransactions(id: string, options?: any): Promise<Array<Invoice>>;

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApiInterface
     */
    replay(id: string, options?: any): Promise<{}>;

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApiInterface
     */
    run(id: string, options?: any): Promise<{}>;

    /**
     *
     * @param {string} id
     * @param {string} [filename]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApiInterface
     */
    simulateLoading(id: string, filename?: string, options?: any): Promise<{}>;
}

/**
 * JobApi - object-oriented interface
 * @export
 * @class JobApi
 * @extends {BaseAPI}
 */
export class JobApi extends BaseAPI implements JobApiInterface {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApi
     */
    public afterJob(id: string, options?: any) {
        return JobApiFp(this.configuration).afterJob(id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApi
     */
    public extract(id: string, options?: any) {
        return JobApiFp(this.configuration).extract(id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApi
     */
    public getById(id: string, options?: any) {
        return JobApiFp(this.configuration).getById(id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} id
     * @param {number} [top]
     * @param {string} [orderBy]
     * @param {number} [skip]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApi
     */
    public getJobsById(id: string, top?: number, orderBy?: string, skip?: number, options?: any) {
        return JobApiFp(this.configuration).getJobsById(id, top, orderBy, skip, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} id
     * @param {DataViewRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApi
     */
    public getJobsDataView(id: string, body?: DataViewRequest, options?: any) {
        return JobApiFp(this.configuration).getJobsDataView(id, body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApi
     */
    public getSummaryById(id: string, options?: any) {
        return JobApiFp(this.configuration).getSummaryById(id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApi
     */
    public getTransactions(id: string, options?: any) {
        return JobApiFp(this.configuration).getTransactions(id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApi
     */
    public replay(id: string, options?: any) {
        return JobApiFp(this.configuration).replay(id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApi
     */
    public run(id: string, options?: any) {
        return JobApiFp(this.configuration).run(id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} id
     * @param {string} [filename]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApi
     */
    public simulateLoading(id: string, filename?: string, options?: any) {
        return JobApiFp(this.configuration).simulateLoading(id, filename, options)(this.fetch, this.basePath);
    }
}
