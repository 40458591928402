import { observer } from "mobx-react";
import { Component } from "react";
import { IField, TYPE } from "../../../../context/IForm";
import { Form } from "../../../Form";
import { FieldComponent } from "./FieldComponent";
import { ReadOnlyFormCell } from "./ReadOnlyFormCell";

export interface FormCellProps {
    autoFocus?: boolean;
    field: IField;
    form: Form;
    index: number;
}

@observer
export class FormCell extends Component<FormCellProps> {
    render() {
        const { autoFocus, field, form, index } = this.props;

        const extraProps = { key: field.name, autoFocus: autoFocus && index === 0 };
        const isHidden = !form || form.isHidden(field) || field.type === TYPE.HIDDEN;
        const value = form.getValue(field);

        if (isHidden) {
            return null;
        }

        if (form.isReadOnly) {
            return <ReadOnlyFormCell {...this.props} value={value} />;
        }

        return <FieldComponent key={field.name} form={form} field={field} extraProps={extraProps} value={value} />;
    }
}
