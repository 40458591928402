import { MaskTextField } from "./MaskTextField";
import { phone } from "./TextMask";

export class PhoneField extends MaskTextField {
    getInputProps() {
        var props = {
            ...super.getInputProps(),
            type: "tel",
            inputComponent: phone,
        };

        return props;
    }
}
