import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import { css, StyleSheet } from "aphrodite";
import * as React from "react";
import { Action } from "src/pi/context/IForm";
import { IActionMenuItem } from "src/pi/context/IMenu";
import { Form } from "../Form";
import ActionMenuBar from "./ActionMenuBar";

interface FormTitleProps {
    form: Form;
    className?: string;
    title?: string;
    onClose?: () => void;
    onAction?: (action: Action) => any;
}

export class FormTitle extends React.Component<FormTitleProps> {
    onActionClick = (action: IActionMenuItem, event: React.MouseEvent) => {
        const { onAction } = this.props;

        if (!action.action) return false;

        const { form } = this.props;

        // // could/should be part of the form.execute
        // // but ...
        // if (action.action === "#design") {
        //     form.isDesigning = true;
        //     return true;
        // }

        if (onAction) {
            return onAction(action);
        }

        return form.executeAsync(action);
    };

    render() {
        const { className, form, title, onClose } = this.props;
        const { menu, context } = form;

        return (
            <div className={className || css(styles.container)}>
                <Typography color="primary" variant="h6">
                    {title ?? form.title}
                </Typography>

                <div className={css(styles.actions)}>
                    {menu && <ActionMenuBar menu={menu} context={context} onAction={this.onActionClick} />}
                    {onClose && (
                        <IconButton onClick={onClose} title="Close">
                            <CloseIcon />
                        </IconButton>
                    )}
                </div>
            </div>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        display: "flex",
        justifyContent: "space-between",
    },
    actions: {
        display: "flex",
        justifyContent: "space-between",
    },
});
