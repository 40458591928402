import jwt_decode from "jwt-decode";
import { observable } from "mobx";
import { AppConfig, User, WorkingHoursSetting } from "@crochik/pi-api";
import { Default } from "src/pi/context/AppContext";
import * as API from "./services/API";

import * as api from "@crochik/pi-api";
import App from "src/pi/application/App";
import DialogService from "src/pi/services/Dialog";

export interface JwtClaims {
    // [claim: string]: unknown;

    /** The "iss" (issuer) claim identifies the principal that issued the JWT. The processing of this claim is generally application specific. The "iss" value is a case-sensitive string containing a StringOrURI value. */
    iss?: string;
    /** The "sub" (subject) claim identifies the principal that is the subject of the JWT. The claims in a JWT are normally statements about the subject. The subject value MUST either be scoped to be locally unique in the context of the issuer or be globally unique. The processing of this claim is generally application specific. The "sub" value is a case-sensitive string containing a StringOrURI value. */
    sub?: string;
    /** The "aud" (audience) claim identifies the recipients that the JWT is intended for. Each principal intended to process the JWT MUST identify itself with a value in the audience claim. If the principal processing the claim does not identify itself with a value in the "aud" claim when this claim is present, then the JWT MUST be rejected. In the general case, the "aud" value is an array of case-sensitive strings, each containing a StringOrURI value. In the special case when the JWT has one audience, the "aud" value MAY be a single case-sensitive string containing a StringOrURI value. The interpretation of audience values is generally application specific. */
    aud?: string | string[];
    /** The "exp" (expiration time) claim identifies the expiration time on or after which the JWT MUST NOT be accepted for processing. The processing of the "exp" claim requires that the current date/time MUST be before the expiration date/time listed in the "exp" claim. Implementers MAY provide for some small leeway, usually no more than a few minutes, to account for clock skew. Its value MUST be a number containing a NumericDate value. */
    exp?: number;
    /** The "nbf" (not before) claim identifies the time before which the JWT MUST NOT be accepted for processing. The processing of the "nbf" claim requires that the current date/time MUST be after or equal to the not-before date/time listed in the "nbf" claim. Implementers MAY provide for some small leeway, usually no more than a few minutes, to account for clock skew. Its value MUST be a number containing a NumericDate value. */
    nbf?: number;
    /** The "iat" (issued at) claim identifies the time at which the JWT was issued. This claim can be used to determine the age of the JWT. Its value MUST be a number containing a NumericDate value. */
    iat?: number;
    /** The "jti" (JWT ID) claim provides a unique identifier for the JWT. The identifier value MUST be assigned in a manner that ensures that there is a negligible probability that the same value will be accidentally assigned to a different data object; if the application uses multiple issuers, collisions MUST be prevented among values produced by different issuers as well. The "jti" claim can be used to prevent the JWT from being replayed. The "jti" value is a case-sensitive string. */
    jti?: string;

    email?: string;
    family_name?: string;
    given_name?: string;
    name?: string;
    idp?: string;
    scope?: string[];
    pi_profile_id?: string;
    role?: string;
    pi_account_id?: string;
    pi_org_id?: string;
    preferred_username?: string;
    client_id?: string;
    client_account_id?: string;

    // salesforce canvas
    client_param_embedded?: boolean;
    client_param_embeddedHeight?: string;
}

export interface IBootstrap {
    primaryColor: string;
    secondaryColor: string;
    authority: string;
    baseApiUrl: string;
    apiScope: string;
    idps: string[];
    stripeKey?: string;
    appName?: string;
}

interface ILaunchUrl {
    path: string;
    hash?: string;
    search?: string;
    searchParams?: { [name: string]: string };
    referrer?: string;
}

export interface ISlimUser {
    access_token: string;
    profile?: { idp?: string };
}

export interface ISession {
    launchUrl: ILaunchUrl;
    user?: User;
    idpUser?: ISlimUser;
    workingHours?: WorkingHoursSetting;
    appConfig?: AppConfig;
    bootstrap: IBootstrap;
    jwt?: JwtClaims;

    embedded: boolean;
    embeddedHeight?: number;
}


export class Session {
    static _manager: Session | undefined = undefined;
    state: ISession;

    static get(): ISession {
        return Default.state.get("Session") as ISession;
    }

    static manager(): Session {
        if (!Session._manager) throw new Error("Session manager has not been initialized");
        return Session._manager;
    }

    get launchUrl(): ILaunchUrl {
        return this.state.launchUrl;
    }
    get workingHours(): WorkingHoursSetting | undefined {
        return this.state.workingHours;
    }
    set workingHours(value: WorkingHoursSetting | undefined) {
        this.state.workingHours = value;
    }
    get user(): User | undefined {
        return this.state.user;
    }
    set user(value: User | undefined) {
        this.state.user = value;
    }

    constructor(bootstrap: IBootstrap) {
        Session._manager = this;

        this.state = observable({
            bootstrap,
            launchUrl: this.parseLocation(),
            isSyncEnabled: false,
            hasMicrosoftAccount: false,
            user: undefined,
            workingHours: undefined,
            embedded: false,
        });

        Default.state.set("Session", this.state);
    }

    async updateStatus(idpUser: ISlimUser, saveAutoLogin: boolean = false) {
        var user: User;

        try {
            App().initConfig(this.state.bootstrap.baseApiUrl,idpUser.access_token);

            user = await API.me();
            this.state.idpUser = idpUser;
            this.state.jwt = jwt_decode<JwtClaims>(idpUser.access_token);
            this.state.embeddedHeight = this.state.jwt?.client_param_embeddedHeight ? Number(this.state.jwt?.client_param_embeddedHeight) : undefined;
            this.state.embedded = this.state.jwt?.client_param_embedded ? Boolean(this.state.jwt?.client_param_embedded) : false;

            if (saveAutoLogin) {
                // save "autologin" token
                localStorage.setItem(
                    "autoLogin",
                    JSON.stringify({
                        userId: user.id,
                        orgId: user.organizationId,
                        accountId: user.accountId,
                    })
                );
            }
        } catch (ex) {
            console.error(ex);

            this.state.user = undefined;
            this.state.idpUser = undefined;
            this.state.jwt = undefined;

            App().resetConfig();

            DialogService.critical({
                title: "User Error",
                message: "Failed to load user info",
            });
            return;
        }

        this.state.user = user;
    }

    private nullIfEmpty(value?: string) {
        return value && value.length > 0 ? value : undefined;
    }

    public parseLocation(): ILaunchUrl {
        var search = window.location.search;
        var searchParams: { [name: string]: string } | undefined = undefined;
        if (search && search.startsWith("?")) {
            var params = search.substring(1).split("&");
            // tslint:disable-next-line:forin
            for (var param in params) {
                var tokens = params[param].split("=");
                if (tokens.length === 2) {
                    if (!searchParams) {
                        searchParams = {};
                    }
                    searchParams[tokens[0]] = decodeURIComponent(tokens[1]);
                }
            }
        }

        var requestStr = sessionStorage.getItem("request");
        if (requestStr) {
            var launchUrl = JSON.parse(requestStr) as ILaunchUrl;
            // console.log(`found on storage: ${requestStr}`);
            launchUrl.searchParams = {
                ...launchUrl.searchParams,
                ...searchParams,
            };
            if (launchUrl.searchParams && Object.keys(launchUrl.searchParams).length < 1) {
                launchUrl.searchParams = undefined;
            }
            if (!launchUrl.search) {
                launchUrl.search = this.nullIfEmpty(window.location.search);
            }

            sessionStorage.removeItem("request");

            return launchUrl;
        }

        const hash = !!window.location.hash && window.location.hash.startsWith("#") ? window.location.hash.substring(1) : undefined;

        var req: ILaunchUrl = {
            search: this.nullIfEmpty(window.location.search),
            path: window.location.pathname,
            searchParams: searchParams,
            referrer: this.nullIfEmpty(document.referrer),
            hash: hash,
        };

        // var request = JSON.stringify(req);
        // sessionStorage.setItem("request", request);

        return req;
    }
}
