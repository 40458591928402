import { Button, Link, Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { StyleSheet, css } from "aphrodite";
import * as React from "react";
import sanitizeHtml from "sanitize-html";
import { LabelStyle, PalletColor } from "@crochik/pi-api";
import App from "src/pi/application/App";
import { IField, ILabelFieldOptions } from "../../../context/IForm";
import { Form } from "../../Form";
import { FieldProps } from "./FieldProps";

export type Color = "error" | "inherit" | "primary" | "secondary" | "initial" | "textPrimary" | "textSecondary" | undefined;

interface ILabelFieldProps extends FieldProps<string> {
    form: Form;
}

const onClickCell = (form: Form, field: IField) => (e: React.MouseEvent) => {
    e.stopPropagation();

    console.log("click");

    const url = form.getLinkUrl(field);
    if (url) {
        App().openInNewTab(url);
    }
};

const wrapValue = (form: Form, field: IField, content: React.ReactElement) => {
    return field.options?.linkUrl ? (
        <div key={field.name} className={css(styles.container)}>
            <Link onClick={onClickCell(form, field)} underline="hover" color="primary" style={{ cursor: "pointer" }}>
                {content}
            </Link>
        </div>
    ) : (
        <div key={field.name} className={css(styles.container)}>
            {content}
        </div>
    );
};

export class LabelField extends React.Component<ILabelFieldProps> {
    render() {
        const { form, field } = this.props;
        const options = field.options as ILabelFieldOptions;
        const value = field.label ?? field.name ?? "[no-name]";

        if (options.style === LabelStyle.Html) {
            const clean = sanitizeHtml(value, {
                allowedTags: ["p", "em", "strong", "b", "i", "span"],
                // allowedAttributes: {
                //     'p': ["style"],
                // },
                allowedStyles: {
                    "*": {
                        // Match HEX and RGB
                        color: [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
                        "text-align": [/^left$/, /^right$/, /^center$/],
                        // Match any number with px, em, or %
                        // 'font-size': [/^\d+(?:px|em|%)$/]
                    },
                    // 'p': {
                    //     'font-size': [/^\d+rem$/]
                    // }
                },
            });

            return <div dangerouslySetInnerHTML={{ __html: clean }} />;
        }

        var variant: Variant = "body1";
        var color: Color = "initial";
        switch (options.color) {
            case PalletColor.Error:
                color = "error";
                break;
            case PalletColor.Primary:
                color = "primary";
                break;
            case PalletColor.Secondary:
                color = "secondary";
                break;
            case PalletColor.TextPrimary:
                color = "textPrimary";
                break;
            case PalletColor.TextSecondary:
                color = "textSecondary";
                break;
        }

        switch (options.style) {
            case LabelStyle.Header:
                variant = "h6";
                break;
            case LabelStyle.Normal:
                variant = "body1";
                break;
            case LabelStyle.Subheader:
                variant = "subtitle1";
                break;
            case LabelStyle.Subheader2:
                variant = "subtitle2";
                break;
        }

        if (options.multline) {
            const lines = value.split("\n");
            return wrapValue(
                form,
                field,
                <>
                    {lines.map((x, i) => (
                        <div key={`line-${i}`}>
                            <Typography color={color} variant={variant}>
                                {x}
                            </Typography>
                        </div>
                    ))}
                </>
            );
        }

        if (options.style === LabelStyle.Button && options.linkUrl) {
            // link should be handled by container (e.g. readonlyformcell)
            const buttonColor = options.color === PalletColor.Secondary ? "secondary" : "primary";
            return (
                <div className={css(styles.container)}>
                    <Button color={buttonColor} variant="outlined" onClick={onClickCell(form, field)}>
                        {value}
                    </Button>
                </div>
            );
        }

        return wrapValue(
            form,
            field,
            <Typography color={color} variant={variant}>
                {value}
            </Typography>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        paddingTop: 16,
        paddingBottom: 8,
    },
});
