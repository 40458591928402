import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Alert, IconButton } from "@mui/material";
import * as React from "react";
import { FieldProps } from "../FieldProps";


import { toJS } from 'mobx';
import { observer } from "mobx-react";
import { useCallback, useEffect, useState } from "react";
import { IDictionaryFieldOptions, IField, TYPE } from "../../../../context/IForm";
import { Form } from "../../../Form";
import { factory } from "../Factory";
import Section from "../Section";
import "./styles.css";

export interface IProps extends FieldProps {
    form: Form;
    keyField: IField;
    valueField: IField;
}

interface IKeyValueProps extends IProps {
    fieldName: string;
    row: string;
    editing: boolean;
    initialKeyValue?: string;
    initialValueValue?: any;
    disabled?: boolean;
    onCancel?: () => void;
    onStartEditing?: (key: string) => void;
    onDelete?: (key: string) => void;
    onApply?: (key: string, value: any) => void;
}

function KeyValue({
    editing,
    disabled,
    fieldName,
    form,
    initialKeyValue,
    initialValueValue,
    keyField,
    row,
    valueField,
    onCancel,
    onApply,
    onDelete,
    onStartEditing,
}: IKeyValueProps) {
    const [keyValue, setKeyValue] = useState<string | undefined>(initialKeyValue);
    const [valueValue, setValueValue] = useState<any>(initialValueValue);

    const onKeyChange = useCallback((f: IField, v: any) => {
        setKeyValue(v);
    }, []);

    const onValueChange = useCallback((f: IField, v: any) => {
        setValueValue(v);
    }, []);

    const keyProps: FieldProps = {
        field: {
            ...keyField,
            name: `${fieldName}#${row}#key`,
            label: keyField?.label ?? keyField.name,
        },
        error: undefined,
        disabled: !editing,
        value: keyValue,
        onChange: onKeyChange,
    };

    const valueProps: FieldProps = {
        field: {
            ...valueField,
            name: `${fieldName}#${row}#value`,
            label: valueField?.label ?? valueField.name,
        },
        error: undefined,
        disabled: !editing,
        value: valueValue,
        onChange: onValueChange,
    };

    const startEditing = () => {
        onStartEditing?.(row);
    }

    const apply = () => {
        if (!keyValue) return;
        onApply?.(keyValue, valueValue);
    }

    const cancel = () => {
        setKeyValue(initialKeyValue);
        setValueValue(initialValueValue);
        onCancel?.();
    }

    const deleteRow = () => {
        onDelete?.(row);
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} key={row}>
            <div style={{ width: '40%', paddingRight: '4px' }} key="key">{factory.render(form, keyField, keyProps)}</div>
            <div style={{ width: '60%', paddingLeft: '4px' }} key="value">{factory.render(form, valueField, valueProps)}</div>
            <div style={{ width: '100px' }}>
                {
                    editing && (
                        <>
                            <IconButton disabled={!keyValue || !valueValue} onClick={apply}><CheckIcon /></IconButton>
                            <IconButton onClick={cancel}><CancelIcon /></IconButton>
                        </>
                    )
                }
                {
                    row !== "#new" && !editing && (
                        <>
                            <IconButton disabled={disabled} onClick={startEditing}><EditIcon /></IconButton>
                            <IconButton disabled={disabled} onClick={deleteRow}><DeleteIcon /></IconButton>
                        </>
                    )
                }
            </div>
        </div>
    )
}

function DictionaryFieldF(props: IProps) {
    const { field, value, onChange } = props;
    const options = field?.options as IDictionaryFieldOptions;
    const { keyField, valueField } = options ?? {};

    const [error, setError] = useState<string>();
    const [editing, setEditing] = useState<string>();
    const [rows, setRows] = useState<string[]>([]);

    useEffect(() => {
        switch (keyField?.type) {
            case TYPE.REFERENCE:
            case TYPE.SELECT:
            case TYPE.TEXT:
                break;
            default:
                setError("Invalid key field");
                return;
        }

    }, [keyField?.type, valueField?.type, keyField?.options, valueField?.options])

    useEffect(() => {
        console.log('list changed', value);
        const list = value ? Object.keys(value) : [];
        list.sort();

        setRows(list);
    }, [value]);

    if (error) {
        return (
            <Section title={field.label ?? field.name ?? ""}><Alert severity="error">{error}</Alert></Section>
        )
    }

    const onStartEditing = (row: string) => {
        setEditing(row)
    }

    const onStartAdding = () => {
        setEditing("#new")
    }

    const onCancel = () => {
        setEditing(undefined);
    }

    const onApply = (k: string, v: any) => {
        const newValue = {
            ...value
        };

        if (editing) delete newValue[editing];
        newValue[k] = v;
        onChange?.(field, newValue);
        setEditing(undefined)
    }

    const onAdd = (k: string, v: any) => {
        const newValue = {
            ...value
        };

        newValue[k] = v;
        onChange?.(field, newValue);
        setEditing(undefined)
    }

    const onDelete = (k: string) => {
        const newValue = {
            ...value
        };
        delete newValue[k];
        onChange?.(field, newValue);
        setEditing(undefined)
    }

    return (
        <Section title={field.label ?? field.name ?? ""} style={{paddingLeft: 8}}>
            <div style={{maxHeight: '50vh', overflow: 'auto'}}>
                {
                    rows.map(k =>
                        <KeyValue
                            key={k}
                            {...props}
                            fieldName={field.name ?? ''}
                            row={k}
                            editing={editing === k}
                            disabled={!!editing && editing !== k}
                            initialKeyValue={k}
                            initialValueValue={value[k]}
                            onCancel={onCancel}
                            onApply={onApply}
                            onStartEditing={onStartEditing}
                            onDelete={onDelete}
                        />
                    )
                }
            </div>
            <div>
                {
                    editing === "#new" && (
                        <KeyValue
                            key='#new'
                            {...props}
                            fieldName={field.name ?? ''}
                            row={editing}
                            editing={true}
                            onCancel={onCancel}
                            onApply={onAdd}
                        />
                    )
                }
                {
                    !editing && (
                        <IconButton onClick={onStartAdding}><AddIcon /></IconButton>
                    )
                }
            </div>
        </Section>
    );
}

@observer
export class DictionaryField extends React.Component<IProps> {
    render() {
        const { value, ...other } = this.props;
        const v = toJS(value);

        return <DictionaryFieldF {...other} value={v} />
    }
}