import { useEffect, useState } from "react";
import * as api from "@crochik/pi-api";
import { useForm } from "src/pi/hooks/useForm";
import App from "src/pi/application/App";
import { Action } from "src/pi/context/IForm";
import { Form } from "../../../Form";
import { FormDialog } from "../../FormDialog";
import { URI } from "../../../../api/URI";

export interface IFormLayoutSaverProps {
    onClose: (saved: boolean) => void;
    layouts?: api.BreakpointLayouts;
}

export function FormLayoutSaver({ onClose, layouts }: IFormLayoutSaverProps) {
    const form = useForm();
    const [saveLayoutsDialog, setSaveLayoutsDialog] = useState<Form>();

    useEffect(() => {
        setSaveLayoutsDialog(undefined);

        if (!layouts) {
            return;
        }

        if (!form.url) return;

        const uri = new URI(`dataform:${form.url}`);
        const formUrl = uri.getPathAndQuery("/Layout/Save");
        App().loadFormAsync(formUrl)
            .then(saveForm => {
                if (!saveForm) return;

                setSaveLayoutsDialog(Form.create(saveForm, formUrl));
            })
            .catch(reason => {
                console.error(reason);
            });

    }, [layouts, form.url]);

    const saveLayoutsAsync = async () => {
        if (!saveLayoutsDialog || !layouts) return;

        const input = saveLayoutsDialog.values;
        const response = await form.saveLayoutsAsync(input, layouts);
        if (!response) {
            alert("error");
            return;
        }

        onClose(true);
    };

    const onSaveLayoutActionAsync = async (action: Action) => {
        switch (action.action) {
            case "#cancel": // discard
                onClose(false);
                break;

            case "#save":
                await saveLayoutsAsync();
                break;

            default:
                await form.executeAsync(action);
                break;
        }

        setSaveLayoutsDialog(undefined);
    };

    const onCloseDialogAsync = () => {
        onClose(false);
        return Promise.resolve();
    }

    if (!saveLayoutsDialog) return null;

    return (
        <FormDialog form={saveLayoutsDialog} onCloseAsync={onCloseDialogAsync} onActionAsync={onSaveLayoutActionAsync} />
    );
}
