import { Dialog, Paper } from "@mui/material";
import { css, StyleSheet } from "aphrodite";
import { observer } from "mobx-react";
import { Component, createRef, ReactNode, RefObject } from "react";
import * as api from "@crochik/pi-api";
import { BreakpointLayouts, ScreenBreakpoint } from "@crochik/pi-api";
import { FormProvider } from "src/pi/hooks/useForm";
import App from "src/pi/application/App";
import { Form } from "../../Form";
import { FormBuilder } from "./FormBuilder";
import { GridFormLayout } from "./FormLayout/GridFormLayout";
import { StackFormLayout } from "./FormLayout/StackFormLayout";
import { IField } from "../../../context/IForm";
import { FieldTypeIcon } from "./FormCell/DragAndDrop/FieldTypeIcon";
import { Dump } from "../../../Dump";

interface Props {
    autoFocus?: boolean;
    form: Form;
}

interface State {
    saveLayoutsDialog?: Form;
    layout: api.FormLayout;
}

@observer
export class FormBody extends Component<Props, State> {
    private ref: RefObject<HTMLDivElement>;

    constructor(props: Props) {
        super(props);
        this.ref = createRef();
        this.state = {
            layout: {
                t: "FormLayout", // ???
                type: undefined, // stack
                breakpoint: api.ScreenBreakpoint.Sm
            }
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        // Initialize breakpoint
        this.handleResize();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.form !== this.props.form) {
            this.handleResize();
        }
    }

    handleResize = () => {
        const { form } = this.props;
        const width = this.ref.current?.offsetWidth || 0;
        const containerBreakpoint = App().getBreakpointFromWidth(width);
        const layout = form.getLayout(containerBreakpoint);

        this.setState({ layout });
    };

    getLayout(layouts: BreakpointLayouts, breakpoint?: ScreenBreakpoint) {
        switch (breakpoint) {
            case ScreenBreakpoint.Xs:
                return layouts.xs;
            case ScreenBreakpoint.Sm:
                return layouts.sm;
            case ScreenBreakpoint.Md:
                return layouts.md;
            case ScreenBreakpoint.Lg:
                return layouts.lg;
            case ScreenBreakpoint.Xl:
                return layouts.xl;
        }

        return undefined;
    }

    onCloseDesigner = (layouts: BreakpointLayouts) => {
        const { form } = this.props;
        form.isDesigning = false;
        
        const layout = this.getLayout(layouts, this.state.layout?.breakpoint);
        if (layout) this.setState({ layout });
    };

    onRenderField = (field: IField) => {
        const { form } = this.props;
        const title = `${field.label ?? field.name}${field.isRequired ? " *" : ""}`;

        const ro = form.isReadOnly || field.isReadOnly;
        const defaultValue = typeof(field.defaultValue) === "object" ? <Dump style={{margin: 0}} object={field.defaultValue}/> : field.defaultValue;
        return (
            <Paper style={{
                margin: 4,
                width: "100%",
                backgroundColor:  "#eeeeee",
                minHeight: "60px",
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
                boxSizing: "border-box",
                border: ro ? "2px dashed gray" : undefined,
            }} elevation={ro ? 0 : 2}>
                <div
                    style={{
                        padding: 6, display: "flex", flexDirection: "row", width: "100%"
                    }}
                >
                    <div style={{ width: 64, display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <FieldTypeIcon type={field.type!} /></div>
                    <div>
                        {title}
                        {field.label !== field.name && (<>
                            <br />
                            <span style={{ fontSize: "10pt" }}>{field.name}</span>
                        </>)}
                        {defaultValue && <div style={{border: "1px solid black", color: 'white', background: 'gray', padding: 4, fontSize: '10pt'}}>{defaultValue}</div>}
                    </div>
                </div>
            </Paper>
        );
    }

    render() {
        const { form } = this.props;
        const { layout } = this.state;

        return (
            <FormProvider form={form}>
                <div className={css(styles.formBody)} ref={this.ref}>
                    {form.isDesigning &&
                        <Dialog open={form.isDesigning} fullScreen>
                            <FormBuilder initialBreakpoint={layout.breakpoint!} onClose={this.onCloseDesigner}  />
                        </Dialog>
                    }
                    {layout.type === "Grid" ? <GridFormLayout layout={layout as api.GridFormLayout} form={form} /> :
                        <StackFormLayout />}
                </div>
            </FormProvider>
        );
    }
}

const styles = StyleSheet.create({
    formBody: {
        height: "100%",
        width: "100%"
    }
});
