import * as React from "react";
import { observer } from "mobx-react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

function Transition(props: any) {
    return <Slide direction="up" {...props} />;
}

export interface DialogProperties {
    title: string;
    message: string;
    positiveCaption: string;
    negativeCaption?: string;
    open: boolean;
    onPositive: () => any;
    onNegative: () => any;
}

interface State {
    open: boolean;
}

@observer
export class ConfirmDialog extends React.Component<DialogProperties, State> {
    constructor(props: DialogProperties) {
        super(props);

        this.state = {
            open: props.open,
        };
    }

    close() {
        this.setState({ open: false });
    }

    onPositive = () => {
        this.close();
        this.props.onPositive();
    };

    onNegative = () => {
        this.close();
        this.props.onNegative();
    };

    render() {
        return (
            <Dialog open={this.state.open} TransitionComponent={Transition} keepMounted={false} onClose={this.onNegative}>
                <DialogTitle>{this.props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{this.props.message}</DialogContentText>
                </DialogContent>
                <DialogActions style={{ padding: 24 }}>
                    <Button onClick={this.onPositive}>{this.props.positiveCaption}</Button>
                    {this.props.negativeCaption ? <Button onClick={this.onNegative}>{this.props.negativeCaption}</Button> : undefined}
                </DialogActions>
            </Dialog>
        );
    }
}

export default ConfirmDialog;
