import { Form } from "../../../Form";
import { FieldProps } from "../FieldProps";
import { Alert, InputAdornment, TextField, TextFieldProps } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Loader } from "@googlemaps/js-api-loader";

interface ILocationFieldProps extends FieldProps<any> {
    form: Form;
    readonly?: boolean;
}

export function LocationField(props: ILocationFieldProps) {
    const { form, field, value, disabled, readonly, onChange } = props;
    const [coordinates, setCoordinates] = useState<number[]>();
    const [error, setError] = useState<string>();
    const [inputValue, setInputValue] = useState<string>();

    const autocompleteRef = useRef<HTMLInputElement>();

    const onSelect = (place: google.maps.places.PlaceResult) => {
        console.log("selected", place);

        if (!place?.address_components?.length) return;

        console.log("place", place);

        if (place.geometry?.location) {
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();
            console.log("Location", lat, lng);

            onChange?.(field, `${lng},${lat}`);
        }
    };

    useEffect(() => {
        const ref = autocompleteRef?.current;
        if (!ref) {
            console.log("no ref");
            return;
        }

        console.log("load library");
        const loader = new Loader({
            apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
            libraries: ["places"]
        });

        loader
            .load()
            .then((google) => {
                console.log("loaded", google.maps.places);
                if (!google?.maps?.places?.Autocomplete) {
                    console.error("can't use library: google?.maps?.places?.Autocomplete");
                    return;
                }

                const options: google.maps.places.AutocompleteOptions = {
                    componentRestrictions: { country: ["us", "ca"] },
                    fields: ["address_components", "name", "geometry/location"],
                    types: ["address"]
                };

                const autocomplete = new google.maps.places.Autocomplete(ref, options);
                const callback = onSelect;
                new (google.maps.event.addListener as any)(autocomplete, "place_changed", () => {
                    const place = autocomplete.getPlace();
                    callback(place);
                });
            })
            .catch(e => {
                // do something
                console.error("failed to load google maps library", e);
            });
    }, []);

    useEffect(() => {
        console.log('value changed', value);
        if (!value) {
            setCoordinates(undefined);
            return;
        }

        if (Array.isArray(value.coordinates) && value.coordinates.length === 2) {
            setCoordinates(value.coordinates);
            return;
        }

        if (typeof (value) === "string") {
            const parts = (value as string).split(",");
            if (parts.length !== 2) {
                setError("Invalid location");
                return;
            }
            setCoordinates([
                parseFloat(parts[0]),
                parseFloat(parts[1])
            ]);
            return;
        }

        setError("Unexpected location");

    }, [value]);

    useEffect(()=>{
        const textValue = coordinates ? `${coordinates[0].toFixed(6)}, ${coordinates[1].toFixed(6)}` : "";
        setInputValue(textValue);

    }, [coordinates]);

    const onKeyPressed = (event: any) => {
        let value: string | null = event.target.value;
        if (!value || value?.trim() === "") {
            return;
        }

        const parts = value.split(',');
        if (parts.length===2) {
            // lat/long?
            try {
                const lng = parseFloat(parts[0]);
                const lat = parseFloat(parts[1]);
                if (lat>=-90 && lat<=90 && lng>=-180 && lng<=180) {
                    console.log("Location", lat, lng);
                    onChange?.(field, `${lng},${lat}`);
                    return;
                }
            } catch{
                // ...
            }
        }
    };

    const onChangeValue = (event: any) => {
        console.log('onChangeValue', event.target.value);
        let value: string | null = event.target.value;
        if (value?.trim() === "") {
            value = null;
        }

        setInputValue(value ?? "");
    };

    const renderMap = (coordinates: number[]) => {
        const url = `https://maps.googleapis.com/maps/api/staticmap?markers=color:blue%7C${coordinates[1]},${coordinates[0]}&size=400x400&zoom=14&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;

        return (
            <div style={{
                padding: 8,
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                overflow: "auto"
            }}>
                <img src={url} alt="map" />
            </div>
        );
    };

    if (error) {
        return <Alert severity="error">{error}</Alert>;
    }

    if (disabled || readonly) {
        if (!coordinates) {
            return (
                <div style={{
                    padding: 8,
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    overflow: "auto"
                }}>
                    &nbsp;
                </div>
            );
        }

        // TODO: check options
        // return renderMap(coordinates);

        return (
            <div>LNG: {coordinates[0]}<br /> LAT: {coordinates[1]}</div>
        );
    }

    const onClear = () => {
        form.setValue(field, undefined);
    };

    const textFieldProps: TextFieldProps = {
        required: field.isRequired,
        id: field.name!,
        label: field.label ?? field.name!,
        disabled: disabled,
        value: inputValue,
        onChange: onChangeValue,
        onKeyPress: onKeyPressed,
        // helperText: this.props.error ? this.props.error : null,
        margin: "normal",
        // style: {
        //     ...this.props.field.style,
        //     ...this.props.style
        // },
        autoFocus: true,
        InputProps: {
            endAdornment: (
                <InputAdornment position="end">
                    <IconButton onClick={onClear} sx={{ padding: "2px" }}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                    {/*<IconButton sx={{ padding: "2px" }}>*/}
                    {/*    <ArrowDropDownIcon />*/}
                    {/*</IconButton>*/}
                </InputAdornment>
            )
        },
        InputLabelProps: { shrink: true },
        fullWidth: true,
        multiline: false,
        rows: 1
    };

    return <TextField inputRef={autocompleteRef} variant="filled" {...textFieldProps}  />;
}