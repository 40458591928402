import * as React from "react";
import { FieldProps } from "./FieldProps";
import { FileDropTarget } from "../../../ui/FileDropTarget";

// function loadFile(file: File): Promise<string> {
//     return new Promise((resolve, reject) => {
//         var reader = new FileReader();
//         var buffer = '';

//         reader.onload = (onload: ProgressEvent<FileReader>) => {
//             if (onload.target) {
//                 const str = onload.target['result'];
//                 buffer += str;
//             }
//         };

//         reader.onloadend = (ev: ProgressEvent<FileReader>) => {
//             resolve(buffer);
//         };

//         reader.onerror = (ev: ProgressEvent<FileReader>) => {
//             console.error(ev);
//             reject('error');
//         };

//         reader.readAsText(file);
//     });
// }

export class FileField extends React.Component<FieldProps> {
    render() {
        return (
            <div>
                <FileDropTarget onFilesDropped={this.loadFiles}>
                    <input type="file" onChange={this.onChange} />
                </FileDropTarget>
            </div>
        );
    }

    onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            // console.log('files', e.target.files);
            this.loadFile(e.target.files[0]);
        }
        // var value = e.target.value;

        // if (this.props.onChange) {
        //     this.props.onChange(this.props.field, value);
        // }
    };

    loadFiles = async (files: File[]) => {
        if (files.length !== 1) {
            alert("only one file");
            return;
        }

        this.loadFile(files[0]);
    };

    loadFile = async (file: File) => {
        // var contents = await loadFile(file);
        // // await this.parse(contents);
        // console.log(contents);

        if (this.props.onChange) {
            this.props.onChange(this.props.field, file);
        }
    };
}
