import { observer } from "mobx-react";
import * as React from "react";

import app, { App } from "../../../application/App";
import { ActionMenuItem, Menu, MenuItem, PageMenuItem } from "../../../application/Menu";

import ListItemText from "@mui/material/ListItemText";
// import ListSubheader from '@mui/material/ListSubheader';
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Collapse, Typography } from "@mui/material";
import MuiMenuItem from "@mui/material/MenuItem";
import MuiMenuList from "@mui/material/MenuList";
//import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Divider from "@mui/material/Divider";

@observer
export default class TopMenuComponent extends React.Component {
    app: App;

    constructor(props: any) {
        super(props);
        this.app = app();
    }

    selectPage = (page: PageMenuItem) => {
        // console.log(`select page ${page.label}`);
        this.app.selectPageAsync(page.page, {
            name: page.name,
            label: page.label,
        });
    };

    execute = (action: ActionMenuItem) => {
        // console.log(`execute action ${action.label}`);
        if (!action.action) return;
        this.app.executeAsync(action.action);
    };

    renderPage(item: PageMenuItem) {
        var isSelected = this.app.page && item.page === this.app.page.name;

        return (
            <MuiMenuItem selected={isSelected} key={item.name} onClick={() => this.selectPage(item)} disabled={!item.isEnabled}>
                <ListItemText primary={<Typography>{item.label}</Typography>} />
            </MuiMenuItem>
        );
    }

    renderAction(item: ActionMenuItem) {
        return (
            <MuiMenuItem key={item.name} onClick={() => this.execute(item)} disabled={!item.isEnabled}>
                <ListItemText primary={<Typography>{item.label}</Typography>} />
            </MuiMenuItem>
        );
    }

    renderMenu(menu: Menu) {
        if (!menu.isEnabled) {
            return (
                <MuiMenuItem key={menu.name} disabled={true}>
                    <ListItemText primary={<Typography color="disabled">{menu.label}</Typography>} />
                </MuiMenuItem>
            );
        }

        var style = menu.open && menu.collapsible ? { backgroundColor: "#77777722" } : {};

        return (
            <div key={menu.name}>
                <MuiMenuItem key={menu.name} onClick={() => (menu.open = !menu.open)} style={style}>
                    <ListItemText primary={<Typography>{menu.label}</Typography>} />
                    {menu.open ? menu.collapsible ? <ExpandLess /> : undefined : <ExpandMore />}
                </MuiMenuItem>

                <Collapse
                    in={menu.open}
                    timeout="auto"
                    unmountOnExit={true}
                    style={{
                        ...style,
                        paddingLeft: "16px",
                    }}
                >
                    <MuiMenuList>{menu.items.map((item) => this.renderItem(item))}</MuiMenuList>
                </Collapse>
                {menu.open && <Divider />}
            </div>
        );
    }

    renderItem(item: MenuItem) {
        if (!item.isVisible) {
            return undefined;
        }

        if (item instanceof PageMenuItem) {
            return this.renderPage(item);
        }

        if (item instanceof Menu) {
            return this.renderMenu(item);
        }

        if (item instanceof ActionMenuItem) {
            return this.renderAction(item);
        }

        return (
            <MuiMenuItem key={item.name}>
                <ListItemText primary={<Typography>{item.label}</Typography>} />
            </MuiMenuItem>
        );
    }

    render() {
        if (!this.app.menu || this.app.menu.items.length < 1) return <div />;

        return <MuiMenuList>{this.app.menu.items.map((item) => this.renderItem(item))}</MuiMenuList>;
    }
}
