import { FormControl } from "@mui/material";
import MuiTextField from "@mui/material/TextField";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { InputTextField } from "./InputTextField";

export class TimeField extends InputTextField {
    protected onChange = (value: moment.Moment | null, keyboardInputValue?: string) => {

        if (this.props.onChange) {
            this.props.onChange(this.props.field, value?.format("YYYY-MM-DDTHH:mm") ?? null);
        }
    };

    render() {
        let { value } = this.props;
        if (value) {
            if (value.length === 5) {
                const parts = value.split(":");
                value = moment().startOf('day').add(parseInt(parts[0]), 'hours').add(parseInt(parts[1]), 'minutes').format("YYYY-MM-DDTHH:mm");
            } else {
                value = moment(value).format("YYYY-MM-DDTHH:mm");
            }
        }

        const props = {
            ...this.calcProps(),
            fullWidth: true,
            type: "time",
            value
        };

        if (!props.value && props.disabled) {
            // prevent rendering place holder
            props.type = "text";

            return <MuiTextField {...props} variant="filled" />;
        }

        return (
            <div
                style={{
                    display: "flex",
                    paddingTop: 16,
                    paddingBottom: 8,
                    // ...style,
                }}
                key={this.props.field.name}
            >
                <FormControl style={{ width: "100%" }} >
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <TimePicker
                            value={value}
                            disabled={props.disabled}
                            onChange={this.onChange}
                            renderInput={(p) => <MuiTextField {...p} label={props.label} variant="filled" required={props.required ?? false} />}
                        />
                    </LocalizationProvider>
                </FormControl>
            </div>
        );
    }
}
