import { App } from "src/pi/application/App";
import { DataView } from "src/pi/ui/DataView";
import * as User from "./User";
// import DisableO365Sync from './DisableO365Sync';
// import EnableO365Sync from './EnableO365Sync';
import * as Account from "./Account";
import * as Appointment from "./Appointment";
import * as AppointmentType from "./AppointmentType";
import * as LeadType from "./LeadType";
import * as OrgMetaData from "./OrgMetaData";
import * as Settings from "./Settings";
import * as Singer from "./Singer";

import { Form } from "src/pi/ui/Form";

import { Page } from "src/pi/application/Page";
import { Default } from "src/pi/context/AppContext";

export default function register() {
    App.bindAction("linkMicrosoftAccount", Account.addMicrosoft);
    App.bindAction("linkGTMAccount", Account.addGoToMeeting);
    App.bindAction("linkZoomAccount", Account.addZoom);
    App.bindAction("linkRealMagic", Account.addRealMagic);
    App.bindAction("linkSalesforce", Account.addSalesforce);
    App.bindAction("o365EnableAdmin", Account.enableO365AdminAcces);

    App.bindAction("loginOffice365", User.signInWithMicrosoft);
    App.bindAction("loginInspireNet", () => User.signIn("InspireNet"));
    App.bindAction("loginSalesforce", () => User.signIn("Salesforce"));
    App.bindAction("loginGoogle", () => User.signIn("Google"));
    App.bindAction("login", () => User.signIn());

    App.bindAction("logout", User.logout);

    // App.bindAction('disableO365Sync', DisableO365Sync);
    // App.bindAction('enableO365Sync', EnableO365Sync);

    App.bindAction("AddAppointment", Appointment.add);

    DataView.bindAction("AppointmentTypes", "add", AppointmentType.add);
    // DataView.bindAction('LeadTypes', 'add', LeadType.add);

    DataView.bindAction("SingerJobs", "execute", Singer.sync);
    Page.bindAction("SingerJobs", "execute", Singer.sync);

    Default.actions.set("save", LeadType.saveMapping, "page.LeadType.Mapping");
    Default.actions.set("save", LeadType.saveMapping, "page.AppointmentType.Mapping");
    Default.actions.set("save", LeadType.saveLeadTypeSettings, "page.LeadType.Settings");

    DataView.bindAction("AppointmentIntegrations", "openAppointment", Appointment.openAppointment);

    // Form.bindAction("Appointment", "cancel", Appointment.cancel);
    Form.bindAction("Appointment", "openLead", Appointment.openLead);
    Form.bindAction("Appointment", "open", Appointment.open);

    Form.bindAction("AppointmentType", "Save", AppointmentType.save);
    Form.bindAction("AppointmentTypeSettings", "Save", AppointmentType.saveAll);
    Form.bindAction("AppointmentTypeUrl", "DisableScheduler", AppointmentType.disableScheduler);

    // Form.bindAction("SlackAccountIntegration", "Save", SlackIntegration.save);
    // Form.bindAction("SlackAccountIntegration", "Delete", SlackIntegration.disableAccountIntegration);
    // Form.bindAction("SendGridAccountIntegration", "Save", SendGridIntegration.save);
    // Form.bindAction("SendGridAccountIntegration", "Delete", SendGridIntegration.disableAccountIntegration);

    // Form.bindAction("LeadType", "Save", LeadType.save);
    // Form.bindAction("LeadType", "Delete", LeadType.deleteLeadType);

    Form.bindAction("WorkingHours", "save", Settings.saveWorkingHours);
    Form.bindAction("BasicAppointmentTypeSettings", "Save", AppointmentType.saveSettingsAsync);

    // Form.bindAction('InspirenetDialog', 'save', Inspirenet.addEvent);

    DataView.bindAction("Settings", "edit", Settings.edit);

    DataView.bindAction("UserIdentities", "AddMicrosoft", Account.addMicrosoft);
    DataView.bindAction("UserIdentities", "AddZoom", Account.addZoom);
    DataView.bindAction("UserIdentities", "AddGoToMeeting", Account.addGoToMeeting);
    DataView.bindAction("UserIdentities", "AddRealMagic", Account.addRealMagic);
    DataView.bindAction("UserIdentities", "AddSalesforce", Account.addSalesforce);

    // DataView.bindAction("Flows", "add", Flow.add);

    DataView.bindAction("OrgMetaData", "save", OrgMetaData.save);
    DataView.bindAction("OrgMetaData", "remove", OrgMetaData.remove);
}
