import { IDictionary, Dictionary } from "./Dictionary";

export interface IService {}

export class Service implements IService {}

export interface IServices extends IDictionary<any> {
    register(t: typeof Service, service: Service): void;
    getService<T extends Service>(type: typeof Service): T;
}

export class Services extends Dictionary<IService> implements IServices {
    register(t: typeof Service, service: Service): void {
        // console.log(`Registered Service ${t.name} -> ${service.constructor.name}`);
        this.set(t.name, service);
    }
    getService<T extends Service>(t: typeof Service): T {
        // console.log(`get Service ${t.name}`);
        return this.get(t.name) as T;
    }
}
