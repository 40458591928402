import CloseIcon from "@mui/icons-material/Close";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { StyleSheet, css } from "aphrodite";
import { useState } from "react";
import { UnlayerTemplateEditor } from "./UnlayerTemplateEditor";

const styles = StyleSheet.create({
    dialogContent: {
        padding: 0,
        overflow: "hidden",
    },
});

interface IUnlayerDialogProps {
    id?: string;
    title: string;
    initValues: object;
    onDone: (id: string | undefined) => void;
}

export function UnlayerDialog({ title, onDone, id, initValues }: IUnlayerDialogProps) {
    const [isSaving, setSaving] = useState<boolean>();

    const onClose = () => {
        setSaving(false);
        onDone(undefined);
    };

    const onSaveClick = () => {
        setSaving(true);
    };

    const onStopSaving = () => {
        setSaving(false);
    };

    return (
        <Dialog title={title} onClose={onClose} open={true} scroll="paper" maxWidth={"xl"} fullScreen={true}>
            <DialogTitle
                sx={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                {title}
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={css(styles.dialogContent)} dividers={true}>
                <UnlayerTemplateEditor onDone={onDone} id={id} showSave={isSaving ?? false} onStopSaving={onStopSaving} initValues={initValues} />
            </DialogContent>
            <DialogActions style={{ padding: 24 }}>
                <Button onClick={onSaveClick} variant="contained">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
