import { IDictionary, Dictionary } from "./Dictionary";

export type IAction = string | Function;

export interface IActions extends IDictionary<Function> {
    execute(action: IAction, context?: string, ...args: any[]): any;
    executeAsync<T>(action: IAction, context?: string, ...args: any[]): Promise<T>;
    // registerListener<T extends Function>(context: string, fieldName: string, event: Event, action: T);
    // getListener<T extends Function>(context: string, fieldName: string, event: Event): T | undefined;
}

export class Actions extends Dictionary<Function> implements IActions {
    // registerListener<T extends Function>(context: string, fieldName: string, event: Event, action: T) {
    //     var key = `${context}.${fieldName}.${event}`;
    //     console.log(`Registering event listener: ${key}`);
    //     this.set(key, action);
    // }

    // getListener<T extends Function>(context: string, fieldName: string, event: Event): T | undefined {
    //     var key = `${context}.${fieldName}.${event}`;
    //     return this.get(key) as T;
    // }

    execute(action: IAction, context?: string, ...args: any[]): any {
        if (!action) throw new Error("Missing Action");

        if (typeof action === "string") {
            var name = Actions.resolve(action, context);
            var found = this.get(name);
            if (!found) {
                const msg = `Action not found: ${name}`;
                console.error(msg);
                // throw new Error(msg);
                return null;
            }
            action = found;
        }

        if (typeof action === "function") {
            return action.call(this, context, args);
        }

        throw new Error("Invalid Action");
    }

    // ????
    async executeAsync<T>(action: IAction, context?: string, ...args: any[]): Promise<T> {
        if (!action) throw new Error("Missing Action");

        if (typeof action === "string") {
            var name = Actions.resolve(action, context);
            var found = this.get(name);
            if (!found) {
                let msg = `Action not found: ${name}`;
                console.error(msg);
                throw new Error(msg);
            }
            action = found;
        }

        if (typeof action === "function") {
            return await action.call(this, context, args);
        }

        throw new Error("Invalid Action");
    }
}
