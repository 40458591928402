import { observer } from "mobx-react";
import * as React from "react";

import { Button, Menu, MenuItem } from "@mui/material";
import IconButton from "@mui/material/IconButton";

import App from "../../../application/App";
import { evaluate, evaluateWithContext } from "../../../context/Expression";
import { IActionMenuItem, IMenu, IMenuItem } from "../../../context/IMenu";
import { NamedIcon } from "./NamedIcon";
import { ProxyMenuItem } from "../DataViewComponent/ObjectPopupMenu";
import { Default } from "../../../context/AppContext";
import { ActionMenuItem } from "@crochik/pi-api";

interface Props {
    menu?: IMenu;
    context: string | object;
    style?: React.CSSProperties;
    onAction?: (action: IActionMenuItem, event: React.MouseEvent) => any;
}

interface State {
    anchor?: HTMLElement;
    menu?: IMenu;
}

@observer
export default class ActionMenuBar extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    execute = (action: IActionMenuItem, event: React.MouseEvent) => {
        const { context, onAction } = this.props;

        if (onAction) {
            return onAction(action, event);
        }

        if (!action.action || typeof (context) != "string") return false;

        return App().executeAsync(action.action, context, event);
    };

    evaluateState(conditions?: string[]|null): boolean {
        const { context } = this.props;
        return (typeof (context) === "string") ?
            evaluate(conditions, context) :
            evaluateWithContext(context, conditions);
    }

    onClick = (item: IMenuItem) => (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.stopPropagation();

        if ("action" in item) {
            const action = item as IActionMenuItem;
            this.execute(action, event);
            this.onCloseMenu();
            return;
        }

        if ("items" in item) {
            const menu = item as IMenu;
            this.setState({
                anchor: event.currentTarget,
                menu,
            });
            return;
        }
    };

    renderTopMenuItem(item: IMenuItem) {
        const isEnabled = this.evaluateState(item.enable);

        if (item.icon) {
            return (
                <IconButton key={item.name!} color="inherit" onClick={this.onClick(item)} title={item.label ?? item.name!} disabled={!isEnabled}>
                    <NamedIcon icon={item.icon} />
                </IconButton>
            );
        }

        return (
            <Button key={item.name} onClick={this.onClick(item)} color="inherit" disabled={!isEnabled}>
                {item.label || item.name}
            </Button>
        );
    }

    onCloseMenu = () => {
        this.setState({
            anchor: undefined,
            menu: undefined,
        });
    };

    renderPopupMenu() {
        const { anchor, menu } = this.state;

        if (!anchor || !menu) return;

        let context : object;

        if (typeof(this.props.context)==="string") {
            console.error('context', this.props.context);
            context = Default.state.get(this.props.context);
            // return null;
        } else {
            context = this.props.context;
        }

        const onItemAction = (item: ActionMenuItem, event: React.MouseEvent) => {
            console.log('clicked item', item);

            this.execute(item, event);
            this.onCloseMenu();
        };

        return (
            <Menu
                id="long-menu"
                keepMounted={false}
                open={true}
                anchorEl={anchor}
                onClose={this.onCloseMenu}
                PaperProps={{
                    style: {
                        maxHeight: 400,
                        minWidth: 200,
                    },
                }}
            >
                {menu.items && menu.items.map((x) => <ProxyMenuItem item={x} context={context} onAction={onItemAction} />)}
            </Menu>
        );
    }

    isVisible = (menuItem: IMenuItem) => {
        if (!menuItem) return false;
        if (!this.evaluateState(menuItem.visible)) return false;
        if ("action" in menuItem) return true;
        if (!("items" in menuItem)) return false;
        return (menuItem.items as IMenuItem[]).find(x => this.evaluateState(x.visible));
    }

    render() {
        const { menu, style } = this.props;
        if (!menu || !menu.items || menu.items.length < 1) return null;

        const menuItems = menu?.items?.filter(this.isVisible);
        if (!menuItems || menuItems.length < 1) return null;

        return (
            <div style={style}>
                {menuItems.map((x) => this.renderTopMenuItem(x))}
                {this.renderPopupMenu()}
            </div>
        );
    }
}
