import { css, StyleSheet } from "aphrodite";
import * as React from "react";
import { useTheme } from "@mui/material";

export default function TypingIndicator(){
    const theme = useTheme()

    return (
        <div className={css(styles.typing)} style={{background: theme.palette.primary.main}}>
            <div className={css(styles.typing_dot)} style={{background: theme.palette.primary.contrastText}}></div>
            <div className={css(styles.typing_dot)} style={{background: theme.palette.primary.contrastText}}></div>
            <div className={css(styles.typing_dot)} style={{background: theme.palette.primary.contrastText}}></div>
        </div>
    )
}

const keyframes = {
    "0%": {
        opacity: 0
    },

    "50%": {
        opacity: .8
    },

    "100%": {
        opacity: 0
    }
};

const styles = StyleSheet.create({
    typing: {
        width: "5em",
        height: "2em",
        position: "relative",
        padding: "10px",
        marginLeft: "5px",
        borderRadius: "20px",
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly'
    },

    typing_dot: {
        float: "left",
        width: "8px",
        height: "8px",
        margin: "0 4px",
        borderRadius: "50%",
        animationName: [keyframes],
        animationDuration: '2s',
        animationIterationCount: 'infinite',

        ":nth-child(1)": {
            animationDelay: "0s",
        },
        ":nth-child(2)": {
            animationDelay: "0.4s",
        },
        ":nth-child(3)": {
            animationDelay: "0.8s",
        }
    },

});