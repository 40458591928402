import { Dialog, DialogContent, DialogTitle, IconButton, useMediaQuery, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { css, StyleSheet } from "aphrodite";
import { Scheduler } from "./Scheduler";
import { ISchedulerProps } from "./ISchedulerProps";

export function SchedulerDialog(props: ISchedulerProps) {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const { onClose, title } = props;

    return (
        <Dialog title={title} onClose={onClose} open={true} scroll="paper" maxWidth={"md"} fullScreen={isSmallScreen}>
            <DialogTitle
                sx={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between"
                }}
            >
                {title}
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={css(styles.dialogContent)} dividers={false}>
                <Scheduler {...props} />
            </DialogContent>
        </Dialog>
    );
}

const styles = StyleSheet.create({
    dialogContent: {
        padding: 24,
        paddingTop: 0,
    },
});
