import { IDictionary, Dictionary } from "./Dictionary";

export interface IState extends IDictionary<any> {
    // returns whether all the 'state values' are not null/false
    evaluate(keys: string[] | undefined, context: string | undefined): boolean;
    setValue(context: string, name: string, value: any): any;
}

export class State extends Dictionary<any> implements IState {
    evaluate(keys: string[] | undefined, context: string | undefined): boolean {
        if (!keys) return true;

        for (var c = 0; c < keys.length; c++) {
            var key = State.resolve(keys[c], context);
            var value = this.get(key);
            if (!value) {
                console.debug(`evaluate return false for ${key}: ${value}`);
                return false;
            }
        }

        return true;
    }

    setValue(context: string, name: string, value: any): any {
        var key = State.resolve(name, context);
        return this.set(key, value);
    }
}
