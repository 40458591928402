import { Typography } from "@mui/material";
import MuiTextField, { TextFieldProps } from "@mui/material/TextField";
import sanitizeHtml from "sanitize-html";
import { ITextFieldOptions } from "../../../context/IForm";
import { FieldProps } from "./FieldProps";
import { InputTextField } from "./InputTextField";

interface IRowProperties extends FieldProps<string> {
    children?: React.ReactElement;
}

function Row(props: IRowProperties) {
    const { field, children, disabled } = props;
    // const theme = useTheme(); // how to get the background color for disabled fields from theme? ?!??!?!?!?!
    // ...
    const backgroundColor = disabled ? "#e0e0e0" : "#fcfcfc";
    return (
        <div key={field.name} style={{ marginTop: 6, marginBottom: 6, backgroundColor, padding: 8, borderRadius: 6 }}>
            <Typography variant="subtitle2" color="default">
                {field.label || field.name}
            </Typography>
            {children}
        </div>
    );
}

export function HtmlField(props: FieldProps<string>) {
    const { value } = props;

    const clean = value
        ? sanitizeHtml(value, {
            allowedTags: ["p", "em", "strong", "b", "i", "span"],
            // allowedAttributes: {
            //     'p': ["style"],
            // },
            allowedStyles: {
                "*": {
                    // Match HEX and RGB
                    color: [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
                    "text-align": [/^left$/, /^right$/, /^center$/]
                    // Match any number with px, em, or %
                    // 'font-size': [/^\d+(?:px|em|%)$/]
                }
                // 'p': {
                //     'font-size': [/^\d+rem$/]
                // }
            }
        })
        : "<div>&nbsp;</div>";

    return (
        <Row {...props}>
            <div style={{ maxHeight: "300px", overflow: "auto", padding: 8 }}
                 dangerouslySetInnerHTML={{ __html: clean }} />
        </Row>
    );
}

export class TextField extends InputTextField {
    render() {
        const options = this.props.field.options as ITextFieldOptions;

        const { multline, contentType } = options ?? {};
        if (this.props.disabled && contentType === "text/html") return <HtmlField {...this.props} />;

        var props: TextFieldProps = {
            ...this.calcProps(),
            fullWidth: true,
            type: "text",
            sx: {
                marginTop: "16px",
                marginBottom: "8px",
                maxHeight: "300px",
                overflow: "auto"
            }
        };

        if (multline) {
            // prevent auto-submit and allow multiple lines
            props = {
                ...props,
                multiline: true
            };

            if (Array.isArray(this.props.value)) {
                props.value = this.props.value.reduce((p, c) => !!p ? `${p}\n${c}` : c);
            }

            delete props.onKeyPress;
        }

        return <MuiTextField {...props} variant="filled" className="TextField" />;
    }
}
