import * as React from "react";
import { DataView } from "src/pi/ui/DataView";

export interface IOnClickArgs {
    row: object;
    field?: string | null;
    linkUrl?: string | null;
}

export interface IDataViewContext {
    dataView: DataView;
    onClick: (e: React.MouseEvent, args: IOnClickArgs) => void;
    onObjectMenu?: (anchor: HTMLElement, row: object, objectType?: string) => void;
}

export const DataViewContext = React.createContext<IDataViewContext | null>(null);

export const useDataViewContext = () => {
    const value = React.useContext(DataViewContext);
    if (!!value) {
        return value;
    }
    throw new Error("Invalid context.");
};
