import { Client } from "../../pi/api/Client";
import App from "../../pi/application/App";

function link(provider: string, tenant?: string) {
    console.log("Link Account");

    const apiConfig = App().clientConfiguration;
    const accessToken = apiConfig ? apiConfig.accessToken : undefined;
    if (!accessToken) {
        console.error("Missing token");
        return;
    }

    const authority = process.env.REACT_APP_authority;

    let url = `${authority}/ExternalAccount/Add?provider=${provider}&returnUrl=${encodeURIComponent(document.location.href)}&token=${accessToken}`;
    if (tenant) url += `&tenant=${tenant}`;
    document.location.href = url;
}

export function addMicrosoft() {
    link("Microsoft");
}

export function addAccount() {
    const tenantId = process.env.REACT_APP_microsoft_tenant;
    link("Microsoft", tenantId);
}

export function addZoom() {
    link("Zoom");
}

export function addGoToMeeting() {
    link("GoToMeeting");
}

export function addSalesforce() {
    link("Salesforce");
}

export function addRealMagic() {
    link("RealMagic");
}

interface IMicrosoftEnableAdmin {
    url: string;
}

export async function enableO365AdminAcces() {
    const client = App().apiClient;
    if (!client) {
        console.error("No client");
        return;
    }

    const resp = await client.getJson<IMicrosoftEnableAdmin>("/o365/v1/Admin/EnableAdmin").catch((reason) => {
        console.error("failed to get url", reason);
    });

    if (resp) {
        window.open(resp.url, "_blank");
    }
}
