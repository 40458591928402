// TODO: make this more generic and shared

export function parseError(requestError: any) {
    if ("statusText" in requestError) {
        return requestError["statusText"];
    }

    if (typeof requestError === "string") {
        return requestError;
    }

    return "Unexpected error";
}
