import { MaskTextField } from "./MaskTextField";
import { email } from "./TextMask";

export class EmailField extends MaskTextField {
    getInputProps() {
        var props = {
            ...super.getInputProps(),
            type: "text",
            inputComponent: email,
        };

        return props;
    }
}
