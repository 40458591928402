import { css, StyleSheet } from "aphrodite";
import { useForm } from "src/pi/hooks/useForm";
import { FormCell } from "../FormCell/FormCell";

export function StackFormLayout() {
    const form = useForm();
    return (
        <div className={css(styles.container)}>
            {form.fields?.map((field, index) => {
                return <FormCell key={`form-cell-${index}`} field={field} form={form} index={index} />;
            })}
        </div>
    );
}

const styles = StyleSheet.create({
    container: {},
});
