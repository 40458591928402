import { Alert } from "@mui/material";
import { css, StyleSheet } from "aphrodite";
import { observer } from "mobx-react";
import { Component } from "react";
import * as api from "@crochik/pi-api";
import { Form } from "../../../Form";
import { FormCell } from "../FormCell/FormCell";

interface Props {
    form: Form;
    layout: api.GridFormLayout;
}

@observer
export class GridFormLayout extends Component<Props> {
    render() {
        const { layout, form } = this.props;

        if (!layout?.rows) {
            return <Alert severity="error">Missing Layout</Alert>;
        }

        let index = 0;
        return layout.rows.map((row, rowIndex) => {
            const nFields = row.fields?.length;
            if (!nFields) return null;

            return (
                <div key={`row-${rowIndex}`} className={css(styles.row)}>
                    {nFields && row.fields?.map((field, fieldIndex) => {
                        const totalWidth = row.fields!.reduce((p, f) => p + (f.width ?? 1), 0)
                        const flex = 12 * (field.width ?? 1) / totalWidth;
                        const fullField = form.fields?.find((x) => x.name === field.name);
                        if (!fullField) {
                            return null;
                        }
                        return (
                            <div
                                key={`row-${rowIndex}-field-${fieldIndex}`}
                                style={{ width: '100%', position: 'relative', flex, paddingLeft: fieldIndex > 0 ? 4 : 0, paddingRight: fieldIndex < nFields - 1 ? 4 : 0 }}
                                className="GridFormLayout_Cell"
                            >
                                <FormCell field={fullField} form={form} index={index++} />
                            </div>
                        );
                    })}
                </div>
            );
        });
    }
}

const styles = StyleSheet.create({
    row: {
        display: "flex",
        flex: 1,
        // padding: 4,
    },
});
