/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * O365
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
// @ts-nocheck

import * as url from "url";
import * as portableFetch from "portable-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "https://localhost".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = portableFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError";
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 *
 * @export
 * @interface Actor
 */
export interface Actor {
    /**
     *
     * @type {string}
     * @memberof Actor
     */
    t?: string;
}

/**
 *
 * @export
 * @interface CalendarEvent
 */
export interface CalendarEvent {
    /**
     *
     * @type {string}
     * @memberof CalendarEvent
     */
    id?: string;
    /**
     *
     * @type {FreeBusyStatus}
     * @memberof CalendarEvent
     */
    showAs?: FreeBusyStatus;
    /**
     *
     * @type {CalendarEventType}
     * @memberof CalendarEvent
     */
    type?: CalendarEventType;
    /**
     *
     * @type {boolean}
     * @memberof CalendarEvent
     */
    isCancelled?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof CalendarEvent
     */
    isAllDay?: boolean;
    /**
     *
     * @type {string}
     * @memberof CalendarEvent
     */
    subject?: string;
    /**
     *
     * @type {Date}
     * @memberof CalendarEvent
     */
    start?: Date;
    /**
     *
     * @type {Date}
     * @memberof CalendarEvent
     */
    end?: Date;
    /**
     *
     * @type {string}
     * @memberof CalendarEvent
     */
    webLink?: string;
    /**
     *
     * @type {string}
     * @memberof CalendarEvent
     */
    source?: string;
}

/**
 *
 * @export
 * @enum {string}
 */
export enum CalendarEventType {
    SingleInstance = <any>"SingleInstance",
    Occurrence = <any>"Occurrence",
    Exception = <any>"Exception",
    SeriesMaster = <any>"SeriesMaster",
}

/**
 *
 * @export
 * @enum {string}
 */
export enum FreeBusyStatus {
    Free = <any>"Free",
    Tentative = <any>"Tentative",
    Busy = <any>"Busy",
    Oof = <any>"Oof",
    WorkingElsewhere = <any>"WorkingElsewhere",
    Unknown = <any>"Unknown",
}

/**
 *
 * @export
 * @interface O365Subscription
 */
export interface O365Subscription {
    /**
     *
     * @type {string}
     * @memberof O365Subscription
     */
    notificationUrl?: string;
    /**
     *
     * @type {string}
     * @memberof O365Subscription
     */
    resource?: string;
    /**
     *
     * @type {Date}
     * @memberof O365Subscription
     */
    expiresOn?: Date;
    /**
     *
     * @type {string}
     * @memberof O365Subscription
     */
    description?: string;
    /**
     *
     * @type {string}
     * @memberof O365Subscription
     */
    entityId?: string;
    /**
     *
     * @type {string}
     * @memberof O365Subscription
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof O365Subscription
     */
    accountId?: string;
    /**
     *
     * @type {string}
     * @memberof O365Subscription
     */
    name?: string;
    /**
     *
     * @type {Date}
     * @memberof O365Subscription
     */
    createdOn?: Date;
    /**
     *
     * @type {Date}
     * @memberof O365Subscription
     */
    lastModifiedOn?: Date;
    /**
     *
     * @type {Actor}
     * @memberof O365Subscription
     */
    lastActor?: Actor;
}

/**
 * AdminApi - fetch parameter creator
 * @export
 */
export const AdminApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableAdmin(options: any = {}): FetchArgs {
            const localVarPath = `/o365/v1/Admin/EnableAdmin`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === "function" ? configuration.accessToken("oauth2", []) : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [tenant]
         * @param {string} [state]
         * @param {boolean} [adminConsent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        microsoftAdmin(tenant?: string, state?: string, adminConsent?: boolean, options: any = {}): FetchArgs {
            const localVarPath = `/o365/v1/Admin/EnableAdmin/Callback`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tenant !== undefined) {
                localVarQueryParameter["tenant"] = tenant;
            }

            if (state !== undefined) {
                localVarQueryParameter["state"] = state;
            }

            if (adminConsent !== undefined) {
                localVarQueryParameter["admin_consent"] = adminConsent;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableAdmin(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdminApiFetchParamCreator(configuration).enableAdmin(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [tenant]
         * @param {string} [state]
         * @param {boolean} [adminConsent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        microsoftAdmin(tenant?: string, state?: string, adminConsent?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdminApiFetchParamCreator(configuration).microsoftAdmin(tenant, state, adminConsent, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    };
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableAdmin(options?: any) {
            return AdminApiFp(configuration).enableAdmin(options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [tenant]
         * @param {string} [state]
         * @param {boolean} [adminConsent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        microsoftAdmin(tenant?: string, state?: string, adminConsent?: boolean, options?: any) {
            return AdminApiFp(configuration).microsoftAdmin(tenant, state, adminConsent, options)(fetch, basePath);
        },
    };
};

/**
 * AdminApi - interface
 * @export
 * @interface AdminApi
 */
export interface AdminApiInterface {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    enableAdmin(options?: any): Promise<{}>;

    /**
     *
     * @param {string} [tenant]
     * @param {string} [state]
     * @param {boolean} [adminConsent]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    microsoftAdmin(tenant?: string, state?: string, adminConsent?: boolean, options?: any): Promise<{}>;
}

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI implements AdminApiInterface {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public enableAdmin(options?: any) {
        return AdminApiFp(this.configuration).enableAdmin(options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [tenant]
     * @param {string} [state]
     * @param {boolean} [adminConsent]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public microsoftAdmin(tenant?: string, state?: string, adminConsent?: boolean, options?: any) {
        return AdminApiFp(this.configuration).microsoftAdmin(tenant, state, adminConsent, options)(this.fetch, this.basePath);
    }
}

/**
 * EventApi - fetch parameter creator
 * @export
 */
export const EventApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {Date} [start]
         * @param {Date} [end]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvents(start?: Date, end?: Date, options: any = {}): FetchArgs {
            const localVarPath = `/o365/v1/Event`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === "function" ? configuration.accessToken("oauth2", []) : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (start !== undefined) {
                localVarQueryParameter["start"] = (start as any).toISOString();
            }

            if (end !== undefined) {
                localVarQueryParameter["end"] = (end as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {Date} [start]
         * @param {Date} [end]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserEvents(id: string, start?: Date, end?: Date, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling getUserEvents.");
            }
            const localVarPath = `/o365/v1/User({id})/Event`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === "function" ? configuration.accessToken("oauth2", []) : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (start !== undefined) {
                localVarQueryParameter["start"] = (start as any).toISOString();
            }

            if (end !== undefined) {
                localVarQueryParameter["end"] = (end as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * EventApi - functional programming interface
 * @export
 */
export const EventApiFp = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {Date} [start]
         * @param {Date} [end]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvents(start?: Date, end?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CalendarEvent>> {
            const localVarFetchArgs = EventApiFetchParamCreator(configuration).getEvents(start, end, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} id
         * @param {Date} [start]
         * @param {Date} [end]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserEvents(id: string, start?: Date, end?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CalendarEvent>> {
            const localVarFetchArgs = EventApiFetchParamCreator(configuration).getUserEvents(id, start, end, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    };
};

/**
 * EventApi - factory interface
 * @export
 */
export const EventApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {Date} [start]
         * @param {Date} [end]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvents(start?: Date, end?: Date, options?: any) {
            return EventApiFp(configuration).getEvents(start, end, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} id
         * @param {Date} [start]
         * @param {Date} [end]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserEvents(id: string, start?: Date, end?: Date, options?: any) {
            return EventApiFp(configuration).getUserEvents(id, start, end, options)(fetch, basePath);
        },
    };
};

/**
 * EventApi - interface
 * @export
 * @interface EventApi
 */
export interface EventApiInterface {
    /**
     *
     * @param {Date} [start]
     * @param {Date} [end]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApiInterface
     */
    getEvents(start?: Date, end?: Date, options?: any): Promise<Array<CalendarEvent>>;

    /**
     *
     * @param {string} id
     * @param {Date} [start]
     * @param {Date} [end]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApiInterface
     */
    getUserEvents(id: string, start?: Date, end?: Date, options?: any): Promise<Array<CalendarEvent>>;
}

/**
 * EventApi - object-oriented interface
 * @export
 * @class EventApi
 * @extends {BaseAPI}
 */
export class EventApi extends BaseAPI implements EventApiInterface {
    /**
     *
     * @param {Date} [start]
     * @param {Date} [end]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public getEvents(start?: Date, end?: Date, options?: any) {
        return EventApiFp(this.configuration).getEvents(start, end, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} id
     * @param {Date} [start]
     * @param {Date} [end]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public getUserEvents(id: string, start?: Date, end?: Date, options?: any) {
        return EventApiFp(this.configuration).getUserEvents(id, start, end, options)(this.fetch, this.basePath);
    }
}

/**
 * NotificationApi - fetch parameter creator
 * @export
 */
export const NotificationApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} accountId
         * @param {string} [validationToken]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        post(accountId: string, validationToken?: string, options: any = {}): FetchArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling post.");
            }
            const localVarPath = `/o365/v1/Notification/{accountId}`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "POST" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (validationToken !== undefined) {
                localVarQueryParameter["validationToken"] = validationToken;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} accountId
         * @param {string} userId
         * @param {string} [validationToken]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        post_1(accountId: string, userId: string, validationToken?: string, options: any = {}): FetchArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling post_1.");
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError("userId", "Required parameter userId was null or undefined when calling post_1.");
            }
            const localVarPath = `/o365/v1/Notification/{accountId}/{userId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "POST" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (validationToken !== undefined) {
                localVarQueryParameter["validationToken"] = validationToken;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} accountId
         * @param {string} [validationToken]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        post(accountId: string, validationToken?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = NotificationApiFetchParamCreator(configuration).post(accountId, validationToken, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} accountId
         * @param {string} userId
         * @param {string} [validationToken]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        post_1(accountId: string, userId: string, validationToken?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = NotificationApiFetchParamCreator(configuration).post_1(accountId, userId, validationToken, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    };
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {string} accountId
         * @param {string} [validationToken]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        post(accountId: string, validationToken?: string, options?: any) {
            return NotificationApiFp(configuration).post(accountId, validationToken, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} accountId
         * @param {string} userId
         * @param {string} [validationToken]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        post_1(accountId: string, userId: string, validationToken?: string, options?: any) {
            return NotificationApiFp(configuration).post_1(accountId, userId, validationToken, options)(fetch, basePath);
        },
    };
};

/**
 * NotificationApi - interface
 * @export
 * @interface NotificationApi
 */
export interface NotificationApiInterface {
    /**
     *
     * @param {string} accountId
     * @param {string} [validationToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApiInterface
     */
    post(accountId: string, validationToken?: string, options?: any): Promise<{}>;

    /**
     *
     * @param {string} accountId
     * @param {string} userId
     * @param {string} [validationToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApiInterface
     */
    post_1(accountId: string, userId: string, validationToken?: string, options?: any): Promise<{}>;
}

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI implements NotificationApiInterface {
    /**
     *
     * @param {string} accountId
     * @param {string} [validationToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public post(accountId: string, validationToken?: string, options?: any) {
        return NotificationApiFp(this.configuration).post(accountId, validationToken, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} accountId
     * @param {string} userId
     * @param {string} [validationToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public post_1(accountId: string, userId: string, validationToken?: string, options?: any) {
        return NotificationApiFp(this.configuration).post_1(accountId, userId, validationToken, options)(this.fetch, this.basePath);
    }
}

/**
 * SubscriptionApi - fetch parameter creator
 * @export
 */
export const SubscriptionApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling _delete.");
            }
            const localVarPath = `/o365/v1/Subscription/User({id})`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "DELETE" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === "function" ? configuration.accessToken("oauth2", []) : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeUser(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling subscribeUser.");
            }
            const localVarPath = `/o365/v1/Subscription/User({id})`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "POST" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === "function" ? configuration.accessToken("oauth2", []) : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * SubscriptionApi - functional programming interface
 * @export
 */
export const SubscriptionApiFp = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<O365Subscription>> {
            const localVarFetchArgs = SubscriptionApiFetchParamCreator(configuration)._delete(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeUser(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<O365Subscription> {
            const localVarFetchArgs = SubscriptionApiFetchParamCreator(configuration).subscribeUser(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    };
};

/**
 * SubscriptionApi - factory interface
 * @export
 */
export const SubscriptionApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(id: string, options?: any) {
            return SubscriptionApiFp(configuration)._delete(id, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeUser(id: string, options?: any) {
            return SubscriptionApiFp(configuration).subscribeUser(id, options)(fetch, basePath);
        },
    };
};

/**
 * SubscriptionApi - interface
 * @export
 * @interface SubscriptionApi
 */
export interface SubscriptionApiInterface {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApiInterface
     */
    _delete(id: string, options?: any): Promise<Array<O365Subscription>>;

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApiInterface
     */
    subscribeUser(id: string, options?: any): Promise<O365Subscription>;
}

/**
 * SubscriptionApi - object-oriented interface
 * @export
 * @class SubscriptionApi
 * @extends {BaseAPI}
 */
export class SubscriptionApi extends BaseAPI implements SubscriptionApiInterface {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public _delete(id: string, options?: any) {
        return SubscriptionApiFp(this.configuration)._delete(id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscribeUser(id: string, options?: any) {
        return SubscriptionApiFp(this.configuration).subscribeUser(id, options)(this.fetch, this.basePath);
    }
}

/**
 * UserApi - fetch parameter creator
 * @export
 */
export const UserApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(options: any = {}): FetchArgs {
            const localVarPath = `/o365/v1/User/User`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === "function" ? configuration.accessToken("oauth2", []) : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersForAccount(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling getUsersForAccount.");
            }
            const localVarPath = `/o365/v1/User/Account({id})/User`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === "function" ? configuration.accessToken("oauth2", []) : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).getUsers(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersForAccount(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).getUsersForAccount(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    };
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(options?: any) {
            return UserApiFp(configuration).getUsers(options)(fetch, basePath);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersForAccount(id: string, options?: any) {
            return UserApiFp(configuration).getUsersForAccount(id, options)(fetch, basePath);
        },
    };
};

/**
 * UserApi - interface
 * @export
 * @interface UserApi
 */
export interface UserApiInterface {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getUsers(options?: any): Promise<{}>;

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getUsersForAccount(id: string, options?: any): Promise<{}>;
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI implements UserApiInterface {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUsers(options?: any) {
        return UserApiFp(this.configuration).getUsers(options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUsersForAccount(id: string, options?: any) {
        return UserApiFp(this.configuration).getUsersForAccount(id, options)(this.fetch, this.basePath);
    }
}
