import * as React from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { Breakpoint } from "@mui/material";
import { observer } from "mobx-react";
import { ScreenBreakpoint } from "@crochik/pi-api";
import App from "../../application/App";
import { Action } from "../../context/IForm";
import { Form } from "../Form";
import { FormActions } from "../material/FormActions";
import { FormBody } from "./FormBody/FormBody";
import { FormTitle } from "./FormTitle";

interface Props {
    form: string | Form;
    open?: boolean;
    onCloseAsync: (implicit?: boolean) => Promise<any>;
    onActionAsync?: (action: Action, form?: Form) => Promise<any>;
    contentClassName?: string;
    skipTransition?: boolean;
}

interface State {
    form?: Form;
}

@observer
export class FormDialog extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            form: typeof this.props.form === "string" ? undefined : this.props.form,
        };
    }

    get form() {
        return this.state.form ?? (this.props.form instanceof Form ? this.props.form : undefined);
    }

    // TODO: add code to handle prop changes
    // ...

    async componentDidMount() {
        this.load(this.props);
    }

    async load(props: Props) {
        if (typeof props.form === "string") {
            console.log('load', props.form);
            const form = await App().loadFormAsync(props.form);
            this.setState({ form });
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.form !== this.props.form) {
            this.load(this.props);
        }
    }

    onCloseAsync = (implicit: boolean) => async () => {
        if (this.props.onCloseAsync) {
            await this.props.onCloseAsync(implicit);
        }
    };

    onActionAsync = async (action: Action) => {
        const { form } = this.state;
        const { onActionAsync } = this.props;
        if (onActionAsync) {
            return await onActionAsync?.(action, form);
        }

        return {
            success: false,
            message: `No action handler provided`
        }
    };

    // private transitionComponent = React.forwardRef((props: TransitionProps & { children: React.ReactElement<any, any> }, ref) => (
    //     <Slide direction="up" {...props} ref={ref} />
    // ));

    renderActions() {
        const form = this.form;
        if (!form || !form.actions || form.actions.length < 1) return undefined;

        return (
            <DialogActions style={{ padding: 24 }}>
                <FormActions form={form} variant="text" onActionAsync={this.onActionAsync} color="primary" />
            </DialogActions>
        );
    }

    render() {
        const form = this.form;
        if (!form) {
            return <Dialog className="FormDialog" open={this.props.open !== false} onClose={this.onCloseAsync(true)}></Dialog>;
        }

        const breakpoint = form.getContainerBreakpoint(App().breakpoint);
        const fullScreen = breakpoint === ScreenBreakpoint.Xs;

        return (
            <Dialog
                className="FormDialog"
                title={form.title}
                open={this.props.open !== false}
                onClose={this.onCloseAsync(true)}
                // TransitionComponent={this.transitionComponent}
                fullWidth={true}
                maxWidth={breakpoint?.toString().toLowerCase() as Breakpoint}
                fullScreen={fullScreen}
                scroll="paper"
            >
                <DialogTitle>
                    <FormTitle form={form} onClose={this.onCloseAsync(false)} onAction={this.onActionAsync} />
                </DialogTitle>

                <DialogContent className={this.props.contentClassName || "DialogContent"} style={fullScreen ? undefined : { minWidth: 400 }} dividers={false}>
                    <FormBody form={form} autoFocus={true} />
                </DialogContent>
                {this.renderActions()}
            </Dialog>
        );
    }
}
