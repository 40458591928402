import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import EventIcon from "@mui/icons-material/Event";
import { Button, IconButton, InputAdornment, Popover, TextField } from "@mui/material";
import { StyleSheet, css } from "aphrodite";
import { toJS } from "mobx";
import moment from "moment-timezone";
import { useCallback, useMemo, useState } from "react";
import "../../Timeslot/day-picker.css";
import { FieldProps } from "../FieldProps";
import { DatePopoverField } from "./DatePopoverField";
import "./styles.css";

interface Preset {
    name: string;
    range: string[];
}

export function DateRangeField(props: FieldProps) {
    const { field, onChange, value } = props;
    const [anchorElement0, setAnchorElement0] = useState<HTMLDivElement>();
    const [anchorElement1, setAnchorElement1] = useState<HTMLDivElement>();
    const [presetAnchor, setPresetAnchor] = useState<any>();

    const handlePopoverOpen0: React.MouseEventHandler<any> = (e) => setAnchorElement0(e.currentTarget);

    const onClosePopover0 = () => setAnchorElement0(undefined);

    const handlePopoverOpen1: React.MouseEventHandler<any> = (e) => setAnchorElement1(e.currentTarget);

    const onClosePopover1 = () => setAnchorElement1(undefined);

    const handlePresetPopoverOpen: React.MouseEventHandler<any> = (e) => setPresetAnchor(e.currentTarget);

    const onClosePresetPopover = () => setPresetAnchor(undefined);

    const date0 = useMemo(() => {
        const valueArray: string[] = toJS(value);
        if (!!valueArray?.length && valueArray?.[0] !== undefined) {
            const d = moment(valueArray[0]);
            if (d?.isValid()) {
                // TODO: check precision
                // ...
                const local = d.tz(moment.tz.guess()).startOf("day");
                return local.utc().toDate();
            }
        }
        return undefined;
    }, [value]);

    const date1 = useMemo(() => {
        const valueArray: string[] = toJS(value);
        if (!!valueArray?.length && valueArray?.[1] !== undefined) {
            const d = moment(valueArray[1]);
            if (d?.isValid()) {
                // TODO: check precision
                // ...
                const local = d.tz(moment.tz.guess()).startOf("day");
                return local.utc().toDate();
            }
        }
        return undefined;
    }, [value]);

    const onSelectDate0 = useCallback(
        (newDate: Date | undefined) => {
            setAnchorElement0(undefined);
            onChange?.(field, [newDate ? moment(newDate).utc().toDate() : undefined, date1 ? moment(date1).utc().toDate() : undefined]);
        },
        [date1, field, onChange]
    );

    const onSelectDate1 = useCallback(
        (newDate: Date | undefined) => {
            setAnchorElement1(undefined);
            onChange?.(field, [date0 ? moment(date0).utc().toDate() : undefined, newDate ? moment(newDate).utc().toDate() : undefined]);
        },
        [date0, field, onChange]
    );

    const onSelectPreset = (preset: Preset) => () => {
        onChange?.(field, preset.range);
        setPresetAnchor(undefined);
    };

    const onClearDate0 = useCallback(() => onSelectDate0(undefined), [onSelectDate0]);

    const onClearDate1 = useCallback(() => onSelectDate1(undefined), [onSelectDate1]);

    const presets: Preset[] = useMemo(() => {
        const options: any = field.options;
        if (!!options?.presets?.length) {
            return options.presets;
        }
        return [];
    }, [field.options]);

    const formatDate = useCallback((date: Date | undefined) => {
        if (!date) {
            return "";
        }
        return moment(date).format("MM/DD/YY");
    }, []);

    return (
        <div className={css(styles.container)}>
            {/* <div className={css(styles.titleRow)}>
                <div>{field.label}</div>
                {(!!date0 || !!date1) && (
                    <div className={css(styles.clearButton)} onClick={onClear}>
                        {"Clear"}
                    </div>
                )}
            </div> */}
            <div className={css(styles.row)}>
                <Button onClick={handlePresetPopoverOpen} variant="outlined" sx={{ marginRight: "4px" }}>
                    <EventIcon htmlColor="black" />
                </Button>
                <div>
                    <TextField
                        label={`${field.label ?? field.name}`}
                        sx={{ width: "100%" }}
                        value={formatDate(date0)}
                        variant="filled"
                        // InputLabelProps={{ shrink: true }}
                        // type="date"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {!!date0 && (
                                        <IconButton onClick={onClearDate0} sx={{ padding: "2px" }}>
                                            <CloseIcon fontSize="small" />
                                        </IconButton>
                                    )}
                                    <IconButton onClick={handlePopoverOpen0} sx={{ padding: "2px" }}>
                                        <ArrowDropDownIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        // label={}
                        sx={{ width: "100%" }}
                        value={formatDate(date1)}
                        InputLabelProps={{ shrink: true }}
                        variant="filled"
                        // type="date"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {!!date1 && (
                                        <IconButton onClick={onClearDate1} sx={{ padding: "2px" }}>
                                            <CloseIcon fontSize="small" />
                                        </IconButton>
                                    )}
                                    <IconButton onClick={handlePopoverOpen1} sx={{ padding: "2px" }}>
                                        <ArrowDropDownIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
            </div>
            <DatePopoverField anchorElement={anchorElement0} defaultValue={date0} onClose={onClosePopover0} onSelect={onSelectDate0} />
            <DatePopoverField anchorElement={anchorElement1} defaultValue={date1} onClose={onClosePopover1} onSelect={onSelectDate1} />
            <Popover
                open={!!presetAnchor}
                anchorEl={presetAnchor}
                onClose={onClosePresetPopover}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <div>
                    {!!presets?.length && (
                        <div className={css(styles.presets)}>
                            {presets.map((preset, i) => {
                                return (
                                    <div key={`preset-${i}`} className={css(styles.presetText)} onClick={onSelectPreset(preset)}>
                                        {preset.name}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            </Popover>
        </div>
    );
}

const styles = StyleSheet.create({
    container: {
        marginTop: 12,
        marginBottom: 12,
        borderTopRightRadius: 6,
        borderTopLeftRadius: 6,
        backgroundColor: "#eeeeee",
    },
    titleRow: {
        alignItems: "center",
        justifyContent: "space-between",
        display: "flex",
        width: "100%",
        padding: 6,
    },
    clearButton: {
        fontSize: 12,
        cursor: "pointer",
    },
    row: {
        display: "flex",
        width: "100%",
    },
    inputContainer: {
        cursor: "pointer",
    },
    presets: {
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 16,
        borderRightColor: "#E0E0E0",
        borderRightWidth: 1,
        borderRightStyle: "solid",
    },
    presetText: {
        cursor: "pointer",
        marginBottom: 8,
    },
});
