import { createTheme, ThemeProvider } from "@mui/material/styles";
import * as React from "react";

import * as color from "@mui/material/colors";
import CssBaseline from "@mui/material/CssBaseline";

function withRoot(Component: React.ComponentType) {
    function WithRoot(props: any) {
        const theme = createTheme({
            palette: {
                primary: color[props["primaryColor"]],
                secondary: color[props["secondaryColor"]],
            },
        });

        return (
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Component {...props} />
            </ThemeProvider>
        );
    }

    return WithRoot;
}

export default withRoot;
