import React, { CSSProperties, ReactElement } from "react";

export function Token({ name, style }: { name: string, children?: ReactElement, style?: CSSProperties }) {
    return (
        <span style={{
            borderRadius: 2,
            padding: 4,
            ...style
            // boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19)"
        }}>
            {name.toString()}
        </span>
    );
}