import { Card, Tab, Tabs, Typography, useMediaQuery, useTheme } from "@mui/material";
import { DataViewComponent } from "src/pi/ui/material/DataGridComponent";
import { VerticalForm } from "src/pi/ui/material/VerticalForm";
// import ExpandMoreIcon from '@mui/icons-material/UnfoldMore';
import ExpandLess from "@mui/icons-material/UnfoldLess";

import { CSSProperties, ReactNode, useEffect, useState } from "react";
import { LayoutContainer, LayoutContainerType, LayoutItem, LayoutJustify, ObjectLayoutItem } from "@crochik/pi-api";
import App from "src/pi/application/App";
import { CustomPage2, ExternalPage, FormPage, GridPage, Page } from "src/pi/application/Page";
import { Loading } from "src/pi/ui/material/Loading";
import { URI } from "../api/URI";

function justifyContent(value?: LayoutJustify) {
    switch (value) {
        case LayoutJustify.Between:
            return "space-between";
        case LayoutJustify.Start:
            return "flex-start";
        case LayoutJustify.End:
            return "flex-end";
        default:
            return "flex-start";
    }
}

function Collpased(props: IObjectLayoutItemProps) {
    const [isOpen, setOpen] = useState(false);

    const onClick = () => {
        setOpen(true);
    };

    if (!isOpen) {
        return (
            <Card
                key={props.name}
                style={{
                    cursor: "pointer",
                    width: "100%",
                    ...props.css
                }}
                raised={false}
                onClick={onClick}
                className="CollpasedContainer"
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 24
                    }}
                >
                    <Typography variant="h6" color="primary" id="tableTitle">
                        {props.label || props.name}
                    </Typography>
                    <ExpandLess />
                </div>
            </Card>
        );
    }

    const { lazyLoad, ...other } = props;
    return <Layout key={props.name} {...other} />;
}

const Column = (props: ILayoutContainerProps) => {
    const { css, spacing, parameters } = props;

    if (!props.children) return null;

    let total = 0;
    props.children.forEach((x) => (total += x.weight ?? 0));

    return (
        <div
            style={{
                position: "relative",
                height: "100%",
                width: "100%",
                overflow: "auto",
                ...css
            }}
            key={props.name}
        >
            <div
                style={{
                    position: "relative",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: justifyContent(props.justify),
                    // alignItems: "start",
                    // flexGrow: weight,
                    gap: spacing ?? undefined,
                    overflow: "auto"
                }}
            >
                {props.children &&
                    props.children.map((x, i) => (
                        <Layout key={i} {...x}
                                css={{ height: x.weight ? `${(100 * x.weight) / total}%` : undefined, width: "100%" }}
                                parameters={parameters} />
                    ))}
            </div>
        </div>
    );
};

const Row = (props: ILayoutContainerProps) => {
    const { spacing, parameters } = props;
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));

    if (!props.children) return null;

    let total = 0;
    props.children.forEach((x) => (total += x.weight ?? 0));

    return (
        <div
            key={props.name}
            style={{
                position: "relative",
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: isSmallScreen ? "column" : "row",
                justifyContent: justifyContent(props.justify),
                // alignItems: "start",
                gap: spacing ?? undefined,
                ...props.css
            }}
            className="RowContainer"
        >
            {props.children &&
                props.children.map((x, i) => {
                    // const width = isSmallScreen ? "100%" : x.weight ? `${(100 * x.weight) / total}%` : undefined;
                    return <Layout key={i} {...x} css={{ flex: x.weight ?? 1 }} parameters={parameters} />;
                })}
        </div>
    );
};

const PageComponent = (props: { page: Page; args: any }) => {
    const { page, args } = props;

    if (page instanceof FormPage) return <div>Error</div>; // this.renderForm(page, args);
    if (page instanceof GridPage) return <div>Error</div>; // this.renderGrid(page, args);
    // if (page instanceof CustomPage) return page.render(args) || <div>Error</div>;
    if (page instanceof CustomPage2) return page.render(args) || <div>Error</div>;
    if (page instanceof ExternalPage) return <div>Error</div>; // this.renderExternal(page, args);

    return <div key={page.name}>{page ? page.name : ""}</div>;
};

interface IObjectLayoutItemProps extends ObjectLayoutItem {
    css?: CSSProperties;
    parameters?: { [name: string]: object };
}

const ObjectComponent = (props: IObjectLayoutItemProps) => {
    const [child, setChild] = useState<ReactNode>(<Loading />);

    useEffect(() => {
        console.log("component", props.url);
        if (!props.url) return;

        let url = props.url;
        if (url.indexOf("{{") > 0 && props.parameters) {
            const subst = url.match(/({{[^}]+}})/g) as string[];
            if (subst) {
                for (const s of subst) {
                    const fieldName = s.substring(2, s.length - 2);
                    if (fieldName in props.parameters) {
                        url = url.replace(s, props.parameters[fieldName].toString());
                    } else {
                        console.error(`Parameter ${fieldName} not found`);
                    }
                }
            }
        }

        var uri = new URI(url);
        let parsedUrl = uri.path;
        if (uri.query) {
            uri.searchParams.forEach((value, x) => {
                if (!value) {
                    return;
                }

                parsedUrl += parsedUrl.indexOf("?") > 0 ? "&" : "?";
                parsedUrl += x;
                parsedUrl += "=";

                if (value.startsWith("{{") && value.endsWith("}}") && props?.parameters) {
                    const paramValue = props.parameters[value.substring(2, value.length - 2)];
                    if (paramValue) {
                        parsedUrl += encodeURIComponent(paramValue.toString());
                        return;
                    }
                }

                parsedUrl += encodeURIComponent(value);
            });
        }

        // let title: string | undefined = undefined;
        // if (url.hash) {
        //     const hash = url.hash.substring(1); // remove #
        //     const pairs = hash.split("&");
        //     const args: { [name: string]: string } = {};
        //     pairs.forEach((x) => {
        //         const parts = x.split("=");
        //         if (parts.length !== 2) return;
        //         args[parts[0]] = parts[1];
        //     });
        //     if ("title" in args) {
        //         title = decodeURIComponent(args["title"]);
        //     }
        // }

        switch (uri.scheme) {
            case "datagrid:":
                setChild(
                    <DataViewComponent
                        key={props.name}
                        view={parsedUrl}
                        updateAppTitle={false}
                        style={{ position: "relative", ...props.css }} // , ...props.style
                    />
                );
                break;

            case "dataform:": {
                setChild(
                    <VerticalForm
                        key={props.name}
                        title={props.label ?? props.name!}
                        form={parsedUrl}
                        style={{ position: "relative", overflow: "auto", ...props.css }}
                    /> // , ...props.style
                );
                break;
            }

            case "page:": {
                App()
                    .pasePageUrlAsync(url)
                    .then((parsed) => {
                        if (parsed?.page) {
                            setChild(
                                <div
                                    style={{
                                        position: "relative",
                                        maxHeight: props.style?.maxHeight ?? undefined,
                                        minHeight: props.style?.minHeight ?? undefined,
                                        maxWidth: props.style?.maxWidth ?? undefined,
                                        minWidth: props.style?.minWidth ?? undefined
                                    }}
                                >
                                    <PageComponent page={parsed.page} args={parsed.args} />
                                </div>
                            );
                        }
                    });
                break;
            }

            case "https:":
                setChild(
                    <div
                        style={{
                            position: "relative",
                            maxHeight: props.style?.maxHeight ?? undefined,
                            minHeight: props.style?.minHeight ?? undefined,
                            maxWidth: props.style?.maxWidth ?? undefined,
                            minWidth: props.style?.minWidth ?? undefined,
                            height: "100%",
                            overflow: "hidden"
                        }}
                    >
                        <iframe
                            width="100%"
                            height="100%"
                            style={{border: "1px solid black"}}
                            src={url}
                        />
                    </div>
                );
                break;

            default:
                console.error("invalid object", props.url);
                break;
        }
    }, [props.style, props.name, props.url, props.label, props.css, props.parameters]);

    return <div style={{ height: "100%", position: "relative", ...props.css }}>{child}</div>;
};

interface ILayoutContainerProps extends LayoutContainer {
    css?: CSSProperties;
    parameters?: { [name: string]: object };
}

const TabsContainer = (props: ILayoutContainerProps) => {
    const { css, parameters } = props;

    const [selectedTab, setSelectedTab] = useState(0);

    const onSelectTab = (event, value) => {
        setSelectedTab(value);
    };

    return (
        <div
            style={{
                position: "relative",
                height: "100%",
                ...css
            }}
            className="TabsContainer"
        >
            {!props.children && <div>Tabs</div>}

            <div
                style={{
                    // marginBottom: 6,
                }}
            >
                <Tabs value={selectedTab} indicatorColor="primary" textColor="primary" onChange={onSelectTab}
                      scrollButtons="auto" variant="scrollable">
                    {props.children && props.children.map((x, i) => <Tab key={x.name} label={x.label || x.name} />)}
                </Tabs>
            </div>
            <div
                style={{
                    position: "absolute",
                    width: "100%",
                    height: "calc(100% - 48px)"
                }}
            >
                {props.children && props.children.length > selectedTab && (
                    <Layout
                        key={selectedTab}
                        {...props.children[selectedTab]}
                        css={{ width: "100%", maxHeight: "100%", overflow: "auto" }}
                        parameters={parameters}
                    />
                )}
            </div>
        </div>
    );
};

interface ILayoutItemProps extends LayoutItem {
    css?: CSSProperties;
    parameters?: { [name: string]: object };
}

export function Layout(props: ILayoutItemProps) {
    console.log(`layout: ${props.name}`, props.parameters);

    if ("type" in props) {
        const containerProps = props as ILayoutContainerProps;
        switch (containerProps.type) {
            case LayoutContainerType.Column:
                return <Column key={containerProps.name} {...containerProps} />;
            case LayoutContainerType.Row:
                return <Row key={containerProps.name} {...containerProps} />;
            case LayoutContainerType.Tabs:
                return <TabsContainer key={containerProps.name} {...containerProps} />;
        }
    }

    const itemProps = props as IObjectLayoutItemProps;
    return itemProps.lazyLoad ? <Collpased key={itemProps.name} {...itemProps} /> :
        <ObjectComponent key={itemProps.name} {...itemProps} />;
}
