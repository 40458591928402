/**
 * handle bad formed URIs to account for the differences between browsers on how they handle URL
 * TODO: implement from scratch instead of trying to use URL and work around browser differences
 */
export class URI {
    raw: string;
    url: URL;
    path: string;

    static isUri(str?: string | null): boolean {
        // TODO: make it better
        return !!str && str.indexOf(":") > 0;
    }

    // URI: scheme:[//authority]path[?query][#fragment]
    constructor(url: string) {
        this.raw = url;

        // TODO: use some third party replacement or implement from scratch
        // the behavior is completely different in chrome vs safari, http/https vs other schemes, ...
        // ...
        this.url = new URL(url);

        if (this.url.pathname.startsWith("//")) {
            // chrome handling uri that is not url with the wrong prefix //
            this.path = this.url.pathname.substring(1);
        } else if (!!this.url.host && this.url.host.length > 0) {
            // safari
            this.path = `/${this.url.host}${this.url.pathname}`;
        } else {
            this.path = this.url.pathname;
        }
    }

    get scheme() {
        return this.url.protocol;
    }

    get searchParams() {
        return this.url.searchParams;
    }

    get query() {
        return !!this.url.search && this.url.search.length > 0 ? this.url.search : undefined;
    }

    get fragment() {
        return this.url.hash;
    }

    getParameter(name: string) {
        return this.url.searchParams.get(name);
    }

    getPath(additionalPath?: string) {
        let path = this.path;

        if (!!additionalPath && additionalPath.length > 0) {
            if (path.endsWith("/")) {
                path += additionalPath.startsWith("/") ? additionalPath.substring(1) : additionalPath;
            } else if (!additionalPath.startsWith("/")) {
                path += `/${additionalPath}`;
            } else {
                path += additionalPath;
            }
        }

        // console.log(`>> getPath: ${path}`, this.raw, additionalPath);
        return path;
    }

    getPathAndQuery(additionalPath?: string) {
        const result = `${this.getPath(additionalPath)}${this.query ?? ""}${this.fragment ?? ""}`;
        // console.log(`getPathAndQuery: ${result}`);
        return result;
    }
}
