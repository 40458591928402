import { css, StyleSheet } from "aphrodite";
import MaskedInput from "react-text-mask";
import emailMask from "text-mask-addons/dist/emailMask";

interface TextMaskProps {
    inputRef?: (args: any) => any;
}

export function phone(props: TextMaskProps) {
    const { inputRef, ...other } = props;
    const mask = ["(", /[1-9]/, /\d/, /\d/, ")", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
    const mask2 = ["+", /[1]/, " ", /[1-9]/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];

    return (
        <MaskedInput
            {...other}
            className={css(styles.input)}
            ref={inputRef}
            mask={(value) => {
                if (!value || value.trim().length < 1) return [/[+1-9]/];
                return value[0] === "+" ? mask2 : mask;
            }}
        // placeholderChar={"\u2000"}
        // showMask={true}
        />
    );
}

export function email(props: TextMaskProps) {
    var { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            className={css(styles.input)}
            ref={inputRef}
            mask={emailMask}
            placeholderChar={"\u2000"} // doesn't work!! :(
            showMask={true}
        />
    );
}

export function postalCode(props: TextMaskProps) {
    var { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            className={css(styles.input)}
            ref={inputRef}
            mask={(value) => {
                if (!value || value.trim().length < 1) return [];
                var first = value[0];
                if (first >= "0" && first <= "9") {
                    return [/\d/, /\d/, /\d/, /\d/, /\d/];
                } else if ((first >= "a" && first <= "z") || (first >= "A" && first <= "Z")) {
                    return [/[a-zA-Z]/, /[0-9]/, /[a-zA-Z]/, " ", /[0-9]/, /[a-zA-Z]/, /[0-9]/];
                }
                return [/([0-9A-Z])/];
            }}
            placeholderChar={"\u2000"}
            showMask={true}
        />
    );
}

const styles = StyleSheet.create({
    input: {
        border: "none",
        outline: "none",
        backgroundColor: "transparent",
        fontSize: 16,
        width: "100%",
        margin: 0,
        paddingBottom: 13,
        paddingLeft: 12,
        paddingTop: 26,
    },
});
