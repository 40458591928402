import { reaction } from "mobx";

import { App, default as getApp } from "src/pi/application/App";
import { Default } from "src/pi/context/AppContext";
import { IApp } from "src/pi/context/IApp";
import defaultActions from "./actions";
import defaultServices from "./services";
import * as api from "@crochik/pi-api";

import defaultPages from "./pages";

import { initLoginService } from "./services/LoginService";

import { IBootstrap, Session } from "./Session";
import { URI } from "../pi/api/URI";
import { registerDataViewComponents } from "./components";

async function loadInitialPageAsync(app: App) {
    const session = app.session;

    const { appConfig } = session;
    if (appConfig?.menu) {
        await app.setDefaultMenuNameAsync(appConfig.menu);
    }

    if (session.launchUrl.hash) {
        try {
            const name = atob(session.launchUrl.hash);
            if (await app.selectPageAsync(name)) {
                return;
            }
        } catch (error) {
            console.error("invalid hash", error);
        }
    }

    if (session.launchUrl.search && session.launchUrl.search.startsWith("?")) {
        const search = session.launchUrl.search.substring(1);
        if (search.startsWith("PI")) {
            try {
                const hash = atob(search.substring(2));
                if (await app.selectPageAsync(hash)) {
                    return;
                }
            } catch {
                // ...
            }
        }

        if (URI.isUri(search)) {
            if (await app.selectPageAsync(search)) {
                return;
            }

        } else if (session.launchUrl.searchParams && Object.keys(session.launchUrl.searchParams).length === 1) {
            const name = Object.keys(session.launchUrl.searchParams)[0];
            const id = session.launchUrl.searchParams[name];
            if (await app.selectPageAsync(`${name}=${id}`, "Launch", { id: id })) {
                return;
            }
        }
    }

    if (appConfig?.initialPage) {
        await app.selectPageAsync(appConfig.initialPage);
    }
}

async function loggedIn(app: App) {
    await app.selectPageAsync(undefined);

    const session = Session.get();
    // session.appConfig = await API.appConfig.get();
    session.appConfig = await new api.AppConfigApi(getApp().apiConfig).appConfigGetAppConfig();

    if (!session.user) {
        console.error("Failed to get user");
        // app.selectPage("Welcome");
        // hideMenu();
        return;
    }

    if (localStorage) {
        // save "autologin" token
        localStorage.setItem(
            "autoLogin",
            JSON.stringify({
                userId: session.user.id,
                orgId: session.user.organizationId,
                accountId: session.user.accountId,
            })
        );
    }

    // if (process.env.REACT_APP_FULLSTORY === 'on') { // && Math.random() <= .1
    //     try {
    //         var initFullStory = window['__pi_initFullStory__'];
    //         if (initFullStory) {
    //             initFullStory(window, document, window['_fs_namespace'], 'script', 'user');
    //         }

    //         // FullStory
    //         window['FS'].identify(session.user.id, {
    //             displayName: session.user.name,
    //             impersonate: null
    //         });
    //     } catch (reason) {
    //         console.error('failed to init fullstory');
    //     }
    // }

    await loadInitialPageAsync(app);
}

export default function init(bootstrap: IBootstrap): App {
    if (process.env.NODE_ENV !== "production") {
        window["__pi__"] = Default;
    }

    if (bootstrap.appName) {
        document.title = bootstrap.appName;

        const host = document.location.hostname;
        let meta = document.createElement("link");
        meta.rel = "manifest";
        meta.href = `./client/${host}/manifest.json`;
        document.head.appendChild(meta);

        meta = document.createElement("link");
        meta.rel = "shortcut icon";
        meta.href = `./client/${host}/favicon.png`;
        document.head.appendChild(meta);

        meta = document.createElement("link");
        meta.rel = "apple-touch-icon";
        meta.href = `./client/${host}/favicon.png`;
        document.head.appendChild(meta);
    }

    initLoginService(bootstrap);

    defaultServices();
    defaultActions();
    defaultPages();
    registerDataViewComponents();

    const config: IApp = {
        name: bootstrap.appName ?? "Crochik &#7464;",
        ga: process.env.REACT_APP_GA,
    };

    const app = new App(config);

    reaction(
        () => Session.get().user,
        async () => {
            const user = Session.get().user;
            if (user) {
                await loggedIn(app);
            } else {
                console.log('logged out');
                await app.selectPageAsync();
            }
        }
    );

    return app;
}
