// import * as singerapi from '@APIS/singer';
import App from "src/pi/application/App";
import * as API from "../services/API";
// import Dialog from '@PI/services/Dialog';

export async function sync() {
    // const args = App().args;
    // if (!args || args.length < 2 || !('id' in args[1])) {
    //     Dialog.critical({ message: 'Missing arguments' });
    //     return;
    // }

    // const config = args[1] as singerapi.SingerImportConfig;
    // await API.importJob.run(config.id as string).catch(cause => console.error(cause));

    // hack for now
    const configId = "fc100000-0000-0000-1111-000000000011";
    await API.importJob.run(configId).catch((cause) => console.error(cause));

    App().executeAsync(".page.SingerConfig.show");
}
