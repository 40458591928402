import { FieldProps } from "../FieldProps";
import { Form } from "../../../Form";
import MuiTextField, { TextFieldProps } from "@mui/material/TextField";
import { INumberFieldOptions } from "../../../../context/IForm";
import React, { useEffect, useState } from "react";
import { InputAdornment } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

interface ILocationDistanceFieldProps extends FieldProps<any> {
    form: Form;
    readonly?: boolean;
}

export function LocationDistanceField(props: ILocationDistanceFieldProps) {
    const isMetric = false;

    const { field, value, disabled, error, style, autoFocus } = props;
    const [inputValue, setInputValue] = useState<string>();
    useEffect(() => {
        if (!value) {
            setInputValue("");
            return;
        }

        try {
            const numValue = parseFloat(value);

            if (!isMetric) {
                setInputValue((numValue / 1.609).toFixed(1));
                return;
            }
            setInputValue(numValue.toFixed(1));
            return;

        } catch {
            setInputValue("");
        }

    }, [value]);

    const onChange = (event: any) => {
        let value = event.target.value;
        if (value?.trim() === "") {
            value = null;
        }

        setInputValue(value);
    };

    const onKeyPressed = (event: any) => {
        if (event.key !== "Enter") {
            return;
        }

        event.preventDefault();

        let value = inputValue as any;
        try {
            value = Number.parseFloat(value);
            if (!isMetric){
                value *= 1.609;
            }
        } catch {
            console.error("failed to parse number");
            return;
        }

        if (props.onChange) {
            props.onChange(field, value);
        }
    };

    const onClear = () => {
        if (props.onChange) {
            props.onChange(field, undefined);
        }
    };

    const textFieldProps: TextFieldProps = {
        required: field.isRequired,
        id: field.name!,
        label: field.label ?? field.name!,
        disabled: disabled,
        error: !!error,
        value: inputValue,
        onChange: onChange,
        onKeyPress: onKeyPressed,
        helperText: error ? error : null,
        margin: "normal",
        style: {
            ...field.style,
            ...style
        },
        autoFocus: autoFocus,

        InputLabelProps: { shrink: true },
        InputProps: {
            endAdornment: (
                <InputAdornment position="end">
                    <span title={isMetric ? "kilometers" : "miles"} style={{
                        color: "lightgray",
                        border: "1px solid lightgray",
                        padding: "2px",
                        borderRadius: "4px"
                    }}>{isMetric ? "km" : "mi"}</span>
                    <IconButton onClick={onClear} sx={{ padding: "2px", marginLeft: "12px" }}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </InputAdornment>
            )
        },
        fullWidth: true,
        type: "number"
    };

    return <MuiTextField {...textFieldProps} variant="filled" />;
}