import * as api from "src/apis/singer";
import { Base } from "./api";

class ConfigClient extends Base {
    protected get api() {
        return new api.ConfigApi(this.getConfig() as any);
    }

    get(): Promise<api.SingerImportConfig> {
        return this.api.get();
    }
}

class JobClient extends Base {
    protected get api() {
        return new api.JobApi(this.getConfig() as any);
    }

    get(id: string): Promise<api.SingerJob[]> {
        return this.api.getJobsById(id);
    }

    run(id: string): Promise<Response> {
        return this.api.run(id);
    }

    getById(id: string): Promise<api.SingerJob> {
        return this.api.getById(id);
    }

    getSummary(id: string): Promise<api.SingerJobSummary[]> {
        return this.api.getSummaryById(id);
    }
}

export const importConfig = new ConfigClient();
export const importJob = new JobClient();
