import PendingIcon from "@mui/icons-material/Pending";
import { IconButton, TableCell } from "@mui/material";
import { StyleSheet, css } from "aphrodite";
import { NumberFieldOptionsStyle } from "@crochik/pi-api";
import { IField, INumberFieldOptions, TYPE } from "src/pi/context/IForm";
import { Loading } from "../Loading";
import { IOnClickArgs } from "./DatavViewContext";
import { IDataViewComponentProps } from "./IDataViewComponentProps";
import { LinkUrlIcon } from "./LinkUrlIcon";
import { renderValue } from "./content";

interface IProps extends IDataViewComponentProps {
    loadedFields: { [name: string]: boolean },
    field: IField,
    row: object,
    index: number
    onFilterChange: (field: IField) => (value: object) => void
}

export function DataGridCell(props: IProps) {
    const { loadedFields, field, row, index, view, onClick, onFilterChange } = props;

    var align: "inherit" | "left" | "center" | "right" | "justify" = "inherit";

    switch (field.type) {
        case TYPE.NUMBER:
            if (field.options && "style" in field.options) {
                const options = field.options as INumberFieldOptions;
                switch (options.style) {
                    case NumberFieldOptionsStyle.Currency:
                        align = "right";
                        break;

                    case NumberFieldOptionsStyle.Price:
                    case NumberFieldOptionsStyle.Rating:
                    default:
                        break;
                }
            } else {
                align = "right";
            }
            break;

        case TYPE.LOCATIONDISTANCE:
            align = "right";
            break;

        default:
            break;
    }

    const args: IOnClickArgs = {
        row,
        field: field.name,
    }

    const onClickCell = (e: React.MouseEvent) => onClick(e, args);

    const isClickable = index >= 0;
    let onClickHandler = !view.isSelectable || isClickable ? onClickCell : undefined;

    const loading = view.isLoading ? <Loading /> : <PendingIcon htmlColor="#cccccc" />;

    // TODO: check if field is indexed
    // ...
    const onFilter = onFilterChange(field);

    let content = field.name && loadedFields[field.name] ? renderValue(view, field, row, onFilter) : loading;

    if (field.options?.linkUrl && content) {
        const onClickLink = (e: React.MouseEvent) => onClick(e, { ...args, linkUrl: field.options?.linkUrl })

        content = (
            <div className={css(styles.content)}>
                {content}
                <div id="showOnHoverButton" className={css(styles.showOnHoverButton)}>
                    <IconButton onClick={onClickLink}><LinkUrlIcon url={field?.options.linkUrl} /></IconButton>
                </div>
            </div>
        );
    }

    return (
        <TableCell onClick={onClickHandler} key={field.name} align={align} style={!!onClickHandler ? { cursor: "pointer" } : undefined}>
            {content}
        </TableCell>
    );
}

const styles = StyleSheet.create({
    content: {
        display: 'flex', flexDirection: 'row', alignItems: 'center'
    },
    showOnHoverButton: {
        visibility: 'hidden',
        '@media (hover: none)': {
            visibility: 'visible'
        }
    }
})