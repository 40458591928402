import { Box } from "@mui/system";
import * as React from "react";
import App from "src/pi/application/App";
import { ExternalPage } from "src/pi/application/Page";

export interface IExternalPageProps {
    page: ExternalPage;
}

export class ExternalPageComponent extends React.Component<IExternalPageProps> {
    componentDidMount() {
        App().alwaysCollpaseDrawer = true;
    }

    componentWillUnmount() {
        App().alwaysCollpaseDrawer = false;
    }

    render() {
        const { page } = this.props;
        const { data } = page;
        const { url } = data;

        const style: React.CSSProperties = {
            // margin: 12,
            // height: 'calc(100% - 24px)',
            // width: 'calc(100% - 24px)',
            width: "100%",
            height: "100%",
            overflow: "hidden"
        };

        return (
            <Box
                sx={{
                    padding: 0,
                    width: "100%",
                    height: "100%"
                }}
            >
                {url && <iframe src={url} style={style} title={page.data.label ?? page.data.name!} />}
            </Box>
        );
    }
}
