import { observer } from "mobx-react";
import * as React from "react";
import app, { App } from "../../application/App";

import { Drawer } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import * as muistyles from "@mui/styles";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MenuIcon from "@mui/icons-material/Menu";
import PushPinIcon from "@mui/icons-material/PushPin";
import IconButton from "@mui/material/IconButton";

import PageComponent from "./app/PageComponent";
import TitleBarComponent from "./app/TitleBarComponent";
import TopMenuComponent from "./app/TopMenuComponent";

import { Box } from "@mui/system";
import { computed } from "mobx";
import { ScreenBreakpoint } from "@crochik/pi-api";
import Dialog from "../../services/Dialog";
import ConfirmDialog from "./ConfirmDialog";
import { FormDialog } from "./FormDialog";
import SnackMessage from "./SnackMessage";

const drawerWidth = 240;

interface IProps {
    classes: any;
    theme: any;
    embedded: boolean;
    embeddedHeight?: number;
}

interface IState {
    permanent: boolean;
    open: boolean;
}

@observer
class ResponsiveDrawer extends React.Component<IProps, IState> {
    app: App;

    @computed
    get onlyTempMenu() {
        switch (this.app.breakpoint) {
            case ScreenBreakpoint.Xs:
            case ScreenBreakpoint.Sm:
                return true;

            default:
                return false;
        }
    }

    @computed
    get isMenuPermanent() {
        return !this.onlyTempMenu && this.state.permanent;
    }

    @computed
    get isMenuOpen() {
        if (!this.app.menu) return false;
        return this.state.open;
    }

    constructor(props: IProps) {
        super(props);

        this.app = app();
        this.state = {
            permanent: true,
            open: true,
        };
    }

    // componentDidMount() {
    //     this.app.onReady();
    // }

    togglePermanent = () => {
        if (!this.app.menu) return;
        switch (this.app.breakpoint) {
            case ScreenBreakpoint.Xs:
            case ScreenBreakpoint.Sm:
                return;

            default:
                this.setState({ permanent: !this.state.permanent });
                break;
        }
    };

    openMenu = () => {
        this.setState({
            permanent: false,
            open: true,
        });
    };

    handleDrawerToggle = () => {
        if (!this.app.menu) return;
        this.setState({ open: !this.state.open });
    };

    onReload = () => {
        document.location.reload();
    }

    renderDrawer() {
        if (!this.app.showMenu) {
            return undefined;
        }

        const drawer = (
            <Box style={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
                <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                    <Box
                        style={{
                            height: "92px",
                            paddingTop: "12px",
                            paddingBottom: "12px",
                            width: this.onlyTempMenu ? "100%" : "calc(100% - 40px)",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                            onDoubleClick={this.onReload}
                        >
                            <img src="https://links.fci.cloud/logo.png" height="48" style={{ paddingTop: 6, paddingBottom: 6 }} alt="" />
                            <span style={{ fontSize: "8pt", color: "lightgray" }}>{process.env.REACT_APP_version}</span>
                        </div>
                    </Box>
                    {!this.onlyTempMenu && (
                        <div style={{ display: "flex", alignItems: "center", justifyItems: "center", width: 40 }}>
                            {this.isMenuPermanent ? (
                                <IconButton onClick={this.handleDrawerToggle}>
                                    <ChevronLeftIcon />
                                </IconButton>
                            ) : (
                                <IconButton onClick={this.togglePermanent}>
                                    <PushPinIcon />
                                </IconButton>
                            )}
                        </div>
                    )}
                </div>
                <Box style={{ overflow: "auto" }}>
                    <TopMenuComponent />
                </Box>
            </Box>
        );

        if (this.isMenuPermanent) {
            return (
                <Box
                    sx={{
                        display: this.isMenuOpen ? "block" : "none",
                        width: drawerWidth,
                        flexShrink: 0,
                    }}
                    component="nav"
                >
                    <Drawer
                        variant="permanent"
                        open={this.isMenuOpen}
                        sx={{
                            "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Box>
            );
        }

        return (
            <Box
                sx={{
                    width: drawerWidth,
                }}
                component="nav"
            >
                <Drawer
                    variant="temporary"
                    anchor="left"
                    open={this.isMenuOpen}
                    onClose={this.handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
        );
    }

    renderAppbar() {
        if (!this.app.showTitleBar) return undefined;

        const { embedded } = this.props;

        if (!this.app.showMenu)
            return (
                <AppBar style={{ position: "relative" }}>
                    <Toolbar>
                        <TitleBarComponent embedded={embedded} />
                    </Toolbar>
                </AppBar>
            );

        return (
            <AppBar position="fixed" style={{ position: "relative" }}>
                <Toolbar>
                    {!this.isMenuOpen && (
                        <IconButton color="inherit" onClick={this.openMenu} disabled={!this.app.menu} size="large">
                            <MenuIcon />
                        </IconButton>
                    )}
                    <TitleBarComponent embedded={embedded} />
                </Toolbar>
            </AppBar>
        );
    }

    renderDialogForm() {
        const { dialogs, closeDataFormDialogAsync, onDataFormDialogActionAsync } = app();
        if (dialogs.length < 1) return null;

        // console.log("Render dialogs", dialogs.length);

        return dialogs.map((formDialog) => (
            <FormDialog key={formDialog.key} form={formDialog.form} onCloseAsync={closeDataFormDialogAsync} onActionAsync={onDataFormDialogActionAsync} skipTransition={true} />
        ));
    }

    renderBody() {
        const sxBody = {
            width: this.isMenuPermanent && this.isMenuOpen ? `${this.app.innerWidth - drawerWidth}px` : `${this.app.innerWidth}px`,
        };

        const { embedded } = this.props;

        return (
            <Box
                // className={classes.content}
                component="main"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    width: "100%",
                    position: "relative",
                    boxSizing: "border-box",
                }}
            >
                {this.renderAppbar()}

                <Box
                    sx={{
                        ...sxBody,
                        height: "100%",
                        overflow: "auto",
                        position: "relative",
                    }}
                >
                    <PageComponent embedded={embedded}/>
                </Box>
            </Box>
        );
    }

    render() {
        // const { embedded, embeddedHeight } = this.props;

        var dialog = Dialog.message;
        var snackBar = Dialog.snackBar;

        // if (embedded) {
        //     return (
        //         <>
        //             <AppBar style={{ position: "relative" }}>
        //                 <Toolbar>
        //                     <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
        //                         <Typography variant="h6" noWrap={true} color="inherit">
        //                             {this.app.title}
        //                         </Typography>
        //                         {this.app.page && this.app.pageMenu && <ActionMenuBar context={`page.${this.app.page.name}`} menu={this.app.pageMenu} />}
        //                     </div>
        //                 </Toolbar>
        //             </AppBar>

        //             <div
        //                 style={{
        //                     position: 'relative',
        //                     boxSizing: "border-box",
        //                     display: "flex",
        //                     height: embeddedHeight ? `${embeddedHeight - 64}px` : undefined,
        //                     overflow: "auto",
        //                     width: "100%",
        //                 }}
        //                 className="Chrome2"
        //             >
        //                 <PageComponent />
        //             </div>
        //             {this.renderDialogForm()}
        //             {dialog && <ConfirmDialog {...dialog} />}
        //             {snackBar && <SnackMessage {...snackBar} />}
        //         </>
        //     );
        // }

        return (
            <>
                <Box
                    sx={{
                        display: "flex",
                        flexGrow: 1,
                        height: "100%",
                        zIndex: 1,
                        overflow: "hidden",
                        position: "relative",
                        width: "100%",
                    }}
                >
                    {this.renderDrawer()}
                    {this.renderBody()}
                </Box>

                {this.renderDialogForm()}
                {dialog && <ConfirmDialog {...dialog} />}
                {snackBar && <SnackMessage {...snackBar} />}
            </>
        );
    }
}

// export default muistyles.withStyles(styles as any, { withTheme: true })(ResponsiveDrawer as any);
export default muistyles.withTheme(ResponsiveDrawer as any);
