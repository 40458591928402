import * as React from "react";
import { Default } from "../../context/AppContext";

import Button from "@mui/material/Button";

interface Props {
    title: string;
    disabled?: boolean;
    action: string | Function;
    context?: string;
    variant?: "text" | "outlined" | "contained"; // | 'fab' | 'extendedFab' | 'raised'| 'flat'
    color?: "inherit" | "primary" | "secondary";
}

export default class ActionButton extends React.Component<Props> {
    onClick = () => {
        if (this.props.disabled) return false;
        switch (typeof this.props.action) {
            case "string":
                Default.actions.execute(this.props.action, this.props.context);
                break;
            case "function":
                (this.props.action as () => any).call(this);
                break;
            default:
                break;
        }

        return true;
    };

    render() {
        return (
            <Button variant={this.props.variant || "text"} color={this.props.color || "inherit"} onClick={this.onClick} disabled={this.props.disabled}>
                {this.props.title}
            </Button>
        );
    }
}
