import React from "react";
import * as api from "@crochik/pi-api";
import { IClientConfiguration } from "./App";

export interface IApplicationContext {
    apiConfig?: api.Configuration;
    clientConfiguration?: IClientConfiguration;
    user?: api.User;
    embedded: boolean;
    embeddedHeight?: number;
    innerHeight?: number;
}

export const ApplicationContext = React.createContext<IApplicationContext | null>(null);

export const useApplicationContext = () => {
    const value = React.useContext(ApplicationContext);
    if (!!value) {
        return value;
    }
    throw new Error("Invalid context.");
};
