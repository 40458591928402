import { observer } from "mobx-react";
import * as React from "react";

import { ReactNode, Suspense, useState } from "react";
import { ScreenBreakpoint } from "@crochik/pi-api";
import App from "src/pi/application/App";
import { Loading } from "../Loading";
import { DataGridComponent1 } from "./DataGridComponent1";
import { DataGridProps } from "./DataGridProps";
import { DataViewContainer } from "./DataViewContainer";
import { DataViewContext, IDataViewContext } from "./DatavViewContext";
import { IDataViewComponentProps } from "./IDataViewComponentProps";
import { LinearProgress } from "@mui/material";

const components: { [name: string]: (props: any) => ReactNode} = {};

export function registerComponent(name: string, resolver: (props: any) => ReactNode) {
    components[name] = resolver;
}

export function InnerDataViewComponent(props: IDataViewComponentProps) {
    if (!!props.view?.component && props.view?.component in components) {
        const component = components[props.view?.component];
        return <Suspense fallback={<LinearProgress style={{ width: "100%" }} />}>{component(props)}</Suspense>;
    }

    if (!!props.view?.viewType && props.view?.viewType in components) {
        const component = components[props.view?.viewType];
        return <Suspense fallback={<LinearProgress style={{ width: "100%" }} />}>{component(props)}</Suspense>;
    }

    return <DataGridComponent1 {...props} />;
}

interface IAutoReloadViewComponentProps extends IDataViewContext {
    breakpoint: ScreenBreakpoint;
    component: string;
}

function AutoReloadViewComponent(props: IAutoReloadViewComponentProps) {
    const { breakpoint, ...otherProps } = props;
    const [initialLoad, setInitialLoad] = useState(true);
    const [isLoading, setLoading] = useState(false);

    React.useEffect(() => {
        if (!breakpoint) return;

        if (initialLoad) {
            console.log('first render');
            setInitialLoad(false);
            return;
        }

        console.log('component changed', breakpoint);
        const { dataView } = props;

        if (isLoading) return;

        setLoading(true);
        dataView?.reloadAsync(breakpoint)
            .then(() => {
                setLoading(false);
            })
            .catch(reason => {
                setLoading(false);
            });

    }, [breakpoint])

    if (!props) return null;
    if (isLoading) return <Loading />;

    const { dataView, onClick, onObjectMenu } = otherProps;

    const calcProps: IDataViewComponentProps = {
        // dataView,
        onClick,
        onObjectMenu,
        view: dataView,
    }

    return <InnerDataViewComponent {...calcProps} />
}

@observer
class AutoSize extends React.PureComponent<IDataViewContext> {
    render() {
        // just to create dependency for mobx?
        const breakpoint = App().breakpoint;
        const component = this.props.dataView.viewType;
        return <AutoReloadViewComponent breakpoint={breakpoint} component={component} {...this.props} />
    }
}

export const DataViewComponent = (props: DataGridProps) => (
    <DataViewContainer {...props}>
        <DataViewContext.Consumer>
            {(context) => context ? <AutoSize {...context} /> : null}
        </DataViewContext.Consumer>
    </DataViewContainer>
);
