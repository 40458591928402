import React from "react";
import { Token } from "./Token";

export function FieldName({ name, objectType }: { name?: string | null, objectType?: string }) {
    if (!name) return null;
    const value = (name.startsWith("{{") && name.endsWith("}}")) ?
        name.substring(2, name.length - 2) :
        name;

    return (
        <>
            {
                objectType && (
                    <Token
                        name={objectType}
                        style={{
                            background: objectType==="Context" ? "#777" : "#00c",
                            color: "white"
                        }}
                    />
                )
            }
            <Token
                name={value}
                style={{
                    background: objectType==="Context" ? "#777" : "#007",
                    color: "white"
                }}
            />
        </>
    );
}

