import { Default } from "../context/AppContext";

import App from "./App";

// TODO: move the pages that depend on these to the ui
import { Alert, LinearProgress } from "@mui/material";
import { toJS } from "mobx";
import { ReactNode, Suspense } from "react";
import { IMenu } from "../context/IMenu";
import { ICustomPage, IExternalPage, IFormPage, IGridPage, ILayoutPage, IPage } from "../context/IPage";
import { Layout } from "./LayoutPage";
import { LayoutItem } from "@crochik/pi-api";

export abstract class Page {
    _data: IPage;
    _appMenu?: IMenu;

    get name(): string {
        return this._data.name ?? "[no-name]";
    }

    get label(): string {
        return this._data.label || this.name;
    }

    get menu(): IMenu | undefined {
        return this._data.menu ?? undefined;
    }

    get appMenu(): string | undefined | null {
        return this._data.appMenu;
    }

    get state(): any {
        return Page.getState(this.name);
    }

    static get(name: string): Page {
        return Default.ui.get(name, "page");
    }

    static bindAction(name: string, action: string, funct: Function) {
        Default.actions.set(action, funct, `page.${name}`);
    }

    static setState(name: string, state: any) {
        Default.state.set(name, state, "page");
    }

    static getState(name: string): any {
        return Default.state.get(name, "page");
    }

    constructor(page: IPage) {
        this._data = page;

        const { name } = this._data;
        if (name) {
            // console.log('register page', name, toJS(this));
            Default.ui.set(name, this, "page");
            Page.bindAction(name, "show", () => App().selectPageAsync(page.name!));
        }
    }
}

// TODO: move these under ui
export class FormPage extends Page {
    get data() {
        return this._data as IFormPage;
    }

    get form(): string {
        return this.data.form ?? "[error]";
    }
}

export class GridPage extends Page {
    get data() {
        return this._data as IGridPage;
    }
}

export class ExternalPage extends Page {
    get data() {
        return this._data as IExternalPage;
    }

    get openInNewWindow() {
        return this.data.openInNewWindow;
    }
}

// export class CustomPage extends Page {
//     _component: JSX.Element;

//     get data() {
//         return this._data as ICustomPage;
//     }

//     constructor(page: IPage, component: JSX.Element) {
//         super(page);

//         this._component = component;
//     }

//     render(args: any[]): JSX.Element | undefined {
//         return this._component;
//     }
// }

const customPages: { [name: string]: (props: any) => ReactNode} = {};

export function registerPage(name: string, resolver: (props: any) => ReactNode) {
    customPages[name] = resolver;
}

export class CustomPage2 extends Page {
    get data() {
        return this._data as ICustomPage;
    }

    render(args: any, embedded?: boolean) {
        let props = toJS(args);
        if (Array.isArray(props)) {
            switch (props.length) {
                case 1:
                    // page
                    props = {
                        ...props[0],
                        page: this._data,
                        args: props[0]
                    };
                    break;
                case 2:
                    // launch (initial url)
                    if (props[0] === "Launch")
                        props = {
                            ...props[1],
                            page: this._data,
                            args: props[1]
                        };
                    break;
            }
        } else {
            props = {
                ...props,
                page: this._data
            };
        }

        const { componentName: component } = this.data;
        console.log("component", this.data);
        const lazyLoad = component ? customPages[component] : undefined;
        if (!lazyLoad) {
            return <Alert severity="error">{`Component ${component} not registered`} </Alert>;
        }
        return <Suspense fallback={<LinearProgress style={{ width: "100%" }} />}>{lazyLoad(props)}</Suspense>;
    }
}

export class LayoutPage extends Page {
    get data() {
        return this._data as ILayoutPage;
    }

    render(args: any, embedded?: boolean) {
        console.log("layoutpage", toJS(args));

        if (!this.data.layout) return null;

        const parameters = Array.isArray(args) && args.length > 0 && typeof args[0] === "object" ? args[0] : undefined;
        return (
            <div style={{
                padding: embedded ? undefined : "24px",
                position: "absolute",
                width: "100%",
                height: "100%",
                overflow: "auto"
            }} className="LayoutPage">
                <Layout {...this.data.layout} parameters={parameters} />
            </div>
        );
    }
}

export function createPage(page: IPage): Page {
    switch (page.t) {
        case "GridPage":
            return new GridPage(page);

        case "FormPage":
            return new FormPage(page);

        case "ExternalPage":
            return new ExternalPage(page);

        case "CustomPage":
            return new CustomPage2(page as ICustomPage);

        case "LayoutPage":
            return new LayoutPage(page as ILayoutPage);

        default:
            console.log("Unknown page type", page);
            break;
    }

    // fallback - delete me
    if ("form" in page) {
        return new FormPage(page);
    }

    if ("grid" in page) {
        return new GridPage(page);
    }

    console.log(`Unexpected page type ${page.t} / ${page.name}`, page);
    throw new Error(`Unexpected page type: ${page.name}`);
}
