import { Alert } from "@mui/material";
import { IDictionaryFieldOptions, IField, IReferenceFieldOptions, TYPE } from "../../../context/IForm";
import { Form } from "../../Form";
import { AddressField } from "./AddressField";
import { AppointmentField } from "./AppointmentField";
import { CheckboxField } from "./CheckboxField";
import { ChildrenField } from "./ChildrenField";
import { DateField } from "./DateField";
import { DateRangeField } from "./DateRangeField";
import { DateTimeField } from "./DateTimeField";
import { DictionaryField } from "./DictionaryField";
import { EmailField } from "./EmailField";
import { FieldProps } from "./FieldProps";
import { FileField } from "./FileField";
import { LabelField } from "./LabelField";
import { LocationField } from "./LocationField";
import { LookupField } from "./LookupField";
import { NumberField } from "./NumberField";
import { ObjectField } from "./ObjectField";
import { PasswordField } from "./PasswordField";
import { PhoneField } from "./PhoneField";
import { PostalCodeField } from "./PostalCodeField";
import { ReferenceField, StandardReferenceField } from "./ReferenceField";
import { MultiReferenceField } from "./ReferenceField/MultiReferenceField";
import { RelatedObjectsField } from "./RelatedObjectsField";
import { RemoteFileField } from "./RemoteFileField";
import Section from "./Section";
import { SelectField } from "./SelectField";
import { MultiSelectField } from "./SelectField/MultiSelectField";
import { TagsField } from "./TagsField";
import { TextField } from "./TextField";
import { TimeField } from "./TimeField";
import { URLField } from "./URLField";
import { UnlayerTemplateField } from "./UnlayerTemplateField";
import { LocationDistanceField } from "./LocationDistanceField";
import * as api from "@crochik/pi-api";
import { ExpressionField } from "./ExpressionField";
import { GenericField } from "./GenericField";
import { ArrayField } from "./ArrayField";

class Factory {
    public calculateProps(form: Form, field: IField, other: any): FieldProps {
        var error = form.getError(field);
        var enabled = !field.enable ? true : form.evaluate(...field.enable);

        var props = {
            ...other,
            field,
            error,
            disabled: !enabled
        };

        return props;
    }

    public render(form: Form, field: IField, props: FieldProps): JSX.Element {
        switch (field.type) {
            case TYPE.TEXT:
                return <TextField {...props} />;

            case TYPE.PASSWORD:
                return <PasswordField {...props} />;

            case TYPE.ADDRESS:
                return <AddressField {...props} form={form} />;

            case TYPE.EMAIL:
                return <EmailField {...props} />;

            case TYPE.PHONE:
                return <PhoneField {...props} />;

            case TYPE.TIME:
                return <TimeField {...props} />;

            case TYPE.DATE:
                return <DateField {...props} />;

            case TYPE.DATERANGE:
                return <DateRangeField {...props} />;

            case TYPE.DATETIME:
                return <DateTimeField {...props} />;

            case TYPE.POSTALCODE:
            case "postalCode":
                return <PostalCodeField {...props} />;

            case TYPE.NUMBER:
                return <NumberField {...props} />;

            case TYPE.URL:
                return <URLField {...props} />;

            case TYPE.CHECKBOX:
            case "boolean":
                return <CheckboxField {...props} />;

            case TYPE.MULTISELECT:
                return <MultiSelectField {...props} />;

            case TYPE.SELECT:
                return <SelectField {...props} />;

            case TYPE.LOOKUP:
                return <LookupField {...props} />;

            case TYPE.MULTIREFERENCE:
                return <MultiReferenceField {...props} form={form} />;

            case TYPE.REFERENCE:
                return <ReferenceField form={form} {...props} />;

            case TYPE.LOCATION:
                return (
                    <LocationField {...props} form={form} />
                );

            case TYPE.LOCATIONDISTANCE:
                return (
                    <LocationDistanceField {...props} form={form}/>
                );

            case TYPE.OBJECT:
                return (
                    <Section title={field.label ?? field.name ?? ""}>
                        <ObjectField {...props} key={field.name} form={form} field={field} />
                    </Section>
                );

            case TYPE.RELATEDOBJECTS:
                return <RelatedObjectsField form={form} {...props} />;

            case TYPE.CHILDREN:
                return (
                    <Section title={field.label ?? field.name ?? ""}>
                        <ChildrenField form={form} {...props} />
                    </Section>
                );

            case TYPE.LABEL:
                return <LabelField {...props} form={form} />;

            case TYPE.HIDDEN:
                // ????
                return <></>;

            case TYPE.DICTIONARY: {
                const options = field.options as IDictionaryFieldOptions;
                const { keyFieldName, valueFieldName } = options;
                let { valueField, keyField } = options;

                if (!keyField || !valueField) {
                    keyField = keyFieldName ? form.fieldMap[keyFieldName] : undefined;
                    valueField = valueFieldName ? form.fieldMap[valueFieldName] : undefined;
                }

                if (!keyField || !valueField) return <Alert severity="error">Invalid configuration</Alert>;

                const fieldProps = {
                    ...props,
                    keyField: keyField as IField,
                    valueField: valueField as IField,
                    form
                };

                return <DictionaryField {...fieldProps} />;
            }

            case TYPE.FILE:
                return <FileField {...props} />;

            case TYPE.TAGS:
                return <TagsField {...props} />;

            case TYPE.ARRAY:
                return <ArrayField {...props} form={form} />;

            case TYPE.GENERIC:
                return <GenericField {...props} form={form} />;

            case TYPE.EXPRESSION: {
                const options = field.options as api.ExpressionFieldOptions;
                return <ExpressionField {...props} form={form} valueField={options.valueField!} />;
            }

            default:
                return <span>unrecognized type: "{field.type}"</span>;
        }
    }
}

export const factory = new Factory();
