import { IAction } from "./Actions";
// import { IUIElement } from './UIElement';
import * as api from "@crochik/pi-api";

export enum TYPE {
    ADDRESS = "address",
    ARRAY = "array",
    CHECKBOX = "checkbox",
    DATE = "date",
    DATETIME = "datetime",
    DICTIONARY = "dictionary",
    EMAIL = "email",
    FILE = "file",
    HIDDEN = "hidden",
    LABEL = "label",
    LOOKUP = "lookup",
    NUMBER = "number",
    PASSWORD = "password",
    PHONE = "phone",
    POSTALCODE = "postalcode", // use postalCode?
    REFERENCE = "reference",
    SELECT = "select",
    TEXT = "text",
    TIME = "time",
    URL = "url",
    RELATEDOBJECTS = "relatedObjects",
    TAGS = "tags",
    CHILDREN = "children",
    DATERANGE = "dateRange",
    OBJECT = "object",
    LOCATION = "location",
    MULTISELECT = "multiSelect",
    MULTIREFERENCE = "multiReference",
    GENERIC = "generic",
    CALCULATED = "calculated",
    LOCATIONDISTANCE = "locationdistance",
    EXPRESSION = "expression",
}

export interface IField extends api.FormField {
}

export interface IFieldOptions extends api.FieldOptions {
}

export interface ITextFieldOptions extends api.TextFieldOptions, IFieldOptions {
}

export interface ILabelFieldOptions extends api.LabelFieldOptions, IFieldOptions {
}

export interface INumberFieldOptions extends api.NumberFieldOptions, IFieldOptions {
}

export interface ISelectFieldOptions extends api.SelectFieldOptions, IFieldOptions {
}

export interface IReferenceFieldOptions extends api.ReferenceFieldOptions, IFieldOptions {
}

export interface ITagsFieldOptions extends api.TagsFieldOptions, IFieldOptions {
}

// related objects
export interface IRelatedObjectsFieldOptions extends api.RelatedObjectsFieldOptions, IFieldOptions {
}

export interface IObjectFieldOptions extends api.ObjectFieldOptions, IFieldOptions {
}

export interface IChildrenFieldOptions extends api.ChildrenFieldOptions, IFieldOptions {
}

export interface ILookupFieldOptions extends api.LookupFieldOptions, IFieldOptions {
}

export interface IDictionaryFieldOptions extends api.DictionaryFieldOptions, IFieldOptions {
}

export interface IURLFieldOptions extends api.URLFieldOptions, IFieldOptions {
}

export interface Action extends api.UIElement {
    action?: IAction|null;
}

// TODO: fix IAction so it can use api model
// ...
export interface IForm extends api.Form {
}
