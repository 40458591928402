
import { Alert } from "@mui/material";
import { IReactionDisposer } from "mobx";
import { useEffect, useState } from "react";
import { Operator } from "@crochik/pi-api";
import { IField, IRelatedObjectsFieldOptions } from "src/pi/context/IForm";
import { Form } from "../../../Form";
import { DataViewComponent } from "../../DataViewComponent/DataViewComponent";
import { FieldProps } from "../FieldProps";

interface IProps extends FieldProps {
    form?: Form;
}

export function RelatedObjectsField(props: IProps) {
    const [error, setError] = useState<string>();
    const [baseUrl, setBaseUrl] = useState<string>();
    const [url, setUrl] = useState<string>();
    const [dynCriteria, setDynCriteria] = useState<object>();

    const { field, form } = props;
    const options = field.options as IRelatedObjectsFieldOptions;
    const values = form?.getValues();

    useEffect(() => {
        if (!values) {
            setError("Missing form");
            return;
        }

        if (!options?.url) {
            setError("Missing url");
            return;
        }

        let id: string | undefined;
        if ("_id" in values) {
            id = values["_id"] as string;
        } else if ("Id" in values) {
            id = values["Id"] as string;
        } else if ("id" in values) {
            id = values["id"] as string;
        }

        setBaseUrl(options?.url.indexOf("{{id}}") < 0 ? options.url : options.url.replace("{{id}}", !!id ? id : "MISSING_ID"));
    }, [options.url, values]);

    useEffect(() => {
        if (!options.criteria) return;

        const listeners: IReactionDisposer[] = [];
        for (const criteria of options.criteria) {
            if (criteria?.operator !== Operator.Eq) continue;
            if (typeof criteria.value !== "string") continue;
            if ((!criteria.value?.startsWith("#{{") && !criteria.value?.startsWith("{{")) || !criteria.value.endsWith("}}")) continue;
            const fieldName = criteria.value.substring(criteria.value?.startsWith("#{{") ? 3 : 2, criteria.value.length - 2);
            const listener = form?.bindOnFieldValueChange(fieldName, (form: Form, field: IField, value: any) => {
                setUrl(undefined);
                setDynCriteria(
                    (d) =>
                    (d = {
                        ...d,
                        [fieldName]: value,
                    })
                );
            });
            if (listener) listeners.push(listener);
        }

        return () => {
            for (const listener of listeners) {
                listener();
            }
        };
    }, [form, options.criteria]);

    useEffect(() => {
        if (!options.criteria) {
            // no criteria
            // console.log("load view without criteria", baseUrl);
            setError(undefined);
            setUrl(baseUrl);
            return;
        }

        let viewUrl = baseUrl;
        if (!viewUrl) return;

        for (const criteria of options.criteria) {
            if (criteria?.operator !== Operator.Eq) {
                setError(`Unexpected criteria for ${criteria.fieldName}: ${criteria.operator}`);
                return;
            }
            if ((criteria.value?.startsWith("#{{") || criteria.value?.startsWith("{{")) && criteria.value.endsWith("}}")) {
                const fieldName = criteria.value.substring(criteria.value?.startsWith("#{{") ? 3 : 2, criteria.value.length - 2);
                const value = dynCriteria?.[fieldName];
                if (!value) {
                    return;
                }
                viewUrl += viewUrl.indexOf("?") > 0 ? "&" : "?";
                viewUrl += `${criteria.fieldName}=` + encodeURIComponent(value);
                continue;
            }
        }

        // console.log("load view with criteria", viewUrl);
        setError(undefined);
        setUrl(viewUrl);
    }, [baseUrl, dynCriteria, options.criteria]);

    if (error) {
        return <Alert severity="error">{error}</Alert>;
    }

    if (!url) return null;

    return (
        <DataViewComponent view={url} />
    );
}
