import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { StyleSheet, css } from "aphrodite";
import GenAIContent from "./index";

const styles = StyleSheet.create({
    dialogContent: {
        padding: 0,
        overflow: "hidden",
    },
});

interface IProps {
    id?: string;
    title: string;
    onDone: (id: string | undefined) => void;
}

export function GenAIContentDialog({ title, onDone, id }: IProps) {
    const onClose = () => {
        onDone(id);
    };

    return (
        <Dialog title={title} onClose={onClose} open={true} scroll="paper" maxWidth={"xl"} fullScreen={true}>
            <DialogTitle
                sx={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                {title}
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={css(styles.dialogContent)} dividers={true}>
                <div style={{background: "#ccc", width: "100%", height: "100%"}}>
                <GenAIContent id={id}/>
                </div>
            </DialogContent>
            {/*<DialogActions style={{ padding: 24 }}>*/}
            {/*    <Button onClick={onSaveClick} variant="contained">*/}
            {/*        Done*/}
            {/*    </Button>*/}
            {/*</DialogActions>*/}
        </Dialog>
    );
}
