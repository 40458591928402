import Dialog from "@mui/material/Dialog";
import * as api from "@crochik/auto-pi";
import Button from "@mui/material/Button";
import { Dump } from "../../../../../Dump";

interface IEditFormProps {
    form: api.Form;
    raw?: object;
}

function EditForm({ form, raw }: IEditFormProps) {
    return (
        <div>
            <Dump style={{ fontSize: "8pt" }} object={form}/>
            {
                raw && (
                    <Dump style={{ fontSize: "8pt", color: "blue" }} object={raw}/>
                )
            }
        </div>
    );
}

interface IEditFormDialogProps extends IEditFormProps {
    onClose: () => void;
}

export function EditFormDialog(props: IEditFormDialogProps) {
    const { onClose } = props;

    return (
        <Dialog open={true} fullScreen onClose={onClose}>
            <Button onClick={onClose}>Close</Button>
            <EditForm {...props} />
        </Dialog>
    );
}