export interface IOptions {
    delimiter: string;
    doublequote: boolean;
    lineterminator: string;
    quotechar: string;
    skipinitialspace: boolean;
    skipinitialrows: number;
}

export class CSVWriter {
    options: IOptions;
    rxNeedsQuoting = /^\s|\s$|,|'|\n/;

    constructor() {
        this.options = {
            delimiter: ",",
            doublequote: true,
            lineterminator: "\n",
            quotechar: '"',
            skipinitialspace: true,
            skipinitialrows: 0,
        };
    }

    processField(field: any) {
        if (field === null) {
            // If field is null set to empty string
            field = "";
        } else if (typeof field === "string" && this.rxNeedsQuoting.test(field)) {
            if (this.options.doublequote) {
                field = field.replace(/'/g, "''");
            }
            // Convert string to delimited string
            field = this.options.quotechar + field + this.options.quotechar;
        } else if (typeof field === "number") {
            // Convert number to string
            field = field.toString(10);
        }

        return field;
    }

    export(dataToSerialize: any[][]) {
        var row = "";
        var out = "";
        for (var i = 0; i < dataToSerialize.length; i += 1) {
            var cur = dataToSerialize[i];

            for (var j = 0; j < cur.length; j += 1) {
                var field = this.processField(cur[j]);

                if (j === cur.length - 1) {
                    row += field;
                    out += row + this.options.lineterminator;
                    row = "";
                } else {
                    row += field + this.options.delimiter;
                }
            }
        }

        return out;
    }
}
