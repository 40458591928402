import ReverseSortIcon from "@mui/icons-material/ArrowDownward";
import SortIcon from "@mui/icons-material/ArrowUpward";
import FilterOff from "@mui/icons-material/FilterAltOff";
import HiddenIcon from "@mui/icons-material/VisibilityOff";
import { Box, IconButton } from "@mui/material";
import { CSSProperties, useCallback } from "react";

interface IProps {
    style?: CSSProperties;
    label: string;
    disabled?: boolean;
    children?: React.ReactNode;
    removeFilter: () => void;
    onSort?: (reverse: boolean) => void;
    onHideColumn?: () => void;
}

export const Container: React.FC<IProps> = ({ style, children, disabled, removeFilter, onSort, onHideColumn }) => {
    const onSortClicked = useCallback((reverse) => () => onSort?.(reverse), [onSort]);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                backgroundColor: "#efefef",
                padding: 8,
                minWidth: 200,
                ...style,
            }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "stretch",
                    alignItems: "center",
                    width: "100%",
                }}
            >
                {!disabled && (
                    <IconButton disabled={disabled} onClick={removeFilter}>
                        <FilterOff />
                    </IconButton>
                )}
            </div>

            <Box
                sx={{
                    overflow: "auto",
                    maxHeight: "calc(100% - 40px)",
                }}
            >
                {children}
            </Box>

            {onSort && (
                <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                    <IconButton onClick={onSortClicked(false)}>
                        <SortIcon />
                    </IconButton>
                    <IconButton onClick={onSortClicked(true)}>
                        <ReverseSortIcon />
                    </IconButton>
                    <IconButton onClick={onHideColumn}>
                        <HiddenIcon />
                    </IconButton>
                </div>
            )}
        </div>
    );
};
