import * as React from "react";
import { FieldProps } from "./FieldProps";

import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { PasswordFieldOptions } from "@crochik/pi-api";

interface State {
    showPassword: boolean;
}

export class PasswordField extends React.Component<FieldProps, State> {
    constructor(props: FieldProps) {
        super(props);

        this.state = {
            showPassword: false,
        };
    }

    onChange = (event: any) => {
        var value = event.target.value;

        if (this.props.onChange) {
            this.props.onChange(this.props.field, value);
        }
    };

    onKeyPressed = (event: any) => {
        if (event.key === "Enter" && this.props.onEnterPressed) {
            this.props.onEnterPressed(this.props.field);
            event.preventDefault();
        }
    };

    onClickShowPassword = (event: any) => {
        this.setState({
            ...this.state,
            showPassword: !this.state.showPassword,
        });
    };

    preventDefault = (event: any) => {
        event.preventDefault();
    };

    render() {
        const { value, field } = this.props;

        const { options } = field;
        const { multiline } = (options ?? {}) as PasswordFieldOptions;

        const formProps: any = {
            fullWidth: true,
            margin: "normal",
            style: this.props["style"],
            variant: "outlined",
        };

        return (
            <FormControl {...formProps}>
                <InputLabel required={this.props.field.isRequired} error={this.props.error ? true : false} htmlFor="adornment-password" shrink={true}>
                    {this.props.field.name}
                </InputLabel>
                <Input
                    id="adornment-password"
                    type={this.state.showPassword ? "text" : "password"}
                    onChange={this.onChange}
                    onKeyPress={this.onKeyPressed}
                    error={this.props.error ? true : false}
                    value={value ?? ""}
                    multiline={multiline}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="Toggle password visibility"
                                onClick={this.onClickShowPassword}
                                onMouseDown={this.preventDefault}
                                size="large"
                            >
                                {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
                {!this.props.error ? null : (
                    <FormHelperText id="name-error-text" error={this.props.error ? true : false}>
                        {this.props.error}
                    </FormHelperText>
                )}
            </FormControl>
        );
    }
}
