// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, MessagePayload, NextFn, Observer, onMessage, Unsubscribe } from "firebase/messaging";
import * as google from "src/apis/google";
import App from "src/pi/application/App";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAqRGEIhJJhI7G4mTgAEXljzmyh0GacT2k",
    authDomain: "pi314-490a5.firebaseapp.com",
    projectId: "pi314-490a5",
    storageBucket: "pi314-490a5.appspot.com",
    messagingSenderId: "1022163661776",
    appId: "1:1022163661776:web:7870b2782ffdca7723ffd5",
    measurementId: "G-X6TWK8K757",
};

// Initialize Firebase
export const app = window.Notification ? initializeApp(firebaseConfig) : undefined;
export const analytics = app ? getAnalytics(app) : undefined;
export const messaging = app ? getMessaging(app) : undefined;

async function updateToken(): Promise<boolean> {
    if (!messaging) return false;

    const app = App();
    const { session,  clientConfiguration } = app;
    const { user } = session;
    if (!user?.id) return false;

    console.log("updateToken");
    if (!Notification || Notification.permission !== "granted") {
        return false;
    }

    const token = await getToken(messaging, { vapidKey: "BMpT_H4Wf--k-DNdYk6g4f3wxzvjk0njC1Z3PMJMtJGw7XfA9ng4UuBR76wC2KbA9tsnU3pF3eRMpdGbRqY7Tdw" });
    if (!token) return false;

    const registrationId = await new google.PushNotificationApi(clientConfiguration).create(token).catch((reason) => {
        console.error("failed to register token");
    });

    if (!registrationId) return false;

    addRegistration(user.id, registrationId);
    return true;
}

export async function enableNotifications(): Promise<boolean> {
    if (!window.Notification) return false;

    console.log("Requesting permission...");
    const permission = await Notification.requestPermission().catch((reason) => {
        console.error(reason);
        return "denied";
    });

    if (permission !== "granted") {
        console.log("permission response", permission);
        return false;
    }

    return await updateToken();
}

export async function unregister() {
    const app = App();
    const { session,  clientConfiguration } = app;
    const { user } = session;
    if (!user?.id) return;

    const registrationId = getRegistrationId(user.id);

    if (registrationId) {
        await new google.PushNotificationApi(clientConfiguration).remove(registrationId).catch((reason) => {
            console.error("failed to unregister token");
        });

        console.log("removeToken");
        removeRegistration(user.id);
    }
}

function removeRegistration(userId: string) {
    const registrations = getRegistrations();
    delete registrations[userId];
    putRegistrations(registrations);
}

function addRegistration(userId: string, registrationId: string) {
    const registrations = {
        ...getRegistrations(),
        [userId]: registrationId,
    };

    putRegistrations(registrations);
}

function putRegistrations(registrations: { [userId: string]: string }) {
    localStorage.setItem("fcmRegistration", JSON.stringify(registrations));
}

function getRegistrations(): { [userId: string]: string } {
    const json = localStorage.getItem("fcmRegistration");
    if (!json) return {};
    return JSON.parse(json);
}

function getRegistrationId(userId: string) {
    return getRegistrations()[userId];
}

export function isRegistered(userId: string) {
    if (!window.Notification || Notification.permission !== "granted") return false;
    return userId in getRegistrations();
}

export function addMessageListener(nextOrObserver: NextFn<MessagePayload> | Observer<MessagePayload>): Unsubscribe {
    if (!messaging) {
        console.log("messaging not availble");
        return () => {};
    }

    console.log("add listener");
    return onMessage(messaging, nextOrObserver);
}

console.log("loaded firebase");
