import Dialog from "src/pi/services/Dialog";
import { unregister } from "src/pi/ui/material/NotificationMenu";
import LoginService from "../services/LoginService";

async function signOut() {
    await unregister();
    await LoginService().signOut();
}

export function logout() {
    Dialog.confirm({
        title: "Log Out?",
        message: "Would you like to terminate this session?",
        onPositive: signOut,
        positiveCaption: "Yes",
        negativeCaption: "No",
    });
}

export async function signIn(provider?: string) {
    return await LoginService().signIn(provider);
}

export async function signInWithMicrosoft() {
    const tenantId = process.env.REACT_APP_microsoft_tenant;
    return await LoginService().signIn("Microsoft", tenantId);
}
