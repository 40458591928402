import React, { useEffect, useState } from "react";
import * as yaml from "js-yaml";

export function Dump({ object, style }: { object?: object | null, style?: React.CSSProperties }) {
    const [pre, setPre] = useState<string>();
    useEffect(() => {
        setPre(undefined);
        if (!object) return;

        const options = {
            "styles": {
                "!!null": "empty"
            },
            "sortKeys": true        // sort object keys
        };

        setPre(yaml.dump(object, options));
    }, [object]);

    if (!pre) return null;

    return (
        <pre
            style={{
                margin: 0,
                ...style
            }}
        >
            {pre}
        </pre>
    );
}
