import * as React from "react";
import { observer } from "mobx-react";

import { Snackbar, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export interface Props {
    title: string;
    message: string;
    positiveCaption: string;
    negativeCaption?: string;
    open: boolean;
    onPositive: () => any;
    onNegative: () => any;
}

interface State {
    open: boolean;
}

@observer
export class SnackMessage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            open: props.open,
        };
    }

    close() {
        this.setState({ open: false });
    }

    onPositive = () => {
        this.close();
        this.props.onPositive();
    };

    onNegative = () => {
        this.close();
        this.props.onNegative();
    };

    render() {
        return (
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                open={this.state.open}
                autoHideDuration={3000}
                onClose={this.onPositive}
                ContentProps={{
                    "aria-describedby": "message-id",
                }}
                title={this.props.title}
                message={<span id="message-id">{this.props.message}</span>}
                action={
                    <IconButton key="close" aria-label="Close" color="primary" onClick={this.onPositive} size="large">
                        <CloseIcon />
                    </IconButton>
                }
            />
        );
    }
}

export default SnackMessage;
