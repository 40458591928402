import { observable } from "mobx";

import jwt_decode from "jwt-decode";
import * as Oidc from "oidc-client-ts";
import { Default } from "src/pi/context/AppContext";
import { IBootstrap, ISlimUser, JwtClaims, Session } from "../Session";
import App from "src/pi/application/App";
import { ImpersonateLoginService } from "./ImpersonateLoginService";

interface ISessionState {
    user?: ISlimUser; // Oidc.User;
}

export interface ILoginService {
    init(): Promise<ISlimUser | undefined>;

    signIn(provider?: string, tenant?: string, userId?: string): Promise<Oidc.User | null>;

    signOut(): Promise<any>;

    canRefresh(): Promise<boolean>;

    refreshToken(): Promise<Oidc.User | null>;
}

class LoginService implements ILoginService {
    private readonly state: ISessionState;
    private readonly settings: Oidc.UserManagerSettings;
    private manager: Oidc.UserManager;
    private sessionManager: Session;

    get authorityUrl() {
        return this.settings.authority;
    }

    constructor(bootstrap: IBootstrap, sessionManager: Session) {
        const host = `${document.location.protocol}//${document.location.host}`;
        const client_id = process.env.REACT_APP_client_id || document.location.hostname;
        const authority = process.env.REACT_APP_authority || bootstrap.authority;
        const redirect_uri = process.env.REACT_APP_redirect_uri || `${host}/index.html?`;
        const post_logout_redirect_uri = process.env.REACT_APP_post_logout_redirect_uri || `${host}/index.html`;
        const scope = process.env.REACT_APP_scope || bootstrap.apiScope;
        const acr_values = bootstrap.idps && bootstrap.idps.length > 0 ? `idp:${bootstrap.idps[0]}` : undefined;

        const offlineAccess = scope.indexOf("offline_access") >= 0;

        // Oidc.Log.logger = console;
        this.settings = {
            authority,
            client_id,
            redirect_uri,
            scope,
            post_logout_redirect_uri,
            acr_values,
            response_type: "code", // offlineAccess ? "code" : "id_token token",
            userStore: offlineAccess ? new Oidc.WebStorageStateStore({ store: window.localStorage }) : undefined
        };

        // Oidc.Log.setLogger(console);

        this.sessionManager = sessionManager;
        this.manager = new Oidc.UserManager(this.settings);
        this.manager.stopSilentRenew();
        this.registerEventHandlers();

        this.state = observable({
            user: undefined
        });

        Default.state.set("login", this.state);
    }

    registerEventHandlers() {
        // this.manager.events.addUserLoaded(async (settings) => {
        //     console.log("user loaded!?!?");
        // });

        // this.manager.events.addUserSignedOut(() => {
        //     console.log('user signed out');
        //     console.log(arguments);
        // });

        this.manager.events.addAccessTokenExpiring(async () => {
            console.log("token expiring");

            // localStorage.removeItem("autoLogin");
            // this.sessionManager.user = undefined;

            const user = await this.refreshToken();
            if (user) {
                console.log("signed in silently");
                this.state.user = user as ISlimUser;
                // hack for now to update the token without reloading the page
                App().updateAccessToken(user.access_token);
                // this.sessionManager.updateStatus(this.state.user, false);
                return;
            }

            console.log("can not refresh token");
            alert("This session is about to end.");
        });

        this.manager.events.addAccessTokenExpired(() => {
            console.log("token expired");

            localStorage.removeItem("autoLogin");
            this.sessionManager.user = undefined;

            // remove user from local database and reset ui
            // this.signOut().then(()=>this.state.user = undefined)
            // this.state.user = undefined;
        });

        this.manager.events.addUserSessionChanged(() => {
            console.log("session changed");
        });
    }

    signIn(provider?: string, tenant?: string, userId?: string): Promise<any> {
        var acrValues: string[] = [];
        var args = {};

        if (provider) {
            acrValues.push(`idp:${provider}`);
        }

        if (tenant) {
            acrValues.push(`tenant:${tenant}`);
        }

        if (userId) {
            acrValues.push(`impersonate:${userId}`);
            args["prompt"] = "login";
        }

        if (acrValues.length > 0) {
            args["acr_values"] = acrValues.reduce((p, c) => `${p} ${c}`, "");
        }

        console.log("sign in", args);
        return this.manager.signinRedirect(args);
    }

    async signOut(): Promise<any> {
        console.log("signout");
        localStorage.removeItem("autoLogin");
        sessionStorage.removeItem("request");

        await this.manager.signoutRedirect();
    }

    get hasOfflineAccess() {
        return (this.manager.settings.scope.indexOf("offline_access") >= 0);
    }

    async canRefresh(): Promise<boolean> {
        const user = await this.manager.getUser()
            .catch((e) => {
                console.error("failed to get user", e);
                return null;
            });

        return !!user && this.hasOfflineAccess;
    }

    async refreshToken(): Promise<Oidc.User | null> {
        if (!this.hasOfflineAccess) return null;
        const user = await this.manager.signinSilent()
            .catch(e => {
                console.error("failed to refresh token", e);
            });

        if (!user) return null;

        if (!this.sessionManager.user) {
            // start new session
            console.log("refreshed token, start new session");
            this.state.user = user as ISlimUser;
            await this.sessionManager.updateStatus(this.state.user, false);

        } else {
            console.log("signed in silently, update token");
            this.state.user = user as ISlimUser;

            // hack for now to update the token without reloading the page
            App().updateAccessToken(user.access_token);
        }

        return user;
    }

    /**
     * init session if user is known
     */
    async init(): Promise<ISlimUser | undefined> {
        try {
            let autoLogin = false;
            if (localStorage) {
                const json = localStorage.getItem("autoLogin");
                if (json) {
                    const config = JSON.parse(json);
                    if (config && config["userId"]) {
                        localStorage.removeItem("autoLogin");
                        autoLogin = true;
                    }
                }
            }

            const auth = sessionStorage.getItem("auth");
            if (auth) {
                // token saved in session storage
                sessionStorage.removeItem("auth");
                this.state.user = {
                    access_token: auth
                };

                await this.sessionManager.updateStatus(this.state.user, false);
                return this.state.user;
            }

            // get user from oidc client
            let user = await this.manager.getUser();
            if (user) {
                if (user.expired) {
                    console.log("found user but token is expired");

                    user = await this.refreshToken();
                    if (user) {
                        this.state.user = user as ISlimUser;
                        await this.sessionManager.updateStatus(this.state.user, false);
                        console.log("refreshed token");
                        return this.state.user;
                    }
                } else {
                    // logged in
                    console.log("manager returned user");
                    this.state.user = user as ISlimUser;
                    await this.sessionManager.updateStatus(this.state.user, false);
                    return this.state.user;
                }
            }

            const requestedLocation = this.sessionManager.launchUrl ?? this.sessionManager.parseLocation();
            if (requestedLocation.searchParams || requestedLocation.hash) {
                if (requestedLocation.searchParams && "code" in requestedLocation.searchParams && "scope" in requestedLocation.searchParams) {
                    // probably processing redirect of a login
                    const user = await this.manager.signinRedirectCallback()
                        .catch((e) => {
                            console.log("failed to process redirect callback", e);
                        });

                    if (user) {
                        this.state.user = user as ISlimUser;
                        console.log("got user from redirection");
                        await this.sessionManager.updateStatus(this.state.user, true);
                        return this.state.user;
                    }
                }

                console.log("auto login because of query");
                sessionStorage.setItem("request", JSON.stringify(requestedLocation));
                return await this.signIn();
            }

            // no user, check if should implicitly start login
            if (autoLogin) {
                console.log("auto login in storage");
                return await this.signIn();
            }

            return undefined;

        } catch (ex) {
            console.log(ex);
        }

        return undefined;
    }
}


function isJwtToken(value?: string): JwtClaims | null {
    if (!value) return null;
    if (value.startsWith("#")) value = value.substring(1);
    if (!value.startsWith("ey")) return null;

    try {
        return jwt_decode<JwtClaims>(value);
    } catch (ex) {
        console.log("not jwt", ex);
    }

    return null;
}

var instance: ILoginService | undefined = undefined;

export function initLoginService(bootstrap: IBootstrap) {
    const sessionManager = new Session(bootstrap);

    const auth = sessionStorage.getItem("auth");
    if (auth && isJwtToken(auth)) {
        // token saved in session storage (by redirect, e.g. salesforce)
        sessionStorage.removeItem("auth");
        instance = new ImpersonateLoginService(bootstrap, sessionManager, auth);
        return;
    }

    const jwt = isJwtToken(sessionManager.launchUrl?.hash);
    if (jwt?.name) {
        // launched index page with token (impersonate)
        const token = sessionManager.launchUrl.hash!;
        sessionManager.launchUrl.hash = undefined;
        alert(`Impersonating ${jwt.name}`);
        instance = new ImpersonateLoginService(bootstrap, sessionManager, token);
        return;
    }

    instance = new LoginService(bootstrap, sessionManager);
}

export default function get(): ILoginService {
    if (!instance) throw new Error("Login Service has not been initialized");
    return instance;
}

