import * as api from "@crochik/pi-api";
import { ChatReferenceFieldOptions, ChatRole } from "@crochik/pi-api";
import { FieldProps } from "../FieldProps";
import { Form } from "../../../Form";
import { useEffect, useState } from "react";
import App from "../../../../application/App";
import { GenAIContentDialog } from "../../../GenAIContent/GenAIContentDialog";
import { Button } from "@mui/material";
import { MessageContent } from "../../../GenAIContent";

interface IProps extends FieldProps {
    form?: Form;
}

export function ChatReferenceField(props: IProps) {
    const { field, value, form, onChange } = props;
    const options = field.options as ChatReferenceFieldOptions;

    const [chat, setChat] = useState<api.ChatContentResponse>();
    const [lastMessage, setLastMessage] = useState<api.ResolvedChatMessage>();
    const [error, setError] = useState<string>();
    const [isEditing, setEditing] = useState(false);

    const onDone = (id: string | undefined) => {
        if (!!value){
            // force reload
            // ...
        } else {
            onChange?.(field, chat!.id);
        }

        setEditing(false);
    };

    const startEditing = () => {
        if (!!value) {
            setEditing(true);
            return;
        }

        // create chat
        new api.ContentApi(App().apiConfig)
            .contentCreateChatForObject(options.assistantId!, options.objectTypeToReference!, options.objectIdToReference!)
            .then(x => {
                setChat(x);
                setEditing(true);
            })
            .catch(e => {
                console.error(e);
                setError("Failed to create Chat");
            });
    };

    const load = (id: string) => {
        new api.ContentApi(App().apiConfig)
            .contentGetChatContent(id)
            .then(x => {
                setChat(x);
                if (x.messages?.length! > 0) {
                    const last = x.messages![x.messages!.length! - 1];
                    setLastMessage(last.role === api.ChatRole.Assistant ? last : undefined);
                }
            })
            .catch(e => {
                console.error(e);
                setError("Failed to load Chat");
            });
    }

    useEffect(() => {
        if (!value) {
            return;
        }

        load(value)
    }, [value]);

    return (
        <div>
            {!!lastMessage?.prompt &&
                <MessageContent message={lastMessage}/>
            }
            {isEditing ?
                <GenAIContentDialog onDone={onDone} title={field.label ?? field.name ?? "GenAI Content"} id={chat?.id} /> :
                <Button onClick={startEditing}>Edit</Button>
            }
        </div>
    );
}