import { Actions, IActions } from "./Actions";
import { Dictionary, IDictionary } from "./Dictionary";
import QueryParams from "./QueryParams";
import { IServices, Services } from "./Services";
import { IState, State } from "./State";

export interface IUI extends IDictionary<any> {}

export class UI extends Dictionary<any> implements IUI {}

export interface IAppContext {
    state: IState;
    services: IServices;
    actions: IActions;
    ui: IUI;
}

export class AppContext implements IAppContext {
    state: IState;
    services: IServices;
    actions: IActions;
    ui: IUI;

    constructor() {
        this.state = new State();
        this.services = new Services();
        this.actions = new Actions();
        this.ui = new UI();
    }
}

export let Default = new AppContext();

QueryParams("url");
