import { Default } from "./AppContext";

interface ParsedValue {
    isFinal: boolean;
    result?: any;
    isOpposite: boolean;
    match: RegExpMatchArray | null;
    expression: string;
}

export function parse(name: string): ParsedValue {
    var result: ParsedValue = {
        expression: name,
        isFinal: false,
        isOpposite: false,
        match: null,
    };

    // special values
    switch (name) {
        case "false":
            console.debug(`${name}: constant "false", return false`);
            result.isFinal = true;
            result.result = false;
            return result;

        case "true":
            console.debug(`${name}: constant "true", check next`);
            result.isFinal = true;
            result.result = true;
            return result;

        default:
            break;
    }

    result.isOpposite = result.expression.startsWith("!");
    if (result.isOpposite) result.expression = result.expression.substr(1);

    result.match = result.expression.match(/([._\-a-z0-9A-Z]*)(==|!=|>=|<=|>|<)'([._\-a-z0-9A-Z]*)'/);
    if (result.match) {
        result.expression = result.match[1];
        console.debug(`expression for ${name} ${result.match[2]} "${result.match[3]}"`);
    } else {
        console.debug(`not an expression: ${name}`);
    }

    return result;
}

export function processResult(parsed: ParsedValue): boolean {
    if (parsed.isFinal) return parsed.result as boolean;

    if (parsed.match) {
        console.debug(`evaluate ${parsed.expression} ${parsed.match[2]} "${parsed.match[3]}", with ${parsed.result}`);
        switch (parsed.match[2]) {
            case "==":
            case "!=":
                if (parsed.result === undefined || parsed.result === null) {
                    console.debug(`"result is undefined: will not match anything`);
                    parsed.result = parsed.match[2] === "!=";
                } else if (parsed.result.toString() === parsed.match[3]) {
                    console.debug(`"${parsed.result}==${parsed.match[3]}" evaluated true`);
                    parsed.result = parsed.match[2] === "==";
                } else {
                    console.debug(`"${parsed.result}==${parsed.match[3]}" evaluated false`);
                    parsed.result = parsed.match[2] === "!=";
                }
                break;

            case ">=":
            case "<=":
            case ">":
            case "<":
                console.error(`operator not implemented yet: ${parsed.match[2]}`);
                parsed.result = false;
                break;

            default:
                break;
        }
    }

    if (!parsed.result && !parsed.isOpposite) {
        console.debug(`resulted in negative`);
        // return false;
        parsed.result = false;
    } else if (parsed.result && parsed.isOpposite) {
        console.debug(`resulted in positive, but using !`);
        parsed.result = false;
    } else {
        parsed.result = true;
    }

    return parsed.result;
}

export function evaluate(condition?: string | string[] | null, context?: string): boolean {
    if (!condition) return true;

    if (Array.isArray(condition)) {
        for (var c = 0; c < condition.length; c++) {
            if (!evaluate(condition[c], context)) {
                console.debug(`${condition[c]} resulted in false: return false`);
                return false;
            }
        }

        console.debug("all conditions resulted in true: return true");
        return true;
    }

    let parsed = parse(condition);
    if (!parsed.isFinal) {
        parsed.result = Default.state.get(parsed.expression, context);
    }

    return processResult(parsed);
}

export function evaluateWithContext(context: object, condition?: string | string[]|null): boolean {
    if (!condition) return true;

    if (Array.isArray(condition)) {
        for (var c = 0; c < condition.length; c++) {
            if (!evaluateWithContext(context, condition[c])) {
                console.debug(`${condition[c]} resulted in false: return false`);
                return false;
            }
        }

        console.debug("all conditions resulted in true: return true");
        return true;
    }

    let parsed = parse(condition);
    if (!parsed.isFinal) {
        parsed.result = resolveValueFromContext(context, parsed.expression);
    }

    return processResult(parsed);
}

function resolveValueFromContext(context: object, path: string) {
    let keys = path.split(".");
    var obj = context as { [key: string]: any };

    for (var c = 0; c < keys.length - 1; c++) {
        if (keys[c] in obj) {
            obj = obj[keys[c]] as unknown as { [key: string]: object };
            continue;
        }

        return undefined;
    }

    return typeof obj === "object" ? obj[keys[keys.length - 1]] : undefined;
}
