/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * SendGrid
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
// @ts-nocheck

import * as portableFetch from "portable-fetch";
import * as url from "url";
import { Configuration } from "./configuration";

const BASE_PATH = "https://localhost".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = portableFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError";
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 *
 * @export
 * @interface DataFormActionRequest
 */
export interface DataFormActionRequest {
    /**
     *
     * @type {string}
     * @memberof DataFormActionRequest
     */
    action?: string;
    /**
     *
     * @type {{ [key: string]: any; }}
     * @memberof DataFormActionRequest
     */
    parameters?: { [key: string]: any };
    /**
     *
     * @type {Array<string>}
     * @memberof DataFormActionRequest
     */
    selectedIds?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof DataFormActionRequest
     */
    view?: string;
}

/**
 *
 * @export
 * @interface DataFormActionResponse
 */
export interface DataFormActionResponse {
    /**
     *
     * @type {string}
     * @memberof DataFormActionResponse
     */
    action?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof DataFormActionResponse
     */
    ids?: Array<string>;
    /**
     *
     * @type {boolean}
     * @memberof DataFormActionResponse
     */
    success?: boolean;
    /**
     *
     * @type {string}
     * @memberof DataFormActionResponse
     */
    message?: string;
    /**
     *
     * @type {string}
     * @memberof DataFormActionResponse
     */
    nextUrl?: string;
}

/**
 *
 * @export
 * @interface EmailAddress
 */
export interface EmailAddress {
    /**
     *
     * @type {string}
     * @memberof EmailAddress
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAddress
     */
    email?: string;
}

/**
 *
 * @export
 * @interface EmailMessage
 */
export interface EmailMessage {
    /**
     *
     * @type {EmailAddress}
     * @memberof EmailMessage
     */
    from?: EmailAddress;
    /**
     *
     * @type {Array<EmailAddress>}
     * @memberof EmailMessage
     */
    to?: Array<EmailAddress>;
    /**
     *
     * @type {Array<EmailAddress>}
     * @memberof EmailMessage
     */
    cc?: Array<EmailAddress>;
    /**
     *
     * @type {Array<EmailAddress>}
     * @memberof EmailMessage
     */
    bcc?: Array<EmailAddress>;
    /**
     *
     * @type {EmailAddress}
     * @memberof EmailMessage
     */
    replyTo?: EmailAddress;
    /**
     *
     * @type {string}
     * @memberof EmailMessage
     */
    subject?: string;
    /**
     *
     * @type {string}
     * @memberof EmailMessage
     */
    plainBody?: string;
    /**
     *
     * @type {string}
     * @memberof EmailMessage
     */
    htmlBody?: string;
    /**
     *
     * @type {Array<MimeContent>}
     * @memberof EmailMessage
     */
    contents?: Array<MimeContent>;
    /**
     *
     * @type {string}
     * @memberof EmailMessage
     */
    templateId?: string;
    /**
     *
     * @type {any}
     * @memberof EmailMessage
     */
    templateData?: any;
}

/**
 *
 * @export
 * @enum {string}
 */
export enum EntityTrunkLevel {
    User = <any>"User",
    Organization = <any>"Organization",
    Account = <any>"Account",
}

/**
 *
 * @export
 * @interface FieldOptions
 */
export interface FieldOptions {
    /**
     *
     * @type {string}
     * @memberof FieldOptions
     */
    t?: string;
    /**
     *
     * @type {string}
     * @memberof FieldOptions
     */
    linkUrl?: string;
}

/**
 *
 * @export
 * @interface Form
 */
export interface Form {
    /**
     *
     * @type {string}
     * @memberof Form
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof Form
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof Form
     */
    title?: string;
    /**
     *
     * @type {Array<FormField>}
     * @memberof Form
     */
    fields?: Array<FormField>;
    /**
     *
     * @type {boolean}
     * @memberof Form
     */
    isReadOnly?: boolean;
    /**
     *
     * @type {Array<FormAction>}
     * @memberof Form
     */
    actions?: Array<FormAction>;
    /**
     *
     * @type {Menu}
     * @memberof Form
     */
    menu?: Menu;
    /**
     *
     * @type {FormLayouts}
     * @memberof Form
     */
    layouts?: FormLayouts;
    /**
     *
     * @type {string}
     * @memberof Form
     */
    objectType?: string;
}

/**
 *
 * @export
 * @interface FormAction
 */
export interface FormAction {
    /**
     *
     * @type {string}
     * @memberof FormAction
     */
    action?: string;
    /**
     *
     * @type {string}
     * @memberof FormAction
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof FormAction
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof FormAction
     */
    label?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof FormAction
     */
    enable?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof FormAction
     */
    visible?: Array<string>;
    /**
     *
     * @type {boolean}
     * @memberof FormAction
     */
    isReadOnly?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof FormAction
     */
    isVisible?: boolean;
}

/**
 *
 * @export
 * @interface FormField
 */
export interface FormField {
    /**
     *
     * @type {string}
     * @memberof FormField
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof FormField
     */
    description?: string;
    /**
     *
     * @type {any}
     * @memberof FormField
     */
    defaultValue?: any;
    /**
     *
     * @type {boolean}
     * @memberof FormField
     */
    isRequired?: boolean;
    /**
     *
     * @type {any}
     * @memberof FormField
     */
    style?: any;
    /**
     *
     * @type {FieldOptions}
     * @memberof FormField
     */
    options?: FieldOptions;
    /**
     *
     * @type {string}
     * @memberof FormField
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof FormField
     */
    label?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof FormField
     */
    enable?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof FormField
     */
    visible?: Array<string>;
    /**
     *
     * @type {boolean}
     * @memberof FormField
     */
    isReadOnly?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof FormField
     */
    isVisible?: boolean;
}

/**
 *
 * @export
 * @interface FormLayout
 */
export interface FormLayout {
    /**
     *
     * @type {string}
     * @memberof FormLayout
     */
    type?: string;
    /**
     *
     * @type {ScreenBreakpoint}
     * @memberof FormLayout
     */
    breakpoint?: ScreenBreakpoint;
}

/**
 *
 * @export
 * @interface FormLayouts
 */
export interface FormLayouts {
    /**
     *
     * @type {FormLayout}
     * @memberof FormLayouts
     */
    xs?: FormLayout;
    /**
     *
     * @type {FormLayout}
     * @memberof FormLayouts
     */
    sm?: FormLayout;
    /**
     *
     * @type {FormLayout}
     * @memberof FormLayouts
     */
    md?: FormLayout;
    /**
     *
     * @type {FormLayout}
     * @memberof FormLayouts
     */
    lg?: FormLayout;
    /**
     *
     * @type {FormLayout}
     * @memberof FormLayouts
     */
    xl?: FormLayout;
}

/**
 *
 * @export
 * @enum {string}
 */
export enum Icons {
    Account = <any>"Account",
    Action = <any>"Action",
    Add = <any>"Add",
    Download = <any>"Download",
    Edit = <any>"Edit",
    Menu = <any>"Menu",
    More = <any>"More",
    Notifications = <any>"Notifications",
    Refresh = <any>"Refresh",
    Remove = <any>"Remove",
    Save = <any>"Save",
    Search = <any>"Search",
    Settings = <any>"Settings",
    Upload = <any>"Upload",
    Print = <any>"Print",
    Design = <any>"Design",
}

/**
 *
 * @export
 * @interface IntegrationAuth
 */
export interface IntegrationAuth {
    /**
     *
     * @type {string}
     * @memberof IntegrationAuth
     */
    apiKey?: string;
}

/**
 *
 * @export
 * @interface IntegrationData
 */
export interface IntegrationData {
    /**
     *
     * @type {string}
     * @memberof IntegrationData
     */
    fromName?: string;
    /**
     *
     * @type {string}
     * @memberof IntegrationData
     */
    fromEmail?: string;
    /**
     *
     * @type {string}
     * @memberof IntegrationData
     */
    templateId?: string;
    /**
     *
     * @type {string}
     * @memberof IntegrationData
     */
    webhookSignatureKey?: string;
    /**
     *
     * @type {string}
     * @memberof IntegrationData
     */
    unsubscribeUrlTemplate?: string;
    /**
     *
     * @type {string}
     * @memberof IntegrationData
     */
    unsubscribedRedirectUrl?: string;
}

/**
 *
 * @export
 * @interface IntegrationRequest
 */
export interface IntegrationRequest {
    /**
     *
     * @type {IntegrationData}
     * @memberof IntegrationRequest
     */
    data?: IntegrationData;
    /**
     *
     * @type {IntegrationAuth}
     * @memberof IntegrationRequest
     */
    auth?: IntegrationAuth;
}

/**
 *
 * @export
 * @interface Menu
 */
export interface Menu {
    /**
     *
     * @type {Array<MenuItem>}
     * @memberof Menu
     */
    items?: Array<MenuItem>;
    /**
     *
     * @type {boolean}
     * @memberof Menu
     */
    collapsible?: boolean;
    /**
     *
     * @type {string}
     * @memberof Menu
     */
    t?: string;
    /**
     *
     * @type {Icons}
     * @memberof Menu
     */
    icon?: Icons;
    /**
     *
     * @type {string}
     * @memberof Menu
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof Menu
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof Menu
     */
    label?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof Menu
     */
    enable?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof Menu
     */
    visible?: Array<string>;
    /**
     *
     * @type {boolean}
     * @memberof Menu
     */
    isReadOnly?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof Menu
     */
    isVisible?: boolean;
}

/**
 *
 * @export
 * @interface MenuItem
 */
export interface MenuItem {
    /**
     *
     * @type {string}
     * @memberof MenuItem
     */
    t?: string;
    /**
     *
     * @type {Icons}
     * @memberof MenuItem
     */
    icon?: Icons;
    /**
     *
     * @type {string}
     * @memberof MenuItem
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof MenuItem
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof MenuItem
     */
    label?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof MenuItem
     */
    enable?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof MenuItem
     */
    visible?: Array<string>;
    /**
     *
     * @type {boolean}
     * @memberof MenuItem
     */
    isReadOnly?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof MenuItem
     */
    isVisible?: boolean;
}

/**
 *
 * @export
 * @interface MimeContent
 */
export interface MimeContent {
    /**
     *
     * @type {string}
     * @memberof MimeContent
     */
    contentType?: string;
    /**
     *
     * @type {number}
     * @memberof MimeContent
     */
    size?: number;
    /**
     *
     * @type {string}
     * @memberof MimeContent
     */
    content?: string;
    /**
     *
     * @type {string}
     * @memberof MimeContent
     */
    filename?: string;
}

/**
 *
 * @export
 * @enum {string}
 */
export enum ScreenBreakpoint {
    Xs = <any>"xs",
    Sm = <any>"sm",
    Md = <any>"md",
    Lg = <any>"lg",
    Xl = <any>"xl",
}

/**
 *
 * @export
 * @interface Template
 */
export interface Template {
    /**
     *
     * @type {string}
     * @memberof Template
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof Template
     */
    name?: string;
    /**
     *
     * @type {Array<TemplateVersion>}
     * @memberof Template
     */
    versions?: Array<TemplateVersion>;
}

/**
 *
 * @export
 * @interface TemplateVersion
 */
export interface TemplateVersion {
    /**
     *
     * @type {string}
     * @memberof TemplateVersion
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof TemplateVersion
     */
    templateId?: string;
    /**
     *
     * @type {boolean}
     * @memberof TemplateVersion
     */
    active?: boolean;
    /**
     *
     * @type {string}
     * @memberof TemplateVersion
     */
    name?: string;
    /**
     *
     * @type {boolean}
     * @memberof TemplateVersion
     */
    generatePlainContent?: boolean;
    /**
     *
     * @type {string}
     * @memberof TemplateVersion
     */
    subject?: string;
    /**
     *
     * @type {Date}
     * @memberof TemplateVersion
     */
    updatedAt?: Date;
    /**
     *
     * @type {string}
     * @memberof TemplateVersion
     */
    editor?: string;
}

/**
 *
 * @export
 * @interface TrunkIntegrationData
 */
export interface TrunkIntegrationData {
    /**
     *
     * @type {IntegrationData}
     * @memberof TrunkIntegrationData
     */
    data?: IntegrationData;
    /**
     *
     * @type {EntityTrunkLevel}
     * @memberof TrunkIntegrationData
     */
    level?: EntityTrunkLevel;
    /**
     *
     * @type {string}
     * @memberof TrunkIntegrationData
     */
    entityId?: string;
}

/**
 *
 * @export
 * @interface TwilioIntegrationAuth
 */
export interface TwilioIntegrationAuth {
    /**
     *
     * @type {string}
     * @memberof TwilioIntegrationAuth
     */
    token?: string;
}

/**
 *
 * @export
 * @interface TwilioIntegrationData
 */
export interface TwilioIntegrationData {
    /**
     *
     * @type {string}
     * @memberof TwilioIntegrationData
     */
    accountSid?: string;
    /**
     *
     * @type {string}
     * @memberof TwilioIntegrationData
     */
    messagingServiceSid?: string;
    /**
     *
     * @type {string}
     * @memberof TwilioIntegrationData
     */
    phoneNumber?: string;
    /**
     *
     * @type {string}
     * @memberof TwilioIntegrationData
     */
    statusCallbackUrl?: string;
}

/**
 *
 * @export
 * @interface TwilioIntegrationRequest
 */
export interface TwilioIntegrationRequest {
    /**
     *
     * @type {TwilioIntegrationData}
     * @memberof TwilioIntegrationRequest
     */
    data?: TwilioIntegrationData;
    /**
     *
     * @type {TwilioIntegrationAuth}
     * @memberof TwilioIntegrationRequest
     */
    auth?: TwilioIntegrationAuth;
}

/**
 *
 * @export
 * @interface TwilioTrunkIntegrationData
 */
export interface TwilioTrunkIntegrationData {
    /**
     *
     * @type {TwilioIntegrationData}
     * @memberof TwilioTrunkIntegrationData
     */
    data?: TwilioIntegrationData;
    /**
     *
     * @type {EntityTrunkLevel}
     * @memberof TwilioTrunkIntegrationData
     */
    level?: EntityTrunkLevel;
    /**
     *
     * @type {string}
     * @memberof TwilioTrunkIntegrationData
     */
    entityId?: string;
}

/**
 *
 * @export
 * @interface UnlayerImageUploadResponse
 */
export interface UnlayerImageUploadResponse {
    /**
     *
     * @type {string}
     * @memberof UnlayerImageUploadResponse
     */
    url?: string;
}

/**
 *
 * @export
 * @interface UnlayerMergeTag
 */
export interface UnlayerMergeTag {
    /**
     *
     * @type {string}
     * @memberof UnlayerMergeTag
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof UnlayerMergeTag
     */
    value?: string;
}

/**
 *
 * @export
 * @interface UnlayerMergeTagGroup
 */
export interface UnlayerMergeTagGroup {
    /**
     *
     * @type {string}
     * @memberof UnlayerMergeTagGroup
     */
    name?: string;
    /**
     *
     * @type {{ [key: string]: UnlayerMergeTag; }}
     * @memberof UnlayerMergeTagGroup
     */
    mergeTags?: { [key: string]: UnlayerMergeTag };
}

/**
 *
 * @export
 * @interface UnlayerSession
 */
export interface UnlayerSession {
    /**
     *
     * @type {number}
     * @memberof UnlayerSession
     */
    projectId?: number;
    /**
     *
     * @type {string}
     * @memberof UnlayerSession
     */
    userId?: string;
    /**
     *
     * @type {string}
     * @memberof UnlayerSession
     */
    signature?: string;
    /**
     *
     * @type {Array<UnlayerMergeTagGroup>}
     * @memberof UnlayerSession
     */
    mergeTags?: Array<UnlayerMergeTagGroup>;
}

/**
 * EmailApi - fetch parameter creator
 * @export
 */
export const EmailApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {EmailMessage} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmail(body?: EmailMessage, options: any = {}): FetchArgs {
            const localVarPath = `/sendgrid/v1/Email`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "POST" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === "function" ? configuration.accessToken("oauth2", []) : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter["Content-Type"] = "application/json-patch+json";

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = <any>"EmailMessage" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || "";

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * EmailApi - functional programming interface
 * @export
 */
export const EmailApiFp = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {EmailMessage} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmail(body?: EmailMessage, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = EmailApiFetchParamCreator(configuration).sendEmail(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    };
};

/**
 * EmailApi - factory interface
 * @export
 */
export const EmailApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {EmailMessage} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmail(body?: EmailMessage, options?: any) {
            return EmailApiFp(configuration).sendEmail(body, options)(fetch, basePath);
        },
    };
};

/**
 * EmailApi - interface
 * @export
 * @interface EmailApi
 */
export interface EmailApiInterface {
    /**
     *
     * @param {EmailMessage} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApiInterface
     */
    sendEmail(body?: EmailMessage, options?: any): Promise<{}>;
}

/**
 * EmailApi - object-oriented interface
 * @export
 * @class EmailApi
 * @extends {BaseAPI}
 */
export class EmailApi extends BaseAPI implements EmailApiInterface {
    /**
     *
     * @param {EmailMessage} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public sendEmail(body?: EmailMessage, options?: any) {
        return EmailApiFp(this.configuration).sendEmail(body, options)(this.fetch, this.basePath);
    }
}

/**
 * SMSApi - fetch parameter creator
 * @export
 */
export const SMSApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} id
         * @param {string} [messageSid]
         * @param {string} [smsSid]
         * @param {string} [body]
         * @param {string} [messageStatus]
         * @param {string} [optOutType]
         * @param {string} [messagingServiceSid]
         * @param {number} [numMedia]
         * @param {number} [referralNumMedia]
         * @param {string} [accountSid]
         * @param {string} [from]
         * @param {string} [to]
         * @param {string} [fromCity]
         * @param {string} [fromState]
         * @param {string} [fromZip]
         * @param {string} [fromCountry]
         * @param {string} [toCity]
         * @param {string} [toState]
         * @param {string} [toZip]
         * @param {string} [toCountry]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        incomingSMS(
            id: string,
            messageSid?: string,
            smsSid?: string,
            body?: string,
            messageStatus?: string,
            optOutType?: string,
            messagingServiceSid?: string,
            numMedia?: number,
            referralNumMedia?: number,
            accountSid?: string,
            from?: string,
            to?: string,
            fromCity?: string,
            fromState?: string,
            fromZip?: string,
            fromCountry?: string,
            toCity?: string,
            toState?: string,
            toZip?: string,
            toCountry?: string,
            options: any = {}
        ): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling incomingSMS.");
            }
            const localVarPath = `/twilio/v1/Entity({id})/SMS`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "POST" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (messageSid !== undefined) {
                localVarQueryParameter["MessageSid"] = messageSid;
            }

            if (smsSid !== undefined) {
                localVarQueryParameter["SmsSid"] = smsSid;
            }

            if (body !== undefined) {
                localVarQueryParameter["Body"] = body;
            }

            if (messageStatus !== undefined) {
                localVarQueryParameter["MessageStatus"] = messageStatus;
            }

            if (optOutType !== undefined) {
                localVarQueryParameter["OptOutType"] = optOutType;
            }

            if (messagingServiceSid !== undefined) {
                localVarQueryParameter["MessagingServiceSid"] = messagingServiceSid;
            }

            if (numMedia !== undefined) {
                localVarQueryParameter["NumMedia"] = numMedia;
            }

            if (referralNumMedia !== undefined) {
                localVarQueryParameter["ReferralNumMedia"] = referralNumMedia;
            }

            if (accountSid !== undefined) {
                localVarQueryParameter["AccountSid"] = accountSid;
            }

            if (from !== undefined) {
                localVarQueryParameter["From"] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter["To"] = to;
            }

            if (fromCity !== undefined) {
                localVarQueryParameter["FromCity"] = fromCity;
            }

            if (fromState !== undefined) {
                localVarQueryParameter["FromState"] = fromState;
            }

            if (fromZip !== undefined) {
                localVarQueryParameter["FromZip"] = fromZip;
            }

            if (fromCountry !== undefined) {
                localVarQueryParameter["FromCountry"] = fromCountry;
            }

            if (toCity !== undefined) {
                localVarQueryParameter["ToCity"] = toCity;
            }

            if (toState !== undefined) {
                localVarQueryParameter["ToState"] = toState;
            }

            if (toZip !== undefined) {
                localVarQueryParameter["ToZip"] = toZip;
            }

            if (toCountry !== undefined) {
                localVarQueryParameter["ToCountry"] = toCountry;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {string} [errorCode]
         * @param {string} [channelInstallSid]
         * @param {string} [channelStatusMessage]
         * @param {string} [channelPrefix]
         * @param {string} [eventType]
         * @param {string} [messageSid]
         * @param {string} [smsSid]
         * @param {string} [body]
         * @param {string} [messageStatus]
         * @param {string} [optOutType]
         * @param {string} [messagingServiceSid]
         * @param {number} [numMedia]
         * @param {number} [referralNumMedia]
         * @param {string} [accountSid]
         * @param {string} [from]
         * @param {string} [to]
         * @param {string} [fromCity]
         * @param {string} [fromState]
         * @param {string} [fromZip]
         * @param {string} [fromCountry]
         * @param {string} [toCity]
         * @param {string} [toState]
         * @param {string} [toZip]
         * @param {string} [toCountry]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendStatusCallback(
            id: string,
            errorCode?: string,
            channelInstallSid?: string,
            channelStatusMessage?: string,
            channelPrefix?: string,
            eventType?: string,
            messageSid?: string,
            smsSid?: string,
            body?: string,
            messageStatus?: string,
            optOutType?: string,
            messagingServiceSid?: string,
            numMedia?: number,
            referralNumMedia?: number,
            accountSid?: string,
            from?: string,
            to?: string,
            fromCity?: string,
            fromState?: string,
            fromZip?: string,
            fromCountry?: string,
            toCity?: string,
            toState?: string,
            toZip?: string,
            toCountry?: string,
            options: any = {}
        ): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling sendStatusCallback.");
            }
            const localVarPath = `/twilio/v1/SMS({id})/Status`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "POST" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (errorCode !== undefined) {
                localVarQueryParameter["ErrorCode"] = errorCode;
            }

            if (channelInstallSid !== undefined) {
                localVarQueryParameter["ChannelInstallSid"] = channelInstallSid;
            }

            if (channelStatusMessage !== undefined) {
                localVarQueryParameter["ChannelStatusMessage"] = channelStatusMessage;
            }

            if (channelPrefix !== undefined) {
                localVarQueryParameter["ChannelPrefix"] = channelPrefix;
            }

            if (eventType !== undefined) {
                localVarQueryParameter["EventType"] = eventType;
            }

            if (messageSid !== undefined) {
                localVarQueryParameter["MessageSid"] = messageSid;
            }

            if (smsSid !== undefined) {
                localVarQueryParameter["SmsSid"] = smsSid;
            }

            if (body !== undefined) {
                localVarQueryParameter["Body"] = body;
            }

            if (messageStatus !== undefined) {
                localVarQueryParameter["MessageStatus"] = messageStatus;
            }

            if (optOutType !== undefined) {
                localVarQueryParameter["OptOutType"] = optOutType;
            }

            if (messagingServiceSid !== undefined) {
                localVarQueryParameter["MessagingServiceSid"] = messagingServiceSid;
            }

            if (numMedia !== undefined) {
                localVarQueryParameter["NumMedia"] = numMedia;
            }

            if (referralNumMedia !== undefined) {
                localVarQueryParameter["ReferralNumMedia"] = referralNumMedia;
            }

            if (accountSid !== undefined) {
                localVarQueryParameter["AccountSid"] = accountSid;
            }

            if (from !== undefined) {
                localVarQueryParameter["From"] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter["To"] = to;
            }

            if (fromCity !== undefined) {
                localVarQueryParameter["FromCity"] = fromCity;
            }

            if (fromState !== undefined) {
                localVarQueryParameter["FromState"] = fromState;
            }

            if (fromZip !== undefined) {
                localVarQueryParameter["FromZip"] = fromZip;
            }

            if (fromCountry !== undefined) {
                localVarQueryParameter["FromCountry"] = fromCountry;
            }

            if (toCity !== undefined) {
                localVarQueryParameter["ToCity"] = toCity;
            }

            if (toState !== undefined) {
                localVarQueryParameter["ToState"] = toState;
            }

            if (toZip !== undefined) {
                localVarQueryParameter["ToZip"] = toZip;
            }

            if (toCountry !== undefined) {
                localVarQueryParameter["ToCountry"] = toCountry;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [errorCode]
         * @param {string} [channelInstallSid]
         * @param {string} [channelStatusMessage]
         * @param {string} [channelPrefix]
         * @param {string} [eventType]
         * @param {string} [messageSid]
         * @param {string} [smsSid]
         * @param {string} [body]
         * @param {string} [messageStatus]
         * @param {string} [optOutType]
         * @param {string} [messagingServiceSid]
         * @param {number} [numMedia]
         * @param {number} [referralNumMedia]
         * @param {string} [accountSid]
         * @param {string} [from]
         * @param {string} [to]
         * @param {string} [fromCity]
         * @param {string} [fromState]
         * @param {string} [fromZip]
         * @param {string} [fromCountry]
         * @param {string} [toCity]
         * @param {string} [toState]
         * @param {string} [toZip]
         * @param {string} [toCountry]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statusCallback(
            errorCode?: string,
            channelInstallSid?: string,
            channelStatusMessage?: string,
            channelPrefix?: string,
            eventType?: string,
            messageSid?: string,
            smsSid?: string,
            body?: string,
            messageStatus?: string,
            optOutType?: string,
            messagingServiceSid?: string,
            numMedia?: number,
            referralNumMedia?: number,
            accountSid?: string,
            from?: string,
            to?: string,
            fromCity?: string,
            fromState?: string,
            fromZip?: string,
            fromCountry?: string,
            toCity?: string,
            toState?: string,
            toZip?: string,
            toCountry?: string,
            options: any = {}
        ): FetchArgs {
            const localVarPath = `/twilio/v1/SMS/Status`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "POST" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (errorCode !== undefined) {
                localVarQueryParameter["ErrorCode"] = errorCode;
            }

            if (channelInstallSid !== undefined) {
                localVarQueryParameter["ChannelInstallSid"] = channelInstallSid;
            }

            if (channelStatusMessage !== undefined) {
                localVarQueryParameter["ChannelStatusMessage"] = channelStatusMessage;
            }

            if (channelPrefix !== undefined) {
                localVarQueryParameter["ChannelPrefix"] = channelPrefix;
            }

            if (eventType !== undefined) {
                localVarQueryParameter["EventType"] = eventType;
            }

            if (messageSid !== undefined) {
                localVarQueryParameter["MessageSid"] = messageSid;
            }

            if (smsSid !== undefined) {
                localVarQueryParameter["SmsSid"] = smsSid;
            }

            if (body !== undefined) {
                localVarQueryParameter["Body"] = body;
            }

            if (messageStatus !== undefined) {
                localVarQueryParameter["MessageStatus"] = messageStatus;
            }

            if (optOutType !== undefined) {
                localVarQueryParameter["OptOutType"] = optOutType;
            }

            if (messagingServiceSid !== undefined) {
                localVarQueryParameter["MessagingServiceSid"] = messagingServiceSid;
            }

            if (numMedia !== undefined) {
                localVarQueryParameter["NumMedia"] = numMedia;
            }

            if (referralNumMedia !== undefined) {
                localVarQueryParameter["ReferralNumMedia"] = referralNumMedia;
            }

            if (accountSid !== undefined) {
                localVarQueryParameter["AccountSid"] = accountSid;
            }

            if (from !== undefined) {
                localVarQueryParameter["From"] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter["To"] = to;
            }

            if (fromCity !== undefined) {
                localVarQueryParameter["FromCity"] = fromCity;
            }

            if (fromState !== undefined) {
                localVarQueryParameter["FromState"] = fromState;
            }

            if (fromZip !== undefined) {
                localVarQueryParameter["FromZip"] = fromZip;
            }

            if (fromCountry !== undefined) {
                localVarQueryParameter["FromCountry"] = fromCountry;
            }

            if (toCity !== undefined) {
                localVarQueryParameter["ToCity"] = toCity;
            }

            if (toState !== undefined) {
                localVarQueryParameter["ToState"] = toState;
            }

            if (toZip !== undefined) {
                localVarQueryParameter["ToZip"] = toZip;
            }

            if (toCountry !== undefined) {
                localVarQueryParameter["ToCountry"] = toCountry;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * SMSApi - functional programming interface
 * @export
 */
export const SMSApiFp = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} id
         * @param {string} [messageSid]
         * @param {string} [smsSid]
         * @param {string} [body]
         * @param {string} [messageStatus]
         * @param {string} [optOutType]
         * @param {string} [messagingServiceSid]
         * @param {number} [numMedia]
         * @param {number} [referralNumMedia]
         * @param {string} [accountSid]
         * @param {string} [from]
         * @param {string} [to]
         * @param {string} [fromCity]
         * @param {string} [fromState]
         * @param {string} [fromZip]
         * @param {string} [fromCountry]
         * @param {string} [toCity]
         * @param {string} [toState]
         * @param {string} [toZip]
         * @param {string} [toCountry]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        incomingSMS(
            id: string,
            messageSid?: string,
            smsSid?: string,
            body?: string,
            messageStatus?: string,
            optOutType?: string,
            messagingServiceSid?: string,
            numMedia?: number,
            referralNumMedia?: number,
            accountSid?: string,
            from?: string,
            to?: string,
            fromCity?: string,
            fromState?: string,
            fromZip?: string,
            fromCountry?: string,
            toCity?: string,
            toState?: string,
            toZip?: string,
            toCountry?: string,
            options?: any
        ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SMSApiFetchParamCreator(configuration).incomingSMS(
                id,
                messageSid,
                smsSid,
                body,
                messageStatus,
                optOutType,
                messagingServiceSid,
                numMedia,
                referralNumMedia,
                accountSid,
                from,
                to,
                fromCity,
                fromState,
                fromZip,
                fromCountry,
                toCity,
                toState,
                toZip,
                toCountry,
                options
            );
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} id
         * @param {string} [errorCode]
         * @param {string} [channelInstallSid]
         * @param {string} [channelStatusMessage]
         * @param {string} [channelPrefix]
         * @param {string} [eventType]
         * @param {string} [messageSid]
         * @param {string} [smsSid]
         * @param {string} [body]
         * @param {string} [messageStatus]
         * @param {string} [optOutType]
         * @param {string} [messagingServiceSid]
         * @param {number} [numMedia]
         * @param {number} [referralNumMedia]
         * @param {string} [accountSid]
         * @param {string} [from]
         * @param {string} [to]
         * @param {string} [fromCity]
         * @param {string} [fromState]
         * @param {string} [fromZip]
         * @param {string} [fromCountry]
         * @param {string} [toCity]
         * @param {string} [toState]
         * @param {string} [toZip]
         * @param {string} [toCountry]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendStatusCallback(
            id: string,
            errorCode?: string,
            channelInstallSid?: string,
            channelStatusMessage?: string,
            channelPrefix?: string,
            eventType?: string,
            messageSid?: string,
            smsSid?: string,
            body?: string,
            messageStatus?: string,
            optOutType?: string,
            messagingServiceSid?: string,
            numMedia?: number,
            referralNumMedia?: number,
            accountSid?: string,
            from?: string,
            to?: string,
            fromCity?: string,
            fromState?: string,
            fromZip?: string,
            fromCountry?: string,
            toCity?: string,
            toState?: string,
            toZip?: string,
            toCountry?: string,
            options?: any
        ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SMSApiFetchParamCreator(configuration).sendStatusCallback(
                id,
                errorCode,
                channelInstallSid,
                channelStatusMessage,
                channelPrefix,
                eventType,
                messageSid,
                smsSid,
                body,
                messageStatus,
                optOutType,
                messagingServiceSid,
                numMedia,
                referralNumMedia,
                accountSid,
                from,
                to,
                fromCity,
                fromState,
                fromZip,
                fromCountry,
                toCity,
                toState,
                toZip,
                toCountry,
                options
            );
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [errorCode]
         * @param {string} [channelInstallSid]
         * @param {string} [channelStatusMessage]
         * @param {string} [channelPrefix]
         * @param {string} [eventType]
         * @param {string} [messageSid]
         * @param {string} [smsSid]
         * @param {string} [body]
         * @param {string} [messageStatus]
         * @param {string} [optOutType]
         * @param {string} [messagingServiceSid]
         * @param {number} [numMedia]
         * @param {number} [referralNumMedia]
         * @param {string} [accountSid]
         * @param {string} [from]
         * @param {string} [to]
         * @param {string} [fromCity]
         * @param {string} [fromState]
         * @param {string} [fromZip]
         * @param {string} [fromCountry]
         * @param {string} [toCity]
         * @param {string} [toState]
         * @param {string} [toZip]
         * @param {string} [toCountry]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statusCallback(
            errorCode?: string,
            channelInstallSid?: string,
            channelStatusMessage?: string,
            channelPrefix?: string,
            eventType?: string,
            messageSid?: string,
            smsSid?: string,
            body?: string,
            messageStatus?: string,
            optOutType?: string,
            messagingServiceSid?: string,
            numMedia?: number,
            referralNumMedia?: number,
            accountSid?: string,
            from?: string,
            to?: string,
            fromCity?: string,
            fromState?: string,
            fromZip?: string,
            fromCountry?: string,
            toCity?: string,
            toState?: string,
            toZip?: string,
            toCountry?: string,
            options?: any
        ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SMSApiFetchParamCreator(configuration).statusCallback(
                errorCode,
                channelInstallSid,
                channelStatusMessage,
                channelPrefix,
                eventType,
                messageSid,
                smsSid,
                body,
                messageStatus,
                optOutType,
                messagingServiceSid,
                numMedia,
                referralNumMedia,
                accountSid,
                from,
                to,
                fromCity,
                fromState,
                fromZip,
                fromCountry,
                toCity,
                toState,
                toZip,
                toCountry,
                options
            );
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    };
};

/**
 * SMSApi - factory interface
 * @export
 */
export const SMSApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {string} id
         * @param {string} [messageSid]
         * @param {string} [smsSid]
         * @param {string} [body]
         * @param {string} [messageStatus]
         * @param {string} [optOutType]
         * @param {string} [messagingServiceSid]
         * @param {number} [numMedia]
         * @param {number} [referralNumMedia]
         * @param {string} [accountSid]
         * @param {string} [from]
         * @param {string} [to]
         * @param {string} [fromCity]
         * @param {string} [fromState]
         * @param {string} [fromZip]
         * @param {string} [fromCountry]
         * @param {string} [toCity]
         * @param {string} [toState]
         * @param {string} [toZip]
         * @param {string} [toCountry]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        incomingSMS(
            id: string,
            messageSid?: string,
            smsSid?: string,
            body?: string,
            messageStatus?: string,
            optOutType?: string,
            messagingServiceSid?: string,
            numMedia?: number,
            referralNumMedia?: number,
            accountSid?: string,
            from?: string,
            to?: string,
            fromCity?: string,
            fromState?: string,
            fromZip?: string,
            fromCountry?: string,
            toCity?: string,
            toState?: string,
            toZip?: string,
            toCountry?: string,
            options?: any
        ) {
            return SMSApiFp(configuration).incomingSMS(
                id,
                messageSid,
                smsSid,
                body,
                messageStatus,
                optOutType,
                messagingServiceSid,
                numMedia,
                referralNumMedia,
                accountSid,
                from,
                to,
                fromCity,
                fromState,
                fromZip,
                fromCountry,
                toCity,
                toState,
                toZip,
                toCountry,
                options
            )(fetch, basePath);
        },
        /**
         *
         * @param {string} id
         * @param {string} [errorCode]
         * @param {string} [channelInstallSid]
         * @param {string} [channelStatusMessage]
         * @param {string} [channelPrefix]
         * @param {string} [eventType]
         * @param {string} [messageSid]
         * @param {string} [smsSid]
         * @param {string} [body]
         * @param {string} [messageStatus]
         * @param {string} [optOutType]
         * @param {string} [messagingServiceSid]
         * @param {number} [numMedia]
         * @param {number} [referralNumMedia]
         * @param {string} [accountSid]
         * @param {string} [from]
         * @param {string} [to]
         * @param {string} [fromCity]
         * @param {string} [fromState]
         * @param {string} [fromZip]
         * @param {string} [fromCountry]
         * @param {string} [toCity]
         * @param {string} [toState]
         * @param {string} [toZip]
         * @param {string} [toCountry]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendStatusCallback(
            id: string,
            errorCode?: string,
            channelInstallSid?: string,
            channelStatusMessage?: string,
            channelPrefix?: string,
            eventType?: string,
            messageSid?: string,
            smsSid?: string,
            body?: string,
            messageStatus?: string,
            optOutType?: string,
            messagingServiceSid?: string,
            numMedia?: number,
            referralNumMedia?: number,
            accountSid?: string,
            from?: string,
            to?: string,
            fromCity?: string,
            fromState?: string,
            fromZip?: string,
            fromCountry?: string,
            toCity?: string,
            toState?: string,
            toZip?: string,
            toCountry?: string,
            options?: any
        ) {
            return SMSApiFp(configuration).sendStatusCallback(
                id,
                errorCode,
                channelInstallSid,
                channelStatusMessage,
                channelPrefix,
                eventType,
                messageSid,
                smsSid,
                body,
                messageStatus,
                optOutType,
                messagingServiceSid,
                numMedia,
                referralNumMedia,
                accountSid,
                from,
                to,
                fromCity,
                fromState,
                fromZip,
                fromCountry,
                toCity,
                toState,
                toZip,
                toCountry,
                options
            )(fetch, basePath);
        },
        /**
         *
         * @param {string} [errorCode]
         * @param {string} [channelInstallSid]
         * @param {string} [channelStatusMessage]
         * @param {string} [channelPrefix]
         * @param {string} [eventType]
         * @param {string} [messageSid]
         * @param {string} [smsSid]
         * @param {string} [body]
         * @param {string} [messageStatus]
         * @param {string} [optOutType]
         * @param {string} [messagingServiceSid]
         * @param {number} [numMedia]
         * @param {number} [referralNumMedia]
         * @param {string} [accountSid]
         * @param {string} [from]
         * @param {string} [to]
         * @param {string} [fromCity]
         * @param {string} [fromState]
         * @param {string} [fromZip]
         * @param {string} [fromCountry]
         * @param {string} [toCity]
         * @param {string} [toState]
         * @param {string} [toZip]
         * @param {string} [toCountry]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statusCallback(
            errorCode?: string,
            channelInstallSid?: string,
            channelStatusMessage?: string,
            channelPrefix?: string,
            eventType?: string,
            messageSid?: string,
            smsSid?: string,
            body?: string,
            messageStatus?: string,
            optOutType?: string,
            messagingServiceSid?: string,
            numMedia?: number,
            referralNumMedia?: number,
            accountSid?: string,
            from?: string,
            to?: string,
            fromCity?: string,
            fromState?: string,
            fromZip?: string,
            fromCountry?: string,
            toCity?: string,
            toState?: string,
            toZip?: string,
            toCountry?: string,
            options?: any
        ) {
            return SMSApiFp(configuration).statusCallback(
                errorCode,
                channelInstallSid,
                channelStatusMessage,
                channelPrefix,
                eventType,
                messageSid,
                smsSid,
                body,
                messageStatus,
                optOutType,
                messagingServiceSid,
                numMedia,
                referralNumMedia,
                accountSid,
                from,
                to,
                fromCity,
                fromState,
                fromZip,
                fromCountry,
                toCity,
                toState,
                toZip,
                toCountry,
                options
            )(fetch, basePath);
        },
    };
};

/**
 * SMSApi - interface
 * @export
 * @interface SMSApi
 */
export interface SMSApiInterface {
    /**
     *
     * @param {string} id
     * @param {string} [messageSid]
     * @param {string} [smsSid]
     * @param {string} [body]
     * @param {string} [messageStatus]
     * @param {string} [optOutType]
     * @param {string} [messagingServiceSid]
     * @param {number} [numMedia]
     * @param {number} [referralNumMedia]
     * @param {string} [accountSid]
     * @param {string} [from]
     * @param {string} [to]
     * @param {string} [fromCity]
     * @param {string} [fromState]
     * @param {string} [fromZip]
     * @param {string} [fromCountry]
     * @param {string} [toCity]
     * @param {string} [toState]
     * @param {string} [toZip]
     * @param {string} [toCountry]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SMSApiInterface
     */
    incomingSMS(
        id: string,
        messageSid?: string,
        smsSid?: string,
        body?: string,
        messageStatus?: string,
        optOutType?: string,
        messagingServiceSid?: string,
        numMedia?: number,
        referralNumMedia?: number,
        accountSid?: string,
        from?: string,
        to?: string,
        fromCity?: string,
        fromState?: string,
        fromZip?: string,
        fromCountry?: string,
        toCity?: string,
        toState?: string,
        toZip?: string,
        toCountry?: string,
        options?: any
    ): Promise<{}>;

    /**
     *
     * @param {string} id
     * @param {string} [errorCode]
     * @param {string} [channelInstallSid]
     * @param {string} [channelStatusMessage]
     * @param {string} [channelPrefix]
     * @param {string} [eventType]
     * @param {string} [messageSid]
     * @param {string} [smsSid]
     * @param {string} [body]
     * @param {string} [messageStatus]
     * @param {string} [optOutType]
     * @param {string} [messagingServiceSid]
     * @param {number} [numMedia]
     * @param {number} [referralNumMedia]
     * @param {string} [accountSid]
     * @param {string} [from]
     * @param {string} [to]
     * @param {string} [fromCity]
     * @param {string} [fromState]
     * @param {string} [fromZip]
     * @param {string} [fromCountry]
     * @param {string} [toCity]
     * @param {string} [toState]
     * @param {string} [toZip]
     * @param {string} [toCountry]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SMSApiInterface
     */
    sendStatusCallback(
        id: string,
        errorCode?: string,
        channelInstallSid?: string,
        channelStatusMessage?: string,
        channelPrefix?: string,
        eventType?: string,
        messageSid?: string,
        smsSid?: string,
        body?: string,
        messageStatus?: string,
        optOutType?: string,
        messagingServiceSid?: string,
        numMedia?: number,
        referralNumMedia?: number,
        accountSid?: string,
        from?: string,
        to?: string,
        fromCity?: string,
        fromState?: string,
        fromZip?: string,
        fromCountry?: string,
        toCity?: string,
        toState?: string,
        toZip?: string,
        toCountry?: string,
        options?: any
    ): Promise<{}>;

    /**
     *
     * @param {string} [errorCode]
     * @param {string} [channelInstallSid]
     * @param {string} [channelStatusMessage]
     * @param {string} [channelPrefix]
     * @param {string} [eventType]
     * @param {string} [messageSid]
     * @param {string} [smsSid]
     * @param {string} [body]
     * @param {string} [messageStatus]
     * @param {string} [optOutType]
     * @param {string} [messagingServiceSid]
     * @param {number} [numMedia]
     * @param {number} [referralNumMedia]
     * @param {string} [accountSid]
     * @param {string} [from]
     * @param {string} [to]
     * @param {string} [fromCity]
     * @param {string} [fromState]
     * @param {string} [fromZip]
     * @param {string} [fromCountry]
     * @param {string} [toCity]
     * @param {string} [toState]
     * @param {string} [toZip]
     * @param {string} [toCountry]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SMSApiInterface
     */
    statusCallback(
        errorCode?: string,
        channelInstallSid?: string,
        channelStatusMessage?: string,
        channelPrefix?: string,
        eventType?: string,
        messageSid?: string,
        smsSid?: string,
        body?: string,
        messageStatus?: string,
        optOutType?: string,
        messagingServiceSid?: string,
        numMedia?: number,
        referralNumMedia?: number,
        accountSid?: string,
        from?: string,
        to?: string,
        fromCity?: string,
        fromState?: string,
        fromZip?: string,
        fromCountry?: string,
        toCity?: string,
        toState?: string,
        toZip?: string,
        toCountry?: string,
        options?: any
    ): Promise<{}>;
}

/**
 * SMSApi - object-oriented interface
 * @export
 * @class SMSApi
 * @extends {BaseAPI}
 */
export class SMSApi extends BaseAPI implements SMSApiInterface {
    /**
     *
     * @param {string} id
     * @param {string} [messageSid]
     * @param {string} [smsSid]
     * @param {string} [body]
     * @param {string} [messageStatus]
     * @param {string} [optOutType]
     * @param {string} [messagingServiceSid]
     * @param {number} [numMedia]
     * @param {number} [referralNumMedia]
     * @param {string} [accountSid]
     * @param {string} [from]
     * @param {string} [to]
     * @param {string} [fromCity]
     * @param {string} [fromState]
     * @param {string} [fromZip]
     * @param {string} [fromCountry]
     * @param {string} [toCity]
     * @param {string} [toState]
     * @param {string} [toZip]
     * @param {string} [toCountry]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SMSApi
     */
    public incomingSMS(
        id: string,
        messageSid?: string,
        smsSid?: string,
        body?: string,
        messageStatus?: string,
        optOutType?: string,
        messagingServiceSid?: string,
        numMedia?: number,
        referralNumMedia?: number,
        accountSid?: string,
        from?: string,
        to?: string,
        fromCity?: string,
        fromState?: string,
        fromZip?: string,
        fromCountry?: string,
        toCity?: string,
        toState?: string,
        toZip?: string,
        toCountry?: string,
        options?: any
    ) {
        return SMSApiFp(this.configuration).incomingSMS(
            id,
            messageSid,
            smsSid,
            body,
            messageStatus,
            optOutType,
            messagingServiceSid,
            numMedia,
            referralNumMedia,
            accountSid,
            from,
            to,
            fromCity,
            fromState,
            fromZip,
            fromCountry,
            toCity,
            toState,
            toZip,
            toCountry,
            options
        )(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} id
     * @param {string} [errorCode]
     * @param {string} [channelInstallSid]
     * @param {string} [channelStatusMessage]
     * @param {string} [channelPrefix]
     * @param {string} [eventType]
     * @param {string} [messageSid]
     * @param {string} [smsSid]
     * @param {string} [body]
     * @param {string} [messageStatus]
     * @param {string} [optOutType]
     * @param {string} [messagingServiceSid]
     * @param {number} [numMedia]
     * @param {number} [referralNumMedia]
     * @param {string} [accountSid]
     * @param {string} [from]
     * @param {string} [to]
     * @param {string} [fromCity]
     * @param {string} [fromState]
     * @param {string} [fromZip]
     * @param {string} [fromCountry]
     * @param {string} [toCity]
     * @param {string} [toState]
     * @param {string} [toZip]
     * @param {string} [toCountry]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SMSApi
     */
    public sendStatusCallback(
        id: string,
        errorCode?: string,
        channelInstallSid?: string,
        channelStatusMessage?: string,
        channelPrefix?: string,
        eventType?: string,
        messageSid?: string,
        smsSid?: string,
        body?: string,
        messageStatus?: string,
        optOutType?: string,
        messagingServiceSid?: string,
        numMedia?: number,
        referralNumMedia?: number,
        accountSid?: string,
        from?: string,
        to?: string,
        fromCity?: string,
        fromState?: string,
        fromZip?: string,
        fromCountry?: string,
        toCity?: string,
        toState?: string,
        toZip?: string,
        toCountry?: string,
        options?: any
    ) {
        return SMSApiFp(this.configuration).sendStatusCallback(
            id,
            errorCode,
            channelInstallSid,
            channelStatusMessage,
            channelPrefix,
            eventType,
            messageSid,
            smsSid,
            body,
            messageStatus,
            optOutType,
            messagingServiceSid,
            numMedia,
            referralNumMedia,
            accountSid,
            from,
            to,
            fromCity,
            fromState,
            fromZip,
            fromCountry,
            toCity,
            toState,
            toZip,
            toCountry,
            options
        )(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [errorCode]
     * @param {string} [channelInstallSid]
     * @param {string} [channelStatusMessage]
     * @param {string} [channelPrefix]
     * @param {string} [eventType]
     * @param {string} [messageSid]
     * @param {string} [smsSid]
     * @param {string} [body]
     * @param {string} [messageStatus]
     * @param {string} [optOutType]
     * @param {string} [messagingServiceSid]
     * @param {number} [numMedia]
     * @param {number} [referralNumMedia]
     * @param {string} [accountSid]
     * @param {string} [from]
     * @param {string} [to]
     * @param {string} [fromCity]
     * @param {string} [fromState]
     * @param {string} [fromZip]
     * @param {string} [fromCountry]
     * @param {string} [toCity]
     * @param {string} [toState]
     * @param {string} [toZip]
     * @param {string} [toCountry]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SMSApi
     */
    public statusCallback(
        errorCode?: string,
        channelInstallSid?: string,
        channelStatusMessage?: string,
        channelPrefix?: string,
        eventType?: string,
        messageSid?: string,
        smsSid?: string,
        body?: string,
        messageStatus?: string,
        optOutType?: string,
        messagingServiceSid?: string,
        numMedia?: number,
        referralNumMedia?: number,
        accountSid?: string,
        from?: string,
        to?: string,
        fromCity?: string,
        fromState?: string,
        fromZip?: string,
        fromCountry?: string,
        toCity?: string,
        toState?: string,
        toZip?: string,
        toCountry?: string,
        options?: any
    ) {
        return SMSApiFp(this.configuration).statusCallback(
            errorCode,
            channelInstallSid,
            channelStatusMessage,
            channelPrefix,
            eventType,
            messageSid,
            smsSid,
            body,
            messageStatus,
            optOutType,
            messagingServiceSid,
            numMedia,
            referralNumMedia,
            accountSid,
            from,
            to,
            fromCity,
            fromState,
            fromZip,
            fromCountry,
            toCity,
            toState,
            toZip,
            toCountry,
            options
        )(this.fetch, this.basePath);
    }
}

/**
 * SettingsApi - fetch parameter creator
 * @export
 */
export const SettingsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {IntegrationRequest} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addIntegration(body?: IntegrationRequest, options: any = {}): FetchArgs {
            const localVarPath = `/sendgrid/v1/Settings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === "function" ? configuration.accessToken("oauth2", []) : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter["Content-Type"] = "application/json-patch+json";

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = <any>"IntegrationRequest" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || "";

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {IntegrationRequest} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addIntegrationToEntity(id: string, body?: IntegrationRequest, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling addIntegrationToEntity.");
            }
            const localVarPath = `/sendgrid/v1/Entity({id})/Settings`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === "function" ? configuration.accessToken("oauth2", []) : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter["Content-Type"] = "application/json-patch+json";

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = <any>"IntegrationRequest" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || "";

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountIntegration(options: any = {}): FetchArgs {
            const localVarPath = `/sendgrid/v1/Account/Settings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === "function" ? configuration.accessToken("oauth2", []) : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntegration(options: any = {}): FetchArgs {
            const localVarPath = `/sendgrid/v1/Settings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === "function" ? configuration.accessToken("oauth2", []) : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrunkIntegration(options: any = {}): FetchArgs {
            const localVarPath = `/sendgrid/v1/Settings/Trunk`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === "function" ? configuration.accessToken("oauth2", []) : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeIntegration(options: any = {}): FetchArgs {
            const localVarPath = `/sendgrid/v1/Settings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "DELETE" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === "function" ? configuration.accessToken("oauth2", []) : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * SettingsApi - functional programming interface
 * @export
 */
export const SettingsApiFp = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {IntegrationRequest} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addIntegration(body?: IntegrationRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IntegrationData> {
            const localVarFetchArgs = SettingsApiFetchParamCreator(configuration).addIntegration(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} id
         * @param {IntegrationRequest} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addIntegrationToEntity(id: string, body?: IntegrationRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IntegrationData> {
            const localVarFetchArgs = SettingsApiFetchParamCreator(configuration).addIntegrationToEntity(id, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountIntegration(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IntegrationData> {
            const localVarFetchArgs = SettingsApiFetchParamCreator(configuration).getAccountIntegration(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntegration(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IntegrationData> {
            const localVarFetchArgs = SettingsApiFetchParamCreator(configuration).getIntegration(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrunkIntegration(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<TrunkIntegrationData>> {
            const localVarFetchArgs = SettingsApiFetchParamCreator(configuration).getTrunkIntegration(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeIntegration(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SettingsApiFetchParamCreator(configuration).removeIntegration(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    };
};

/**
 * SettingsApi - factory interface
 * @export
 */
export const SettingsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {IntegrationRequest} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addIntegration(body?: IntegrationRequest, options?: any) {
            return SettingsApiFp(configuration).addIntegration(body, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} id
         * @param {IntegrationRequest} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addIntegrationToEntity(id: string, body?: IntegrationRequest, options?: any) {
            return SettingsApiFp(configuration).addIntegrationToEntity(id, body, options)(fetch, basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountIntegration(options?: any) {
            return SettingsApiFp(configuration).getAccountIntegration(options)(fetch, basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntegration(options?: any) {
            return SettingsApiFp(configuration).getIntegration(options)(fetch, basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrunkIntegration(options?: any) {
            return SettingsApiFp(configuration).getTrunkIntegration(options)(fetch, basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeIntegration(options?: any) {
            return SettingsApiFp(configuration).removeIntegration(options)(fetch, basePath);
        },
    };
};

/**
 * SettingsApi - interface
 * @export
 * @interface SettingsApi
 */
export interface SettingsApiInterface {
    /**
     *
     * @param {IntegrationRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApiInterface
     */
    addIntegration(body?: IntegrationRequest, options?: any): Promise<IntegrationData>;

    /**
     *
     * @param {string} id
     * @param {IntegrationRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApiInterface
     */
    addIntegrationToEntity(id: string, body?: IntegrationRequest, options?: any): Promise<IntegrationData>;

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApiInterface
     */
    getAccountIntegration(options?: any): Promise<IntegrationData>;

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApiInterface
     */
    getIntegration(options?: any): Promise<IntegrationData>;

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApiInterface
     */
    getTrunkIntegration(options?: any): Promise<Array<TrunkIntegrationData>>;

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApiInterface
     */
    removeIntegration(options?: any): Promise<{}>;
}

/**
 * SettingsApi - object-oriented interface
 * @export
 * @class SettingsApi
 * @extends {BaseAPI}
 */
export class SettingsApi extends BaseAPI implements SettingsApiInterface {
    /**
     *
     * @param {IntegrationRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public addIntegration(body?: IntegrationRequest, options?: any) {
        return SettingsApiFp(this.configuration).addIntegration(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} id
     * @param {IntegrationRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public addIntegrationToEntity(id: string, body?: IntegrationRequest, options?: any) {
        return SettingsApiFp(this.configuration).addIntegrationToEntity(id, body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public getAccountIntegration(options?: any) {
        return SettingsApiFp(this.configuration).getAccountIntegration(options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public getIntegration(options?: any) {
        return SettingsApiFp(this.configuration).getIntegration(options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public getTrunkIntegration(options?: any) {
        return SettingsApiFp(this.configuration).getTrunkIntegration(options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public removeIntegration(options?: any) {
        return SettingsApiFp(this.configuration).removeIntegration(options)(this.fetch, this.basePath);
    }
}

/**
 * TemplateApi - fetch parameter creator
 * @export
 */
export const TemplateApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplates(options: any = {}): FetchArgs {
            const localVarPath = `/sendgrid/v1/Template`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === "function" ? configuration.accessToken("oauth2", []) : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * TemplateApi - functional programming interface
 * @export
 */
export const TemplateApiFp = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplates(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Template>> {
            const localVarFetchArgs = TemplateApiFetchParamCreator(configuration).getTemplates(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    };
};

/**
 * TemplateApi - factory interface
 * @export
 */
export const TemplateApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplates(options?: any) {
            return TemplateApiFp(configuration).getTemplates(options)(fetch, basePath);
        },
    };
};

/**
 * TemplateApi - interface
 * @export
 * @interface TemplateApi
 */
export interface TemplateApiInterface {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApiInterface
     */
    getTemplates(options?: any): Promise<Array<Template>>;
}

/**
 * TemplateApi - object-oriented interface
 * @export
 * @class TemplateApi
 * @extends {BaseAPI}
 */
export class TemplateApi extends BaseAPI implements TemplateApiInterface {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public getTemplates(options?: any) {
        return TemplateApiFp(this.configuration).getTemplates(options)(this.fetch, this.basePath);
    }
}

/**
 * TwilioSettingsApi - fetch parameter creator
 * @export
 */
export const TwilioSettingsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {TwilioIntegrationRequest} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addIntegration(body?: TwilioIntegrationRequest, options: any = {}): FetchArgs {
            const localVarPath = `/twilio/v1/Settings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === "function" ? configuration.accessToken("oauth2", []) : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter["Content-Type"] = "application/json-patch+json";

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = <any>"TwilioIntegrationRequest" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || "";

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {TwilioIntegrationRequest} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addIntegrationToEntity(id: string, body?: TwilioIntegrationRequest, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling addIntegrationToEntity.");
            }
            const localVarPath = `/twilio/v1/Entity({id})/Settings`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === "function" ? configuration.accessToken("oauth2", []) : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter["Content-Type"] = "application/json-patch+json";

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = <any>"TwilioIntegrationRequest" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || "";

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountIntegration(options: any = {}): FetchArgs {
            const localVarPath = `/twilio/v1/Account/Settings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === "function" ? configuration.accessToken("oauth2", []) : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntegration(options: any = {}): FetchArgs {
            const localVarPath = `/twilio/v1/Settings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === "function" ? configuration.accessToken("oauth2", []) : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrunkIntegration(options: any = {}): FetchArgs {
            const localVarPath = `/twilio/v1/Settings/Trunk`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === "function" ? configuration.accessToken("oauth2", []) : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeIntegration(options: any = {}): FetchArgs {
            const localVarPath = `/twilio/v1/Settings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "DELETE" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === "function" ? configuration.accessToken("oauth2", []) : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * TwilioSettingsApi - functional programming interface
 * @export
 */
export const TwilioSettingsApiFp = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {TwilioIntegrationRequest} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addIntegration(body?: TwilioIntegrationRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TwilioIntegrationData> {
            const localVarFetchArgs = TwilioSettingsApiFetchParamCreator(configuration).addIntegration(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} id
         * @param {TwilioIntegrationRequest} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addIntegrationToEntity(
            id: string,
            body?: TwilioIntegrationRequest,
            options?: any
        ): (fetch?: FetchAPI, basePath?: string) => Promise<TwilioIntegrationData> {
            const localVarFetchArgs = TwilioSettingsApiFetchParamCreator(configuration).addIntegrationToEntity(id, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountIntegration(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TwilioIntegrationData> {
            const localVarFetchArgs = TwilioSettingsApiFetchParamCreator(configuration).getAccountIntegration(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntegration(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TwilioIntegrationData> {
            const localVarFetchArgs = TwilioSettingsApiFetchParamCreator(configuration).getIntegration(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrunkIntegration(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<TwilioTrunkIntegrationData>> {
            const localVarFetchArgs = TwilioSettingsApiFetchParamCreator(configuration).getTrunkIntegration(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeIntegration(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = TwilioSettingsApiFetchParamCreator(configuration).removeIntegration(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    };
};

/**
 * TwilioSettingsApi - factory interface
 * @export
 */
export const TwilioSettingsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {TwilioIntegrationRequest} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addIntegration(body?: TwilioIntegrationRequest, options?: any) {
            return TwilioSettingsApiFp(configuration).addIntegration(body, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} id
         * @param {TwilioIntegrationRequest} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addIntegrationToEntity(id: string, body?: TwilioIntegrationRequest, options?: any) {
            return TwilioSettingsApiFp(configuration).addIntegrationToEntity(id, body, options)(fetch, basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountIntegration(options?: any) {
            return TwilioSettingsApiFp(configuration).getAccountIntegration(options)(fetch, basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntegration(options?: any) {
            return TwilioSettingsApiFp(configuration).getIntegration(options)(fetch, basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrunkIntegration(options?: any) {
            return TwilioSettingsApiFp(configuration).getTrunkIntegration(options)(fetch, basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeIntegration(options?: any) {
            return TwilioSettingsApiFp(configuration).removeIntegration(options)(fetch, basePath);
        },
    };
};

/**
 * TwilioSettingsApi - interface
 * @export
 * @interface TwilioSettingsApi
 */
export interface TwilioSettingsApiInterface {
    /**
     *
     * @param {TwilioIntegrationRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwilioSettingsApiInterface
     */
    addIntegration(body?: TwilioIntegrationRequest, options?: any): Promise<TwilioIntegrationData>;

    /**
     *
     * @param {string} id
     * @param {TwilioIntegrationRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwilioSettingsApiInterface
     */
    addIntegrationToEntity(id: string, body?: TwilioIntegrationRequest, options?: any): Promise<TwilioIntegrationData>;

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwilioSettingsApiInterface
     */
    getAccountIntegration(options?: any): Promise<TwilioIntegrationData>;

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwilioSettingsApiInterface
     */
    getIntegration(options?: any): Promise<TwilioIntegrationData>;

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwilioSettingsApiInterface
     */
    getTrunkIntegration(options?: any): Promise<Array<TwilioTrunkIntegrationData>>;

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwilioSettingsApiInterface
     */
    removeIntegration(options?: any): Promise<{}>;
}

/**
 * TwilioSettingsApi - object-oriented interface
 * @export
 * @class TwilioSettingsApi
 * @extends {BaseAPI}
 */
export class TwilioSettingsApi extends BaseAPI implements TwilioSettingsApiInterface {
    /**
     *
     * @param {TwilioIntegrationRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwilioSettingsApi
     */
    public addIntegration(body?: TwilioIntegrationRequest, options?: any) {
        return TwilioSettingsApiFp(this.configuration).addIntegration(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} id
     * @param {TwilioIntegrationRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwilioSettingsApi
     */
    public addIntegrationToEntity(id: string, body?: TwilioIntegrationRequest, options?: any) {
        return TwilioSettingsApiFp(this.configuration).addIntegrationToEntity(id, body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwilioSettingsApi
     */
    public getAccountIntegration(options?: any) {
        return TwilioSettingsApiFp(this.configuration).getAccountIntegration(options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwilioSettingsApi
     */
    public getIntegration(options?: any) {
        return TwilioSettingsApiFp(this.configuration).getIntegration(options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwilioSettingsApi
     */
    public getTrunkIntegration(options?: any) {
        return TwilioSettingsApiFp(this.configuration).getTrunkIntegration(options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwilioSettingsApi
     */
    public removeIntegration(options?: any) {
        return TwilioSettingsApiFp(this.configuration).removeIntegration(options)(this.fetch, this.basePath);
    }
}

/**
 * UnlayerApi - fetch parameter creator
 * @export
 */
export const UnlayerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} [templateType]
         * @param {string} [templatedObjectId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(templateType?: string, templatedObjectId?: string, options: any = {}): FetchArgs {
            const localVarPath = `/sendgrid/v1/Unlayer`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === "function" ? configuration.accessToken("oauth2", []) : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (templateType !== undefined) {
                localVarQueryParameter["templateType"] = templateType;
            }

            if (templatedObjectId !== undefined) {
                localVarQueryParameter["templatedObjectId"] = templatedObjectId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFormToSaveDataView(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling getFormToSaveDataView.");
            }
            const localVarPath = `/sendgrid/v1/Unlayer({id})/SendTestEmail/DataForm`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === "function" ? configuration.accessToken("oauth2", []) : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {DataFormActionRequest} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runAction(id: string, body?: DataFormActionRequest, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling runAction.");
            }
            const localVarPath = `/sendgrid/v1/Unlayer({id})/SendTestEmail/DataForm`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "POST" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === "function" ? configuration.accessToken("oauth2", []) : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter["Content-Type"] = "application/json-patch+json";

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = <any>"DataFormActionRequest" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || "";

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [contentType]
         * @param {string} [contentDisposition]
         * @param {string} [headers]
         * @param {number} [length]
         * @param {string} [name]
         * @param {string} [fileName]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile(
            contentType?: string,
            contentDisposition?: string,
            headers?: string,
            length?: number,
            name?: string,
            fileName?: string,
            options: any = {}
        ): FetchArgs {
            const localVarPath = `/sendgrid/v1/Unlayer/Upload`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "POST" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === "function" ? configuration.accessToken("oauth2", []) : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (contentType !== undefined) {
                localVarFormParams.set("ContentType", contentType as any);
            }

            if (contentDisposition !== undefined) {
                localVarFormParams.set("ContentDisposition", contentDisposition as any);
            }

            if (headers !== undefined) {
                localVarFormParams.set("Headers", headers as any);
            }

            if (length !== undefined) {
                localVarFormParams.set("Length", length as any);
            }

            if (name !== undefined) {
                localVarFormParams.set("Name", name as any);
            }

            if (fileName !== undefined) {
                localVarFormParams.set("FileName", fileName as any);
            }

            localVarHeaderParameter["Content-Type"] = "application/x-www-form-urlencoded";

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * UnlayerApi - functional programming interface
 * @export
 */
export const UnlayerApiFp = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} [templateType]
         * @param {string} [templatedObjectId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(templateType?: string, templatedObjectId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UnlayerSession> {
            const localVarFetchArgs = UnlayerApiFetchParamCreator(configuration).get(templateType, templatedObjectId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFormToSaveDataView(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Form> {
            const localVarFetchArgs = UnlayerApiFetchParamCreator(configuration).getFormToSaveDataView(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} id
         * @param {DataFormActionRequest} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runAction(id: string, body?: DataFormActionRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DataFormActionResponse> {
            const localVarFetchArgs = UnlayerApiFetchParamCreator(configuration).runAction(id, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [contentType]
         * @param {string} [contentDisposition]
         * @param {string} [headers]
         * @param {number} [length]
         * @param {string} [name]
         * @param {string} [fileName]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile(
            contentType?: string,
            contentDisposition?: string,
            headers?: string,
            length?: number,
            name?: string,
            fileName?: string,
            options?: any
        ): (fetch?: FetchAPI, basePath?: string) => Promise<UnlayerImageUploadResponse> {
            const localVarFetchArgs = UnlayerApiFetchParamCreator(configuration).uploadFile(
                contentType,
                contentDisposition,
                headers,
                length,
                name,
                fileName,
                options
            );
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    };
};

/**
 * UnlayerApi - factory interface
 * @export
 */
export const UnlayerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {string} [templateType]
         * @param {string} [templatedObjectId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(templateType?: string, templatedObjectId?: string, options?: any) {
            return UnlayerApiFp(configuration).get(templateType, templatedObjectId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFormToSaveDataView(id: string, options?: any) {
            return UnlayerApiFp(configuration).getFormToSaveDataView(id, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} id
         * @param {DataFormActionRequest} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runAction(id: string, body?: DataFormActionRequest, options?: any) {
            return UnlayerApiFp(configuration).runAction(id, body, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [contentType]
         * @param {string} [contentDisposition]
         * @param {string} [headers]
         * @param {number} [length]
         * @param {string} [name]
         * @param {string} [fileName]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile(contentType?: string, contentDisposition?: string, headers?: string, length?: number, name?: string, fileName?: string, options?: any) {
            return UnlayerApiFp(configuration).uploadFile(contentType, contentDisposition, headers, length, name, fileName, options)(fetch, basePath);
        },
    };
};

/**
 * UnlayerApi - interface
 * @export
 * @interface UnlayerApi
 */
export interface UnlayerApiInterface {
    /**
     *
     * @param {string} [templateType]
     * @param {string} [templatedObjectId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnlayerApiInterface
     */
    get(templateType?: string, templatedObjectId?: string, options?: any): Promise<UnlayerSession>;

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnlayerApiInterface
     */
    getFormToSaveDataView(id: string, options?: any): Promise<Form>;

    /**
     *
     * @param {string} id
     * @param {DataFormActionRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnlayerApiInterface
     */
    runAction(id: string, body?: DataFormActionRequest, options?: any): Promise<DataFormActionResponse>;

    /**
     *
     * @param {string} [contentType]
     * @param {string} [contentDisposition]
     * @param {string} [headers]
     * @param {number} [length]
     * @param {string} [name]
     * @param {string} [fileName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnlayerApiInterface
     */
    uploadFile(
        contentType?: string,
        contentDisposition?: string,
        headers?: string,
        length?: number,
        name?: string,
        fileName?: string,
        options?: any
    ): Promise<UnlayerImageUploadResponse>;
}

/**
 * UnlayerApi - object-oriented interface
 * @export
 * @class UnlayerApi
 * @extends {BaseAPI}
 */
export class UnlayerApi extends BaseAPI implements UnlayerApiInterface {
    /**
     *
     * @param {string} [templateType]
     * @param {string} [templatedObjectId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnlayerApi
     */
    public get(templateType?: string, templatedObjectId?: string, options?: any) {
        return UnlayerApiFp(this.configuration).get(templateType, templatedObjectId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnlayerApi
     */
    public getFormToSaveDataView(id: string, options?: any) {
        return UnlayerApiFp(this.configuration).getFormToSaveDataView(id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} id
     * @param {DataFormActionRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnlayerApi
     */
    public runAction(id: string, body?: DataFormActionRequest, options?: any) {
        return UnlayerApiFp(this.configuration).runAction(id, body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [contentType]
     * @param {string} [contentDisposition]
     * @param {string} [headers]
     * @param {number} [length]
     * @param {string} [name]
     * @param {string} [fileName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnlayerApi
     */
    public uploadFile(contentType?: string, contentDisposition?: string, headers?: string, length?: number, name?: string, fileName?: string, options?: any) {
        return UnlayerApiFp(this.configuration).uploadFile(
            contentType,
            contentDisposition,
            headers,
            length,
            name,
            fileName,
            options
        )(this.fetch, this.basePath);
    }
}

/**
 * UnsubscribeApi - fetch parameter creator
 * @export
 */
export const UnsubscribeApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redirectUnsubscribe(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling redirectUnsubscribe.");
            }
            const localVarPath = `/sendgrid/v1/Unsubscribe/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * UnsubscribeApi - functional programming interface
 * @export
 */
export const UnsubscribeApiFp = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redirectUnsubscribe(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UnsubscribeApiFetchParamCreator(configuration).redirectUnsubscribe(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    };
};

/**
 * UnsubscribeApi - factory interface
 * @export
 */
export const UnsubscribeApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redirectUnsubscribe(id: string, options?: any) {
            return UnsubscribeApiFp(configuration).redirectUnsubscribe(id, options)(fetch, basePath);
        },
    };
};

/**
 * UnsubscribeApi - interface
 * @export
 * @interface UnsubscribeApi
 */
export interface UnsubscribeApiInterface {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnsubscribeApiInterface
     */
    redirectUnsubscribe(id: string, options?: any): Promise<{}>;
}

/**
 * UnsubscribeApi - object-oriented interface
 * @export
 * @class UnsubscribeApi
 * @extends {BaseAPI}
 */
export class UnsubscribeApi extends BaseAPI implements UnsubscribeApiInterface {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnsubscribeApi
     */
    public redirectUnsubscribe(id: string, options?: any) {
        return UnsubscribeApiFp(this.configuration).redirectUnsubscribe(id, options)(this.fetch, this.basePath);
    }
}

/**
 * VoiceApi - fetch parameter creator
 * @export
 */
export const VoiceApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} [callSid]
         * @param {string} [callStatus]
         * @param {string} [apiVersion]
         * @param {string} [direction]
         * @param {string} [forwardedFrom]
         * @param {string} [callerName]
         * @param {string} [parentCallSid]
         * @param {string} [callToken]
         * @param {string} [digits]
         * @param {string} [speechResult]
         * @param {number} [confidence]
         * @param {string} [recordingUrl]
         * @param {string} [recordingStatus]
         * @param {string} [recordingDuration]
         * @param {number} [recordingChannels]
         * @param {string} [recordingSource]
         * @param {string} [finishedOnKey]
         * @param {string} [transcriptionSid]
         * @param {string} [transcriptionText]
         * @param {string} [transcriptionStatus]
         * @param {string} [transcriptionUrl]
         * @param {string} [recordingSid]
         * @param {string} [dialCallStatus]
         * @param {string} [dialCallSid]
         * @param {string} [dialCallDuration]
         * @param {string} [sipDomain]
         * @param {string} [sipUsername]
         * @param {string} [sipCallId]
         * @param {string} [sipSourceIp]
         * @param {string} [accountSid]
         * @param {string} [from]
         * @param {string} [to]
         * @param {string} [fromCity]
         * @param {string} [fromState]
         * @param {string} [fromZip]
         * @param {string} [fromCountry]
         * @param {string} [toCity]
         * @param {string} [toState]
         * @param {string} [toZip]
         * @param {string} [toCountry]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        incomingCall(
            callSid?: string,
            callStatus?: string,
            apiVersion?: string,
            direction?: string,
            forwardedFrom?: string,
            callerName?: string,
            parentCallSid?: string,
            callToken?: string,
            digits?: string,
            speechResult?: string,
            confidence?: number,
            recordingUrl?: string,
            recordingStatus?: string,
            recordingDuration?: string,
            recordingChannels?: number,
            recordingSource?: string,
            finishedOnKey?: string,
            transcriptionSid?: string,
            transcriptionText?: string,
            transcriptionStatus?: string,
            transcriptionUrl?: string,
            recordingSid?: string,
            dialCallStatus?: string,
            dialCallSid?: string,
            dialCallDuration?: string,
            sipDomain?: string,
            sipUsername?: string,
            sipCallId?: string,
            sipSourceIp?: string,
            accountSid?: string,
            from?: string,
            to?: string,
            fromCity?: string,
            fromState?: string,
            fromZip?: string,
            fromCountry?: string,
            toCity?: string,
            toState?: string,
            toZip?: string,
            toCountry?: string,
            options: any = {}
        ): FetchArgs {
            const localVarPath = `/twilio/v1/Voice/voice`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "POST" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (callSid !== undefined) {
                localVarQueryParameter["CallSid"] = callSid;
            }

            if (callStatus !== undefined) {
                localVarQueryParameter["CallStatus"] = callStatus;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter["ApiVersion"] = apiVersion;
            }

            if (direction !== undefined) {
                localVarQueryParameter["Direction"] = direction;
            }

            if (forwardedFrom !== undefined) {
                localVarQueryParameter["ForwardedFrom"] = forwardedFrom;
            }

            if (callerName !== undefined) {
                localVarQueryParameter["CallerName"] = callerName;
            }

            if (parentCallSid !== undefined) {
                localVarQueryParameter["ParentCallSid"] = parentCallSid;
            }

            if (callToken !== undefined) {
                localVarQueryParameter["CallToken"] = callToken;
            }

            if (digits !== undefined) {
                localVarQueryParameter["Digits"] = digits;
            }

            if (speechResult !== undefined) {
                localVarQueryParameter["SpeechResult"] = speechResult;
            }

            if (confidence !== undefined) {
                localVarQueryParameter["Confidence"] = confidence;
            }

            if (recordingUrl !== undefined) {
                localVarQueryParameter["RecordingUrl"] = recordingUrl;
            }

            if (recordingStatus !== undefined) {
                localVarQueryParameter["RecordingStatus"] = recordingStatus;
            }

            if (recordingDuration !== undefined) {
                localVarQueryParameter["RecordingDuration"] = recordingDuration;
            }

            if (recordingChannels !== undefined) {
                localVarQueryParameter["RecordingChannels"] = recordingChannels;
            }

            if (recordingSource !== undefined) {
                localVarQueryParameter["RecordingSource"] = recordingSource;
            }

            if (finishedOnKey !== undefined) {
                localVarQueryParameter["FinishedOnKey"] = finishedOnKey;
            }

            if (transcriptionSid !== undefined) {
                localVarQueryParameter["TranscriptionSid"] = transcriptionSid;
            }

            if (transcriptionText !== undefined) {
                localVarQueryParameter["TranscriptionText"] = transcriptionText;
            }

            if (transcriptionStatus !== undefined) {
                localVarQueryParameter["TranscriptionStatus"] = transcriptionStatus;
            }

            if (transcriptionUrl !== undefined) {
                localVarQueryParameter["TranscriptionUrl"] = transcriptionUrl;
            }

            if (recordingSid !== undefined) {
                localVarQueryParameter["RecordingSid"] = recordingSid;
            }

            if (dialCallStatus !== undefined) {
                localVarQueryParameter["DialCallStatus"] = dialCallStatus;
            }

            if (dialCallSid !== undefined) {
                localVarQueryParameter["DialCallSid"] = dialCallSid;
            }

            if (dialCallDuration !== undefined) {
                localVarQueryParameter["DialCallDuration"] = dialCallDuration;
            }

            if (sipDomain !== undefined) {
                localVarQueryParameter["SipDomain"] = sipDomain;
            }

            if (sipUsername !== undefined) {
                localVarQueryParameter["SipUsername"] = sipUsername;
            }

            if (sipCallId !== undefined) {
                localVarQueryParameter["SipCallId"] = sipCallId;
            }

            if (sipSourceIp !== undefined) {
                localVarQueryParameter["SipSourceIp"] = sipSourceIp;
            }

            if (accountSid !== undefined) {
                localVarQueryParameter["AccountSid"] = accountSid;
            }

            if (from !== undefined) {
                localVarQueryParameter["From"] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter["To"] = to;
            }

            if (fromCity !== undefined) {
                localVarQueryParameter["FromCity"] = fromCity;
            }

            if (fromState !== undefined) {
                localVarQueryParameter["FromState"] = fromState;
            }

            if (fromZip !== undefined) {
                localVarQueryParameter["FromZip"] = fromZip;
            }

            if (fromCountry !== undefined) {
                localVarQueryParameter["FromCountry"] = fromCountry;
            }

            if (toCity !== undefined) {
                localVarQueryParameter["ToCity"] = toCity;
            }

            if (toState !== undefined) {
                localVarQueryParameter["ToState"] = toState;
            }

            if (toZip !== undefined) {
                localVarQueryParameter["ToZip"] = toZip;
            }

            if (toCountry !== undefined) {
                localVarQueryParameter["ToCountry"] = toCountry;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} [callDuration]
         * @param {string} [called]
         * @param {string} [caller]
         * @param {number} [duration]
         * @param {string} [callSid]
         * @param {string} [callStatus]
         * @param {string} [apiVersion]
         * @param {string} [direction]
         * @param {string} [forwardedFrom]
         * @param {string} [callerName]
         * @param {string} [parentCallSid]
         * @param {string} [callToken]
         * @param {string} [digits]
         * @param {string} [speechResult]
         * @param {number} [confidence]
         * @param {string} [recordingUrl]
         * @param {string} [recordingStatus]
         * @param {string} [recordingDuration]
         * @param {number} [recordingChannels]
         * @param {string} [recordingSource]
         * @param {string} [finishedOnKey]
         * @param {string} [transcriptionSid]
         * @param {string} [transcriptionText]
         * @param {string} [transcriptionStatus]
         * @param {string} [transcriptionUrl]
         * @param {string} [recordingSid]
         * @param {string} [dialCallStatus]
         * @param {string} [dialCallSid]
         * @param {string} [dialCallDuration]
         * @param {string} [sipDomain]
         * @param {string} [sipUsername]
         * @param {string} [sipCallId]
         * @param {string} [sipSourceIp]
         * @param {string} [accountSid]
         * @param {string} [from]
         * @param {string} [to]
         * @param {string} [fromCity]
         * @param {string} [fromState]
         * @param {string} [fromZip]
         * @param {string} [fromCountry]
         * @param {string} [toCity]
         * @param {string} [toState]
         * @param {string} [toZip]
         * @param {string} [toCountry]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statusCallback(
            callDuration?: number,
            called?: string,
            caller?: string,
            duration?: number,
            callSid?: string,
            callStatus?: string,
            apiVersion?: string,
            direction?: string,
            forwardedFrom?: string,
            callerName?: string,
            parentCallSid?: string,
            callToken?: string,
            digits?: string,
            speechResult?: string,
            confidence?: number,
            recordingUrl?: string,
            recordingStatus?: string,
            recordingDuration?: string,
            recordingChannels?: number,
            recordingSource?: string,
            finishedOnKey?: string,
            transcriptionSid?: string,
            transcriptionText?: string,
            transcriptionStatus?: string,
            transcriptionUrl?: string,
            recordingSid?: string,
            dialCallStatus?: string,
            dialCallSid?: string,
            dialCallDuration?: string,
            sipDomain?: string,
            sipUsername?: string,
            sipCallId?: string,
            sipSourceIp?: string,
            accountSid?: string,
            from?: string,
            to?: string,
            fromCity?: string,
            fromState?: string,
            fromZip?: string,
            fromCountry?: string,
            toCity?: string,
            toState?: string,
            toZip?: string,
            toCountry?: string,
            options: any = {}
        ): FetchArgs {
            const localVarPath = `/twilio/v1/Voice/Status`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "POST" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (callDuration !== undefined) {
                localVarQueryParameter["CallDuration"] = callDuration;
            }

            if (called !== undefined) {
                localVarQueryParameter["Called"] = called;
            }

            if (caller !== undefined) {
                localVarQueryParameter["Caller"] = caller;
            }

            if (duration !== undefined) {
                localVarQueryParameter["Duration"] = duration;
            }

            if (callSid !== undefined) {
                localVarQueryParameter["CallSid"] = callSid;
            }

            if (callStatus !== undefined) {
                localVarQueryParameter["CallStatus"] = callStatus;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter["ApiVersion"] = apiVersion;
            }

            if (direction !== undefined) {
                localVarQueryParameter["Direction"] = direction;
            }

            if (forwardedFrom !== undefined) {
                localVarQueryParameter["ForwardedFrom"] = forwardedFrom;
            }

            if (callerName !== undefined) {
                localVarQueryParameter["CallerName"] = callerName;
            }

            if (parentCallSid !== undefined) {
                localVarQueryParameter["ParentCallSid"] = parentCallSid;
            }

            if (callToken !== undefined) {
                localVarQueryParameter["CallToken"] = callToken;
            }

            if (digits !== undefined) {
                localVarQueryParameter["Digits"] = digits;
            }

            if (speechResult !== undefined) {
                localVarQueryParameter["SpeechResult"] = speechResult;
            }

            if (confidence !== undefined) {
                localVarQueryParameter["Confidence"] = confidence;
            }

            if (recordingUrl !== undefined) {
                localVarQueryParameter["RecordingUrl"] = recordingUrl;
            }

            if (recordingStatus !== undefined) {
                localVarQueryParameter["RecordingStatus"] = recordingStatus;
            }

            if (recordingDuration !== undefined) {
                localVarQueryParameter["RecordingDuration"] = recordingDuration;
            }

            if (recordingChannels !== undefined) {
                localVarQueryParameter["RecordingChannels"] = recordingChannels;
            }

            if (recordingSource !== undefined) {
                localVarQueryParameter["RecordingSource"] = recordingSource;
            }

            if (finishedOnKey !== undefined) {
                localVarQueryParameter["FinishedOnKey"] = finishedOnKey;
            }

            if (transcriptionSid !== undefined) {
                localVarQueryParameter["TranscriptionSid"] = transcriptionSid;
            }

            if (transcriptionText !== undefined) {
                localVarQueryParameter["TranscriptionText"] = transcriptionText;
            }

            if (transcriptionStatus !== undefined) {
                localVarQueryParameter["TranscriptionStatus"] = transcriptionStatus;
            }

            if (transcriptionUrl !== undefined) {
                localVarQueryParameter["TranscriptionUrl"] = transcriptionUrl;
            }

            if (recordingSid !== undefined) {
                localVarQueryParameter["RecordingSid"] = recordingSid;
            }

            if (dialCallStatus !== undefined) {
                localVarQueryParameter["DialCallStatus"] = dialCallStatus;
            }

            if (dialCallSid !== undefined) {
                localVarQueryParameter["DialCallSid"] = dialCallSid;
            }

            if (dialCallDuration !== undefined) {
                localVarQueryParameter["DialCallDuration"] = dialCallDuration;
            }

            if (sipDomain !== undefined) {
                localVarQueryParameter["SipDomain"] = sipDomain;
            }

            if (sipUsername !== undefined) {
                localVarQueryParameter["SipUsername"] = sipUsername;
            }

            if (sipCallId !== undefined) {
                localVarQueryParameter["SipCallId"] = sipCallId;
            }

            if (sipSourceIp !== undefined) {
                localVarQueryParameter["SipSourceIp"] = sipSourceIp;
            }

            if (accountSid !== undefined) {
                localVarQueryParameter["AccountSid"] = accountSid;
            }

            if (from !== undefined) {
                localVarQueryParameter["From"] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter["To"] = to;
            }

            if (fromCity !== undefined) {
                localVarQueryParameter["FromCity"] = fromCity;
            }

            if (fromState !== undefined) {
                localVarQueryParameter["FromState"] = fromState;
            }

            if (fromZip !== undefined) {
                localVarQueryParameter["FromZip"] = fromZip;
            }

            if (fromCountry !== undefined) {
                localVarQueryParameter["FromCountry"] = fromCountry;
            }

            if (toCity !== undefined) {
                localVarQueryParameter["ToCity"] = toCity;
            }

            if (toState !== undefined) {
                localVarQueryParameter["ToState"] = toState;
            }

            if (toZip !== undefined) {
                localVarQueryParameter["ToZip"] = toZip;
            }

            if (toCountry !== undefined) {
                localVarQueryParameter["ToCountry"] = toCountry;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * VoiceApi - functional programming interface
 * @export
 */
export const VoiceApiFp = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} [callSid]
         * @param {string} [callStatus]
         * @param {string} [apiVersion]
         * @param {string} [direction]
         * @param {string} [forwardedFrom]
         * @param {string} [callerName]
         * @param {string} [parentCallSid]
         * @param {string} [callToken]
         * @param {string} [digits]
         * @param {string} [speechResult]
         * @param {number} [confidence]
         * @param {string} [recordingUrl]
         * @param {string} [recordingStatus]
         * @param {string} [recordingDuration]
         * @param {number} [recordingChannels]
         * @param {string} [recordingSource]
         * @param {string} [finishedOnKey]
         * @param {string} [transcriptionSid]
         * @param {string} [transcriptionText]
         * @param {string} [transcriptionStatus]
         * @param {string} [transcriptionUrl]
         * @param {string} [recordingSid]
         * @param {string} [dialCallStatus]
         * @param {string} [dialCallSid]
         * @param {string} [dialCallDuration]
         * @param {string} [sipDomain]
         * @param {string} [sipUsername]
         * @param {string} [sipCallId]
         * @param {string} [sipSourceIp]
         * @param {string} [accountSid]
         * @param {string} [from]
         * @param {string} [to]
         * @param {string} [fromCity]
         * @param {string} [fromState]
         * @param {string} [fromZip]
         * @param {string} [fromCountry]
         * @param {string} [toCity]
         * @param {string} [toState]
         * @param {string} [toZip]
         * @param {string} [toCountry]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        incomingCall(
            callSid?: string,
            callStatus?: string,
            apiVersion?: string,
            direction?: string,
            forwardedFrom?: string,
            callerName?: string,
            parentCallSid?: string,
            callToken?: string,
            digits?: string,
            speechResult?: string,
            confidence?: number,
            recordingUrl?: string,
            recordingStatus?: string,
            recordingDuration?: string,
            recordingChannels?: number,
            recordingSource?: string,
            finishedOnKey?: string,
            transcriptionSid?: string,
            transcriptionText?: string,
            transcriptionStatus?: string,
            transcriptionUrl?: string,
            recordingSid?: string,
            dialCallStatus?: string,
            dialCallSid?: string,
            dialCallDuration?: string,
            sipDomain?: string,
            sipUsername?: string,
            sipCallId?: string,
            sipSourceIp?: string,
            accountSid?: string,
            from?: string,
            to?: string,
            fromCity?: string,
            fromState?: string,
            fromZip?: string,
            fromCountry?: string,
            toCity?: string,
            toState?: string,
            toZip?: string,
            toCountry?: string,
            options?: any
        ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = VoiceApiFetchParamCreator(configuration).incomingCall(
                callSid,
                callStatus,
                apiVersion,
                direction,
                forwardedFrom,
                callerName,
                parentCallSid,
                callToken,
                digits,
                speechResult,
                confidence,
                recordingUrl,
                recordingStatus,
                recordingDuration,
                recordingChannels,
                recordingSource,
                finishedOnKey,
                transcriptionSid,
                transcriptionText,
                transcriptionStatus,
                transcriptionUrl,
                recordingSid,
                dialCallStatus,
                dialCallSid,
                dialCallDuration,
                sipDomain,
                sipUsername,
                sipCallId,
                sipSourceIp,
                accountSid,
                from,
                to,
                fromCity,
                fromState,
                fromZip,
                fromCountry,
                toCity,
                toState,
                toZip,
                toCountry,
                options
            );
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} [callDuration]
         * @param {string} [called]
         * @param {string} [caller]
         * @param {number} [duration]
         * @param {string} [callSid]
         * @param {string} [callStatus]
         * @param {string} [apiVersion]
         * @param {string} [direction]
         * @param {string} [forwardedFrom]
         * @param {string} [callerName]
         * @param {string} [parentCallSid]
         * @param {string} [callToken]
         * @param {string} [digits]
         * @param {string} [speechResult]
         * @param {number} [confidence]
         * @param {string} [recordingUrl]
         * @param {string} [recordingStatus]
         * @param {string} [recordingDuration]
         * @param {number} [recordingChannels]
         * @param {string} [recordingSource]
         * @param {string} [finishedOnKey]
         * @param {string} [transcriptionSid]
         * @param {string} [transcriptionText]
         * @param {string} [transcriptionStatus]
         * @param {string} [transcriptionUrl]
         * @param {string} [recordingSid]
         * @param {string} [dialCallStatus]
         * @param {string} [dialCallSid]
         * @param {string} [dialCallDuration]
         * @param {string} [sipDomain]
         * @param {string} [sipUsername]
         * @param {string} [sipCallId]
         * @param {string} [sipSourceIp]
         * @param {string} [accountSid]
         * @param {string} [from]
         * @param {string} [to]
         * @param {string} [fromCity]
         * @param {string} [fromState]
         * @param {string} [fromZip]
         * @param {string} [fromCountry]
         * @param {string} [toCity]
         * @param {string} [toState]
         * @param {string} [toZip]
         * @param {string} [toCountry]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statusCallback(
            callDuration?: number,
            called?: string,
            caller?: string,
            duration?: number,
            callSid?: string,
            callStatus?: string,
            apiVersion?: string,
            direction?: string,
            forwardedFrom?: string,
            callerName?: string,
            parentCallSid?: string,
            callToken?: string,
            digits?: string,
            speechResult?: string,
            confidence?: number,
            recordingUrl?: string,
            recordingStatus?: string,
            recordingDuration?: string,
            recordingChannels?: number,
            recordingSource?: string,
            finishedOnKey?: string,
            transcriptionSid?: string,
            transcriptionText?: string,
            transcriptionStatus?: string,
            transcriptionUrl?: string,
            recordingSid?: string,
            dialCallStatus?: string,
            dialCallSid?: string,
            dialCallDuration?: string,
            sipDomain?: string,
            sipUsername?: string,
            sipCallId?: string,
            sipSourceIp?: string,
            accountSid?: string,
            from?: string,
            to?: string,
            fromCity?: string,
            fromState?: string,
            fromZip?: string,
            fromCountry?: string,
            toCity?: string,
            toState?: string,
            toZip?: string,
            toCountry?: string,
            options?: any
        ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = VoiceApiFetchParamCreator(configuration).statusCallback(
                callDuration,
                called,
                caller,
                duration,
                callSid,
                callStatus,
                apiVersion,
                direction,
                forwardedFrom,
                callerName,
                parentCallSid,
                callToken,
                digits,
                speechResult,
                confidence,
                recordingUrl,
                recordingStatus,
                recordingDuration,
                recordingChannels,
                recordingSource,
                finishedOnKey,
                transcriptionSid,
                transcriptionText,
                transcriptionStatus,
                transcriptionUrl,
                recordingSid,
                dialCallStatus,
                dialCallSid,
                dialCallDuration,
                sipDomain,
                sipUsername,
                sipCallId,
                sipSourceIp,
                accountSid,
                from,
                to,
                fromCity,
                fromState,
                fromZip,
                fromCountry,
                toCity,
                toState,
                toZip,
                toCountry,
                options
            );
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    };
};

/**
 * VoiceApi - factory interface
 * @export
 */
export const VoiceApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {string} [callSid]
         * @param {string} [callStatus]
         * @param {string} [apiVersion]
         * @param {string} [direction]
         * @param {string} [forwardedFrom]
         * @param {string} [callerName]
         * @param {string} [parentCallSid]
         * @param {string} [callToken]
         * @param {string} [digits]
         * @param {string} [speechResult]
         * @param {number} [confidence]
         * @param {string} [recordingUrl]
         * @param {string} [recordingStatus]
         * @param {string} [recordingDuration]
         * @param {number} [recordingChannels]
         * @param {string} [recordingSource]
         * @param {string} [finishedOnKey]
         * @param {string} [transcriptionSid]
         * @param {string} [transcriptionText]
         * @param {string} [transcriptionStatus]
         * @param {string} [transcriptionUrl]
         * @param {string} [recordingSid]
         * @param {string} [dialCallStatus]
         * @param {string} [dialCallSid]
         * @param {string} [dialCallDuration]
         * @param {string} [sipDomain]
         * @param {string} [sipUsername]
         * @param {string} [sipCallId]
         * @param {string} [sipSourceIp]
         * @param {string} [accountSid]
         * @param {string} [from]
         * @param {string} [to]
         * @param {string} [fromCity]
         * @param {string} [fromState]
         * @param {string} [fromZip]
         * @param {string} [fromCountry]
         * @param {string} [toCity]
         * @param {string} [toState]
         * @param {string} [toZip]
         * @param {string} [toCountry]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        incomingCall(
            callSid?: string,
            callStatus?: string,
            apiVersion?: string,
            direction?: string,
            forwardedFrom?: string,
            callerName?: string,
            parentCallSid?: string,
            callToken?: string,
            digits?: string,
            speechResult?: string,
            confidence?: number,
            recordingUrl?: string,
            recordingStatus?: string,
            recordingDuration?: string,
            recordingChannels?: number,
            recordingSource?: string,
            finishedOnKey?: string,
            transcriptionSid?: string,
            transcriptionText?: string,
            transcriptionStatus?: string,
            transcriptionUrl?: string,
            recordingSid?: string,
            dialCallStatus?: string,
            dialCallSid?: string,
            dialCallDuration?: string,
            sipDomain?: string,
            sipUsername?: string,
            sipCallId?: string,
            sipSourceIp?: string,
            accountSid?: string,
            from?: string,
            to?: string,
            fromCity?: string,
            fromState?: string,
            fromZip?: string,
            fromCountry?: string,
            toCity?: string,
            toState?: string,
            toZip?: string,
            toCountry?: string,
            options?: any
        ) {
            return VoiceApiFp(configuration).incomingCall(
                callSid,
                callStatus,
                apiVersion,
                direction,
                forwardedFrom,
                callerName,
                parentCallSid,
                callToken,
                digits,
                speechResult,
                confidence,
                recordingUrl,
                recordingStatus,
                recordingDuration,
                recordingChannels,
                recordingSource,
                finishedOnKey,
                transcriptionSid,
                transcriptionText,
                transcriptionStatus,
                transcriptionUrl,
                recordingSid,
                dialCallStatus,
                dialCallSid,
                dialCallDuration,
                sipDomain,
                sipUsername,
                sipCallId,
                sipSourceIp,
                accountSid,
                from,
                to,
                fromCity,
                fromState,
                fromZip,
                fromCountry,
                toCity,
                toState,
                toZip,
                toCountry,
                options
            )(fetch, basePath);
        },
        /**
         *
         * @param {number} [callDuration]
         * @param {string} [called]
         * @param {string} [caller]
         * @param {number} [duration]
         * @param {string} [callSid]
         * @param {string} [callStatus]
         * @param {string} [apiVersion]
         * @param {string} [direction]
         * @param {string} [forwardedFrom]
         * @param {string} [callerName]
         * @param {string} [parentCallSid]
         * @param {string} [callToken]
         * @param {string} [digits]
         * @param {string} [speechResult]
         * @param {number} [confidence]
         * @param {string} [recordingUrl]
         * @param {string} [recordingStatus]
         * @param {string} [recordingDuration]
         * @param {number} [recordingChannels]
         * @param {string} [recordingSource]
         * @param {string} [finishedOnKey]
         * @param {string} [transcriptionSid]
         * @param {string} [transcriptionText]
         * @param {string} [transcriptionStatus]
         * @param {string} [transcriptionUrl]
         * @param {string} [recordingSid]
         * @param {string} [dialCallStatus]
         * @param {string} [dialCallSid]
         * @param {string} [dialCallDuration]
         * @param {string} [sipDomain]
         * @param {string} [sipUsername]
         * @param {string} [sipCallId]
         * @param {string} [sipSourceIp]
         * @param {string} [accountSid]
         * @param {string} [from]
         * @param {string} [to]
         * @param {string} [fromCity]
         * @param {string} [fromState]
         * @param {string} [fromZip]
         * @param {string} [fromCountry]
         * @param {string} [toCity]
         * @param {string} [toState]
         * @param {string} [toZip]
         * @param {string} [toCountry]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statusCallback(
            callDuration?: number,
            called?: string,
            caller?: string,
            duration?: number,
            callSid?: string,
            callStatus?: string,
            apiVersion?: string,
            direction?: string,
            forwardedFrom?: string,
            callerName?: string,
            parentCallSid?: string,
            callToken?: string,
            digits?: string,
            speechResult?: string,
            confidence?: number,
            recordingUrl?: string,
            recordingStatus?: string,
            recordingDuration?: string,
            recordingChannels?: number,
            recordingSource?: string,
            finishedOnKey?: string,
            transcriptionSid?: string,
            transcriptionText?: string,
            transcriptionStatus?: string,
            transcriptionUrl?: string,
            recordingSid?: string,
            dialCallStatus?: string,
            dialCallSid?: string,
            dialCallDuration?: string,
            sipDomain?: string,
            sipUsername?: string,
            sipCallId?: string,
            sipSourceIp?: string,
            accountSid?: string,
            from?: string,
            to?: string,
            fromCity?: string,
            fromState?: string,
            fromZip?: string,
            fromCountry?: string,
            toCity?: string,
            toState?: string,
            toZip?: string,
            toCountry?: string,
            options?: any
        ) {
            return VoiceApiFp(configuration).statusCallback(
                callDuration,
                called,
                caller,
                duration,
                callSid,
                callStatus,
                apiVersion,
                direction,
                forwardedFrom,
                callerName,
                parentCallSid,
                callToken,
                digits,
                speechResult,
                confidence,
                recordingUrl,
                recordingStatus,
                recordingDuration,
                recordingChannels,
                recordingSource,
                finishedOnKey,
                transcriptionSid,
                transcriptionText,
                transcriptionStatus,
                transcriptionUrl,
                recordingSid,
                dialCallStatus,
                dialCallSid,
                dialCallDuration,
                sipDomain,
                sipUsername,
                sipCallId,
                sipSourceIp,
                accountSid,
                from,
                to,
                fromCity,
                fromState,
                fromZip,
                fromCountry,
                toCity,
                toState,
                toZip,
                toCountry,
                options
            )(fetch, basePath);
        },
    };
};

/**
 * VoiceApi - interface
 * @export
 * @interface VoiceApi
 */
export interface VoiceApiInterface {
    /**
     *
     * @param {string} [callSid]
     * @param {string} [callStatus]
     * @param {string} [apiVersion]
     * @param {string} [direction]
     * @param {string} [forwardedFrom]
     * @param {string} [callerName]
     * @param {string} [parentCallSid]
     * @param {string} [callToken]
     * @param {string} [digits]
     * @param {string} [speechResult]
     * @param {number} [confidence]
     * @param {string} [recordingUrl]
     * @param {string} [recordingStatus]
     * @param {string} [recordingDuration]
     * @param {number} [recordingChannels]
     * @param {string} [recordingSource]
     * @param {string} [finishedOnKey]
     * @param {string} [transcriptionSid]
     * @param {string} [transcriptionText]
     * @param {string} [transcriptionStatus]
     * @param {string} [transcriptionUrl]
     * @param {string} [recordingSid]
     * @param {string} [dialCallStatus]
     * @param {string} [dialCallSid]
     * @param {string} [dialCallDuration]
     * @param {string} [sipDomain]
     * @param {string} [sipUsername]
     * @param {string} [sipCallId]
     * @param {string} [sipSourceIp]
     * @param {string} [accountSid]
     * @param {string} [from]
     * @param {string} [to]
     * @param {string} [fromCity]
     * @param {string} [fromState]
     * @param {string} [fromZip]
     * @param {string} [fromCountry]
     * @param {string} [toCity]
     * @param {string} [toState]
     * @param {string} [toZip]
     * @param {string} [toCountry]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoiceApiInterface
     */
    incomingCall(
        callSid?: string,
        callStatus?: string,
        apiVersion?: string,
        direction?: string,
        forwardedFrom?: string,
        callerName?: string,
        parentCallSid?: string,
        callToken?: string,
        digits?: string,
        speechResult?: string,
        confidence?: number,
        recordingUrl?: string,
        recordingStatus?: string,
        recordingDuration?: string,
        recordingChannels?: number,
        recordingSource?: string,
        finishedOnKey?: string,
        transcriptionSid?: string,
        transcriptionText?: string,
        transcriptionStatus?: string,
        transcriptionUrl?: string,
        recordingSid?: string,
        dialCallStatus?: string,
        dialCallSid?: string,
        dialCallDuration?: string,
        sipDomain?: string,
        sipUsername?: string,
        sipCallId?: string,
        sipSourceIp?: string,
        accountSid?: string,
        from?: string,
        to?: string,
        fromCity?: string,
        fromState?: string,
        fromZip?: string,
        fromCountry?: string,
        toCity?: string,
        toState?: string,
        toZip?: string,
        toCountry?: string,
        options?: any
    ): Promise<{}>;

    /**
     *
     * @param {number} [callDuration]
     * @param {string} [called]
     * @param {string} [caller]
     * @param {number} [duration]
     * @param {string} [callSid]
     * @param {string} [callStatus]
     * @param {string} [apiVersion]
     * @param {string} [direction]
     * @param {string} [forwardedFrom]
     * @param {string} [callerName]
     * @param {string} [parentCallSid]
     * @param {string} [callToken]
     * @param {string} [digits]
     * @param {string} [speechResult]
     * @param {number} [confidence]
     * @param {string} [recordingUrl]
     * @param {string} [recordingStatus]
     * @param {string} [recordingDuration]
     * @param {number} [recordingChannels]
     * @param {string} [recordingSource]
     * @param {string} [finishedOnKey]
     * @param {string} [transcriptionSid]
     * @param {string} [transcriptionText]
     * @param {string} [transcriptionStatus]
     * @param {string} [transcriptionUrl]
     * @param {string} [recordingSid]
     * @param {string} [dialCallStatus]
     * @param {string} [dialCallSid]
     * @param {string} [dialCallDuration]
     * @param {string} [sipDomain]
     * @param {string} [sipUsername]
     * @param {string} [sipCallId]
     * @param {string} [sipSourceIp]
     * @param {string} [accountSid]
     * @param {string} [from]
     * @param {string} [to]
     * @param {string} [fromCity]
     * @param {string} [fromState]
     * @param {string} [fromZip]
     * @param {string} [fromCountry]
     * @param {string} [toCity]
     * @param {string} [toState]
     * @param {string} [toZip]
     * @param {string} [toCountry]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoiceApiInterface
     */
    statusCallback(
        callDuration?: number,
        called?: string,
        caller?: string,
        duration?: number,
        callSid?: string,
        callStatus?: string,
        apiVersion?: string,
        direction?: string,
        forwardedFrom?: string,
        callerName?: string,
        parentCallSid?: string,
        callToken?: string,
        digits?: string,
        speechResult?: string,
        confidence?: number,
        recordingUrl?: string,
        recordingStatus?: string,
        recordingDuration?: string,
        recordingChannels?: number,
        recordingSource?: string,
        finishedOnKey?: string,
        transcriptionSid?: string,
        transcriptionText?: string,
        transcriptionStatus?: string,
        transcriptionUrl?: string,
        recordingSid?: string,
        dialCallStatus?: string,
        dialCallSid?: string,
        dialCallDuration?: string,
        sipDomain?: string,
        sipUsername?: string,
        sipCallId?: string,
        sipSourceIp?: string,
        accountSid?: string,
        from?: string,
        to?: string,
        fromCity?: string,
        fromState?: string,
        fromZip?: string,
        fromCountry?: string,
        toCity?: string,
        toState?: string,
        toZip?: string,
        toCountry?: string,
        options?: any
    ): Promise<{}>;
}

/**
 * VoiceApi - object-oriented interface
 * @export
 * @class VoiceApi
 * @extends {BaseAPI}
 */
export class VoiceApi extends BaseAPI implements VoiceApiInterface {
    /**
     *
     * @param {string} [callSid]
     * @param {string} [callStatus]
     * @param {string} [apiVersion]
     * @param {string} [direction]
     * @param {string} [forwardedFrom]
     * @param {string} [callerName]
     * @param {string} [parentCallSid]
     * @param {string} [callToken]
     * @param {string} [digits]
     * @param {string} [speechResult]
     * @param {number} [confidence]
     * @param {string} [recordingUrl]
     * @param {string} [recordingStatus]
     * @param {string} [recordingDuration]
     * @param {number} [recordingChannels]
     * @param {string} [recordingSource]
     * @param {string} [finishedOnKey]
     * @param {string} [transcriptionSid]
     * @param {string} [transcriptionText]
     * @param {string} [transcriptionStatus]
     * @param {string} [transcriptionUrl]
     * @param {string} [recordingSid]
     * @param {string} [dialCallStatus]
     * @param {string} [dialCallSid]
     * @param {string} [dialCallDuration]
     * @param {string} [sipDomain]
     * @param {string} [sipUsername]
     * @param {string} [sipCallId]
     * @param {string} [sipSourceIp]
     * @param {string} [accountSid]
     * @param {string} [from]
     * @param {string} [to]
     * @param {string} [fromCity]
     * @param {string} [fromState]
     * @param {string} [fromZip]
     * @param {string} [fromCountry]
     * @param {string} [toCity]
     * @param {string} [toState]
     * @param {string} [toZip]
     * @param {string} [toCountry]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoiceApi
     */
    public incomingCall(
        callSid?: string,
        callStatus?: string,
        apiVersion?: string,
        direction?: string,
        forwardedFrom?: string,
        callerName?: string,
        parentCallSid?: string,
        callToken?: string,
        digits?: string,
        speechResult?: string,
        confidence?: number,
        recordingUrl?: string,
        recordingStatus?: string,
        recordingDuration?: string,
        recordingChannels?: number,
        recordingSource?: string,
        finishedOnKey?: string,
        transcriptionSid?: string,
        transcriptionText?: string,
        transcriptionStatus?: string,
        transcriptionUrl?: string,
        recordingSid?: string,
        dialCallStatus?: string,
        dialCallSid?: string,
        dialCallDuration?: string,
        sipDomain?: string,
        sipUsername?: string,
        sipCallId?: string,
        sipSourceIp?: string,
        accountSid?: string,
        from?: string,
        to?: string,
        fromCity?: string,
        fromState?: string,
        fromZip?: string,
        fromCountry?: string,
        toCity?: string,
        toState?: string,
        toZip?: string,
        toCountry?: string,
        options?: any
    ) {
        return VoiceApiFp(this.configuration).incomingCall(
            callSid,
            callStatus,
            apiVersion,
            direction,
            forwardedFrom,
            callerName,
            parentCallSid,
            callToken,
            digits,
            speechResult,
            confidence,
            recordingUrl,
            recordingStatus,
            recordingDuration,
            recordingChannels,
            recordingSource,
            finishedOnKey,
            transcriptionSid,
            transcriptionText,
            transcriptionStatus,
            transcriptionUrl,
            recordingSid,
            dialCallStatus,
            dialCallSid,
            dialCallDuration,
            sipDomain,
            sipUsername,
            sipCallId,
            sipSourceIp,
            accountSid,
            from,
            to,
            fromCity,
            fromState,
            fromZip,
            fromCountry,
            toCity,
            toState,
            toZip,
            toCountry,
            options
        )(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} [callDuration]
     * @param {string} [called]
     * @param {string} [caller]
     * @param {number} [duration]
     * @param {string} [callSid]
     * @param {string} [callStatus]
     * @param {string} [apiVersion]
     * @param {string} [direction]
     * @param {string} [forwardedFrom]
     * @param {string} [callerName]
     * @param {string} [parentCallSid]
     * @param {string} [callToken]
     * @param {string} [digits]
     * @param {string} [speechResult]
     * @param {number} [confidence]
     * @param {string} [recordingUrl]
     * @param {string} [recordingStatus]
     * @param {string} [recordingDuration]
     * @param {number} [recordingChannels]
     * @param {string} [recordingSource]
     * @param {string} [finishedOnKey]
     * @param {string} [transcriptionSid]
     * @param {string} [transcriptionText]
     * @param {string} [transcriptionStatus]
     * @param {string} [transcriptionUrl]
     * @param {string} [recordingSid]
     * @param {string} [dialCallStatus]
     * @param {string} [dialCallSid]
     * @param {string} [dialCallDuration]
     * @param {string} [sipDomain]
     * @param {string} [sipUsername]
     * @param {string} [sipCallId]
     * @param {string} [sipSourceIp]
     * @param {string} [accountSid]
     * @param {string} [from]
     * @param {string} [to]
     * @param {string} [fromCity]
     * @param {string} [fromState]
     * @param {string} [fromZip]
     * @param {string} [fromCountry]
     * @param {string} [toCity]
     * @param {string} [toState]
     * @param {string} [toZip]
     * @param {string} [toCountry]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoiceApi
     */
    public statusCallback(
        callDuration?: number,
        called?: string,
        caller?: string,
        duration?: number,
        callSid?: string,
        callStatus?: string,
        apiVersion?: string,
        direction?: string,
        forwardedFrom?: string,
        callerName?: string,
        parentCallSid?: string,
        callToken?: string,
        digits?: string,
        speechResult?: string,
        confidence?: number,
        recordingUrl?: string,
        recordingStatus?: string,
        recordingDuration?: string,
        recordingChannels?: number,
        recordingSource?: string,
        finishedOnKey?: string,
        transcriptionSid?: string,
        transcriptionText?: string,
        transcriptionStatus?: string,
        transcriptionUrl?: string,
        recordingSid?: string,
        dialCallStatus?: string,
        dialCallSid?: string,
        dialCallDuration?: string,
        sipDomain?: string,
        sipUsername?: string,
        sipCallId?: string,
        sipSourceIp?: string,
        accountSid?: string,
        from?: string,
        to?: string,
        fromCity?: string,
        fromState?: string,
        fromZip?: string,
        fromCountry?: string,
        toCity?: string,
        toState?: string,
        toZip?: string,
        toCountry?: string,
        options?: any
    ) {
        return VoiceApiFp(this.configuration).statusCallback(
            callDuration,
            called,
            caller,
            duration,
            callSid,
            callStatus,
            apiVersion,
            direction,
            forwardedFrom,
            callerName,
            parentCallSid,
            callToken,
            digits,
            speechResult,
            confidence,
            recordingUrl,
            recordingStatus,
            recordingDuration,
            recordingChannels,
            recordingSource,
            finishedOnKey,
            transcriptionSid,
            transcriptionText,
            transcriptionStatus,
            transcriptionUrl,
            recordingSid,
            dialCallStatus,
            dialCallSid,
            dialCallDuration,
            sipDomain,
            sipUsername,
            sipCallId,
            sipSourceIp,
            accountSid,
            from,
            to,
            fromCity,
            fromState,
            fromZip,
            fromCountry,
            toCity,
            toState,
            toZip,
            toCountry,
            options
        )(this.fetch, this.basePath);
    }
}

/**
 * WebhookApi - fetch parameter creator
 * @export
 */
export const WebhookApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        event(accountId: string, options: any = {}): FetchArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling event.");
            }
            const localVarPath = `/sendgrid/v1/Webhook/{accountId}`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "POST" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        event_1(accountId: string, options: any = {}): FetchArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling event_1.");
            }
            const localVarPath = `/sendgrid/v1/Webhook`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "POST" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * WebhookApi - functional programming interface
 * @export
 */
export const WebhookApiFp = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        event(accountId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = WebhookApiFetchParamCreator(configuration).event(accountId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        event_1(accountId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = WebhookApiFetchParamCreator(configuration).event_1(accountId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    };
};

/**
 * WebhookApi - factory interface
 * @export
 */
export const WebhookApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {string} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        event(accountId: string, options?: any) {
            return WebhookApiFp(configuration).event(accountId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        event_1(accountId: string, options?: any) {
            return WebhookApiFp(configuration).event_1(accountId, options)(fetch, basePath);
        },
    };
};

/**
 * WebhookApi - interface
 * @export
 * @interface WebhookApi
 */
export interface WebhookApiInterface {
    /**
     *
     * @param {string} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhookApiInterface
     */
    event(accountId: string, options?: any): Promise<{}>;

    /**
     *
     * @param {string} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhookApiInterface
     */
    event_1(accountId: string, options?: any): Promise<{}>;
}

/**
 * WebhookApi - object-oriented interface
 * @export
 * @class WebhookApi
 * @extends {BaseAPI}
 */
export class WebhookApi extends BaseAPI implements WebhookApiInterface {
    /**
     *
     * @param {string} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhookApi
     */
    public event(accountId: string, options?: any) {
        return WebhookApiFp(this.configuration).event(accountId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhookApi
     */
    public event_1(accountId: string, options?: any) {
        return WebhookApiFp(this.configuration).event_1(accountId, options)(this.fetch, this.basePath);
    }
}
