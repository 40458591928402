import { Default } from "./AppContext";

type StringStringDict = { [name: string]: string };

export interface CurrentURL {
    path: string;
    queryParams?: StringStringDict;
}

class LocationState {
    // ULRSearchParams probably doesn't work on IE (Edge?)
    // homegrown parser
    static getSearchParams(url: URL): StringStringDict | undefined {
        var search = url.search;

        var result: StringStringDict = {};
        if (!search || !search.startsWith("?")) return result;

        var params = search.substring(1).split("&");
        params.forEach((param) => {
            var tokens = param.split("=");
            if (tokens.length === 2) {
                result[tokens[0]] = decodeURIComponent(tokens[1]);
            }
        });

        return result;
    }
}

export default function save(statePath: string) {
    var url = new URL(window.location.href);
    var params = LocationState.getSearchParams(url);

    var currentUrl: CurrentURL = {
        queryParams: params,
        path: url.pathname,
    };

    Default.state.set(statePath, currentUrl);

    // change url
    // window.history.pushState(currentUrl, 'ProgramInterface.com', '/');
    // window.onpopstate = (e) => {
    //     if ( e.state ) {
    //         Default.state.set(statePath, currentUrl);
    //     }
    // };
}
