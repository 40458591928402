type ContentType = "application/json" | "application/x-www-form-urlencoded" | "multipart/form-data";

interface RequestOptions {
    method?: string;
    path: string;
    data?: object;
    contentType?: ContentType;
    headers?: object;
}

export class RequestError {
    error?: number;
    status?: string;
    json?: object;
    body?: string;

    static async New(response: Response): Promise<RequestError> {
        var e = new RequestError();
        e.status = response.statusText;
        e.error = response.status;

        if (response) {
            e.body = await response.text().catch((r) => undefined);
            try {
                if (e.body) e.json = JSON.parse(e.body);
            } catch { }
        }

        return e;
    }
}

export class Client {
    baseUrl: string;
    accessToken?: string;

    constructor(baseUrl: string, accessToken?: string) {
        this.baseUrl = baseUrl;
        this.accessToken = accessToken;
    }

    async get(path: string): Promise<Response> {
        return await this.request({ path });
    }

    async put(path: string, data: object, contentType?: ContentType): Promise<Response> {
        return await this.request({ path, data, contentType, method: "put" });
    }

    async post(path: string, data: object, contentType?: ContentType): Promise<Response> {
        return await this.request({ path, data, contentType });
    }

    async getJson<T>(path: string): Promise<T> {
        return await this.requestJson<T>({ path });
    }

    async putJson<T>(path: string, data: object, contentType?: ContentType): Promise<T> {
        return await this.requestJson<T>({ path, data, contentType, method: "put" });
    }

    async postJson<T>(path: string, data: object, contentType?: ContentType): Promise<T> {
        return await this.requestJson<T>({ path, data, contentType });
    }

    async postForm<T>(path: string, form: FormData): Promise<T> {
        const options: RequestOptions = {
            path,
            data: form,
            contentType: "multipart/form-data",
        };

        var resp = await this.request(options, { Accept: "application/json" });
        var json = await resp.json();
        return json;
    }

    async request(options: RequestOptions, headers?: { [key: string]: string }): Promise<Response> {
        let url = `${this.baseUrl}${options.path}`;

        if (options.data) {
            if (!headers) headers = {};
            headers["Content-Type"] = options.contentType || "application/json";
        }

        if (this.accessToken) {
            if (!headers) headers = {};
            headers["Authorization"] = `Bearer ${this.accessToken}`;
        }

        var body;
        if (options.data) {
            if (options.data instanceof FormData) {
                // already form, make sure there is no Content-Type header
                if (headers) delete headers["Content-Type"];
                body = options.data;
            } else {
                var data = options.data as { [key: string]: string };
                switch (options.contentType) {
                    case "application/x-www-form-urlencoded":
                        var query = "";
                        Object.keys(data).forEach(
                            (key, index) => (query += index > 0 ? `&${key}=${encodeURIComponent(data[key])}` : `${key}=${encodeURIComponent(data[key])}`)
                        );
                        body = query;
                        break;

                    case "multipart/form-data":
                        var form = new FormData();
                        Object.keys(data).forEach((key) => form.append(key, encodeURIComponent(data[key])));
                        body = form;
                        break;

                    default:
                        body = JSON.stringify(data);
                        break;
                }
            }
        }

        const fetchOpts = {
            method: options.method || (options.data ? "post" : "get"),
            body,
            headers,
            mode: "cors" as RequestMode,
        };

        const resp = await fetch(url, fetchOpts);
        if (resp.status < 200 || resp.status >= 300) {
            const err = await RequestError.New(resp);
            throw err;
        }

        return resp;
    }

    async requestJson<T>(options: RequestOptions): Promise<T> {
        var resp = await this.request(options, {
            ...options.headers,
            Accept: "application/json"
        });
        var json = await resp.json();
        return json;
    }
}

export async function parseResponseError(e) {
    if (e instanceof Response) {
        const resp = e as Response;
        const json = await resp.json().catch((e) => {
            return {};
        });
        return "message" in json ? `${e.statusText}: ${json.message}` : resp.status;
    }

    if (e instanceof RequestError) {
        const resp = e as RequestError;
        switch (resp.error) {
            case 403:
                return `Sorry, access forbidden`;
            case 401:
                return `Please log in`;
        }

        if (resp.json && "message" in resp.json) {
            return resp.json["message"];
        }

        return resp.status || `API returned ${resp.error}`;
    }

    return e.toString();
}
