import * as React from "react";

import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

import { IconButton } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { observer } from "mobx-react";
import App from "../../../application/App";
import { DataView } from "../../DataView";
import { LocalFilterPanel } from "./LocalFilterPanel";

interface Props {
    dataView: DataView;
}

@observer
class Title extends React.PureComponent<Props> {
    render() {
        const { dataView } = this.props;
        const title = `Filter (${dataView.filteredCount} matches)`;

        return <span>{title}</span>;
    }
}

// @observer
export class LocalFilterDialog extends React.Component<Props> {
    onClose = () => {
        const { dataView } = this.props;
        dataView.filterVisible = false;
    };

    private transitionComponent = React.forwardRef((props: TransitionProps & { children: React.ReactElement<any, any> }, ref) => (
        <Slide direction="up" {...props} ref={ref} />
    ));

    render() {
        const { dataView } = this.props;
        const fullScreen = App().innerWidth < 600;

        return (
            <Dialog
                className="FilterDialog"
                open={dataView.filterVisible}
                onClose={this.onClose}
                TransitionComponent={this.transitionComponent}
                fullWidth={true}
                maxWidth="sm"
                fullScreen={fullScreen}
            >
                <DialogTitle
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Title dataView={dataView} />
                    {fullScreen && (
                        <IconButton onClick={this.onClose}>
                            <CloseIcon />
                        </IconButton>
                    )}
                </DialogTitle>

                <DialogContent>
                    <LocalFilterPanel dataView={dataView} fieldNames={dataView.filterableFields} />
                </DialogContent>
            </Dialog>
        );
    }
}
