
import * as React from "react";
import { registerComponent } from "../../pi/ui/material/DataViewComponent/DataViewComponent";

export function registerDataViewComponents() {
    console.debug('register components');

    const Card = React.lazy(() => import("src/pi/ui/material/DataViewComponent/CardViewComponent"));
    registerComponent("Card", (props) => <Card {...props} />);

    const Chart = React.lazy(() => import("src/pi/ui/material/DataViewComponent/DataViewBarChart"));
    registerComponent("Chart", (props) => <Chart {...props} />);

    const Calendar = React.lazy(() => import("src/pi/ui/material/DataViewComponent/CalendarViewComponent"));
    registerComponent("Calendar", (props) => <Calendar {...props} />);

    const Map = React.lazy(() => import("src/pi/ui/material/DataViewComponent/MapViewComponent"));
    registerComponent("Map", (props) => <Map {...props} />);

    const ImageGallery = React.lazy(() => import("src/pi/ui/material/DataViewComponent/ImageGalleryViewComponent"));
    registerComponent("ImageGallery", (props) => <ImageGallery {...props} />);

    const AIChatMessages = React.lazy(() => import("./AIChatMessages"));
    registerComponent("AIChatMessages", (props) => <AIChatMessages {...props} />);

}