/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Google
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
// @ts-nocheck

import * as portableFetch from "portable-fetch";
import * as url from "url";
import { Configuration } from "./configuration";

const BASE_PATH = "https://localhost".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = portableFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError";
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 *
 * @export
 * @interface SpaceMessage
 */
export interface SpaceMessage {
    /**
     *
     * @type {string}
     * @memberof SpaceMessage
     */
    space?: string;
    /**
     *
     * @type {string}
     * @memberof SpaceMessage
     */
    key?: string;
    /**
     *
     * @type {string}
     * @memberof SpaceMessage
     */
    token?: string;
    /**
     *
     * @type {string}
     * @memberof SpaceMessage
     */
    text?: string;
}

/**
 * MessageApi - fetch parameter creator
 * @export
 */
export const MessageApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} [token]
         * @param {string} [message]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNotification(token?: string, message?: string, options: any = {}): FetchArgs {
            const localVarPath = `/google/v1/Message`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "POST" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter["token"] = token;
            }

            if (message !== undefined) {
                localVarQueryParameter["message"] = message;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * MessageApi - functional programming interface
 * @export
 */
export const MessageApiFp = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} [token]
         * @param {string} [message]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNotification(token?: string, message?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
            const localVarFetchArgs = MessageApiFetchParamCreator(configuration).sendNotification(token, message, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    };
};

/**
 * MessageApi - factory interface
 * @export
 */
export const MessageApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {string} [token]
         * @param {string} [message]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNotification(token?: string, message?: string, options?: any) {
            return MessageApiFp(configuration).sendNotification(token, message, options)(fetch, basePath);
        },
    };
};

/**
 * MessageApi - interface
 * @export
 * @interface MessageApi
 */
export interface MessageApiInterface {
    /**
     *
     * @param {string} [token]
     * @param {string} [message]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApiInterface
     */
    sendNotification(token?: string, message?: string, options?: any): Promise<boolean>;
}

/**
 * MessageApi - object-oriented interface
 * @export
 * @class MessageApi
 * @extends {BaseAPI}
 */
export class MessageApi extends BaseAPI implements MessageApiInterface {
    /**
     *
     * @param {string} [token]
     * @param {string} [message]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public sendNotification(token?: string, message?: string, options?: any) {
        return MessageApiFp(this.configuration).sendNotification(token, message, options)(this.fetch, this.basePath);
    }
}

/**
 * PushNotificationApi - fetch parameter creator
 * @export
 */
export const PushNotificationApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} [token]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(token?: string, options: any = {}): FetchArgs {
            const localVarPath = `/google/v1/PushNotification`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "POST" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === "function" ? configuration.accessToken("oauth2", []) : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (token !== undefined) {
                localVarQueryParameter["token"] = token;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling remove.");
            }
            const localVarPath = `/google/v1/PushNotification({id})`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "DELETE" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === "function" ? configuration.accessToken("oauth2", []) : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAll(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling removeAll.");
            }
            const localVarPath = `/google/v1/PushNotification`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "DELETE" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === "function" ? configuration.accessToken("oauth2", []) : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * PushNotificationApi - functional programming interface
 * @export
 */
export const PushNotificationApiFp = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} [token]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(token?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = PushNotificationApiFetchParamCreator(configuration).create(token, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PushNotificationApiFetchParamCreator(configuration).remove(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAll(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PushNotificationApiFetchParamCreator(configuration).removeAll(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    };
};

/**
 * PushNotificationApi - factory interface
 * @export
 */
export const PushNotificationApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {string} [token]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(token?: string, options?: any) {
            return PushNotificationApiFp(configuration).create(token, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: string, options?: any) {
            return PushNotificationApiFp(configuration).remove(id, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAll(id: string, options?: any) {
            return PushNotificationApiFp(configuration).removeAll(id, options)(fetch, basePath);
        },
    };
};

/**
 * PushNotificationApi - interface
 * @export
 * @interface PushNotificationApi
 */
export interface PushNotificationApiInterface {
    /**
     *
     * @param {string} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PushNotificationApiInterface
     */
    create(token?: string, options?: any): Promise<string>;

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PushNotificationApiInterface
     */
    remove(id: string, options?: any): Promise<{}>;

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PushNotificationApiInterface
     */
    removeAll(id: string, options?: any): Promise<{}>;
}

/**
 * PushNotificationApi - object-oriented interface
 * @export
 * @class PushNotificationApi
 * @extends {BaseAPI}
 */
export class PushNotificationApi extends BaseAPI implements PushNotificationApiInterface {
    /**
     *
     * @param {string} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PushNotificationApi
     */
    public create(token?: string, options?: any) {
        return PushNotificationApiFp(this.configuration).create(token, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PushNotificationApi
     */
    public remove(id: string, options?: any) {
        return PushNotificationApiFp(this.configuration).remove(id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PushNotificationApi
     */
    public removeAll(id: string, options?: any) {
        return PushNotificationApiFp(this.configuration).removeAll(id, options)(this.fetch, this.basePath);
    }
}

/**
 * SpaceApi - fetch parameter creator
 * @export
 */
export const SpaceApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {SpaceMessage} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(body?: SpaceMessage, options: any = {}): FetchArgs {
            const localVarPath = `/Message`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "POST" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === "function" ? configuration.accessToken("oauth2", []) : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter["Content-Type"] = "application/json-patch+json";

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = <any>"SpaceMessage" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || "";

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * SpaceApi - functional programming interface
 * @export
 */
export const SpaceApiFp = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {SpaceMessage} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(body?: SpaceMessage, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SpaceApiFetchParamCreator(configuration).get(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    };
};

/**
 * SpaceApi - factory interface
 * @export
 */
export const SpaceApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {SpaceMessage} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(body?: SpaceMessage, options?: any) {
            return SpaceApiFp(configuration).get(body, options)(fetch, basePath);
        },
    };
};

/**
 * SpaceApi - interface
 * @export
 * @interface SpaceApi
 */
export interface SpaceApiInterface {
    /**
     *
     * @param {SpaceMessage} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpaceApiInterface
     */
    get(body?: SpaceMessage, options?: any): Promise<{}>;
}

/**
 * SpaceApi - object-oriented interface
 * @export
 * @class SpaceApi
 * @extends {BaseAPI}
 */
export class SpaceApi extends BaseAPI implements SpaceApiInterface {
    /**
     *
     * @param {SpaceMessage} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpaceApi
     */
    public get(body?: SpaceMessage, options?: any) {
        return SpaceApiFp(this.configuration).get(body, options)(this.fetch, this.basePath);
    }
}
