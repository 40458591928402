import * as api from "@crochik/pi-api";
import App from "src/pi/application/App";

function getApiConfig(): api.Configuration {
    var apiConfig = App().apiConfig;
    if (!apiConfig) throw new Error("No session");
    return apiConfig;
}

export class Base {
    getConfig() {
        return getApiConfig();
    }
}
