import { Alert, Autocomplete, Checkbox, TextField } from "@mui/material";
import { css, StyleSheet } from "aphrodite";
import { useCallback, useEffect, useMemo, useState } from "react";
import * as api from "@crochik/pi-api";

import App from "../../../../application/App";
import { Loading } from "../../Loading";
import { TimeslotPicker } from "../../Timeslot/TimeslotPicker";
import { ISchedulerProps } from "./ISchedulerProps";

interface IAutocompleteOption {
    isCurrentlyAssigned?: boolean;
    label: string;
    user: api.UserWithSchedulingSettings | null;
}

export function Scheduler({ appointment, filter, onScheduleAppointment, allowUserSelection, timeZone }: ISchedulerProps) {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string>();
    const [slots, setSlots] = useState<api.SlotsResp>();
    const [users, setUsers] = useState<api.UserWithSchedulingSettings[]>([]);
    const [selectedSlot, setSelectedSlot] = useState<api.TimeSlot>();
    const [selectedUser, setSelectedUser] = useState<api.UserWithSchedulingSettings | null>(null);
    const [assignedEntityId, setAssignedEntityId] = useState("");
    const [allowUnavailable, setAllowUnavailable] = useState(false);
    const [showUnavailable, setShowUnavailable] = useState(false);

    const { LeadId, OrganizationId, EntityId } = filter as { LeadId?: string, OrganizationId?: string, EntityId?: string }

    const onSelectSlot = useCallback(
        (slot: api.TimeSlot) => {
            setSelectedSlot(slot);
            onScheduleAppointment?.(slot, showUnavailable, selectedUser?.userId);
        },
        [onScheduleAppointment, setSelectedSlot, selectedUser?.userId, showUnavailable]
    );

    const handleError = useCallback((reason: any) => {
        if ("statusText" in reason) {
            setError(reason["statusText"]);
        } else if (typeof reason === "string") {
            setError(reason);
        } else {
            setError("Unexpected error");
        }

        setIsLoading(false);
    }, []);

    useEffect(() => {
        const apiConfig = App().apiConfig;
        const appointmentSchedulerApi = new api.AppointmentSchedulerApi(apiConfig);

        setIsLoading(true);

        if (!!selectedUser?.userId && !!selectedUser?.appointmentTypeId) {
            setAllowUnavailable(false);
            const { appointmentTypeId, userId } = selectedUser;
            appointmentSchedulerApi
                .appointmentSchedulerGetSlotsForUser(userId, appointmentTypeId)
                .then((resp) => {
                    setIsLoading(false);
                    setSlots(resp);
                })
                .catch(handleError);
            return;
        }

        if (LeadId || OrganizationId || EntityId) {
            let promise: Promise<api.SlotsResp>;
            if (!!LeadId) {
                appointmentSchedulerApi.appointmentSchedulerGetSettings(LeadId)
                    .then(r => {
                        setAllowUnavailable(!!r?.unavailableSlots?.assignEntityId);
                    })
                    .catch(error => {
                        console.error(error);
                    });


                promise = showUnavailable ?
                    appointmentSchedulerApi.appointmentSchedulerGetSlotsByLead(LeadId, undefined, undefined, true) :
                    appointmentSchedulerApi.appointmentSchedulerGetSlotsByLead(LeadId)

            } else if (!!OrganizationId) {
                setAllowUnavailable(false);
                promise = appointmentSchedulerApi.appointmentSchedulerGetSlotsForEntity(OrganizationId)
            } else if (!!EntityId) {
                setAllowUnavailable(false);
                promise = appointmentSchedulerApi.appointmentSchedulerGetSlotsForEntity(EntityId)
            } else {
                setAllowUnavailable(false);
                setError("Invalid filter");
                return;
            }

            promise
                .then((resp) => {
                    setIsLoading(false);
                    setSlots(resp);
                    if (!!resp?.users?.length) {
                        setUsers(resp.users);
                    }
                    if (!!resp?.assignedEntityId) {
                        setAssignedEntityId(resp.assignedEntityId);
                    }
                })
                .catch(handleError);
            return;
        }

        setError("Invalid state");

    }, [LeadId, OrganizationId, EntityId, selectedUser, timeZone, showUnavailable, handleError]);

    const onSelectUser = (_, value: IAutocompleteOption | null) => {
        if (!!value) {
            setSelectedUser(value.user);
        } else {
            setSelectedUser(null);
        }
    };

    const anyAvailableOption = useMemo(() => ({ label: "Any Available", user: null, isCurrentlyAssigned: false }), []);

    const autocompleteOptions = useMemo(() => {
        if (!users?.length) {
            return [];
        }
        let options: IAutocompleteOption[] = [anyAvailableOption];
        users.forEach((user) => {
            if (!!user?.name && !!user?.userId) {
                const isCurrentlyAssigned = assignedEntityId === user.userId;
                options.push({ label: user.name, user, isCurrentlyAssigned });
            }
        });
        return options;
    }, [assignedEntityId, users, anyAvailableOption]);

    const toggleShowUnavailable = () => {
        setShowUnavailable(!showUnavailable);
    }

    // const autocompleteDefaultValue = useMemo(() => {
    //     if (!autocompleteOptions?.length) {
    //         return undefined;
    //     }
    //     return autocompleteOptions.find((x) => x.isCurrentlyAssigned) || autocompleteOptions[0];
    // }, [autocompleteOptions]);

    if (!!error) {
        return <Alert severity="error">{error}</Alert>;
    }

    return (
        <div className={css(styles.content)}>
            <div className={css(styles.leftPanel)}>
                {isLoading && <Loading />}

                {allowUserSelection && !!autocompleteOptions?.length && (
                    <Autocomplete
                        disableClearable
                        defaultValue={anyAvailableOption}
                        onChange={onSelectUser}
                        options={autocompleteOptions}
                        renderInput={(params) => <TextField {...params} label="User" variant="filled" />}
                        style={{ width: '100%', maxWidth: "450px", marginBottom: 24 }}
                        renderOption={(props, option) => (
                            <li {...props} className={css(styles.autocompleteOption)}>
                                {option.label}
                            </li>
                        )}
                    />
                )}

                {/* {!isLoading && slots && <QuickslotPicker timeZone={timeZone} selectedSlot={selectedSlot} onSelect={onSelectSlot} slots={slots.suggestedSlots ?? []} />} */}
            </div>
            {!isLoading && slots && (
                <div>
                    <TimeslotPicker timeZone={timeZone} selectedSlot={selectedSlot} onSelect={onSelectSlot} slots={slots.slots ?? []} />
                    {allowUnavailable && <div><Checkbox onChange={toggleShowUnavailable} checked={showUnavailable} />Request Unavailable Slot</div>}
                </div>
            )}

        </div>
    );
}

const styles = StyleSheet.create({
    content_old: {
        backgroundColor: "#EEEEEE",
        alignItems: "flex-start",
        display: "flex",
        flexDirection: "row",
        flex: 1,
        width: "100%",
        height: "100%",
        border: "12px solid #eee",
        "@media (max-width: 900px)": {
            alignItems: "center",
            flexDirection: "column",
            flex: 0,
        },
    },
    content: {
        // backgroundColor: "#EEEEEE",
        // alignItems: "flex-start",
        // display: "flex",
        // flexDirection: "row",
        // flex: 1,
        // width: "100%",
        // height: "100%",
        // border: "12px solid #eee",
        // padding: 0,
        // "@media (max-width: 900px)": {
        //     alignItems: "center",
        //     flexDirection: "column",
        //     flex: 0,
        // },
    },
    dialogFooter: {
        alignItems: "center",
        display: "flex",
        justifyContent: "flex-end",
        width: "100%",
    },
    userInputContainer: {
        paddingBottom: 12,
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        borderBottom: "1px solid #eee",
        display: "flex",
        justifyContent: "space-between",
    },
    autocompleteOption: {
        cursor: "pointer",
        paddingBottom: 8,
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 8,
        ":hover": {
            backgroundColor: "#EEEEEE",
        },
    },
    autocompleteOptionBold: {
        fontWeight: "bold",
    },
    leftPanel_old: {
        flex: 1,
        paddingRight: 12,
        "@media (max-width: 500px)": {
            flex: 0,
            padding: 0
        },
    },
    leftPanel: {
    }
});
