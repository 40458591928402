import { css, StyleSheet } from "aphrodite";
import { useMemo } from "react";
import { useDrop } from "react-dnd";
import { DraggableFormCellItem } from "./DraggableFormCell";
import { DraggableItemTypes } from "./DraggableItemTypes";

interface Props {
    onDrop(item: DraggableFormCellItem): void;
    rowIndex: number;
}

export function DropNewFormCell(props: Props) {
    const { onDrop, rowIndex } = props;
    const onDropItem = (item: DraggableFormCellItem) => {
        onDrop({ ...item, newRowId: rowIndex });
    };

    const [{ isOver }, drop] = useDrop(() => ({
        accept: DraggableItemTypes.FIELD,
        drop: onDropItem,
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    }));

    const styles = useMemo(() => {
        return StyleSheet.create({
            container: {
                display: "flex",
                flex: 1,
                marginLeft: 32,
            },
            content: {
                display: "flex",
                flex: 1,
                height: 12,
                alignItems: "center",
                justifyContent: "center",
            },
            contentHover: {
                marginTop: 12,
                marginBottom: 12,
                height: 60,
                borderWidth: 2,
                borderStyle: "dashed",
                borderColor: "#42A5F5",
                borderRadius: 8,
                color: "#42A5F5",
                transition: "opacity 500ms ease-in",
            },
        });
    }, []);

    return (
        <div className={css(styles.container)} ref={drop}>
            <div className={css(styles.content, isOver && styles.contentHover)}>{isOver && <div>{"+ New Row"}</div>}</div>
        </div>
    );
}
