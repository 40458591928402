import { IField } from "src/pi/context/IForm";

export const DraggableItemTypes = {
    ROW: "row",
    FIELD: "field",
};

export interface Row {
    fields: IField[];
}
