import { observable, action } from "mobx";

import { Default } from "../context/AppContext";
import { DialogProperties } from "../ui/material/ConfirmDialog";

export interface Information {
    title: string;
    message: string;
    positiveCaption?: string;
    onPositive?: () => any;
}

export interface Confirmation {
    title: string;
    message: string;
    positiveCaption?: string;
    negativeCaption?: string;
    onPositive?: () => any;
    onNegative?: () => any;
}

export interface Error {
    title?: string;
    message: string;
    onClose?: () => any;
}

interface State {
    message?: DialogProperties;
    // TODO: add support for multiple
    snackBar?: DialogProperties;
}

export class DialogService {
    _boundState: State;

    get message(): DialogProperties | undefined {
        return this._boundState.message;
    }

    get snackBar(): DialogProperties | undefined {
        return this._boundState.snackBar;
    }

    constructor() {
        this._boundState = observable({
            message: undefined,
            snackBar: undefined,
        });

        Default.state.setValue("service", "DialogService", this._boundState);
    }

    @action
    confirm(message: Confirmation) {
        this._boundState.message = {
            title: message.title,
            message: message.message,
            positiveCaption: !message.positiveCaption ? "OK" : message.positiveCaption,
            negativeCaption: !message.negativeCaption ? "Cancel" : message.negativeCaption,
            onPositive: () => {
                this._boundState.message = undefined;
                if (message.onPositive) {
                    message.onPositive();
                }
            },
            onNegative: () => {
                this._boundState.message = undefined;
                if (message.onNegative) {
                    message.onNegative();
                }
            },
            open: true,
        };
    }

    @action
    inform(message: Information) {
        // TODO: handle multiple
        // ...
        this._boundState.snackBar = {
            title: message.title,
            message: message.message,
            positiveCaption: !message.positiveCaption ? "OK" : message.positiveCaption,
            onPositive: () => {
                this._boundState.snackBar = undefined;
                if (message.onPositive) {
                    message.onPositive();
                }
            },
            onNegative: () => {
                this._boundState.snackBar = undefined;
            },
            open: true,
        };
    }

    @action
    critical(message: Error) {
        this._boundState.message = {
            title: message.title || "Critical Error",
            message: message.message,
            positiveCaption: "OK",
            onPositive: () => {
                this._boundState.message = undefined;
                if (message.onClose) {
                    message.onClose();
                }
            },
            onNegative: () => {
                this._boundState.message = undefined;
                if (message.onClose) {
                    message.onClose();
                }
            },
            open: true,
        };
    }

    @action
    error(message: Error) {
        this._boundState.snackBar = {
            title: message.title || "Error",
            message: message.message,
            positiveCaption: "OK",
            onPositive: () => {
                this._boundState.snackBar = undefined;
                if (message.onClose) {
                    message.onClose();
                }
            },
            onNegative: () => {
                this._boundState.snackBar = undefined;
                if (message.onClose) {
                    message.onClose();
                }
            },
            open: true,
        };
    }
}

const instance = new DialogService();
export default instance;
