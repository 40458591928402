import { Dump } from "../../Dump";
import { FieldName } from "./FieldName";
import { Token } from "./Token";

export function UnknownValue({ value, objectType }: { value: any, objectType?: string }) {
    if (!value) return null;
    if (typeof (value) === "object") {
        console.log("object value", value);
        return <Dump style={{
            position: "relative",
            border: "1px solid gray",
            fontSize: "8pt",
            background: "#eee",
            maxHeight: "200px",
            overflow: "auto",
        }} object={value} />
    }

    if (typeof(value)==="string")
    {
        if (value.startsWith("{{") && value.endsWith("}}")){
            if (value.indexOf(' ')<0) {
                return <FieldName name={value} objectType={objectType}/>
            }

            // TODO: actual expression
            // ...

            return <Token name={value} style={{background: '#07c', color: 'white'}}/>;
        }
    }

    return <Token name={value} style={{background: '#ccc', color: 'black'}}/>;
}