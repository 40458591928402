import * as React from "react";

import { FormControl, TextField } from "@mui/material";
import Autocomplete, { AutocompleteRenderOptionState } /*, { createFilterOptions } */ from "@mui/material/Autocomplete";

import { Form } from "../../../Form";
import { FieldProps } from "../FieldProps";

import { ISelectFieldOptions } from "../../../../context/IForm";
import { IReferenceValue } from "../../../../services/DataService";

interface IState {
    rows?: IReferenceValue[];
    open: boolean;
}

interface IProps extends FieldProps {
    form?: Form;
}

export class SelectField extends React.Component<IProps, IState> {
    requestIndex: number = 0;
    divRef?: React.RefObject<{}>;

    constructor(props: FieldProps) {
        super(props);

        this.state = {
            open: false,
        };

        this.divRef = React.createRef();
    }

    async componentDidMount() {
        const { field } = this.props;
        const options = field.options as ISelectFieldOptions;
        if (!options) {
            console.error("SelectField", "Missing required options");
            return;
        }

        this.load();
    }

    onChange = (event: any) => {
        const { field } = this.props;

        var value = event.target.value;

        if (this.props.onChange) {
            this.props.onChange(field, value);
        }
    };

    onValueChanged = (event: React.ChangeEvent<{}>, value: any) => {
        const { field } = this.props;

        this.setState({
            open: false,
        });

        if (this.props.onChange) {
            this.props.onChange(field, value?.id);
        }
    };

    onInputChanged = async (event: React.ChangeEvent<{}>, value: string, reason: string) => {
        // switch (reason) {
        //     case 'input':
        //         await this.fetch(value);
        //         break;
        // }
    };

    get inputLabelProps() {
        return {
            required: this.props.field.isRequired,
            error: this.props.error ? true : false,
            htmlFor: this.props.field.name,
            shrink: true,
        };
    }

    load() {
        var rows: IReferenceValue[] = [];
        this.addAdditionalItems(rows);
        this.setState({
            rows,
        });
    }

    onOpen = () => {
        this.setState({ open: true });
    };

    onClose = () => {
        this.setState({ open: false }, this.selectText);
    };

    selectText() {
        if (this.divRef && this.divRef.current) {
            const input = (this.divRef.current as HTMLElement).querySelector("INPUT") as HTMLInputElement;
            if (input) input.select();
        }
    }

    private addAdditionalItems(rows: IReferenceValue[]) {
        const { field } = this.props;
        const options = field.options as ISelectFieldOptions;
        const { items } = options;

        if (items && Object.keys(items).length > 0) {
            for (var option of Object.keys(items)) {
                const refValue: IReferenceValue = {
                    id: option,
                    value: items[option],
                };
                rows.push(refValue);
            }
        }
    }

    renderField() {
        const { field, disabled } = this.props;
        const { rows, open } = this.state;

        const value = this.props.value ?? null;
        // const options = field.options as ISelectFieldOptions;

        if (!rows) return null;

        const selected = rows?.find((x) => x.id === value?.toString());

        return (
            <Autocomplete
                ref={this.divRef}
                // filterOptions={filterOptions}
                autoComplete={true}
                autoHighlight={true}
                clearOnEscape={true}
                selectOnFocus={true}
                value={value}
                onChange={this.onValueChanged}
                onInputChange={this.onInputChanged}
                disabled={disabled}
                loading={false}
                open={open}
                options={rows || []}
                getOptionLabel={(x) => x.value ?? (selected ? selected.value : "")}
                isOptionEqualToValue={(o) => o.id === value}
                onOpen={this.onOpen}
                onClose={this.onClose}
                // noOptionsText={loading ? 'Loading...' : ''}
                renderOption={(props: React.HTMLAttributes<HTMLLIElement>, option: IReferenceValue, state: AutocompleteRenderOptionState) => {
                    return (
                        <li {...props}>
                            <span style={{ fontWeight: option.id === field.defaultValue?.toString() ? 600 : undefined }}>{option.value ?? (selected ? selected.value : "")}</span>
                        </li>
                    )
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={field.label || field.name}
                        variant="filled"
                        required={field.isRequired}
                        InputProps={{
                            ...params.InputProps,
                            style: { fontWeight: value === field.defaultValue?.toString() ? 'bold' : undefined },
                            endAdornment: (
                                <React.Fragment>
                                    {/* {loading ? <CircularProgress color="inherit" size={20} /> : null} */}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
        );
    }

    render() {
        const { style, field } = this.props;

        return (
            <div
                style={{
                    display: "flex",
                    marginTop: 16,
                    marginBottom: 8,
                    ...style,
                }}
                key={field.name}
            >
                <FormControl style={{ width: "100%" }}>{this.renderField()}</FormControl>
            </div>
        );
    }
}
