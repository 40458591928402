import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { css, StyleSheet } from "aphrodite";
import { ReactNode, useCallback, useMemo, useRef, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { DropTargetMonitor, useDrop } from "react-dnd";
import { useForm } from "src/pi/hooks/useForm";
import { IField } from "src/pi/context/IForm";
import { DraggableFormCell, DraggableFormCellItem } from "./DraggableFormCell";
import { DraggableItemTypes, Row } from "./DraggableItemTypes";

interface Props {
    onDrop(item: DraggableFormCellItem): void;
    onShowInfo?(field: IField): void;
    onRender?(field: IField): ReactNode;
    row: Row;
    rowIndex: number;
}

const handleWidth = 32;

export function DraggableFormRow(props: Props) {
    const { rowIndex, row, onDrop, onShowInfo, onRender } = props;
    const form = useForm();
    const mainRef = useRef<HTMLDivElement>(null);
    const [hovering, setHovering] = useState(false);
    const [hoveringHandle, setHoveringHandle] = useState(false);
    const [previewItem, setPreviewItem] = useState<DraggableFormCellItem>();

    const getPreviewItem = useCallback(
        (item: DraggableFormCellItem, monitor: DropTargetMonitor<DraggableFormCellItem, any>) => {
            const offset = monitor.getClientOffset();
            if (!offset || !mainRef?.current) {
                return undefined;
            }
            const isSameRow = item.currentRowId === rowIndex;
            const rect = mainRef.current.getBoundingClientRect();
            const deltaX = offset.x - rect.x;
            const numFields = (row?.fields?.length || 0) + (isSameRow ? 0 : 1);
            const fieldWidth = rect.width / numFields;
            const newFieldId = Math.floor(deltaX / fieldWidth);
            return { ...item, newFieldId, newRowId: rowIndex };
        },
        [row.fields, rowIndex]
    );

    const onHover = useCallback(
        (item: DraggableFormCellItem, monitor: DropTargetMonitor<DraggableFormCellItem, any>) => {
            const newPreviewItem = getPreviewItem(item, monitor);
            if (!!previewItem && !newPreviewItem) {
                setPreviewItem(undefined);
            } else if (!previewItem || previewItem.field.name !== item.field.name || previewItem.newFieldId !== newPreviewItem?.newFieldId) {
                setPreviewItem(newPreviewItem);
            }
        },
        [getPreviewItem, previewItem]
    );

    const onDropItem = useCallback(
        (item: DraggableFormCellItem, monitor: DropTargetMonitor<DraggableFormCellItem, any>) => {
            const newPreviewItem = getPreviewItem(item, monitor);
            if (!!newPreviewItem) {
                const isDifferentPosition =
                    newPreviewItem.currentRowId !== newPreviewItem.newRowId || newPreviewItem.currentFieldId !== newPreviewItem.newFieldId;
                if (isDifferentPosition || item.hidden) {
                    onDrop(newPreviewItem);
                    setPreviewItem(undefined);
                }
            }
        },
        [getPreviewItem, onDrop]
    );
    const [{ isOver }, drop] = useDrop<DraggableFormCellItem, any, any>(
        () => ({
            accept: DraggableItemTypes.FIELD,
            drop: onDropItem,
            hover: onHover,
            collect: (monitor) => ({
                isOver: monitor.isOver(),
            }),
        }),
        [onDrop, onHover]
    );

    const onMouseEnter = useCallback(() => setHovering(true), []);

    const onMouseLeave = useCallback(() => setHovering(false), []);

    const onMouseEnterHandle = useCallback(() => setHoveringHandle(true), []);

    const onMouseLeaveHandle = useCallback(() => setHoveringHandle(false), []);

    const fields = useMemo(() => {
        let newFields = [...row.fields];
        if (!!previewItem && isOver && previewItem.newFieldId !== undefined) {
            const duplicateFieldIndex = newFields.indexOf(previewItem.field);
            if (duplicateFieldIndex >= 0) {
                newFields.splice(duplicateFieldIndex, 1);
                newFields.push(previewItem.field);
            } else {
                newFields.splice(previewItem.newFieldId, 0, previewItem.field);
            }
        }
        return newFields;
    }, [row.fields, previewItem, isOver]);

    return (
        <Draggable key={rowIndex} draggableId={`item${rowIndex}`} index={rowIndex}>
            {(provided, snapshot) => {
                const active = hovering || snapshot.isDragging;
                const handleActive = hoveringHandle || snapshot.isDragging;
                return (
                    <div
                        className={css(styles.container, handleActive && styles.containerActive)}
                        ref={provided.innerRef}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                        {...provided.draggableProps}
                    >
                        <div className={css(styles.handle)} {...provided.dragHandleProps} onMouseEnter={onMouseEnterHandle} onMouseLeave={onMouseLeaveHandle}>
                            {active && <DragIndicatorIcon />}
                        </div>
                        <div className={css(styles.main)} ref={mainRef}>
                            <div className={css(styles.drop)} ref={drop}>
                                {fields.map((field, i) => {
                                    const showDrop = !!previewItem && isOver && i === previewItem.newFieldId;
                                    const key = field.name || `row-${rowIndex}-field-${i}`;
                                    return (
                                        <div className={css(styles.field, showDrop && styles.dropField)} key={key}>
                                            <DraggableFormCell row={rowIndex} field={field} form={form} index={i} onShowInfo={onShowInfo} onRender={onRender} />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                );
            }}
        </Draggable>
    );
}

const styles = StyleSheet.create({
    container: {
        display: "flex",
        flex: 1,
        borderWidth: 2,
        borderRadius: 8,
        borderStyle: "solid",
        borderColor: "transparent",
        paddingRight: 32,
    },
    containerActive: {
        borderColor: "#42A5F5",
        boxShadow: "2px 2px 5px 2px rgba(173,173,173,1)",
    },
    handle: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: handleWidth,
        cursor: "move",
    },
    main: {
        display: "flex",
        flex: 1,
    },
    drop: {
        display: "flex",
        flex: 1,
    },
    field: {
        display: "flex",
        flex: 1,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "transparent",
        backgroundColor: "#fff",
    },
    dropField: {
        borderWidth: 2,
        borderStyle: "dashed",
        borderColor: "#42A5F5",
        borderRadius: 8,
    },
});
