import * as React from "react";

import { FormControl, InputLabel, Select } from "@mui/material";
import { FieldProps } from "../FieldProps";

import { ILookupFieldOptions } from "../../../../context/IForm";
import DataService from "../../../../services/DataService";
import { Loading } from "../../Loading";
import "./styles.css";

interface IState {
    rows?: { [value: string]: string };
    error?: string;
}

export class LookupField extends React.Component<FieldProps, IState> {
    constructor(props: FieldProps) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        const { field } = this.props;
        const options = field.options as ILookupFieldOptions;
        if (!options || !options.entity) {
            console.error("LookupField", "Missing required options");
            this.setState({
                error: "Missing required options",
            });
            return;
        }

        try {
            var records = await DataService().selectAsync(options.entity, options.query as any);
            var rows: { [value: string]: string };
            if (Array.isArray(records)) {
                // array
                const nameProperty = options.nameProperty || "name";
                const valueProperty = options.valueProperty || "value";
                rows = {};
                for (var row of records) {
                    const value = row[valueProperty];
                    const name = row[nameProperty];
                    if (value && name) {
                        rows[value] = name;
                    }
                }
            } else {
                // use properties in object
                rows = records as { [value: string]: string };
            }

            this.setState({
                rows,
            });
        } catch (ex) {
            const reason = ex as any;
            this.setState({
                error: reason,
            });
        }
    }

    onChange = (event: any) => {
        var value = event.target.value;

        if (this.props.onChange) {
            this.props.onChange(this.props.field, value);
        }
    };

    get inputLabelProps() {
        return {
            required: this.props.field.isRequired,
            error: this.props.error ? true : false,
            htmlFor: this.props.field.name!,
            shrink: true,
        };
    }

    render() {
        const { rows, error } = this.state;
        if (error) {
            return <div className="LookupField">{error}</div>;
        }

        if (!rows) {
            return (
                <div className="LookupField">
                    <Loading />
                </div>
            );
        }

        const field = this.props.field;
        const value = this.props.value || "";
        const options = field.options as ILookupFieldOptions;
        const { items } = options;

        return (
            <div className="LookupField">
                <FormControl className="LookupField_field">
                    <InputLabel {...this.inputLabelProps}>{field.label ?? field.name!}</InputLabel>
                    <br />
                    <Select native={true} value={value} onChange={this.onChange} inputProps={{ name: field.name!, id: field.name! }}>
                        {!rows[value] && (!options.items || !options.items[value]) && <option value={value} />}
                        {Object.keys(rows).map((name) => (
                            <option key={name} value={name}>
                                {rows[name]}
                            </option>
                        ))}
                        {items &&
                            Object.keys(items).map((name) => (
                                <option key={name} value={name}>
                                    {items[name]}
                                </option>
                            ))}
                    </Select>
                </FormControl>
            </div>
        );
    }
}
