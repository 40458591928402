import EventIcon from '@mui/icons-material/Event';
import { Typography } from "@mui/material";
import moment from "moment-timezone";
import * as api from "@crochik/pi-api";

export function AppointmentCardContent({ appointment }: { appointment: api.ExtendedAppointment; }) {
    const inPast = appointment && moment().isAfter(moment(appointment.start));
    const localTime = `${appointment.localDate} at ${appointment.localTime}`;
    const yourTime = `${moment(appointment.start).format("L")} at ${moment(appointment.start).format("hh:mm A")}`;

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <EventIcon style={{ marginBottom: 8, marginRight: 8, marginTop: 8 }} />
                <div>
                    <Typography>{appointment.name}</Typography>
                </div>
            </div>
            <Typography>
                on {appointment.localDate} at {appointment.localTime} ({appointment.timeZoneId})
                {appointment.entityName && <span><br />with  {appointment.entityName}</span>}
            </Typography>
            {appointment.start && localTime !== yourTime && (
                <>
                    {localTime !== yourTime && <div>{yourTime} (your time)</div>}
                </>
            )}
            {!appointment.isActive && (
                <div>
                    <b>Cancelled</b>
                </div>
            )}
            {appointment.isActive && inPast && (
                <div>
                    <b>Overdue</b>
                </div>
            )}
            {/* {workOrder && <Typography fontSize={12}>Project #{workOrder}</Typography>} */}

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div>
                    <Typography fontSize={12} style={{ marginTop: 12 }}>
                        <span style={{ fontWeight: 'bold' }}>Scheduled</span>
                        <span> ({appointment.tool})</span>
                        <br />
                        {appointment.createdByName && <span>by {appointment.createdByName}<br /></span>}
                        <span>on {moment(appointment.createdOn).format("MM/D/YY")} at {moment(appointment.createdOn).format("hh:mm A")}</span>
                    </Typography>
                </div>

                {appointment.cancelledOn && (
                    <div style={{ marginLeft: 12 }}>
                        <Typography fontSize={12} style={{ marginTop: 12 }}>
                            <span style={{ fontWeight: 'bold' }}>Cancelled</span>
                            <br />
                            {appointment.cancelledByName && <span> by {appointment.cancelledByName}<br /></span>}
                            <span> on {moment(appointment.cancelledOn).format("MM/D/YY")} at {moment(appointment.createdOn).format("hh:mm A")}</span>
                        </Typography>
                    </div>
                )}
            </div>
        </>
    );
}
