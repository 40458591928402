import { Alert } from "@mui/material";
import * as React from "react";
import { useCallback, useEffect } from "react";
import Chrome from "src/pi/ui/material/Chrome2";
import SalesforceWrapper from "./SalesforceWrapper";
import Theme from "./Theme";
import Welcome from "./Welcome";
import init from "./app";
import { IBootstrap } from "./app/Session";
import LoginService from "./app/services/LoginService";
import { App } from "./pi/application/App";
import { ApplicationContext, IApplicationContext } from "./pi/application/ApplicationContext";
import { Loading } from "./pi/ui/material/Loading";
import { reaction } from "mobx";
import { User } from "@crochik/pi-api";

export const ThemedChrome = Theme(Chrome);
export const ThemedLogin = Theme(Welcome);

export function AppComponent(props: any) {
    const [isLoading, setLoading] = React.useState(true);
    const [error, setError] = React.useState<string>();
    const [bootstrap, setBootstrap] = React.useState<IBootstrap>();
    const [app, setApp] = React.useState<App>();
    const [user, setUser] = React.useState<User>();

    useEffect( () => {
        const login = (app: App) => {
            LoginService().init()
                .then(async (user) => {
                    if (!user) {
                        console.log("no user");
                        await app.selectPageAsync();
                    }

                    setLoading(false);
                })
                .catch(() => {
                    setError("Failed to init login service");
                });

        };

        const getClientConfig  = async () =>{
            const host = document.location.hostname;
            let resp = await fetch(`client/${host}/bootstrap.json`);
            switch (resp.status) {
                case 200:
                    break;
                case 404:
                    resp = await fetch(`https://cfg.bootstrapp.cloud/client/${host}/bootstrap.json`);
                    if (resp.status !== 200) {
                        setError(`Failed to load config with: ${resp.statusText ?? resp.status}`);
                        return;
                    }
                    break;
                default:
                    setError(`Failed to load config with: ${resp.statusText ?? resp.status}`);
                    return;
            }

            const json = await resp.text();
            try {
                const bootstrap = JSON.parse(json) as IBootstrap;
                const app = init(bootstrap);
                setBootstrap(bootstrap);
                setApp(app);

                // hack to monitor user changes
                reaction(
                    () => app.session.user,
                    () => {
                        setUser(app.session.user);
                    });

                setUser(app.session.user);
                login(app);
            } catch (e) {
                console.error("failed to parse boostrap", e);
                setError(`Invalid Host Configuration: ${host}`);
            }
        }

        getClientConfig();
    }, []);

    if (error) {
        return <Alert severity="error">{error}</Alert>;
    }

    if (isLoading || !app) {
        return <Loading />;
    }

    if (!user) {
        return <ThemedLogin {...bootstrap} />;
    }

    const appContext: IApplicationContext = {
        apiConfig: app?.apiConfig,
        clientConfiguration: app?.clientConfiguration,
        user: user, // app?.session.user,
        embedded: app?.session.embedded,
        embeddedHeight: app?.session.embeddedHeight,
        innerHeight: app?.innerHeight
    };

    const body = (
        <ApplicationContext.Provider value={appContext}>
            <div
                className="AppComponent"
                style={{
                    fontFamily: "Roboto, sans-serif",
                    position: "fixed",
                    margin: 0,
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                    height: appContext.embeddedHeight ? `${appContext.embeddedHeight}px` : undefined,
                    overflow: "hidden",
                    backgroundColor: "#eeeeee"
                }}
            >
                <ThemedChrome {...bootstrap} embeddedHeight={appContext.embeddedHeight}
                              embedded={appContext.embedded} />
            </div>
        </ApplicationContext.Provider>
    );

    if (app?.session.embedded) {
        // salesforce?
        const secondaryAuth = sessionStorage.getItem("secondaryAuth");
        if (secondaryAuth) {
            return (
                <SalesforceWrapper embeddedHeight={appContext.embeddedHeight}>
                    {body}
                </SalesforceWrapper>
            );
        }
    }

    return body;
}
