import { Popover, useTheme } from "@mui/material";
import { StyleSheet, css } from "aphrodite";
import { useMemo } from "react";
import { DayPicker } from "react-day-picker";

interface IProps {
    anchorElement?: HTMLElement;
    defaultValue?: Date;
    onClose(): void;
    onSelect(date: Date | undefined): void;
}

export function DatePopoverField(props: IProps) {
    const { anchorElement, defaultValue, onClose, onSelect } = props;
    const theme = useTheme();

    const dayPickerStyles = useMemo(() => {
        return StyleSheet.create({
            main: {
                fontFamily: theme.typography.fontFamily,
            },
            selected: {
                color: "#fff",
                backgroundColor: theme.palette.primary.main,
            },
        });
    }, [theme]);

    return (
        <Popover
            id={"test"}
            open={!!anchorElement}
            anchorEl={anchorElement}
            onClose={onClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
        >
            <div className={css(styles.popoverContainer)}>
                <div className={css(styles.popoverRow)}>
                    <DayPicker
                        mode="single"
                        selected={defaultValue}
                        defaultMonth={defaultValue}
                        onSelect={onSelect}
                        className={css(dayPickerStyles.main)}
                        modifiersClassNames={{
                            selected: css(dayPickerStyles.selected),
                        }}
                    />
                </div>
            </div>
        </Popover>
    );
}

const styles = StyleSheet.create({
    popoverContainer: {
        display: "flex",
        flexDirection: "column",
    },
    popoverRow: {
        display: "flex",
    },
});
