import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import { Chip, IconButton } from "@mui/material";
import { StyleSheet, css } from "aphrodite";
import { useMemo } from "react";
import { IReferenceValue } from "src/pi/services/DataService";
import { AutocompleteBaseProps } from "./AutocompleteBase";

interface IProps extends AutocompleteBaseProps {
    onOpen?: React.MouseEventHandler;
    onClear?: () => void;
}

export function AutocompleteReadonly(props: IProps) {
    const { label, value, onClear, onOpen, selectedMap } = props;

    const mappedValue = useMemo(() => {
        if (!value) return [];

        let tmp: IReferenceValue[] = [];

        if (Array.isArray(value)) {
            value.forEach((id) => {
                const found = selectedMap[id];
                if (!!found) {
                    tmp.push(found);
                } else {
                    tmp.push({ id, value: "" });
                }
            });
        }

        return tmp;
    }, [value, selectedMap]);

    return (
        <div>
            <div className={css(styles.chipContainer)}>
                {!!mappedValue?.length && <label className={css(styles.labelText)}>{label}</label>}
                <div className={css(styles.chipRow)}>
                    <div className={css(styles.chipList)} onClick={onOpen}>
                        {!!mappedValue?.length ? (
                            <div className={css(styles.chips)}>
                                {mappedValue.length < 5 ? (
                                    mappedValue.map((x, i) => {
                                        return (
                                            <Chip
                                                key={`value-${x.id}-${i}`}
                                                label={x.value}
                                                sx={{ marginRight: 1, marginBottom: 1, cursor: "pointer", minWidth: 60 }}
                                            />
                                        );
                                    })
                                ) : (
                                    <Chip label={`${mappedValue.length} selected`} sx={{ marginRight: 1, marginBottom: 1, cursor: "pointer" }} />
                                )}
                            </div>
                        ) : (
                            <div className={css(styles.placeholderText)}>{label}</div>
                        )}
                    </div>
                    {!!mappedValue?.length && (
                        <IconButton sx={{ height: 28, width: 28 }} onClick={onClear}>
                            <CloseIcon sx={{ height: 24, width: 24 }} />
                        </IconButton>
                    )}
                    <IconButton sx={{ height: 28, width: 28 }} onClick={onOpen}>
                        <ArrowDropDownIcon sx={{ height: 24, width: 24 }} />
                    </IconButton>
                </div>
            </div>
        </div>
    );
}

const styles = StyleSheet.create({
    chipContainer: {
        backgroundColor: "rgba(0, 0, 0, 0.06)",
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        borderBottomColor: "rgba(0, 0, 0, 0.87)",
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        minHeight: 56,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        paddingLeft: 12,
        paddingRight: 8,
        maxHeight: 300,
        overflowY: "auto",
        overflowX: "hidden",
    },
    chipRow: {
        display: "flex",
    },
    chipList: {
        width: "100%",
    },
    chips: {
        marginTop: 4,
        marginBottom: 6,
    },
    placeholderText: {
        color: "rgba(0, 0, 0, 0.6)",
        fontSize: 16,
    },
    labelText: {
        color: "rgba(0, 0, 0, 0.38)",
        fontSize: 12,
        marginTop: 8,
        marginLeft: 6,
    },
});
