import AccountIcon from "@mui/icons-material/AccountCircle";
import AddIcon from "@mui/icons-material/Add";
import CalendarIcon from '@mui/icons-material/CalendarMonth';
import EditIcon from "@mui/icons-material/Edit";
import FlashOn from "@mui/icons-material/FlashOn";
import DownloadIcon from "@mui/icons-material/GetApp";
import TagIcon from '@mui/icons-material/LocalOffer';
import MenuIcon from "@mui/icons-material/Menu";
import MoreIcon from "@mui/icons-material/MoreVert";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PrintIcon from "@mui/icons-material/Print";
import UploadIcon from "@mui/icons-material/Publish";
import RefreshIcon from "@mui/icons-material/Refresh";
import RemoveIcon from "@mui/icons-material/Remove";
import SaveIcon from "@mui/icons-material/Save";
import SearchIcon from "@mui/icons-material/Search";
// import SyncIcon from '@mui/icons-material/Sync';
// import DesignIcon from "@mui/icons-material/DesignServices";
// import DesignIcon from "@mui/icons-material/ModeEdit";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CampaignIcon from '@mui/icons-material/Campaign';
import CancelIcon from '@mui/icons-material/Cancel';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import DeleteIcon from '@mui/icons-material/Delete';
import EmailIcon from '@mui/icons-material/Email';
import EventIcon from '@mui/icons-material/Event';
import ExpandIcon from '@mui/icons-material/Expand';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import PhoneIcon from '@mui/icons-material/Phone';
import PlaceIcon from '@mui/icons-material/Place';
import SmsIcon from '@mui/icons-material/Sms';
import TaskIcon from '@mui/icons-material/Task';
import AgendaIcon from '@mui/icons-material/ViewAgenda';
import ReminderIcon from '@mui/icons-material/WatchLater';
import MapIcon from '@mui/icons-material/SouthAmerica';
import CardIcon from '@mui/icons-material/ViewAgenda';
import GridIcon from '@mui/icons-material/ViewList';
import ViewIcon from '@mui/icons-material/Wysiwyg';

import { default as DesignIcon, default as SettingsIcon } from "@mui/icons-material/Settings";

export function NamedIcon({ icon }: { icon?: string; }) {
    if (!icon) return null;

    // for now assumes 24px for some reason :(
    if (icon.startsWith("/icons") || icon.startsWith("https://")) return <img src={icon} width={24} alt={icon}/>;

    switch (icon) {
        case "Account": return <AccountIcon />;
        case "Menu": return <MenuIcon />;
        case "More": return <MoreIcon />;
        case "Notifications": return <NotificationsIcon />;
        case "Refresh": return <RefreshIcon />;
        case "Save": return <SaveIcon />;
        case "Search": return <SearchIcon />;
        case "Settings": return <SettingsIcon />;
        case "Action": return <FlashOn />;
        case "Add": return <AddIcon />;
        case "Edit": return <EditIcon />;
        case "Remove": return <RemoveIcon />;
        case "Download": return <DownloadIcon />;
        case "Upload": return <UploadIcon />;
        case "Print": return <PrintIcon />;
        case "Design": return <DesignIcon />;
        case "Tag": return <TagIcon />;
        case "Calendar": return <CalendarIcon />;
        case "Agenda": return <AgendaIcon />;
        case "Sms": return <SmsIcon />;
        case "Email": return <EmailIcon />;
        case "Call": return <PhoneIcon />;
        case "ComposeSMS": return <PermPhoneMsgIcon />;
        case "Contact": return <ContactPageIcon />;
        case "CalendarEvent": return <EventIcon />;
        case "Money": return <AttachMoneyIcon />;
        case "Campaign": return <CampaignIcon />;
        case "Delete": return <DeleteIcon />;
        case "Cancel": return <CancelIcon />;
        case "MyLocation": return <MyLocationIcon />;
        case "Place": return <PlaceIcon />;
        case "Expand": return <ExpandIcon />;
        case "Task": return <TaskIcon />;
        case "Reminder": return <ReminderIcon />;
        case "Map": return <MapIcon/>;
        case "Card": return <CardIcon/>;
        case "Grid": return <GridIcon/>;
        case "View": return <ViewIcon/>;
    }

    return null;
}
