import { createRoot } from "react-dom/client";
import { AppComponent } from "./AppComponent";
import "./index.css";

const container = document.getElementById("root");
if (container) {
    const root = createRoot(container);
    root.render(<AppComponent />);

} else {
    alert("missing root element");
}
