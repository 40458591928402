import { observable } from "mobx";

export interface IDictionary<T> {
    set(name: string, value: T, context?: string): void;
    assign(name: string, value: T, context?: string): T;

    get(name: string, context?: string): T | undefined;
}

export class Dictionary<T> implements IDictionary<T> {
    private data: object;

    static resolve(key: string, context: string | undefined): string {
        if (key.startsWith(".")) return key.slice(1);
        return context ? `${context}.${key}` : key;
    }

    constructor() {
        this.data = {};
    }

    set(name: string, value: T, context?: string): T | undefined {
        let key = Dictionary.resolve(name, context);
        return this.merge(key, value, false);
    }

    merge(key: string, value: T, merge: boolean): T {
        // console.log(`trying to set ${key} to ${value}`);
        let keys = key.split(".");
        var obj: { [key: string]: any } = this.data;

        for (var c = 0; c < keys.length - 1; c++) {
            const token = keys[c];
            if (token === null || token.length === 0) continue;

            let found = obj[token];
            if (!found) {
                obj = obj[token] = observable({});
                continue;
            }

            switch (typeof found) {
                case "object":
                    obj = found;
                    break;
                default:
                    console.log(`${key}: ${key[c]} is not an object, can't assign`);
                    throw new Error(`${key}: ${key[c]} is not an object, can't assign`);
            }
        }

        var prev = obj[keys[keys.length - 1]];

        if (!value) {
            // delete obj[keys[keys.length - 1]];
            obj[keys[keys.length - 1]] = null;
        } else {
            if (merge && prev && typeof value === "object") {
                const obj = value as unknown as { [key: string]: T };
                Object.keys(value).forEach((prop) => (prev[prop] = obj[prop]));
            } else {
                obj[keys[keys.length - 1]] = value;
            }
        }

        return prev;
    }

    assign(name: string, value: T, context?: string): T {
        let key = Dictionary.resolve(name, context);
        return this.merge(key, value, true);
    }

    get(name: string, context?: string): T | undefined {
        var key = Dictionary.resolve(name, context);
        let keys = key.split(".");
        var obj = this.data as { [key: string]: T };

        for (var c = 0; c < keys.length - 1; c++) {
            if (keys[c] in obj) {
                obj = obj[keys[c]] as unknown as { [key: string]: T };
                continue;
            }

            return undefined;
        }

        return typeof obj === "object" ? obj[keys[keys.length - 1]] : undefined;
    }
}
