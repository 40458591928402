import moment from "moment";
import { TimeSlot } from "@crochik/pi-api";

export interface IDayPickerConfig {
    disabledDays: Date[];
    selectedDate: Date;
    fromDate: Date;
    slots: TimeSlot[];
    toDate: Date;
    window: TimePickerWindow;
}

export enum TimePickerWindow {
    All = "All",
    Morning = "Morning",
    Afternoon = "Afternoon",
    Evening = "Evening",
}

export function formatDate(date: Date | undefined, long?: boolean) {
    if (!date) {
        return "";
    }
    return moment(date).format(long ? "dddd, MMMM Do" : "ddd, MMM Do");
}

export function formatTime(date: Date | undefined, timeZone?: string) {
    if (!date) {
        return "";
    }

    if (timeZone) {
        return moment(date).tz(timeZone).format("hh:mm a");
    }

    return moment(date).format("hh:mm a");
}

export function getDaysArray(start: Date, end: Date) {
    for (var arr: Date[] = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
        arr.push(new Date(dt));
    }
    return arr;
}

export function getIsSameDay(date1: Date | undefined, date2: Date | undefined, timeZone?: string) {
    if (!date1 || !date2) return false;

    if (timeZone) {
        return moment(date1).utc().tz(timeZone).isSame(moment(date2).utc().tz(timeZone), "day");
    }

    return moment(date1).isSame(moment(date2), "day");
}

export function formatDateTimeShort(date: Date | undefined, timeZone?: string) {
    if (!date) {
        return "";
    }

    if (timeZone) {
        return moment(date).utc().tz(timeZone).format("ddd, MMM D, h:mm a");
    }

    return moment(date).format("ddd, MMM D, h:mm a");
}

export function getDayPickerConfig(slots: TimeSlot[], window: TimePickerWindow, currentlySelectedDate?: Date) {
    // Filter slots by selected window
    const selectedWindowSlots = slots.filter((timeSlot) => {
        const date = moment(timeSlot.start);
        if (!date) {
            return false;
        }

        const hours = date.hours();
        const minutes = date.minutes();
        if (window === TimePickerWindow.All) {
            return true;
        } else if (window === TimePickerWindow.Morning) {
            return hours < 12 || (hours === 12 && minutes === 0);
        } else if (window === TimePickerWindow.Afternoon) {
            return (hours === 12 && minutes === 30) || (hours > 12 && hours < 16) || (hours === 16 && minutes === 0);
        } else if (window === TimePickerWindow.Evening) {
            return (hours === 16 && minutes === 30) || hours > 16;
        }
        return false;
    });
    // Calculate which days are disabled
    const fromDate = new Date();
    const toDate = !!selectedWindowSlots?.length ? selectedWindowSlots[selectedWindowSlots.length - 1].start || new Date() : new Date();
    const daysArray = getDaysArray(fromDate, toDate);
    const disabledDays: Date[] = [];
    const validDays: Date[] = [];
    let newSelectedDate = currentlySelectedDate;
    daysArray.forEach((day) => {
        const dayHasSlots = selectedWindowSlots.some((slot) => getIsSameDay(day, slot?.start));
        if (!dayHasSlots) {
            disabledDays.push(day);
            if (!!newSelectedDate && getIsSameDay(newSelectedDate, day)) {
                newSelectedDate = undefined;
            }
        } else {
            validDays.push(day);
        }
    });
    const firstValidDay = !!validDays?.length ? validDays[0] : fromDate;
    // Create config
    const config: IDayPickerConfig = {
        slots: selectedWindowSlots,
        fromDate,
        toDate,
        disabledDays,
        selectedDate: newSelectedDate || firstValidDay,
        window,
    };
    return config;
}
