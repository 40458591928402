import * as React from "react";
import { CSSProperties, useCallback } from "react";
import * as api from "@crochik/pi-api";
import { Paper, Typography, useTheme } from "@mui/material";
import { css, StyleSheet } from "aphrodite";

export function ChatMessageComponent({ message, onClick, selected }: {
    message: api.ResolvedChatMessage,
    selected: boolean,
    onClick?: (message: api.ResolvedChatMessage) => void
}) {
    const theme = useTheme();
    const style: CSSProperties = message.role === api.ChatRole.Assistant ?
        {
            backgroundColor: selected ? theme.palette.primary.light : theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            cursor: !!onClick ? "pointer" : undefined
        } :
        (message.role === api.ChatRole.User ? {} : {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText
        });

    const click = useCallback(() => {
        if (message.role === api.ChatRole.Assistant) {
            onClick?.(message);
        }
    }, [message]);

    let text = message.prompt!;
    return (
        <Paper className={css(styles[message.role!.toString()])} style={style} onClick={click}
               elevation={selected ? 6 : 2}>
            <Typography variant={"subtitle2"}>{text}</Typography>
        </Paper>
    );
}

export function ChatMessagesComponent({ messages, onClick, selected }: {
    messages: api.ResolvedChatMessage[],
    selected?: api.ResolvedChatMessage,
    onClick?: (message: api.ResolvedChatMessage) => void
}) {
    return (
        <div style={{ display: "flex", flexDirection: "column", padding: 12 }}>
            {messages.map((x, i) => {
                    return x.role === api.ChatRole.System ? null :
                        <div style={{ paddingTop: 12, paddingBottom: 12 }} key={`${x.prompt}_${i}`}>
                            <ChatMessageComponent selected={x === selected} message={x} onClick={onClick}
                                                  key={`${x.role}${i}`} />
                        </div>;
                }
            )}
        </div>
    );
}

const styles = StyleSheet.create({
    Assistant: {
        marginRight: "10%",
        padding: 12,
        borderRadius: 12
    },
    User: {
        marginLeft: "10%",
        padding: 12,
        borderRadius: 12
    },
    System: {
        // backgroundColor: "white",
        padding: 12
        // border: "2px solid black",
    }
});