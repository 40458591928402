import SearchIcon from "@mui/icons-material/Search";
import { IconButton } from "@mui/material";
import MuiTextField from "@mui/material/TextField";
import * as React from "react";
// import ClearIcon from '@mui/icons-material/Clear';
import ClearIcon from "@mui/icons-material/HighlightOff";

export interface SearchProps {
    value?: string;
    label?: string;
    placeholder?: string;
    collapsible?: boolean;
    disabled?: boolean;

    onChange?: (value: string) => void;
    onSearch?: (value?: string) => void;
    style?: React.CSSProperties;
    variant?: "standard" | "filled" | "outlined";
}

interface State {
    value?: string;
    open: boolean;
}

export class SearchInput extends React.Component<SearchProps, State> {
    constructor(props: SearchProps) {
        super(props);

        this.state = {
            value: props.value,
            open: !props.collapsible || (!!props.value && props.value.length > 1),
        };
    }

    componentDidUpdate(prevProps: SearchProps) {
        if (this.props.value !== prevProps.value) {
            this.setState({
                value: this.props.value,
                open: !this.props.collapsible || (!!this.props.value && this.props.value.length > 1),
            });
        }
    }

    onChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const value = e.target.value;
        if (this.props.onChange) {
            this.props.onChange(value);
        } else {
            this.setState({
                value,
            });
        }
    };

    onKeyPress = (event: React.KeyboardEvent) => {
        if (event.key !== "Enter") return;

        const { onSearch } = this.props;
        const { value } = this.state;
        if (onSearch) onSearch(value);
    };

    toggleSearch = () => {
        const { onSearch, value, collapsible } = this.props;
        const { open } = this.state;
        if (!open) {
            this.setState({
                open: true,
            });
            return;
        }

        if (!!value && value.length > 0) {
            this.setState(
                {
                    value: undefined,
                },
                () => !!onSearch && onSearch(undefined)
            );
        } else {
            if (this.state.value && this.state.value.length > 0) {
                !!onSearch && onSearch(this.state.value);
            } else if (collapsible) {
                this.setState({
                    open: false,
                });
            }
        }
    };

    render() {
        const { label, placeholder, value, collapsible } = this.props;
        const { open } = this.state;

        const style: React.CSSProperties = {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            ...this.props.style,
        };

        const adornment = (
            <IconButton onClick={this.toggleSearch} color={open ? "primary" : "default"}>
                {value && value.length ? <ClearIcon /> : <SearchIcon />}
            </IconButton>
        );

        return (
            <div style={style}>
                {open ? (
                    <MuiTextField
                        variant="filled"
                        label={label}
                        placeholder={placeholder}
                        type="text"
                        autoFocus={(!!collapsible && open) || !collapsible}
                        value={this.state.value || ""}
                        onChange={this.onChange}
                        onKeyPress={this.onKeyPress}
                        InputProps={{ endAdornment: adornment }}
                        InputLabelProps={{ shrink: true }}
                        disabled={this.props.disabled}
                    />
                ) : (
                    adornment
                )}
            </div>
        );
    }
}
