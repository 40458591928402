import { TextField, TextFieldProps } from "@mui/material";
import { runInAction } from "mobx";
import React, { createRef } from "react";
import { Form } from "../../Form";
import "./AddressField.css";
import { FieldProps } from "./FieldProps";
import { Loader } from "@googlemaps/js-api-loader";

interface IAddressFieldProps extends FieldProps<string> {
    form?: Form;
}

export class AddressField extends React.Component<IAddressFieldProps> {
    private readonly autocompleteRef: React.RefObject<HTMLInputElement>;

    constructor(props: IAddressFieldProps) {
        super(props);

        this.autocompleteRef = createRef<HTMLInputElement>();
    }

    componentDidMount() {
        this.initPlaceAPI();
    }

    private initPlaceAPI() {
        const ref = this.autocompleteRef?.current;
        if (!ref) return;

        const loader = new Loader({
            apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
            libraries: ["places"]
        });

        loader
            .load()
            .then((google) => {
                console.log('loaded', google.maps.places);
                if (!google?.maps?.places?.Autocomplete) {
                    console.error("can't use library: google?.maps?.places?.Autocomplete");
                    return;
                }

                const options: google.maps.places.AutocompleteOptions = {
                    componentRestrictions: { country: ["us", "ca"] },
                    fields: ["address_components", "name", "geometry/location"],
                    types: ["address"]
                };

                const autocomplete = new google.maps.places.Autocomplete(ref, options);
                const callback = this.onAutoComplete;
                new (google.maps.event.addListener as any)(autocomplete, "place_changed", () => {
                    const place = autocomplete.getPlace();
                    callback(place);
                });
            })
            .catch(e => {
                // do something
                console.error("failed to load google maps library", e)
            });
    }

    protected onChange = (event: any) => {
        let value: string | null = event.target.value;
        if (value?.trim() === "") {
            value = null;
        }

        if (this.props.onChange) {
            this.props.onChange(this.props.field, value);
        }
    };

    private onAutoComplete = (place: google.maps.places.PlaceResult) => {
        const { onChange, field, form } = this.props;

        runInAction(() => {
            onChange?.(field, place.name || null);
            if (!form) return;

            const setValue = (fieldName, value) => {
                const field = form.fieldMap[fieldName];
                if (field) {
                    form.setValue(field, value);
                } else {
                    console.error(`did not find field: ${fieldName}`);
                }
            };

            if (!place?.address_components?.length) return;

            console.log("place", place);

            if (place.geometry?.location) {
                const lat = place.geometry.location.lat();
                const lng = place.geometry.location.lng();
                console.log("Location", lat, lng);

                setValue("Location", {
                    type: "Point",
                    coordinates: [lng, lat]
                });
            }

            place.address_components.forEach((component) => {
                if (!!component?.types?.length) {
                    component.types.forEach((type) => {
                        if (type === "locality") {
                            setValue("City", component.short_name);
                        } else if (type === "administrative_area_level_1") {
                            setValue("State", component.short_name);
                        } else if (type === "country") {
                            setValue("Country", component.short_name);
                        } else if (type === "postal_code") {
                            setValue("PostalCode", component.short_name);
                        }
                    });
                }
            });
        });
    };

    protected onKeyPressed = (event: any) => {
        if (event.key === "Enter" && this.props.onEnterPressed) {
            if (this.props.onEnterPressed(this.props.field)) {
                event.preventDefault();
            }
        }
    };

    render() {
        const field = this.props.field;

        const props: TextFieldProps = {
            required: field.isRequired,
            id: field.name!,
            label: field.label ?? field.name!,
            disabled: this.props.disabled,
            error: this.props.error ? true : false,
            value: this.props.value ?? "",
            onChange: this.onChange,
            onKeyPress: this.onKeyPressed,
            helperText: this.props.error ? this.props.error : null,
            margin: "normal",
            style: {
                ...this.props.field.style,
                ...this.props.style
            },
            autoFocus: this.props.autoFocus,
            InputLabelProps: { shrink: true },
            fullWidth: true,
            multiline: false,
            rows: 1
        };

        return <TextField inputRef={this.autocompleteRef} variant="filled" {...props} />;
    }
}
