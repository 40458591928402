import { toJS } from "mobx";
import App from "src/pi/application/App";
import { Default } from "src/pi/context/AppContext";
import * as API from "../services/API";

import { FieldMapperConfig, LeadType, LeadTypeSettings } from "@crochik/pi-api";
import { State as MappingState } from "../pages/OldFieldsMapping/Mapping";

// async function exec() {
//     try {
//         var leadType = await API.createLeadType('New Lead Source');
//         App().selectPage(`LeadType=${leadType.id}`, '', { id: leadType.id });

//     } catch (ex) {
//         Dialog.critical({
//             title: 'Error',
//             message: 'Failed to create new Lead Source'
//         });
//     }
// }

// export function add() {
//     Dialog.confirm({
//         title: 'New Lead Source',
//         message: 'Create a new lead source for your organization?',
//         onPositive: exec,
//         positiveCaption: 'Yes',
//         negativeCaption: 'No',
//     });
// }

async function reloadAsync(id: string) {
    // console.log("reload page");
    await App().selectPageAsync(`LeadType(${id})`, null, { id }, 0);
}

export async function saveMapping(context: string) {
    await App().selectPageAsync();

    var state: MappingState = Default.state.get(context);

    try {
        var src = toJS(state);
        if (!src || !src.settings || !src.fields) {
            throw new Error("Nothing to save");
        }

        var fields: FieldMapperConfig[] = [];
        for (var field of src.fields) {
            if (field.name && field.source) {
                fields.push(field);
            }
        }

        const settings = {
            ...src.settings,
            fields,
        };

        await API.saveLeadTypeSettings(state.leadTypeId, settings);

        // TODO: update state?
        // ...
    } catch (ex) {
        console.error(ex);
    }

    window.setTimeout(() => reloadAsync(state.leadTypeId), 100);
}

export async function saveLeadTypeSettings(context: string, args: any[]) {
    try {
        const id = args[0] as string;
        const settings = args[1] as LeadTypeSettings;
        await API.saveLeadTypeSettings(id, settings);
    } catch (ex) {
        console.error(ex);
    }
}

// function execDelete(id: string) {
//     API.deleteLeadType(id)
//         .then(async (lt) => {
//             Dialog.inform({ title: "Success", message: `Successfully deleted Lead Source: ${lt.name}` });
//             await App().selectPageAsync("LeadTypes");
//         })
//         .catch((ex) => Dialog.critical({ title: "Error", message: "Failed to delete Lead Source" }));
// }

// export async function deleteLeadType(context: string) {
//     console.log("delete lead type");
//
//     var record: LeadType = Default.state.get(context);
//     if (!record.id) {
//         Promise.reject("missing args");
//         return;
//     }
//
//     let id = record.id;
//
//     Dialog.confirm({
//         title: "Delete Lead Source",
//         message: "All Leads for this source will also be deleted. Continue?",
//         onPositive: () => execDelete(id),
//         positiveCaption: "Yes",
//         negativeCaption: "No",
//     });
// }

// export async function save(context: string) {
//     console.log("save lead type");
//
//     var record: LeadType = Default.state.get(context);
//     if (!record.id) {
//         Promise.reject("missing args");
//         return;
//     }
//
//     try {
//         record = await API.updateLeadType(record.id, record.name!, record.flowId!);
//         Default.state.assign(context, record);
//
//         Dialog.inform({ title: "Success", message: "Lead Source saved successfully." });
//     } catch (ex) {
//         console.error(ex);
//         Dialog.critical({
//             title: "Error",
//             message: "Failed to update Lead Source",
//         });
//     }
// }
