import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { Alert, Grid, Paper, Typography } from "@mui/material";
import { FormBody } from "../material/FormBody/FormBody";
import { Form } from "../Form";
import * as api from "@crochik/pi-api";
import { ChatRole, FIELDTYPE, FormAction, TextField, TextFieldOptions } from "@crochik/pi-api";
import { FormActions } from "../material/FormActions";
import { Action } from "../../context/IForm";
import App from "../../application/App";
import { ChatMessagesComponent } from "./ChatMessageComponent";
import { Loading } from "../material/Loading";
import TypingIndicator from "../material/TypingIndicator";

interface IProps {
    id?: string;
    args?: object;
}

export function MessageContent({ message }: { message: api.ResolvedChatMessage }) {
    let text = message.content!;
    return (
        <div style={{ padding: 24, width: "100%", height: "100%", overflow: "auto" }}>
            {text?.split("\n").map((line, i) => <Typography key={`${line}${i}`}>{line}<br /></Typography>)}
        </div>
    );
}

export default function GenAIContent(props: IProps) {
    const { id } = props;
    const [chat, setChat] = useState<api.ChatContentResponse>();
    const [lastMessage, setLastMessage] = useState<api.ResolvedChatMessage>();
    const [contentMessage, setContentMessage] = useState<api.ResolvedChatMessage>();
    const [error, setError] = useState<string>();
    const [busy, setBusy] = useState(false);
    const [count, setCount] = useState<number>(0);

    const fieldOptions: TextFieldOptions = {
        t: "TextFieldOptions",
        multline: true
    };

    const field: TextField = {
        t: FIELDTYPE.Text,
        type: FIELDTYPE.Text,
        options: fieldOptions,
        name: "Prompt",
        label: "Reply",
        isRequired: true
    };

    const action: FormAction = {
        name: "Send",
        enable: [
            "Prompt"
        ]
    };

    const form = Form.create({
        name: "GenAIContent",
        fields: [
            field
        ],
        actions: [
            action
        ]
    }, null);

    useEffect(() => {
        setContentMessage(lastMessage);
    }, [lastMessage]);

    useEffect(() => {
        if (!chat) {
            setBusy(true);
            setLastMessage(undefined);
            return;
        }

        if (chat.messages?.length! > 0) {
            const last = chat.messages![chat.messages!.length! - 1];
            setLastMessage(last.role === api.ChatRole.Assistant ? last : undefined);
        } else {
            setLastMessage(undefined);
        }

        setBusy(!!chat.isBusy);
    }, [chat]);

    const load = useCallback((id: string) => {
        new api.ContentApi(App().apiConfig)
            .contentGetChatContent(id)
            .then(x => {
                setChat(x);
            })
            .catch(e => {
                console.error(e);
                setError("Failed to load Chat");
            });
    }, []);

    useEffect(() => {
        if (!id) {
            setError("Missing Chat");
            return;
        }
        load(id);
    }, [load, id]);

    useEffect(() => {
        if (!!count && count >= 60) {
            console.log("stop timer");
            setError("Failed to generate completion.");
            return;
        }

        console.log("start timer");
        if (busy) {
            setTimeout(checkStatus, 1000);
        } else {
            setCount(0)
        }
    }, [count, busy]);

    const checkStatus = () => {
        console.log(`check status: ${count}`);

        new api.ContentApi(App().apiConfig)
            .contentGetChatContent(id!)
            .then(x => {
                if (x.isBusy) {
                    console.log("still busy");
                    setCount(count + 1);
                    return;
                }

                console.log("finished");
                setChat(x);
            })
            .catch(e => {
                console.error(e);
                setError("Failed to load Chat");
            });
    };

    const onAction = async (action: Action) => {
        const message = form.getValue("Prompt");

        if (!chat) return;

        setBusy(true);

        chat.messages?.push({
            prompt: message,
            role: ChatRole.User
        });

        chat.isBusy = true;

        setChat(chat);

        new api.ContentApi(App().apiConfig)
            .contentAddUserMessageToChat(id!, {
                message: message
            })
            .then(x => {
                setChat(x);
            })
            .catch(e => {
                console.error(e);
                setError("Failed to load Chat");
            });
    };

    if (error) return <Alert severity="error">{error}</Alert>;
    if (!chat) return <Loading />;

    return (
        <div style={{
            height: "100%",
            position: "relative",
            padding: "24"
        }}>
            <Grid container={true} spacing={0} style={{ height: "100%", width: "100%", padding: 12 }}>
                <Grid item={true} lg={8} sm={6} xs={12} style={{ height: "100%", width: "100%", padding: 12 }}>
                    {contentMessage &&
                        <Paper style={{ height: "100%", width: "100%" }}>
                            <MessageContent message={contentMessage} />
                        </Paper>
                    }
                </Grid>
                <Grid item={true} lg={4} sm={6} xs={12} style={{ height: "100%" }}>
                    <div style={{
                        height: "calc(100% - 212px)",
                        overflow: "auto"
                    }}>
                        <ChatMessagesComponent messages={chat.messages!} onClick={setContentMessage}
                                               selected={contentMessage} />
                        {busy && <TypingIndicator />}
                    </div>

                    <Paper style={{ height: "200px", width: "100%", overflow: "auto", padding: "12px" }}>
                        {
                            <div style={{ position: "relative" }}>
                                {busy && <TypingIndicator />}
                                {!busy && lastMessage?.role === api.ChatRole.Assistant && !!lastMessage.prompt &&
                                    <div><Typography color="primary"
                                                     variant="subtitle1">{lastMessage.prompt}</Typography></div>
                                }
                                <FormBody form={form} />
                                <FormActions form={form} align="right" color="primary"
                                             onActionAsync={onAction} />

                                {busy && <div style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    background: "white",
                                    opacity: .2
                                }}></div>}

                            </div>
                        }
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}



