// import * as React from 'react';

import { Default } from "src/pi/context/AppContext";
import { Service } from "src/pi/context/Services";
import ErrorService from "src/pi/services/ErrorService";
import { RequestError } from "../../pi/api/Client";

export enum ExceptionType {
    Other,
}

export class Exception extends Service {
    raise(type: ExceptionType, e?: any, other?: any) {
        var msg = e instanceof RequestError ? `Error: ${e.error}: ${e.status}` : e;
        var title = `Exception ${type}`;
        ErrorService().message(msg, true, title, undefined);
    }
}

class ExceptionImpl extends Service {
    raise(type: ExceptionType, e?: any, other?: any) {
        var title;
        var msg;
        var critical = true;
        var onClose: (() => any) | undefined = undefined;

        switch (type) {
            default:
                title = "Unexpected Error";
                msg = "Unexpected error";
                break;
        }

        ErrorService().message(msg, critical, title, onClose);
    }
}

export function register() {
    Default.services.register(Exception, new ExceptionImpl());
}

export default function get(): Exception {
    return Default.services.getService(Exception);
}
