import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Checkbox, FormControl, FormControlLabel, IconButton, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent, Switch } from "@mui/material";
import { StyleSheet, css } from "aphrodite";
import * as React from "react";
import { CheckboxFieldOptions, CheckboxFieldOptionsStyle } from "@crochik/pi-api";
import { FieldProps } from "./FieldProps";

interface Props extends FieldProps { }

export class CheckboxField extends React.Component<Props> {
    onChange = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (this.props.onChange) {
            this.props.onChange(this.props.field, checked);
        }
    };

    onChangeClearable = (event: SelectChangeEvent<any>) => {
        if (!this.props.onChange) return;

        let value: boolean | undefined = undefined;
        switch (event.target.value) {
            case "true":
                value = true;
                break;
            case "false":
                value = false;
                break;
            case "#any":
                value = undefined;
                break;
        }

        this.props.onChange(this.props.field, value);
    };

    onClear = (e: React.MouseEvent) => {
        if (this.props.onChange) {
            this.props.onChange(this.props.field, undefined);
        }
    };

    renderCheckbox() {
        const { style, value } = this.props;
        return <Checkbox style={style} checked={value ?? false} onChange={this.onChange} />;
    }

    renderToggle() {
        const { style, value } = this.props;
        return <Switch style={style} checked={value ?? false} onChange={this.onChange} />;
    }

    renderClearButton() {
        const { value } = this.props;
        if (value !== undefined) {
            return (
                <IconButton aria-label="clear" onClick={this.onClear} edge="end" className={css(styles.clearButton)}>
                    <CloseIcon />
                </IconButton>
            );
        }
        return null;
    }

    renderDropdown() {
        const { field, disabled, value } = this.props;
        const { isRequired } = field;
        const label = field.label || field.name;
        const stringValue = value !== undefined ? (value === true ? "true" : "false") : "#none";
        return (
            <FormControl variant="filled" sx={{ width: "100%" }} disabled={disabled}>
                <InputLabel>{label}</InputLabel>
                <Select
                    label={label}
                    variant="filled"
                    // endAdornment={this.renderClearButton()}
                    value={stringValue}
                    onChange={this.onChangeClearable}
                >
                    <MenuItem value={"true"}>
                        <div className={css(styles.menuItem)}>
                            <CheckCircleIcon sx={{ marginRight: 1 }} />
                            <ListItemText>{label}</ListItemText>
                        </div>
                    </MenuItem>
                    <MenuItem value={"false"}>
                        <div className={css(styles.menuItem)}>
                            <HighlightOffIcon sx={{ marginRight: 1 }} />
                            <ListItemText>{label}</ListItemText>
                        </div>
                    </MenuItem>
                    {!isRequired && (
                        <MenuItem value={"#any"}>
                            <div className={css(styles.menuItem)}>
                                <ListItemText>-- Any --</ListItemText>
                            </div>
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
        );
    }

    renderControl() { }

    render() {
        const { field, disabled } = this.props;
        const label = field.label || field.name;
        const checkboxOptions: CheckboxFieldOptions | undefined = field.options ?? undefined;

        switch (checkboxOptions?.style) {
            case CheckboxFieldOptionsStyle.Toggle:
                return (
                    <div className={css(styles.container)}>
                        <FormControlLabel disabled={disabled} control={this.renderToggle()} label={label} />
                    </div>
                );

            case CheckboxFieldOptionsStyle.Dropdown:
                return <div className={css(styles.container)}>{this.renderDropdown()}</div>;

            default:
                return (
                    <div className={css(styles.container)}>
                        <FormControlLabel disabled={disabled} control={this.renderCheckbox()} label={label} />
                    </div>
                );
        }
    }
}

const styles = StyleSheet.create({
    container: {
        paddingTop: "12px",
        paddingBottom: "12px",
        width: "100%",
    },
    clearButton: {
        position: "absolute",
        right: 40,
    },
    menuItem: {
        display: "flex",
        alignItems: "center",
    },
});
