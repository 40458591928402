import { computed, observable } from "mobx"; // , autorun, action
import { Default } from "../context/AppContext";
import { evaluate } from "../context/Expression";
import { IActionMenuItem, IMenu, IMenuItem, IPageMenuItem } from "../context/IMenu";

export abstract class MenuItem {
    _data: IMenuItem;

    get name(): string {
        return this._data.name ?? "[no-name]";
    }

    get label(): string {
        return this._data.label || this.name;
    }

    @computed
    get isEnabled(): boolean {
        // return Default.state.evaluate(this._data.enable, 'app');
        return evaluate(this._data.enable, "app");
    }

    @computed
    get isVisible(): boolean {
        // return Default.state.evaluate(this._data.visible, 'app');
        return evaluate(this._data.visible, "app");
    }

    constructor(data: IMenuItem) {
        this._data = data;
    }
}

export class ActionMenuItem extends MenuItem {
    get data() {
        return this._data as IActionMenuItem;
    }
    get action() {
        return this.data.action;
    }
}

export class PageMenuItem extends MenuItem {
    get data() {
        return this._data as IPageMenuItem;
    }
    get page(): string {
        return this.data.page ?? "Error";
    }
}

interface MenuState {
    open: boolean;
}

export class Menu extends MenuItem {
    get data() {
        return this._data as IMenu;
    }

    items: MenuItem[];
    _state: MenuState;

    @computed
    get open(): boolean {
        return this._state.open;
    }

    set open(open: boolean) {
        this._state.open = open;
    }

    static get(name: string): Menu {
        return Default.ui.get(name, "menu");
    }

    static create(menu: IMenu): Menu {
        return new Menu(menu);
    }

    constructor(data: IMenu) {
        super(data);

        this.items = [];

        const { items, name } = data;

        if (items) {
            items.forEach((item) => {
                if ("page" in item) {
                    var pageItem = item as IPageMenuItem;
                    // console.log(`page: ${pageItem.page}`);
                    var page = new PageMenuItem(pageItem);
                    this.items.push(page);
                    return;
                }
                if ("action" in item) {
                    var actionItem = item as IActionMenuItem;
                    // console.log(`action: ${actionItem.action}`);
                    var action = new ActionMenuItem(actionItem);
                    this.items.push(action);
                    return;
                }
                if ("items" in item) {
                    var menuItem = item as IMenu;
                    var menu = new Menu(menuItem);
                    this.items.push(menu);
                    return;
                }
                console.error("unexpected menu item", item);
            });
        }

        var state: MenuState = {
            open: !data.collapsible,
        };

        this._state = observable(state);

        if (name) {
            Default.ui.set(name, this, "menu");
            Default.state.set(name, this._state, "menu");
        }
    }

    get collapsible(): boolean {
        return this.data.collapsible ? true : false;
    }
}
