import moment from "moment";
import * as api from "@crochik/pi-api";
import { DayOfWeek } from "@crochik/pi-api";
import App from "../../../pi/application/App";

var _defaultAppointmentType: string | undefined;
var _integrations: api.AppIntegration[] | undefined = undefined;

export interface IGetUserAvailability {
    userId: string;
    appointmentTypeId: string;
    start: Date;
    end: Date;
}

function available(): api.AvailabilityApi {
    return new api.AvailabilityApi(App().apiConfig);
}
function appointmentType(): api.AppointmentTypeApi {
    return new api.AppointmentTypeApi(App().apiConfig);
}
function user(): api.UserApi {
    return new api.UserApi(App().apiConfig);
}
function organization(): api.OrganizationApi {
    return new api.OrganizationApi(App().apiConfig);
}
function identity(): api.IdentityApi {
    return new api.IdentityApi(App().apiConfig);
}
function setting(): api.SettingApi {
    return new api.SettingApi(App().apiConfig);
}
function leadType(): api.LeadTypeApi {
    return new api.LeadTypeApi(App().apiConfig);
}
function integration(): api.IntegrationApi {
    return new api.IntegrationApi(App().apiConfig);
}
function integrationLead(): api.IntegrationLeadApi {
    return new api.IntegrationLeadApi(App().apiConfig);
}

function appConfig(): api.AppConfigApi {
    return new api.AppConfigApi(App().apiConfig);
}

export async function defaultAppointmentType(): Promise<string> {
    if (!_defaultAppointmentType) {
        var appointmentTypes = await getAppointmentTypes();
        if (appointmentTypes.length >= 1) {
            _defaultAppointmentType = appointmentTypes[0].id as string;
        } else {
            // var appointmentType = await appointmentType().appointmentTypePost({
            //     name: 'default'
            // });
            // _defaultAppointmentType = appointmentType().id as string;
            return Promise.reject("No appointment types defined for user");
        }
    }

    return _defaultAppointmentType;
}
export async function getIntegrations(): Promise<api.AppIntegration[]> {
    // cache
    if (!_integrations) {
        _integrations = await integration().integrationGetAll();
    }

    return _integrations;
}

export function getUserIntegrations(): Promise<api.AppIntegration[]> {
    return integration().integrationGetEntityIntegrations();
}
export function getOrgIntegrations(): Promise<api.AppIntegration[]> {
    return integration().integrationGetOrganizationIntegrations();
}
export function getEntityIntegrations(id?: string): Promise<api.AppIntegration[]> {
    return id ? integration().integrationGetIntegrationsForEntity(id) : integration().integrationGetSelectedIntegrations();
}

export function getAppointmentTypeIntegrations(id: string): Promise<api.AppIntegration[]> {
    return integration().integrationGetAppointmentTypeIntegrations(id);
}
export function getLeadTypeIntegrations(id: string): Promise<api.AppIntegration[]> {
    return integration().integrationGetLeadTypeIntegrations(id);
}

export function me(): Promise<api.User> {
    return user().userMe();
}


export function setAppointmentTypeName(appointmentTypeId: string, name: string): Promise<api.AppointmentType> {
    return appointmentType().appointmentTypeUpdateName(appointmentTypeId, name);
}

export function disableScheduler(appointmentTypeId: string): Promise<api.AppointmentType> {
    return appointmentType().appointmentTypeUpdateLeadType(appointmentTypeId);
}

export function setAppointmentTypeSettings(appointmentTypeId: string, settings: api.SchedulingSettings): Promise<api.AppointmentType> {
    return appointmentType().appointmentTypeUpdateSettings(appointmentTypeId, settings);
}

export function setAppConfig(appointmentTypeId: string, config: api.AppConfig): Promise<api.AppConfig> {
    return appConfig().appConfigSetAppConfig(appointmentTypeId, config);
}

export function getPossibleUsers(leadId: string, appointmentTypeId: string): Promise<api.User[]> {
    return user().userGetPossibleUsers(leadId, appointmentTypeId);
}

export function getOrganizations(): Promise<api.Organization[]> {
    return organization().organizationGetOrganizations();
}

export function getOrgUsers(orgId?: string): Promise<api.User[]> {
    if (orgId) {
        return user().userGetOrganizationUsers(orgId);
    }

    return user().userGetMyOrganizationUsers();
}

export function getUsers(): Promise<api.User[]> {
    return user().userGetUsers();
}

export function getMergeCandidates(): Promise<api.MergeUserCandidateMatch[]> {
    return user().userGetMergeCandidates();
}

export function getAppointmentTypes(): Promise<api.AppointmentType[]> {
    return appointmentType().appointmentTypeGet();
}

export function getAvailability(): Promise<api.Availability[]> {
    return available().availabilityAvailability();
}

export async function updateAvailability(
    dayId: DayOfWeek,
    startMinutes: number,
    durationMinutes: number,
    appointmentTypeIds: string[],
    id?: string
): Promise<api.Availability> {
    var avail: api.Availability = {
        id,
        dayId,
        startMinutes,
        durationMinutes,
        appointmentTypeIds,
    };

    var rows = await available().availabilityPutAvailability([avail]);
    if (rows.length !== 1) throw new Error("Unexpected number of rows");
    return rows[0];
}

export function deleteAvailability(id: string): Promise<void> {
    return available().availabilityDeleteAvailability_2(id);
}

export async function getOpenSlots(): Promise<api.TimeSlot[]> {
    var appointmentType = await defaultAppointmentType();
    return available().availabilityAllSlots(appointmentType, moment().startOf("day").toDate(), moment().startOf("day").add("days", 14).toDate());
}

export async function getCalendar(args: IGetUserAvailability): Promise<api.EntityOpenSlots> {
    const calendar = await available().availabilityUserAvailability(args.userId, args.appointmentTypeId, args.start, args.end);

    calendar.events = dedup(calendar.events);

    return calendar;
}

export function dedup(events: api.CalendarEvent[] | undefined | null): api.CalendarEvent[] | undefined| null {
    if (!events) return events;

    const piEvents: { [url: string]: boolean } = {};
    for (var evt of events) {
        if (evt.source === "pi" && evt.webLink) {
            piEvents[evt.webLink] = true;
        }
    }

    return events.filter((x) => x.source === "pi" || !x.webLink || !piEvents[x.webLink]);
}

export async function getMyCalendar(start: Date, end: Date): Promise<api.EntityOpenSlots> {
    var client = new api.CalendarApi(App().apiConfig);
    var calendar = await client.calendarGetMyCalendar(start, end);
    // calendar.events = dedup(calendar.events);

    return calendar;
}


export function setWorkingHours(startMinutes: number, endMinutes: number): Promise<api.WorkingHoursSetting> {
    return setting().settingSetWorkingHours({
        startMinutes,
        endMinutes,
    });
}

export function getLeadIntegrations(id: string): Promise<api.LeadIntegration[]> {
    return integrationLead().integrationLeadGetIntegrations(id);
}

export function getUserIdentities(): Promise<api.Identity[]> {
    return identity().identityIdentity();
}

export function saveLeadTypeSettings(id: string, mapping: api.LeadTypeSettings): Promise<api.LeadTypeSettings> {
    return leadType().leadTypeAddMapping(id, mapping);
}

export function getLeadTypeMapping(id: string): Promise<api.LeadTypeSettings> {
    // return leadType().getMapping(id);
    return leadType().leadTypeCalculateMapping(id);
}

export function getLeadTypeSettings(id: string): Promise<api.LeadTypeSettings> {
    return leadType().leadTypeGetMapping(id);
}

export function getLeadType(id: string): Promise<api.LeadType> {
    return leadType().leadTypeGetById(id);
}

export function addAppointmentType(name: string): Promise<api.AppointmentType> {
    return appointmentType().appointmentTypeAdd(name);
}

export function getAppointmentType(id: any): Promise<api.AppointmentType> {
    return appointmentType().appointmentTypeGetById(id);
}

export function getAvailabilityStats(id?: string|null): Promise<api.AvailabilityStats> {
    if (id) {
        return new api.StatsApi(App().apiConfig).statsGetAvailabilityForOrg(id);
    }

    return new api.StatsApi(App().apiConfig).statsGetOrgAvailability();
}

export function getLeadAggregation(id?: string): Promise<api.LeadAggregation> {
    if (id) {
        return new api.StatsApi(App().apiConfig).statsAggregateLeadsForOrg(id);
    }

    return new api.StatsApi(App().apiConfig).statsGetLeadsPerDay();
}

export function getLeadAggregationForAccount(): Promise<api.LeadAggregation> {
    return new api.StatsApi(App().apiConfig).statsGetLeadsPerDay();
}

export function getLeadsPerHourAggregation(id?: string): Promise<api.LeadAggregation> {
    if (id) {
        return new api.StatsApi(App().apiConfig).statsAggregateLeadsForOrgByHour(id);
    }

    return new api.StatsApi(App().apiConfig).statsGetLeadsPerHour();
}

export function getLeadsPerHourAggregationForAccount(id?: string): Promise<api.LeadAggregation> {
    return new api.StatsApi(App().apiConfig).statsGetLeadsPerHour();
}

export function getLeadEvents(leadId: string): Promise<object[]> {
    // return new api.AuditApi(getApiConfig()).auditLeadAsymc(leadId);
    return Promise.reject("not implemented");
}
