import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { StyleSheet, css } from "aphrodite";
import { useState } from "react";
import { formatDateTimeShort } from "../../Timeslot/dateTimeUtils";

export interface IConfirmAppointmentDialogProps {
    date?: Date;
    onBack(): void;
    onConfirm(note: string): void;
    title: string;
    timeZone?: string;
    showNote?: boolean
}

export function ConfirmAppointmentDialog(props: IConfirmAppointmentDialogProps) {
    const { date, onBack, onConfirm, title, timeZone, showNote } = props;
    const [note, setNote] = useState("");

    const onNoteChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (event) => setNote(event.target.value);

    const onClickConfirm = () => {
        onConfirm(note);
        setNote("");
    };

    return (
        <Dialog title={title} open fullWidth maxWidth={"sm"}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {!!date && <div className={css(styles.date)}>{formatDateTimeShort(date, timeZone)}&nbsp;({timeZone})</div>}
                {showNote && <TextField id="filled-multiline-static" label="Note" multiline rows={4} variant="filled" fullWidth onChange={onNoteChange} value={note} />}
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onBack}>Back</Button>
                <Button sx={{ color: "#fff" }} variant="contained" onClick={onClickConfirm}>Confirm</Button>
            </DialogActions>
        </Dialog>
    );
}

const styles = StyleSheet.create({
    date: {
        fontWeight: "bold",
        marginBottom: 12,
    },
});
