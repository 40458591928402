import React from "react";

import { Stack } from "@mui/material";
import { DataView } from "src/pi/ui/DataView";
import { Loading } from "src/pi/ui/material/Loading";
// import { FilterComponent } from "./FilterComponent";
import { observer } from "mobx-react";
import { LocalFilterComponent } from "src/pi/ui/material/DataViewComponent/LocalFilterComponent";

interface IProps {
    dataView: DataView;
    fieldNames: string[];
}

interface IState {
    isLoading: boolean;
}

@observer
export class LocalFilterPanel extends React.Component<IProps, IState> {
    filtered: string[] = [];

    constructor(props: IProps) {
        super(props);

        this.state = {
            isLoading: false,
        };
    }

    componentDidUpdate(prevProps: IProps) {
        if (this.props.dataView !== prevProps.dataView || this.props.fieldNames !== prevProps.fieldNames) {
            this.filtered = [];
        }
    }

    render() {
        const { dataView } = this.props;
        const { isLoading } = this.state;

        const { filteredFields, filterableFields } = dataView;

        return (
            <Stack spacing={1}>
                {filteredFields.map((x) => (
                    <LocalFilterComponent key={x} dataView={dataView} fieldName={x} />
                ))}
                {filterableFields
                    .filter((x) => filteredFields.indexOf(x) < 0)
                    .map((x) => (
                        <LocalFilterComponent key={x} dataView={dataView} fieldName={x} />
                    ))}
                {isLoading && <Loading />}
            </Stack>
        );
    }
}
