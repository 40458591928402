import React from "react";
import { Form } from "src/pi/ui";

const FormContext = React.createContext<Form | undefined>(undefined);

interface Props {
    form: Form;
    children: React.ReactNode;
}

export const FormProvider = (props: Props) => {
    return <FormContext.Provider value={props.form}>{props.children}</FormContext.Provider>;
};

export const useForm = () => {
    const value = React.useContext(FormContext);
    if (!!value) {
        return value;
    }
    throw new Error("Invalid context. Are you missing a FormProvider?");
};
