import * as React from "react";
import * as chartjs from "chart.js";
import { Bar } from "react-chartjs-2";

import "./styles.css";
import { Card, LinearProgress } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/UnfoldMore";
import ExpandLess from "@mui/icons-material/UnfoldLess";

export interface IBarStyle {
    backgroundColor?: string;
    borderColor?: string;
    borderWidth?: number;
    hoverBackgroundColor?: string;
    hoverBorderColor?: string;
}

interface Props {
    title: string;
    data?: chartjs.ChartData;
    onClick?: () => any;
    onElementsClick?: (e: IElementClick[]) => any;
    isSelected?: boolean;
    height?: number;
}

export interface IElementClick {
    data?: any;
    dsIndex: number;
    dsLabel: string;
    label: string;
    index: number;
}

export const colors = [
    "33, 150, 243",
    "150, 150, 243",
    "150, 33, 243",
    "243, 33, 150",
    "243, 150, 150",
    "243, 150, 33",
    "33, 243, 150",
    "150, 243, 150",
    "150, 243, 33",
    "150, 243, 33",
    "33, 243, 33",
    "33, 243, 150",
];

export function initDataSet(label: string, style: IBarStyle): chartjs.ChartDataSets {
    const ds = {
        ...style,
        label: label,
        borderWidth: 1,
        data: [],
    };

    return ds;
}

export function getStyle(rgbColor: string): IBarStyle {
    const style: IBarStyle = {
        backgroundColor: `rgba(${rgbColor}, .2`,
        borderColor: `rgba(${rgbColor}, 1)`,
        hoverBackgroundColor: `rgba(${rgbColor}, .5)`,
        hoverBorderColor: `rgba(${rgbColor}, 1)`,
    };

    return style;
}

interface IChartElement {
    _datasetIndex: number;
    _index: number;
}

export class BarChart extends React.Component<Props> {
    onClick = () => {
        const { onClick } = this.props;
        if (onClick) onClick();
    };

    onElementsClick = (e: IChartElement[]) => {
        if (!e || e.length < 1 || !this.props.onElementsClick) return;
        if (!this.props.data || !this.props.data.datasets || !this.props.data.labels) return;

        const labels = this.props.data.labels;
        const datasets = this.props.data.datasets;

        const hits = e.map((hit) => {
            const dataset = datasets[hit._datasetIndex];
            if (!dataset || !dataset.data || !dataset.label || !labels) return undefined;

            const index = hit._index;
            return {
                data: dataset.data[index],
                dsIndex: hit._datasetIndex as number,
                dsLabel: dataset.label as string,
                label: labels[index] as string,
                index: index as number,
            };
        });

        var list = hits.filter((x) => x) as IElementClick[];
        this.props.onElementsClick(list);
    };

    render() {
        const { isSelected, data, title, onClick, height } = this.props;

        if (!data) {
            return (
                <Card className="DashboardBarChart">
                    {title}
                    <LinearProgress style={{ width: "100%" }} />
                </Card>
            );
        }

        return (
            <Card className="DashboardBarChart" raised={isSelected}>
                <div className="DashboardBarChart_title" onClick={this.onClick}>
                    <span>{title}</span>
                    {onClick && (isSelected ? <ExpandLess /> : <ExpandMoreIcon />)}
                </div>
                <div className="DashboardBarChart_graph">
                    <Bar
                        data={data}
                        width={100}
                        height={height || 250}
                        onElementsClick={this.onElementsClick}
                        options={{
                            maintainAspectRatio: false,
                            scales: {
                                xAxes: [
                                    {
                                        stacked: true,
                                    },
                                ],
                                yAxes: [
                                    {
                                        stacked: true,
                                    },
                                ],
                            },
                            tooltips: {
                                mode: "index",
                                intersect: false,
                            },
                        }}
                    />
                </div>
            </Card>
        );
    }
}
