import * as api from "@crochik/pi-api";
import { Base } from "./api";

class MetaDataClient extends Base {
    protected get api() {
        return new api.MetaDataApi(this.getConfig());
    }

    all(): Promise<api.EntityMetadata[]> {
        return this.api.metaDataGetAll();
    }

    getForOrg(organizationId: string): Promise<api.EntityMetadata[]> {
        return this.api.metaDataGetByOrganization(organizationId);
    }

    addToOrg(organizationId: string, list: api.EntityMetadata[]): Promise<api.EntityMetadata[]> {
        return this.api.metaDataUpsertToOrganization(organizationId, list);
    }

    removeFromOrg(organizationId: string, list: api.EntityMetadata[]): Promise<api.EntityMetadata[]> {
        return this.api.metaDataDeleteFromOrganization(organizationId, list);
    }
}

export const metaData = new MetaDataClient();
