import * as React from "react";
import { FieldProps } from "./FieldProps";

import { TextFieldProps } from "@mui/material/TextField";

export abstract class InputTextField extends React.Component<FieldProps<string>> {
    protected onChange = (event: any) => {
        let value: string | null = event.target.value;
        if (value?.trim() === "") {
            value = null;
        }

        if (this.props.onChange) {
            this.props.onChange(this.props.field, value);
        }
    };

    protected onKeyPressed = (event: any) => {
        if (event.key === "Enter" && this.props.onEnterPressed) {
            if (this.props.onEnterPressed(this.props.field)) {
                event.preventDefault();
            }
        }
    };

    protected calcProps(): TextFieldProps {
        const field = this.props.field;

        var props: TextFieldProps = {
            required: field.isRequired,
            id: field.name!,
            label: field.label ?? field.name!,
            disabled: this.props.disabled,
            error: this.props.error ? true : false,
            value: this.props.value ?? "",
            onChange: this.onChange,
            onKeyPress: this.onKeyPressed,
            helperText: this.props.error ? this.props.error : null,
            margin: "normal",
            style: {
                ...this.props.field.style,
                ...this.props.style
            },
            autoFocus: this.props.autoFocus,

            InputLabelProps: { shrink: true }
        };

        return props;
    }
}
