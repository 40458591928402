import { observer } from "mobx-react";
import * as React from "react";
import { toJS } from "mobx";
import { FieldProps } from "../FieldProps";
import { Form } from "../../../Form";
import { IField, IObjectFieldOptions, ITextFieldOptions, TYPE } from "../../../../context/IForm";
import { factory } from "../Factory";

import IconButton from "@mui/material/IconButton";
import { FieldTypeIcon } from "../../FormBody/FormCell/DragAndDrop/FieldTypeIcon";
import { useEffect, useState } from "react";
import { MultiSelectFieldOptions } from "@crochik/pi-api";

export interface IProps extends FieldProps {
    form: Form;
}

function GenericFieldF(props: IProps) {
    const { form, field, disabled, value, onChange } = props;

    const initialType = Array.isArray(value) ? TYPE.ARRAY : (typeof value === "object" ? TYPE.OBJECT : TYPE.TEXT);
    const [type, setType] = useState<TYPE>(initialType);
    const [currField, setCurrField] = useState<IField>();

    const toggle = () => {
        if (type === TYPE.TEXT) {
            setType(TYPE.ARRAY);
        } else if (type === TYPE.ARRAY) {
            setType(TYPE.OBJECT);
        } else {
            setType(TYPE.TEXT);
        }
    };

    const updateFieldProps = (newType: TYPE) => {
        const getProps = () => {
            switch (newType) {
                case TYPE.TAGS:
                    return {
                        ...field,
                        t: "TagsField",
                        type: TYPE.TAGS
                    } satisfies IField;

                case TYPE.ARRAY:
                    return {
                        ...field,
                        t: "ArrayField",
                        type: TYPE.ARRAY
                    } satisfies IField;

                case TYPE.OBJECT:
                    return {
                        ...field,
                        t: "ObjectField",
                        type: TYPE.OBJECT,
                        options: {
                            t: "ObjectFieldOptions",
                            objectType: "*"
                        } as any
                    } satisfies IField;

                case TYPE.TEXT:
                default:
                    return {
                        ...field,
                        t: "TextField",
                        type: TYPE.TEXT,
                        options: {
                            multline: true,
                        } as any,
                    } satisfies IField;
            }
        };

        setCurrField(getProps());
    };

    const fieldProps: FieldProps | undefined = currField ? {
        field: currField,
        error: undefined,
        disabled,
        value,
        onChange
    } : undefined;

    useEffect(() => updateFieldProps(type), []);
    useEffect(() => updateFieldProps(type), [type]);

    return (
        <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
            <IconButton onClick={toggle} style={{ marginRight: "4px", marginTop: "4px", background: "#efefef" }}
                        disabled={disabled}>
                <FieldTypeIcon type={type} />
            </IconButton>

            <div style={{ width: "100%" }} key="key">
                {fieldProps && factory.render(form, fieldProps.field, fieldProps)}
            </div>
        </div>
    );
}

@observer
export class GenericField extends React.Component<IProps> {
    render() {
        const { value, field, ...other } = this.props;
        const v = toJS(value);

        return <GenericFieldF {...this.props} value={v} />;
    }
}